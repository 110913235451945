import React, { useState } from "react";
import { Modal, Space, Button, Form, Row, Alert, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import LegajoService from "services/LegajoService";
import { EstadoDetalleEnvio } from "enum/EstadoDetalleEnvio";

const ModalFirmar = ({ visible, setVisible, detalleEnvioId, setUrl, setEstadoDetalleEnvioId }) => {
	const [visibleMensajeError, setVisibleMensajeError] = useState(false);
	const [loading, setLoading] = useState(false);

	const onClickBtnFirmar = async (formulario) => {
		try {
			setLoading(true);

			formulario.detalleEnvioId = detalleEnvioId;
			let data = await LegajoService.firmarColaborador(formulario);
			if (data.claveCorrecta) {
				setVisible(false);
				setUrl(data.urlDocumentoFirmado);
				setEstadoDetalleEnvioId(EstadoDetalleEnvio.VISUALIZADO_FIRMADO);
				// setFirmaConformidad(false);
				// setLoading(false);

				// const newlegajoPendiente = [...legajosPendientes];
				// const legajo = newlegajoPendiente.find(
				//     (item) => item.id === filaSeleccionada
				// );
				// setEstadoDetalleEnvio(8);
				// setNombreColaborador(legajo.colaborador.nombreCompleto);
				// setFechaFirmaColaborador(data.fechaFirma);
			} else {
				setVisibleMensajeError(true);

				// notification.error({
				//     message: "Clave incorrecta",
				//     description:
				//         "La clave ingresada no coincide con la que se tiene registrada",
				// });
				// setLoading(false);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const onClickBtnCancelar = () => {
		setVisibleMensajeError(false);
		setVisible(false);
	};

	const onClickBtnCerrarMensajeError = () => {
		setVisibleMensajeError(false);
	};

	return (
		<Modal
			title="Firmar Documento"
			visible={visible}
			onCancel={onClickBtnCancelar}
			footer={null}
			destroyOnClose={true}
		>
			<Form layout="vertical" onFinish={onClickBtnFirmar}>
				{visibleMensajeError && (
					<Alert
						message="Clave ingresada incorrecta."
						type="error"
						closable
						onClose={onClickBtnCerrarMensajeError}
					/>
				)}

				<Form.Item
					label="Ingrese su clave"
					name="clave"
					rules={[
						{
							required: true,
							message: "Porfavor ingrese su clave",
						},
					]}
				>
					<Input.Password placeholder="Ingrese su clave" />
				</Form.Item>

				<Form.Item>
					<div style={{ display: "flex", flexDirection: "row" }}>
						<Button type="primary" htmlType="submit" loading={loading} block style={{ marginRight: "5px" }}>
							Aceptar
						</Button>

						<Button onClick={onClickBtnCancelar} block disabled={loading} style={{ marginLeft: "5px" }}>
							Cancelar
						</Button>
					</div>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ModalFirmar;
