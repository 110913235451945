import { atom } from "jotai";

export const visibleModalCompletarInformacionPersonalAtom = atom(false);
export const visibleModalAgregarExperienciaLaboralAtom = atom(false);
export const visibleModalCompetenciasLaboralesAtom = atom(false);
export const visibleModalPreguntasAtom = atom(false);
export const visibleModalConfirmarEntrevistaAtom = atom(false);
export const visibleModalEntrevistasRealizadasAtom = atom(false);

export const detalleEntrevistaAtom = atom(undefined);

export const competenciasAtom = atom([]);
export const posicionCompetenciaActualAtom = atom(0);

export const preguntasAtom = atom([]);
export const posicionPreguntaActualAtom = atom(0);

export const visibleModalCargaArchivosAtom = atom(false);
export const indicadorCargarHojaVidaAtom = atom(false);
export const indicadorCargarPsicotestAtom = atom(false);
export const indicadorCargarEvaluacionConocimientoAtom = atom(false);

export const loadingEntrevistaAtom = atom(false)
