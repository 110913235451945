import React from "react";
import { Provider as JotaiProvider } from "jotai";
import {
	ListaSolicitudesPendientesAprobacion,
	ModalAprobarSolicitudAusencia,
	ModalRechazarSolicitudAusencia,
} from "./componentes";
import { VisorPdf } from "./VisorPdf";
import { ModalVerDocumentoAdjunto } from "./componentes/ModalVerDocumentoAdjunto";

const SolicitudesPendientesAprobarPage = () => {
	return (
		<JotaiProvider>
			<ListaSolicitudesPendientesAprobacion />
			<VisorPdf />
			<ModalAprobarSolicitudAusencia />
			<ModalRechazarSolicitudAusencia />
			<ModalVerDocumentoAdjunto />
		</JotaiProvider>
	);
};

export default SolicitudesPendientesAprobarPage;
