import React, { useContext } from "react";
import { Modal } from "antd";
import { cssVisorPdf, cssBodyVisorPdf } from "./estilos";
import { Header } from "./Header";
import { Body } from "./Body";
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";

export function VisorPdf() {
	const { visibleVisorPdf, cerrarVisorPdf } = useContext(MisDocumentosContext);

	function onCerrarModal() {
		cerrarVisorPdf();
	}

	return (
		<React.Fragment>
			<Modal
				title={null}
				visible={visibleVisorPdf}
				onCancel={onCerrarModal}
				bodyStyle={cssBodyVisorPdf}
				style={cssVisorPdf}
				closable={false}
				footer={null}
				destroyOnClose={true}
				className="milegajo-ant-modal"
				width="calc(100vw - 60px)"
				height="calc(100vh - 60px)"
			>
				<Header />
				<Body />
			</Modal>
		</React.Fragment>
	);
}
