import React, { useEffect, useContext } from "react";
import { Avatar, Button, Dropdown, List, Menu, Space } from "antd";
import { CalendarOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Provider as JotaiProvider, useAtom } from "jotai";
import {
	solicitudAusenciaSeleccionadaAtom,
	solicitudesAtom,
	visibleModalAprobarSolicitudAusenciaAtom,
	visibleModalRechazarSolicitudAusenciaAtom,
} from "./SolicitudesPendientesAprobarStore";
import { ApiResponse } from "enum";
import { SolicitudAusenciaService } from "services";
import moment from "moment";
import { ModalAprobarSolicitudAusencia, ModalRechazarSolicitudAusencia } from "./componentes";
import { SecurityContext } from "context/SecurityContextProvider";

const SolicitudesPendientesAprobarPage = () => {
	return (
		<JotaiProvider>
			<Header />
			<Solicitudes />
			<ModalAprobarSolicitudAusencia />
			<ModalRechazarSolicitudAusencia />
		</JotaiProvider>
	);
};

const Solicitudes = () => {
	const [solicitudes, setSolicitudes] = useAtom(solicitudesAtom);
	const [, setSolicitudSeleccionada] = useAtom(solicitudAusenciaSeleccionadaAtom);
	const [, setVisibleModalAprobarSolicitud] = useAtom(visibleModalAprobarSolicitudAusenciaAtom);
	const [, setVisibleModalRechazarSolicitud] = useAtom(visibleModalRechazarSolicitudAusenciaAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isMaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const isMaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	useEffect(() => {
		const cargarSolicitudes = async () => {
			const response = await SolicitudAusenciaService.listarPorJefeInmediato({ estado: "PENDIENTE" });
			if (response.estado === ApiResponse.EXITO) {
				console.log("response.data", response.data);
				setSolicitudes(response.data);
			}
		};

		cargarSolicitudes();
	}, []);

	const onClickBtnAprobar = (solicitud) => {
		setSolicitudSeleccionada(solicitud);
		setVisibleModalAprobarSolicitud(true);
	};

	const onClickBtnRechazar = (solicitud) => {
		setSolicitudSeleccionada(solicitud);
		setVisibleModalRechazarSolicitud(true);
	};

	return (
		<List
			itemLayout="horizontal"
			dataSource={solicitudes}
			renderItem={(item) => (
				<List.Item style={{ padding: "12px" }}>
					<List.Item.Meta
						avatar={<CalendarOutlined style={{ fontSize: "24px" }} />}
						title={item.tipo.descripcion}
						description={
							<div>
								<div>
									{moment(item.fechaInicioAusencia).format("DD/MM/YYYY hh:mm a")} {" - "}
									{moment(item.fechaFinAusencia).format("DD/MM/YYYY hh:mm a")} ({item.diasSolicitados}{" "}
									días)
								</div>
								<div>{item.colaborador.nombreCompleto}</div>
								{(isMaccaferriConstruction || isMaccaferriDePeru) && <div>{item.comentario}</div>}
								<div style={{ marginTop: "5px" }}>
									<Space>
										<Button
											type="primary"
											shape="round"
											size="small"
											onClick={() => onClickBtnAprobar(item)}
										>
											Aprobar
										</Button>
										<Button
											type="danger"
											shape="round"
											size="small"
											onClick={() => onClickBtnRechazar(item)}
										>
											Rechazar
										</Button>
									</Space>
								</div>
							</div>
						}
					/>
				</List.Item>
			)}
		/>
	);
};

const Header = () => {
	const history = useHistory();
	const onClickBtnRetornar = () => {
		history.push("/vacaciones/solicitudes");
	};

	return (
		<div className="container-movil-pdf-header">
			<div style={{ width: "100%", display: "flex", alignItems: "center" }} onClick={onClickBtnRetornar}>
				<ArrowLeftOutlined style={{ fontSize: "20px", marginRight: "8px" }} />
				Solicitudes Pendientes de Aprobación
			</div>
		</div>
	);
};

export default SolicitudesPendientesAprobarPage;
