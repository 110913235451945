import { useEffect } from "react";
import { Form } from "antd";
import { useAtom } from "jotai";
import { frmRenovacionAtom } from "../../BandejaEvaluacionStorage";
import { useState } from "react";

const useRenovacion = () => {
	const [frmRenovacion, setFrmRenovacion] = useAtom(frmRenovacionAtom);
	const [mostrarMesesRenovacion, setMostrarMesesRenovacion] = useState(true);
	const [form] = Form.useForm();

	useEffect(() => {
		if (!frmRenovacion) {
			setFrmRenovacion(form);
		}
	}, []);

	const onRenovarContratoChange = (valor) => {
		if (valor === "SI") {
			setMostrarMesesRenovacion(true);
		} else {
			setMostrarMesesRenovacion(false);
		}
	};

	return { frmRenovacion, onRenovarContratoChange, mostrarMesesRenovacion };
};

export default useRenovacion;
