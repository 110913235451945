import React, { useState } from "react";
import { ContratosPendientesRenovacionTab, SeguimientoContratosTab } from "./componentes";
import { Card } from "antd";
import LoginService from "services/LoginService";

const parametros = window.location.search;
const buscadorParametros = new URLSearchParams(parametros);

const RenovacionContratoPageWeb = () => {
	const sesionTab = buscadorParametros.get("sesionTab");
	const [activeTabKey, setActiveTabKey] = useState(sesionTab ? sesionTab : "pendientes-renovacion");
	let nombreTabRenovacion = "Contratos pendientes de renovación";

	if (LoginService.isAdministradorObra()) {
		nombreTabRenovacion = "Contratos pendientes de notificación";
	}

	const tabs = [
		{
			key: "pendientes-renovacion",
			tab: nombreTabRenovacion,
		},
		{
			key: "seguimiento-contratos",
			tab: "Seguimiento de contratos",
		},
	];

	const listaTabs = {
		"pendientes-renovacion": <ContratosPendientesRenovacionTab />,
		"seguimiento-contratos": <SeguimientoContratosTab />,
	};

	const onTabChange = (key) => {
		setActiveTabKey(key);
	};

	return (
		<Card
			tabList={tabs}
			activeTabKey={activeTabKey}
			onTabChange={(key) => {
				onTabChange(key);
			}}
			// bodyStyle={{ height: "calc(100vh - 185px)", padding: "25px" }}
		>
			{listaTabs[activeTabKey]}
		</Card>
	);
};

export default RenovacionContratoPageWeb;
