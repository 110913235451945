import React, { useContext, useEffect, useState } from 'react';
import { Form, Modal, Input, notification } from "antd";
// import * as atoms from "store/local/contratos/DetalleSeguimientoContratoStore";
import { useAtom } from "jotai";
// import DetalleEnvioService from "services/DetalleEnvioService";
// import { SeguimientoService } from 'services/SeguimientoService';
import { SecurityContext } from 'context/SecurityContextProvider';
import {documentosAtom, consultaDocumentoSeleccionadoAtom,visibleModalActualizarCorreoAtom} from "../../ConsultaDocumentoStore"

const ModalActualizarCorreo = () => {

    const [form] = Form.useForm();

    const { getUsuarioAutenticado } = useContext(SecurityContext);

    const [loading, setLoading] = useState(false);
    const [consultaDocumento, setConsultaDocumento] = useAtom(documentosAtom);
    const [consultaDocumentoSeleccionado] = useAtom(consultaDocumentoSeleccionadoAtom);
    const [visibleModalActualizarCorreo, setVisibleModalActualizarCorreo] = useAtom(visibleModalActualizarCorreoAtom);

    const onClickBtnActualizar = () => {
        form
            .validateFields()
            .then(async (values) => {
                //form.resetFields();
                try {
                    setLoading(true);

                    const data = {
                        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
                        detalleEnvioId: consultaDocumentoSeleccionado.id,
                        correoElectronico: values.correoElectronico,
                        numeroCelular: values.numeroCelular
                    }

                    console.log("data", data);

                    // const respuesta = await SeguimientoService.actualizarCorreoYCelular(data);

                    // actualizarInformacionEnTablaDetalle(data.detalleEnvioId, data.correoElectronico, data.numeroCelular);

                    notification.success({
                        message: "Operación exitosa",
                        description: "Se actualizó el correo electrónico correctamente.",
                    });

                } catch (error) {
                    console.error(error);
                } finally {
                    setVisibleModalActualizarCorreo(false);
                    setLoading(false);
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const actualizarInformacionEnTablaDetalle = (detalleId, correoElectronico, numeroCelular) => {
        const _documentos = [...consultaDocumento.data]; //siempre se debe generar un nuevo array
        const indice = consultaDocumento.data.findIndex((detalle) => detalle.id === detalleId);
        const detalleModificar = consultaDocumento.data[indice];
        
        const detalleModificado = {
            ...detalleModificar,
            colaborador: {
                nombreCompleto:detalleModificar.colaborador.nombreCompleto,
                numeroDocumentoIdentidad:detalleModificar.colaborador.numeroDocumentoIdentidad,
                correoElectronico:correoElectronico,
                numeroCelular: numeroCelular
            }
        }
        _documentos.splice(indice, 1, detalleModificado);
        setConsultaDocumento({...consultaDocumento,data:_documentos});
    }

    const onClickBtnCerrar = () => {
        setVisibleModalActualizarCorreo(false);
    }

    useEffect(() => {
        form.resetFields();
    }, [consultaDocumentoSeleccionado]);

    return (
        <Modal
            title="Actualizar Correo"
            getContainer={false}
            visible={visibleModalActualizarCorreo}
            onOk={onClickBtnActualizar}
            onCancel={onClickBtnCerrar}
            okText="Actualizar"
            cancelText="Cancelar"
            confirmLoading={loading}
            centered
        >

            <Form
                form={form}
                layout="vertical"
                name="frmActualizarCorreo"
                initialValues={{
                    correoElectronico: consultaDocumentoSeleccionado?.colaborador?.correoElectronico,
                    numeroCelular: consultaDocumentoSeleccionado?.colaborador?.numeroCelular
                }}
            >
                <Form.Item
                    name="correoElectronico"
                    label="Correo Electronico"
                    rules={[
                        {
                            required: true,
                            message: 'Campo correo electrónico es obligatorio.',
                        },
                        {
                            type: 'email',
                            message: "Correo electrónico ingresado no es válido.",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item name="numeroCelular" label="Celular">
                    <Input />
                </Form.Item>

            </Form>


        </Modal>
    );
};

export default ModalActualizarCorreo;