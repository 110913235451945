import React, { useEffect } from "react";
import { solicitudesPersonalAtom } from "../store/MisSolicitudesPersonalStore";
import { useAtom } from "jotai";
import SolicitudPersonalService from "services/reclutamiento-y-seleccion/SolicitudPersonalService";

const useListarSolicitudPersonal = () => {

    const [solicitudesPersonal, setSolicitudesPersonal] = useAtom(solicitudesPersonalAtom)
    useEffect(() => { 
        const cargarDatos = async () => {
            const respuesta = await SolicitudPersonalService.listar();
            setSolicitudesPersonal(respuesta);
        }
        cargarDatos();
    },[])
    return{solicitudesPersonal}
    
}
export default useListarSolicitudPersonal;