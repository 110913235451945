export const misDocumentosReducer = (state, action) => {
    switch (action.type) {
        case 'SET_DOCUMENTOS':
            return {
                ...state,
                documentos: action.payload
            }

        case 'SET_DOCUMENTO_SELECCIONADO':
            return {
                ...state,
                documentoSeleccionado: action.payload
            }

        case 'SET_URL_PDF':
            return {
                ...state,
                urlPdf: action.payload
            }

        case 'INICIAR_CARGA_PDF':
            return {
                ...state,
                loadingPdf: true
            }

        case 'FINALIZAR_CARGA_PDF':
            return {
                ...state,
                loadingPdf: false
            }

        case 'MOSTRAR_VISOR_PDF':
            return {
                ...state,
                visibleVisorPdf: true
            }

        case 'CERRAR_VISOR_PDF':
            return {
                ...state,
                visibleVisorPdf: false
            }

        case 'MOSTRAR_VISOR_FORMULARIO':
            return {
                ...state,
                visibleVisorFormulario: true
            }

        case 'CERRAR_VISOR_FORMULARIO':
            return {
                ...state,
                visibleVisorFormulario: false
            }

        case 'MOSTRAR_MODAL_COMPLETAR_FORMULARIO':
            return {
                ...state,
                visibleModalCompletarFormulario: true
            }

        case 'CERRAR_MODAL_COMPLETAR_FORMULARIO':
            return {
                ...state,
                visibleModalCompletarFormulario: false
            }

        case 'MOSTRAR_MODAL_FIRMAR':
            return {
                ...state,
                visibleModalFirmar: true
            }

        case 'CERRAR_MODAL_FIRMAR':
            return {
                ...state,
                visibleModalFirmar: false
            }

        case 'MOSTRAR_MODAL_EXITO':
            return {
                ...state,
                visibleModalExito: true
            }

        case 'CERRAR_MODAL_EXITO':
            return {
                ...state,
                visibleModalExito: false
            }
        
        case 'CERRAR_MODAL_CONSULTAR':
            return {
                ...state,
                visibleModalConsultar: false
            }
        
        case 'MOSTRAR_MODAL_CONSULTAR':
            return {
                ...state,
                visibleModalConsultar: true
            }

        case 'SET_MENSAJE_ERROR':
            return {
                ...state,
                mensajeError: action.payload
            }

        case 'SET_BLOB_PDF':
            return {
                ...state,
                blobPdf: action.payload
            }

        case 'INICIAR':
            return {
                ...state,
                loading: true
            }

        case 'FINALIZAR':
            return {
                ...state,
                loading: false
            }

        case 'SET_DOCUMENTO_SELECCIONADO_ID':
            return {
                ...state,
                documentoSeleccionadoId: action.payload
            }

        case 'SET_RELOAD_PDF':
            return {
                ...state,
                reloadPdf: action.payload
            }

        case 'SET_DATOS_FORMULARIO':
            return {
                ...state,
                datosFormulario: action.payload
            }
        case 'SET_CONSULTAS':
            return {
                ...state,
                consultas:action.payload
            }
        case 'MOSTRAR_MODAL_UPLOAD':
            return {
                ...state,
                visibleModalUpload:true
            }
        case 'CERRAR_MODAL_UPLOAD':
            return {
                ...state,
                visibleModalUpload:false
            }

        default:
            return state;

    }
}