import React from "react";
import { Button, Modal, Upload, notification } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import ImgCrop from "antd-img-crop";
import { useState } from "react";
import { documentoSeleccionadoAtom, documentosAtom, rutaSubirDocumentoAtom } from "pages/onboarding/OnboardingStore";
import LoginService from "services/LoginService";
import { useMediaQuery } from "react-responsive";
import { Headers } from "enum";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { Plataforma } from "enum/Plataforma";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import produce from "immer";
import { EstadoAprobacion } from "enum/EstadoAprobacion";

const SubirImagen = () => {
	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
	const setDocumentos = useSetAtom(documentosAtom);
	const setRutaSubirDocumentos = useSetAtom(rutaSubirDocumentoAtom);
	const [uid, setUid] = useState("");
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });

	const props = {
		name: "file",
		multiple: true,
		action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/cargarDocumentos",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
		},
		data: {
			detalleEnvioId: documentoSeleccionado.id,
			uid: uid,
		},
		beforeUpload(file) {
			setUid(file.uid);
			return file;
		},
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const onChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
	};

	const onClickCargarDocumento = async () => {
		setUploading(true);
		const datosSubida = {
			listaUids: fileList.map((c) => c.uid),
			detalleEnvioId: documentoSeleccionado.id,
			tipoCarga: "IMAGEN",
		};

		const data = await PortalColaboradorService.generarPDFArchivosSubidos(datosSubida);
		if (data === true) {
			setDocumentoSeleccionado({
				...documentoSeleccionado,
				estadoVistaColaborador: EstadoVistaColaborador.FIRMADO,
				estadoAprobacion: EstadoAprobacion.PENDIENTE
			});

			setDocumentos(
				produce((draft) => {
					const documento = draft.find((documento) => documento.id === documentoSeleccionado.id);
					documento.estadoVistaColaborador = EstadoVistaColaborador.FIRMADO;
					documento.estadoAprobacion = EstadoAprobacion.PENDIENTE;
				})
			);

			notification.success({
				description: "Documento Cargado",
				message: "Documento Cargado Correctamente",
			});
			//getDocumentos();
			//onCerrarModal();
			setRutaSubirDocumentos("home");
		} else {
			notification.error({
				description: "Error al cargar",
				message: "Ha ocurrido un error",
			});
		}
		setUploading(false);
	};

	const handleCancel = () => setPreviewVisible(false);

	return (
		<div>
			{/* <ImgCrop rotate minZoom={0.5} aspect={2 / 1} cropperProps={{ restrictPosition: false }} grid> */}
			<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
				<Upload
					{...props}
					listType="picture-card"
					fileList={fileList}
					onChange={onChange}
					onPreview={handlePreview}
					accept={esMovil ? "application/pdf" : undefined}
				>
					{"+ Subir Imágen"}
				</Upload>
			</ImgCrop>

			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<Button
					type="primary"
					onClick={onClickCargarDocumento}
					disabled={fileList.length === 0}
					loading={uploading}
					size="large"
					style={{
						borderRadius: "5px",
					}}
				>
					{uploading ? "Cargando" : "Enviar Documentos"}
				</Button>
			</div>
			<Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} destroyOnClose>
				<img alt="example" style={{ width: "100%" }} src={previewImage} />
			</Modal>
		</div>
	);
};

export default SubirImagen;
