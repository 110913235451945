import React from "react";
import { Container } from "components/Container";
import { Button } from "antd";
import { TablaSolicitudes } from "./componentes/TablaSolicitudes";
import { useAtom } from "jotai";
import { visibleModalCrearSolicitudAtom } from "../commons/store/MisSolicitudesDocumentosStore";
import { ModalCrearSolicitudDocumento } from "../commons/componentes/ModalCrearSolicitudDocumento";

const SolicitudDocumentosPageWeb = () => {
	const [, setVisibleModal] = useAtom(visibleModalCrearSolicitudAtom);

	const onChangeSolicitar = () => {
		setVisibleModal(true);
	};

	return (
		<React.Fragment>
			<Container
				titulo="Mis Solicitudes Documentos"
				botonExtra={
					<Button type="primary" onClick={onChangeSolicitar}>
						Crear solicitud
					</Button>
				}
			>
				<TablaSolicitudes />
			</Container>
			<ModalCrearSolicitudDocumento />
		</React.Fragment>
	);
};
export default SolicitudDocumentosPageWeb;
