import React from "react";
import { Input, Button, Modal, Form, message } from "antd";
import { useAtom } from "jotai";
import { documentoSeleccionadoAtom, documentosAtom, visibleModalOmitirAtom } from "pages/onboarding/OnboardingStore";
import { useState } from "react";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import produce from "immer";

const ModalOmitir = () => {
	const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
	const [, setDocumentos] = useAtom(documentosAtom);
	const [visible, setVisible] = useAtom(visibleModalOmitirAtom);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const onClickBtnFirmar = async () => {
		try {
			setLoading(true);
			form.validateFields()
				.then(async (datos) => {
					await PortalColaboradorService.omitirDocumento({
						detalleEnvioId: documentoSeleccionado.id,
						motivo: datos.motivo,
					});

					setDocumentoSeleccionado(undefined);

					setDocumentos(
						produce((draft) => {
							const indice = draft.findIndex((documento) => documento.id === documentoSeleccionado.id);
							if (indice !== -1) {
								draft.splice(indice, 1); // Eliminar el documento del array
							}
						})
					);

					form.resetFields();
					setVisible(false);

					message.success("Se omitió el documento con éxito.");
				})
				.catch((info) => {
					console.log("Validate Failed:", info);
				});
		} finally {
			setLoading(false);
		}
	};

	const onClickBtnCancelar = () => {
		form.resetFields();
		setVisible(false);
	};

	return (
		<Modal
			title="Omitir Documento"
			open={visible}
			closable={false}
			width={400}
			destroyOnClose={true}
			footer={[
				<Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
					Cancelar
				</Button>,
				<Button
					key="firmar"
					type="primary"
					loading={loading}
					onClick={onClickBtnFirmar}
					style={{ padding: "0px 30px" }}
				>
					Enviar
				</Button>,
			]}
			centered
		>
			<Form
				form={form}
				layout="vertical"
				name="form_in_modal"
				initialValues={{
					modifier: "public",
				}}
			>
				<Form.Item name="motivo" label="Motivo" rules={[{ required: true }]}>
					<Input.TextArea />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ModalOmitir;
