import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { TareasPendientesService } from "services";
import { tareasPendientesAtom } from "pages/tareas-pendientes/TareasPendientesStorage";

const useListarTareasPendientes = () => {

	const [tareasPendientes, setTareasPendientes] = useAtom(tareasPendientesAtom);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const cargarDatos = async () => {
			fetchTareasPendientes();
		};
		cargarDatos();
	}, []);

	const fetchTareasPendientes = async () => {
		const respuesta = await TareasPendientesService.listar();
		setTareasPendientes(respuesta);
		setLoading(false);
	}

	const onSearchTareasPendientes = async (filtro) => {
		if (filtro === "") {
			fetchTareasPendientes();
		} else {
			const tareasPendientes = await TareasPendientesService.listar();
			setTareasPendientes(
				tareasPendientes.filter(tarea =>
					tarea?.colaborador?.nombreCompleto.toLowerCase().includes(filtro.toLowerCase()))
			);
		}
	}

	return {
		tareasPendientes,
		loading,
		// fetchTareasPendientes,
		onSearchTareasPendientes
	};
};

export default useListarTareasPendientes;
