import React, { useEffect, useContext } from "react";
import { Modal, Form, Input } from "antd";
import { useAtom } from "jotai";
import { visibleModalContactoEmergenciaAtom, colaboradorAtom } from "../../../PerfilStore";
import { SecurityContext } from "context/SecurityContextProvider";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { useCargarColaborador } from "pages/perfil/componentes/common/hooks";
const ModalEditarContactoEmergencia = () => {
	const [visibleModal, setVisibleModal] = useAtom(visibleModalContactoEmergenciaAtom);
	const [formulario] = Form.useForm();
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const { cargarColaborador } = useCargarColaborador();
	const [colaborador] = useAtom(colaboradorAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			formulario.setFieldsValue({
				contactoEmergencia: {
					nombres: colaborador.contactoEmergencia?.nombres,
					telefono: colaborador.contactoEmergencia?.telefono,
				},
			});
		};

		cargarDatos();
	}, [colaborador]);

	const handleCancel = async () => {
		setVisibleModal(false);
		formulario.resetFields();
	};
	const handleActualizarContactoEmergencia = async (colaborador) => {
		colaborador.datosPersonales = {
			numeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
		};
		await PortalColaboradorService.actualizarContactoEmergencia(colaborador);
		cargarColaborador();
		handleCancel();
	};
	return (
		<Modal
			title="Editar Contacto Emergencia"
			open={visibleModal}
			onOk={() => formulario.submit()}
			onCancel={handleCancel}
		>
			<Form
				layout="horizontal"
				labelCol={{
					span: 4,
				}}
				wrapperCol={{
					span: 20,
				}}
				form={formulario}
				onFinish={handleActualizarContactoEmergencia}
				initialValues={{
					contactoEmergencia: {
						nombres: colaborador.contactoEmergencia?.nombres,
						telefono: colaborador.contactoEmergencia?.telefono,
					},
				}}
			>
				<Form.Item
					label="Nombre"
					name={["contactoEmergencia", "nombres"]}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Teléfono"
					name={["contactoEmergencia", "telefono"]}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};
export default ModalEditarContactoEmergencia;
