import React from "react";
import { Input, Button, Modal, Form, message } from "antd";
import { useAtom } from "jotai";
import { useState } from "react";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import produce from "immer";
import { openModalOmitirAtom } from "../SubirDocumentoStorage";

const ModalOmitir = ({ tareaSeleccionada, onSuccess }) => {
    const [open, setOpen] = useAtom(openModalOmitirAtom);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onClickBtnEnviar = async () => {
        try {
            setLoading(true);
            form.validateFields()
                .then(async (datos) => {
                    await PortalColaboradorService.omitirDocumento({
                        tareaId: tareaSeleccionada.id,
                        detalleEnvioId: tareaSeleccionada.procesoId,
                        motivo: datos.motivo,
                    });
                    onSuccess();
                    message.success("Se omitió el documento con éxito.");
                })
                .catch((info) => {
                    console.log("Validate Failed:", info);
                });
        } finally {
            setLoading(false);
        }
    };

    const onClickBtnCancelar = () => {
        form.resetFields();
        setOpen(false);
    };

    return (
        <Modal
            title="Omitir Documento"
            open={open}
            closable={false}
            width={400}
            destroyOnClose={true}
            footer={[
                <Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
                    Cancelar
                </Button>,
                <Button
                    key="firmar"
                    type="primary"
                    loading={loading}
                    onClick={onClickBtnEnviar}
                    style={{ padding: "0px 30px" }}
                >
                    Enviar
                </Button>,
            ]}
            centered
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    modifier: "public",
                }}
            >
                <Form.Item name="motivo" label="Motivo" rules={[{ required: true }]}>
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalOmitir;
