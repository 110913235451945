import React, { useState, useEffect } from "react";
import { Button, Result } from "antd";


const LinkExpirado = () => {
    const cerrarVentanaButton = () =>
    {
        try {
            window.close()
        } catch {
            console.log("no se pudo")
        } finally {
            window.location.href='https://www.google.com';
        }
        
        }
    return (
        <div style={{ display:'block' ,margin: 0,  position: 'absolute',  left: '50%',  transform: 'translate(-50%, 50%)'}} ><Result
        status="error"
        title="Link Invalido"
        subTitle="Por favor contacta con el área de soporte."
        extra={[
            <Button key='close' type="primary" onClick={cerrarVentanaButton}>
                Cerrar ventana
            </Button>,
        ]}
    ></Result></div>)
};
export default LinkExpirado;