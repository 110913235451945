import { HttpService } from "../HttpService";
import { URL } from "enum";
const URL_API = URL.RECLUTAMIENTO_Y_SELECCION;

const SolicitudPersonalService = {

     async insertar(solicitudPersonal){
        const respuesta= await HttpService(URL_API).post("/solicitudPersonal",solicitudPersonal);
        return respuesta.data;
    },
    async listar(){
        const respuesta = await HttpService(URL_API).get("/portal-colaborador/solicitudPersonal");
        return respuesta.data;
    },
    async cancelar(data) {
    const respuesta = await HttpService(URL_API).put("/solicitudPersonal/cancelar", data);
    return respuesta.data;
  },
    
}
export default SolicitudPersonalService;