import React from "react";
import { Container } from "components/Container";
import { PlusOutlined } from '@ant-design/icons';
import { Button } from "antd";
import { visibleModalCrearSolicitudAtom } from "../common/store/MisSolicitudesPersonalStore";
import { ModalCrearSolicitudPersonal, } from "../common";
import { useAtom } from "jotai";
import { TablaSolicitudPersonal } from "./componentes";
import { ModalCancelarSolicitud } from "../common/componentes";
const SolicitudPersonalPageWeb = () => {

    const [,setVisibleModal] = useAtom(visibleModalCrearSolicitudAtom);

    const onClickSolicitarPersonal = () => {

        setVisibleModal(true);
     }

    return (
        <React.Fragment>
            <Container titulo="Mis solicitudes" botonExtra={<Button type="primary" icon={<PlusOutlined /> } onClick={onClickSolicitarPersonal}>Solicitar Personal</Button>}>
              <TablaSolicitudPersonal />              
           </Container>
             <ModalCancelarSolicitud/>
             <ModalCrearSolicitudPersonal />
           
        </React.Fragment>
        
  )    
}
export default SolicitudPersonalPageWeb;