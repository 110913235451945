import { useEffect } from "react";
import { Form } from "antd";
import { useAtom } from "jotai";
import { frmObservacionResultadosAtom } from "../../BandejaEvaluacionStorage";

const useObservacionResultado = () => {
	const [frmObservacionResultados, setFrmObservacionResultados] = useAtom(frmObservacionResultadosAtom);
	const [form] = Form.useForm();

	useEffect(() => {
		if (!frmObservacionResultados) {
			setFrmObservacionResultados(form);
		}
	}, []);

	return { frmObservacionResultados };
};

export default useObservacionResultado;
