import React from "react";
import {
    EstadoConsulta
} from "enum/EstadoConsulta";
import { WechatOutlined,CheckCircleOutlined } from "@ant-design/icons";
import * as atoms from "../BandejaConsultaMovilStorage";
import { useAtom } from "jotai";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import ModalVerConsulta from "./ModalVerConsulta";
import { Badge, Popconfirm } from "antd";
import * as atomsConsulta from "../../../../../hooks/consultas/ConsultasStorage";

export const ItemConsulta = ({ consulta }) => {
    const [visibleModalConsulta, setVisibleModalConsulta] = useAtom(atoms.visibleModalVerConsultaAtom);
    const [consultaSeleccionada, setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);
    const [detalleConsulta, setDetalleConsulta] = useAtom(atoms.detalleConsultaAtom);
    const [numeroRespuestas, setNumeroRespuestas] = useAtom(atoms.numeroRespuestasAtom);
    const [consultas, setConsultas] = useAtom(atoms.consultasAtom);
    const [totalRespuestasUsuario, setTotalRespuestasUsuario] = useAtom(atomsConsulta.totalRespuestasUsuarioAtom);
    
    const onClickSeleccionarItem = async () => {        
        setVisibleModalConsulta(true);
        setConsultaSeleccionada(consulta);        
        const _detallesconsulta = await PortalColaboradorService.listaDetalleConsultas(consulta.id);         
        setDetalleConsulta(_detallesconsulta);
        const consultaEnviada = {
            consultaColaboradorId: consulta.id,
            tipoDetalle:"respuestaUsuario",
        }
        await PortalColaboradorService.cambiarEstadoVisualizacionDetalleConsulta(consultaEnviada);
         let listaConsultasId = [];       
         if (consultas.length > 0) {
            
            consultas.map((consulta) => {
                listaConsultasId.push(consulta.id);
            })
            const respuestasXConsulta = await PortalColaboradorService.listaNumeroRespuestas(listaConsultasId, "respuestaUsuario");
             setNumeroRespuestas(respuestasXConsulta);
             let totalRespuestas = 0;
             respuestasXConsulta.length > 0 && respuestasXConsulta.map((respuesta) => {
                 respuesta.numeroRespuestas > 0 && totalRespuestas++;
              })
            setTotalRespuestasUsuario(totalRespuestas);
        }
    }
   
    const classNameEstado =
		consulta.estadoConsulta === EstadoConsulta.RESUELTA ? " pendiente" : " visualizado";
    return (
        <React.Fragment>
            <div className={"bandeja-entrada__item-documento" + classNameEstado} onClick={onClickSeleccionarItem}>
                <div className={"imagen" + classNameEstado}>
                    {consulta.estadoConsulta === EstadoConsulta.RESUELTA && <CheckCircleOutlined />}
                    {(consulta.estadoConsulta == EstadoConsulta.ABIERTO || consulta.estadoConsulta===null) && <WechatOutlined />}
                </div>
                <div className="informacion">
                    <span className="item-documento__tipo">
                        {consulta.asunto !== null ? consulta.asunto :
                            consulta.documentoNombre+" - "+consulta.periodo
                        }
                    </span>
                    {/* <span className={"item-documento__estado"+ classNameEstado}>
                        {consulta.estadoConsulta}
                    </span> */}
                    
                </div>
                <div style={{ width: "50%", textAlign: "right", paddingRight: "10px" }}>
                     {numeroRespuestas.length>0 && numeroRespuestas.map((respuesta) => {
                        return(
                             consulta.id === respuesta.consultaColaboradorId && (
                                <Badge
                                  count={respuesta.numeroRespuestas}                                    
                                />
                                  
                               
                              )
                        )
                    })}   
                    
                    
				</div>

            </div>
            {/* <ModalVerConsulta/> */}
        </React.Fragment>
    );
};