import styled from "@emotion/styled";
import React from "react";

const ListaColaboradores = () => {
	return <Container>Colaboradores!!!</Container>;
};

const Container = styled.div`
	width: 25%;
	border: 1px solid #ccc;
`;

export default ListaColaboradores;
