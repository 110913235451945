import React, { useContext } from "react";
import { PlantillaWeb } from "../pages/plantilla-web";
import { Route, Switch, BrowserRouter, Redirect, useHistory } from "react-router-dom";
import { DashboardPage } from "../pages/dashboard";
import { LegajoListarVisualizadosPage } from "../pages/legajo/listar";
import { LegajoListarNoVisualizadosPage } from "../pages/legajo/listar/LegajoListarNoVisualizados";
import { ActualizarClavePage } from "../pages/cuenta/actualiza-clave";
import { CambiarClavePage } from "../pages/cuenta/actualiza-clave/cambiarClave";
import { GenerarClavePage } from "../pages/cuenta/generar-clave";
import { BandejaEntradaPage } from "../pages/bandeja-entrada";
import { CargarDocumentoPage } from "../pages/bandeja-entrada/CargarDocumento";
import { BandejaEntregaDetallePage } from "../pages/bandeja-entrada-detalle";
import { MisDocumentosPage } from "../pages/mis-documentos";
import { MisFormulariosPage, CompletarFormularioPage as CompletarFormularioGenericoPage } from "pages/mis-formularios";
import { VisorPdfPage } from "pages/mis-documentos/componentes/MisDocumentosMovil/VisorPdf";
import { VisorFormularioPdf } from "pages/mis-documentos/componentes/MisDocumentosMovil/VisorFormularioPdf";
import { CompletarFormularioPage } from "pages/mis-documentos/componentes/CompletarFormularioPage";
import { EnvioDocumentosPage, TareasPendientesPage } from "pages";
import { RedireccionarOnboarding, RedireccionarPaginaPrincipal } from "components";
import { ListarBandejaConsultaPage } from "pages/bandeja-consultas";
import { ListarBandejaEvaluacionPage } from "pages/bandeja-evaluacion";
import { SolicitudAusenciaPage } from "pages/vacaciones";
import { CargarDocumentosPage } from "pages/mis-documentos/componentes/MisDocumentosMovil/CargarDocumentosPage";
import { SolicitudPersonalPage } from "pages/solicitud-personal";
import { EntrevistasPage } from "pages/entrevistas";
import { DetalleEntrevistaPage } from "pages/detalle-entrevista";
import { PerfilColaboradorPage } from "pages/configuracion";
import { OnboardingPage } from "pages/onboarding";
import { PerfilPage } from "pages/perfil";
import { RenovacionContratosPage } from "pages/contratos";
import { SolicitudesPendientesAprobarPage } from "pages/vacaciones/componentes/VacacionesPageMovil/componentes";
import { DetallePerfilMovilPage, PerfilMovilPage } from "pages/movil";
import { SolicitudDocumentosPage } from "pages/solicitud-documentos";
import { ConsultaDocumentoPage } from "pages/consulta-documento";
import { ValidarArchivosSubidosPageWeb } from "pages/onboarding/componentes/OnboardingPageWeb/componentes/ValidarArchivosSubidos";
import { RutasAppV3 } from "./RutasAppV3";
import { VisorFormularioPdfV3 } from "pages/v3/mis-pendientes/componentes/MisDocumentosMovil/VisorFormularioPdfV3";
import { VisorPdfPageV3 } from "pages/v3/mis-pendientes/componentes/MisDocumentosMovil/VisorPdf";
import { CargarDocumentosPageV3 } from "pages/v3/mis-pendientes/componentes/MisDocumentosMovil/CargarDocumentosPage";
import {
	MiPortalPage,
	MisPendientesPage,
	MisDocumentosPage as MisDocumentosPageV3,
	OnboardingPageV3
} from "pages/v3";
import { DetalleTareaPendientePageMovil } from "pages/tareas-pendientes/componentes";

export function RutasApp({ isOnboarding }) {

	return (
		<BrowserRouter>
			<Switch>

				<Route
					exact
					path="/bandejaEntrada/detalle/:documentoDigitalId&:numeroDocumentoIdentidad&:visualizado&:empresaId&:estadoProcesoId"
					component={BandejaEntregaDetallePage}
				/>
				<Route exact path="/mis-documentos/visor-pdf/:documentoId" component={VisorPdfPage} />
				<Route
					exact
					path="/mis-documentos/visor-pdf/:documentoId/:visibleModalExitoParam"
					component={VisorPdfPage}
				/>
				<Route
					exact
					path="/mis-documentos/visor-formulario-pdf/:formularioId/:detalleEnvioId"
					component={VisorFormularioPdf}
				/>

				<Route exact path="/movil/entrevistas/detalle/:entrevistaId" component={DetalleEntrevistaPage} />

				<Route exact path="/movil/tareas-pendientes/:tareaPendienteId" component={DetalleTareaPendientePageMovil} />

				<Route
					exact
					path="/v3/mis-pendientes/visor-formulario-pdf/:formularioId/:detalleEnvioId"
					component={VisorFormularioPdfV3}
				/>

				<Route exact path="/v3/mis-pendientes/visor-pdf/:documentoId" component={VisorPdfPageV3} />

				<Route
					exact
					path="/v3/mis-pendientes/visor-pdf/:documentoId/:visibleModalExitoParam"
					component={VisorPdfPageV3}
				/>
				<Route
					exact
					path="/v3/mis-pendientes/carga-documentos/:documentoId"
					component={CargarDocumentosPageV3}
				/>

				<Route
					exact
					path="/v3/onboarding"
					component={OnboardingPageV3}
				/>

				<Route>
					<PlantillaWeb>
						<Switch>
							{isOnboarding && <Route component={OnboardingPage} />}

							<Route exact path="/dashboard" component={DashboardPage} />
							{/* <Route exact path="/onboarding" component={OnboardingPage} /> */}
							<Route
								exact
								path="/legajo/listarNoVisualizados"
								component={LegajoListarNoVisualizadosPage}
							/>
							<Route exact path="/legajo/listarVisualizados" component={LegajoListarVisualizadosPage} />
							<Route exact path="/actualizarClave" component={ActualizarClavePage} />
							<Route exact path="/cambiarClave" component={CambiarClavePage} />
							<Route exact path="/bandejaEntrada" component={BandejaEntradaPage} />
							<Route exact path="/mis-documentos" component={MisDocumentosPage} />
							<Route exact path="/validar-archivos-subidos" component={ValidarArchivosSubidosPageWeb} />
							<Route exact path="/tareas-pendientes" component={TareasPendientesPage} />
							<Route exact path="/envio-documentos" component={EnvioDocumentosPage} />
							<Route
								exact
								path="/mis-documentos/:documentoId/formulario/:formularioId/completar"
								component={CompletarFormularioPage}
							/>
							<Route exact path="/mis-formularios" component={MisFormulariosPage} />
							<Route
								exact
								path="/mis-formularios/:formularioId/completar"
								component={CompletarFormularioGenericoPage}
							/>
							<Route
								exact
								path="/bandejaEntrada/cargarDocumento/:tipoDocumentoId&:tipoDocumentoFormato&:tipoDocumentoNombre"
								component={CargarDocumentoPage}
							/>
							<Route exact path="/bandejaEntrada/cargarDocumento" component={CargarDocumentoPage} />
							<Route exact path="/generarClave" component={GenerarClavePage} />
							<Route exact path="/bandejaConsulta" component={ListarBandejaConsultaPage} />
							<Route exact path="/bandejaEvaluacion" component={ListarBandejaEvaluacionPage} />
							<Route exact path="/vacaciones/solicitudes" component={SolicitudAusenciaPage} />
							<Route
								exact
								path="/mis-documentos/carga-documentos/:documentoId"
								component={CargarDocumentosPage}
							/>
							<Route exact path="/solicitud-personal" component={SolicitudPersonalPage} />
							<Route exact path="/entrevistas" component={EntrevistasPage} />
							<Route exact path="/entrevistas/detalle/:entrevistaId" component={DetalleEntrevistaPage} />
							{/* <Route exact path="/movil/configuracion/perfil" component={PerfilColaboradorPage} /> */}
							<Route
								exact
								path="/movil/configuracion/seleccionar-empresa"
								component={PerfilColaboradorPage}
							/>

							<Route exact path="/contratos/renovacion" component={RenovacionContratosPage} />

							<Route exact path="/perfil" component={PerfilPage} />
							<Route exact path="/movil/perfil" component={PerfilMovilPage} />
							<Route exact path="/movil/perfil/detalle" component={DetallePerfilMovilPage} />

							<Route
								exact
								path="/movil/vacaciones/solicitudes-pendientes-aprobar"
								component={SolicitudesPendientesAprobarPage}
							/>
							<Route exact path="/solicitud-documentos" component={SolicitudDocumentosPage} />

							<Route exact path="/consulta-documentos" component={ConsultaDocumentoPage} />

							<Route
								exact
								path="/v3/mi-portal"
								component={MiPortalPage}
							/>

							<Route
								exact
								path="/v3/mis-documentos"
								component={MisDocumentosPageV3}
							/>

							<Route
								exact
								path="/v3/mis-documentos/carpeta/:carpetaId"
								component={MisDocumentosPageV3}
							/>

							<Route
								exact
								path="/v3/mis-pendientes"
								component={MisPendientesPage}
							/>

							<Route component={RedireccionarPaginaPrincipal} />

						</Switch>
					</PlantillaWeb>
				</Route>
			</Switch>
		</BrowserRouter>
	);
}
