import React from "react";
import { Button } from "antd";
import { visibleModalCrearSolicitudAtom } from "pages/solicitud-personal/componentes/common/store/MisSolicitudesPersonalStore";
import { useAtom } from "jotai";
import logotipo from "../../../../../../assets/imagenes/logotipo.png";
import "./Header.css";

const Header = () => {
    
    const [, setVisibleModalCrearSolicitud] = useAtom(visibleModalCrearSolicitudAtom);
    return (
        <div className="container-movil-pdf-header">
			<div style={{ width: "60%" }}>
				<img src={logotipo} alt="" style={{ height: "36px", marginRight: "10px" }} />
				Solicitudes de Personal
			</div>
			<div style={{ width: "40%", textAlign: "right", paddingRight: "10px" }}>
				
					<Button type="primary" onClick={() => setVisibleModalCrearSolicitud(true)}>
						Solicitar Personal
					</Button>					
				
			</div>
		</div>
    )

}
export default Header;