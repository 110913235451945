import React, { useContext, useEffect } from "react";
import { Dropdown, Menu, Button, Modal, notification,Popconfirm } from "antd";
import {
  SearchOutlined,  
  UnorderedListOutlined,
  CaretDownOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router";
import { useAtom } from "jotai";
import * as atoms from "../BandejaConsultaStorage"
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { ConsultaService } from "services/ConsultaService";
import { EstadoConsulta } from "enum/EstadoConsulta";
import * as atomsConsulta from "../../../../../hooks/consultas/ConsultasStorage";

const MenuAcciones = ({ consulta }) => {
    const [visibleModalVerConsulta, setVisibleModalVerConsulta] = useAtom(atoms.visibleModalVerConsultaAtom);
    const [consultaSeleccionada, setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);
    const [, setDetallesConsulta] = useAtom(atoms.detalleConsultaAtom);
    const [consultas, setConsultas] = useAtom(atoms.consultasAtom);
    const [numeroRespuestas, setNumeroRespuestas] = useAtom(atoms.numeroRespuestasAtom);
    const [totalRespuestasUsuario, setTotalRespuestasUsuario] = useAtom(atomsConsulta.totalRespuestasUsuarioAtom);

    const obtenerNumeroDetalleNoVisualizadas =async (consultas) => {
        let listaConsultasId = [];       
      if (consultas.length > 0) {
          consultas.map((consulta) => {
            listaConsultasId.push(consulta.id);
          })
          const respuestasXConsulta = await PortalColaboradorService.listaNumeroRespuestas(listaConsultasId, "respuestaUsuario");
          setNumeroRespuestas(respuestasXConsulta);
          let totalRespuestas = 0;
          respuestasXConsulta.length > 0 && respuestasXConsulta.map((respuesta) => {
            respuesta.numeroRespuestas > 0 && totalRespuestas++;
          })
          setTotalRespuestasUsuario(totalRespuestas);
          
      }
    }

  const onClickBtnVerConsulta = async (consulta) => {    
        setVisibleModalVerConsulta(true);
        setConsultaSeleccionada(consulta);
        const _detallesconsulta = await PortalColaboradorService.listaDetalleConsultas(consulta.id);         
        setDetallesConsulta(_detallesconsulta);
        const consultaEnviada = {
            consultaColaboradorId: consulta.id,
            tipoDetalle:"respuestaUsuario",
        }
        await PortalColaboradorService.cambiarEstadoVisualizacionDetalleConsulta(consultaEnviada);
        obtenerNumeroDetalleNoVisualizadas(consultas);
        
    }
    const onConfirmCerrarConsulta = async (consultaId) => {
        
        await ConsultaService.cambiarEstadoConsulta(consultaId);

        setConsultas(consultas.map((consulta) => {
            if (consulta.id === consultaId) {
                return { ...consulta, estadoConsulta: "RESUELTA" };
            }
            return consulta;
        }))
    }
    const onClickMenu = (opcion) => {
    switch (opcion.key) {
      case "ver-consulta":
        onClickBtnVerConsulta(consulta);
        break;

      case "cerrar-consulta":
      
        break;
      
      default:
       
    }
  };
    const menu = () => {
        
            return (
                <Menu onClick={onClickMenu}>
                    
                        <Menu.Item key="ver-consulta">
                            <SearchOutlined /> Ver Consulta
                </Menu.Item>
                {(consulta.estadoConsulta === EstadoConsulta.ABIERTO || consulta.estadoConsulta ===null) && (
                   <Popconfirm
                        title="Estás seguro de cerrar consulta"
                        onConfirm={()=>onConfirmCerrarConsulta(consulta.id)}
                        okText="Si"
                        cancelText="No"
                    >
                        <Menu.Item key="cerrar-consulta">
                            <CloseOutlined /> Cerrar Consulta
                        </Menu.Item>
                   </Popconfirm>
                  
                )}                    
                   
                </Menu>
            );
        
    }
  return (
    <React.Fragment>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomCenter">
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
          <CaretDownOutlined
            style={{
              paddingLeft: "2px",
              fontSize: "12px",
              fontWeight: "bold",
              color: "#1678c1",
            }}
          />
        </a>
      </Dropdown>
    </React.Fragment>
  );
};
export default MenuAcciones;
