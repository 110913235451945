import React, { useContext, useState } from "react";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, notification, Upload } from "antd";
import LoginService from "services/LoginService";
import { Headers } from "enum";
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";
import { PortalColaboradorService } from "services/PortalColaboradorService";

export function CargarPDF({ onCerrarModal }) {
	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const { documentoSeleccionadoId, getDocumentos } = useContext(MisDocumentosContext);
	const [texto, setTexto] = useState("");
	const datos = {
		detalleEnvioId: documentoSeleccionadoId,
		uid: texto,
	};

	const props = {
		name: "file",
		multiple: true,
		action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/cargarDocumentos",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
		},
		data: datos,
		beforeUpload(file) {
			setTexto(file.uid);
			return file;
		},
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
	};

	const onChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

	const onClickCargarDocumento = async () => {
		setUploading(true);
		const datosSubida = {
			listaUids: fileList.map((c) => c.uid),
			detalleEnvioId: documentoSeleccionadoId,
			tipoCarga: "PDF",
		};
		const data = await PortalColaboradorService.generarPDFArchivosSubidos(datosSubida);
		if (data === true) {
			notification.success({
				description: "Documento Cargado",
				message: "Documento Cargado Correctamente",
			});
			getDocumentos();
			onCerrarModal();
		} else {
			notification.error({
				description: "Error al cargar",
				message: "Ha ocurrido un error",
			});
		}
		setUploading(false);
	};

	return (
		<React.Fragment>
			<Upload.Dragger {...props} onChange={onChange} fileList={fileList} accept="application/pdf">
				<p className="ant-upload-drag-icon">
					<PlusOutlined />
				</p>
				<p>Seleccionar Archivo PDF</p>
			</Upload.Dragger>
			<Button
				type="primary"
				onClick={onClickCargarDocumento}
				disabled={fileList.length === 0}
				block
				loading={uploading}
				style={{
					marginTop: 16,
				}}
			>
				{uploading ? "Cargando" : "Enviar Documentos"}
			</Button>
		</React.Fragment>
	);
}
