import React, { useRef } from "react";
import { Alert, Tabs, Button, Modal, message, Upload } from "antd";
import { useState } from "react";
import { InboxOutlined } from '@ant-design/icons'
import SignaturePad from "react-signature-pad-wrapper";
import ImgCrop from "antd-img-crop";


export const ModalSubirFirma = ({ visible, onAceptar, onCerrar }) => {
    const [uploadKey, setUploadKey] = useState(Date.now());
    const [currentTab, setCurrentTab] = useState(1)
    const [loading, setLoading] = useState(false);
    const [firmaUploadLocal, setFirmaUploadLocal] = useState(undefined);
    const refSignature = useRef();

    const onClickBtnCancelar = () => {
        setCurrentTab(1)
        onCerrar();
    };

    const onClickBtnOk = async () => {
        try {
            setLoading(true);
            let firmaFile;
            if (currentTab == 1) {
                firmaFile = firmaUploadLocal;
            }
            else if (currentTab == 2) {
                const base64 = refSignature.current.isEmpty() ? undefined : refSignature.current.toDataURL();
                firmaFile = base64 ? base64ToFile(base64, "firma.png") : undefined;
            }

            if (firmaFile) {
                onAceptar([firmaFile]);
            }

            setCurrentTab(1);
            setLoading(false);

        } catch (error) {
            setLoading(false);

        }
    };
    const onClickBtnLimpiarFirma = () => {
        refSignature.current.clear();
    };

    const DrawSignature = () => {
        return (
            <React.Fragment>
                <SignaturePad
                    ref={refSignature}
                    canvasProps={{ style: { border: "1px dashed #ccc" } }}
                    options={{ minWidth: 2, maxWidth: 3, penColor: "#000" }}
                />
                <Button type="link" onClick={onClickBtnLimpiarFirma}>
                    Limpiar Firma
                </Button>
            </React.Fragment>
        )
    }


    const propsFirma = {
        onRemove: (_) => {
            setFirmaUploadLocal([]);

            return {
                firmaUploadLocal: [],
            };
        },
        beforeUpload: (file) => {
            if (file.type !== "image/png" && file.type !== "image/jpeg") {
                message.error(`${file.name} no es un archivo png ó jpeg`);
            } else {
                setFirmaUploadLocal(file);
                return false;
            }
        },
        firmaUploadLocal,
    };

    const base64ToFile = (base64, filename) => {
        const arr = base64.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    };

    const adjustCanvasSize = async () => {
        setTimeout(() => {
            const canvas = document.querySelector('canvas[data-testid="canvas-element"]');
            if (canvas && canvas.parentElement) {
                canvas.width = 472;
                canvas.height = 273;

            }
        }, 0);
    };


    const onChaneTab = (key) => {
        setFirmaUploadLocal(undefined);
        setUploadKey(Date.now());
        if (refSignature.current && key === 2) {
            adjustCanvasSize()
        }
        setCurrentTab(key)
    }

    return (
        <Modal
            title="Adjuntar Firma"
            open={visible}
            closable={false}
            destroyOnClose={true}
            footer={[
                <Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
                    Cancelar
                </Button>,
                <Button
                    key="firmar"
                    type="primary"
                    loading={loading}
                    onClick={onClickBtnOk}
                    style={{ padding: "0px 30px" }}
                >
                    Aceptar
                </Button>,
            ]}
            centered
        >
            <Alert message={(currentTab === 1 ? "Adjunte" : "Dibuje") + " la firma lo mas parecido a su documento de identidad"} />
            <Tabs
                activeKey={currentTab}
                onChange={onChaneTab}
                items={[
                    {
                        label: `Subir Imagen`,
                        key: 1,
                        children:
                            <ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
                                <Upload.Dragger key={uploadKey} accept={["image/*"]} {...propsFirma} maxCount={1} multiple={false} height={286} >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Presione o arrastre su imagen a esta área para subir su firma</p>
                                    <p className="ant-upload-hint">
                                        Por favor suba una imagen similar a la firma de su documento de identidad
                                    </p>
                                </Upload.Dragger>
                            </ImgCrop>
                    },
                    {
                        label: `Dibujar Firma`,
                        key: 2,
                        children: <DrawSignature />
                    },
                ]}
            />
        </Modal>
    );
};


