import { useEffect } from "react";
import { RenovacionContratosService } from "services/contratos";
import { useAtom } from "jotai";
import LoginService from "services/LoginService";
import {
	contratosAtom,
	paginaActualAtom,
	paginacionAtom,
	totalContratosAtom,
	filtroAtom,
	filtroColaboradorAtom,
	filtroFechaFinAtom,
	filtroProyectoAtom,
	filtroEstadoAtom,
	filtroRespRenovacionAtom,
	filtroFechaInicioAtom,
	filtroEstadoFirmaAtom,
	filtroEstadoColaboradorAtom,
	filtroTipoContratoAtom
} from "../store/SeguimientoContratoStore";

const useListarSeguimientoContratos = () => {
	const [contratosSeguimiento, setContratosSeguimiento] = useAtom(contratosAtom);
	const [paginaActual, setPaginaActual] = useAtom(paginaActualAtom);
	const [, setTotalContratos] = useAtom(totalContratosAtom);
	const [paginacion] = useAtom(paginacionAtom);
	//const [filtroSearch, setFiltroSearch] = useAtom(filtroAtom);
	const [filtroColaborador, setFiltroColaborador] = useAtom(filtroColaboradorAtom);
	const [filtroProyecto, setFiltroProyecto] = useAtom(filtroProyectoAtom);
	const [filtroRespRenovacion, setFiltroRespRenovacion] = useAtom(filtroRespRenovacionAtom);
	const [filtroEstado, setFiltroEstado] = useAtom(filtroEstadoAtom);
	const [filtroFechaFin, setFiltroFechaFin] = useAtom(filtroFechaFinAtom);
	const [filtroFechaInicio, setFiltroFechaInicio] = useAtom(filtroFechaInicioAtom);
	const [filtroEstadoFirma, setFiltroEstadoFirma] = useAtom(filtroEstadoFirmaAtom);
	const [filtroEstadoColaborador, setFiltroEstadoColaborador] = useAtom(filtroEstadoColaboradorAtom);
	const [filtroTipoContrato, setFiltroTipoContrato] = useAtom(filtroTipoContratoAtom);

	// useEffect(() => {
	// 	cargarContratos(paginaActual);
	// }, [filtroColaborador, filtroProyecto, filtroRespRenovacion, filtroEstado, filtroFechaFin, filtroFechaInicio, filtroEstadoFirma]);

	const cargarContratos = async (paginaActual) => {
		if (LoginService.isJefeProyecto()) {
			const response = await RenovacionContratosService.listarContratosPorJefeProyecto({
				filtroColaborador: filtroColaborador,
				filtroProyecto: filtroProyecto,
				filtroRespRenovacion: filtroRespRenovacion,
				filtroEstado: filtroEstado,
				filtroEstadoFirma: filtroEstadoFirma,
				filtroFechaInicio: filtroFechaInicio,
				filtroFechaFin: filtroFechaFin,
				filtroEstadoColaborador:filtroEstadoColaborador,
				filtroTipoContrato:filtroTipoContrato,
				pagina: paginaActual != undefined ? paginaActual : 0,
				cantidad: paginacion,
			});

			contratosSeguimiento({
				...contratosSeguimiento,
				contratos: response.contratos,
				paginaActual: response.paginaActual,
				totalContratos: response.totalContratos,
			});

			// setContratos(response.contratos);
			// setTotalContratos(response.totalContratos);
			// setPaginaActual(response.paginaActual + 1);
		} else if (LoginService.isAdministradorObra()) {
			console.log("AAAfiltroFechaFin", filtroFechaFin);
			const response =
				await RenovacionContratosService.listarContratosPendientesDeAprobarPorAdministradorObraSeguimiento({
					filtroColaborador: filtroColaborador,
					filtroProyecto: filtroProyecto,
					filtroRespRenovacion: filtroRespRenovacion,
					filtroEstado: filtroEstado,
					filtroEstadoFirma: filtroEstadoFirma,
					filtroFechaInicio: filtroFechaInicio,
					filtroFechaFin: filtroFechaFin,
					filtroEstadoColaborador:filtroEstadoColaborador,
					filtroTipoContrato:filtroTipoContrato,
					pagina: paginaActual,
					cantidad: paginacion,
				});

			// setContratos(response.contratos);
			// setTotalContratos(response.totalContratos);
			// setPaginaActual(response.paginaActual + 1);

			contratosSeguimiento({
				...contratosSeguimiento,
				contratos: response.contratos,
				paginaActual: response.paginaActual,
				totalContratos: response.totalContratos,
			});
		}
	};

	return { contratosSeguimiento, setContratosSeguimiento };
};

export default useListarSeguimientoContratos;
