import React from "react";
import logotipo from "../../../../../../assets/imagenes/logotipo.png";
import { Button } from "antd";
import "./Header.css";
import * as atoms from "../BandejaConsultaMovilStorage";
import { useAtom } from "jotai";
import ModalConsultar from "./ModalConsultar";

const Header = () => {
	const [visibleModalConsultar, setVisibleModalConsultar] = useAtom(atoms.visibleModalConsultaAtom);
	const onClickBtnConsultar = () => {
		setVisibleModalConsultar(true);
	}
	return (
		<React.Fragment>
				<div className="container-movil-pdf-header">
					<img
						src={logotipo}
						alt=""
						style={{ height: "36px", marginRight: "10px" }}
					/>
					Bandeja Consultas
					<div style={{ width: "100%", textAlign: "right", paddingRight: "10px" }}>
						<Button type="primary" onClick={onClickBtnConsultar}>
							Crear Consulta
						</Button>
					</div>
			</div>
			<ModalConsultar/>
		</React.Fragment>
	);
}

export default Header;