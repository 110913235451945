import { BaseService } from "./BaseService";
const LegajoService = {
  async listar(colaboradorNumeroDocumentoIdentidad, empresaId) {
    const respuesta = await BaseService.get("/colaborador/listarDocumentos", {
      params: {
        colaboradorNumeroDocumentoIdentidad: colaboradorNumeroDocumentoIdentidad,
        empresaId: empresaId,
      },
    });
    return respuesta.data;
  },
  async verPDF(fd) {
    const respuesta = await BaseService.post("/colaborador/verPDF", fd);
    return respuesta.data;
  },
  /* async buscarDocumentosPorColaborador(numeroDocumentoIdentidad, empresaId) {
    const respuesta = await BaseService.get(
      "/colaborador/buscarDocumentosPorColaborador",
      {
        params: {
          numeroDocumentoIdentidad: numeroDocumentoIdentidad,
          empresaId: empresaId,
        },
      }
    );
    return respuesta.data;
  }, */
  async listarPorVisualizados(
    colaboradorNumeroDocumentoIdentidad,
    empresaId,
    visualizado
  ) {
    const respuesta = await BaseService.get(
      "/colaborador/listarDocumentosVisualizados",
      {
        params: {
          colaboradorNumeroDocumentoIdentidad: colaboradorNumeroDocumentoIdentidad,
          empresaId: empresaId,
          visualizado: visualizado,
        },
      }
    );
    return respuesta.data;
  },
  async buscarPorId(id) {
    const respuesta = await BaseService.get(
      `/colaborador/buscarDocumentoPorId/${id}`
    );
    return respuesta.data;
  },
  async listarComunicaciones(numeroDocumentoIdentidad, empresaId) {
    const respuesta = await BaseService.get(
      "/colaborador/listarComunicaciones",
      {
        params: {
          numeroDocumentoIdentidad: numeroDocumentoIdentidad,
          empresaId: empresaId,
        },
      }
    );
    return respuesta.data;
  },
  async enviarDocumentos(formData) {
    const respuesta = await BaseService.post(
      "/enviarDocumentosCargados",
      formData
    );
    return respuesta.data;
  },
  async verPDFCargaDocumento(detalleEnvioId) {
    const respuesta = await BaseService.get(
      "/cargaDocumento/verPDFCargaDocumento",
      {
        params: {
          detalleEnvioId: detalleEnvioId,
        },
      }
    );
    return respuesta.data;
  },
  async listarDocumentosPendientesCarga(
    colaboradorNumeroDocumentoIdentidad,
    empresaId
  ) {
    const respuesta = await BaseService.get(
      "/colaborador/listarDocumentosPendientesCarga",
      {
        params: {
          colaboradorNumeroDocumentoIdentidad: colaboradorNumeroDocumentoIdentidad,
          empresaId: empresaId,
        },
      }
    );
    return respuesta.data;
  },
  async firmarColaborador(data) {
    const respuesta = await BaseService.post("/colaborador/firmarDocumentoColaborador", data);
    return respuesta.data;
  },
  async consultarColaborador(data) {
    const respuesta = await BaseService.post(
      "/colaborador/consultarColaborador",
      data
    );
    return respuesta.data;
  },
};
export default LegajoService;
