import React from "react";
import { Header, ListaEntrevistas } from "./componentes";

const EntrevistasPageMovil = () => {
	return (
		<React.Fragment>
			<Header />
			<ListaEntrevistas />
		</React.Fragment>
	);
};
export default EntrevistasPageMovil;
