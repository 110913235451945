import React from "react";
import { Button, Modal } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { visibleModalAlertaAtom } from "pages/mis-documentos/storage/MisDocumentosStorage";

const ModalMensajeAlerta = () => {
	const [visibleModal, setVisibleModal] = useAtom(visibleModalAlertaAtom);

	const onClickBtnAceptar = () => {
		setVisibleModal(false);
	};

	return (
		<Modal visible={visibleModal} onCancel={onClickBtnAceptar} centered={true} footer={null}>
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
				<WarningOutlined style={{ marginTop: "5px", fontSize: "48px", color: "#faad14" }} />
				<div style={{ fontSize: "18px", fontWeight: "bold", color: "#ad5c56", marginTop: "5px" }}>Aviso!</div>
				<div style={{ textAlign: "center", marginTop: "5px", padding: "0px 20px" }}>
					Para visualizar este documento debe firmar el documento anterior
				</div>
				<div style={{ marginTop: "20px" }}>
					<Button onClick={onClickBtnAceptar} type="primary">
						Aceptar
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ModalMensajeAlerta;
