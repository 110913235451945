import { atom } from "jotai";

export const solicitudesAtom = atom([]);
export const solicitudAusenciaSeleccionadaAtom = atom(undefined);
export const visibleModalAprobarSolicitudAusenciaAtom = atom(false);
export const visibleModalRechazarSolicitudAusenciaAtom = atom(false);
// export const solicitudesAusenciaPendientesAprobarAtom = atom([]);
// export const visibleModalAprobarSolicitudAusenciaAtom = atom(false);
// export const visibleModalRechazarSolicitudAusenciaAtom = atom(false);
// export const solicitudAusenciaSeleccionadaAtom = atom(undefined);
// export const visibleModalVerDocumentoAdjuntoAtom = atom(false);
// export const urlDocumentoAdjuntoAtom = atom("");
