import React from "react";
import { Modal,Upload,Button,message,Typography } from "antd";
import {
    visibleModalCargaArchivosAtom,
    indicadorCargarHojaVidaAtom,
	indicadorCargarPsicotestAtom,
    indicadorCargarEvaluacionConocimientoAtom,
    detalleEntrevistaAtom} from "pages/detalle-entrevista/DetalleEntrevistaStore";
import { useAtom } from "jotai";
import { UploadOutlined } from '@ant-design/icons';
import LoginService from "services/LoginService";
import { useParams } from "react-router-dom";
import { Headers }  from "enum";

const ModalCargaArchivos = () => {

    const [visible, setVisible] = useAtom(visibleModalCargaArchivosAtom);
    const { entrevistaId } = useParams();
    const [indicadorHojaVida, setIndicadorHojaVida] = useAtom(indicadorCargarHojaVidaAtom);
	const [indicadorPsicotest, setIndicadorPsicotest] = useAtom(indicadorCargarPsicotestAtom);
    const [indicadorEvaluacionConocimiento, setIndicadorEvaluacionConocimiento] = useAtom(indicadorCargarEvaluacionConocimientoAtom);
    const [detalleEntrevista, setDetalleEntrevista] = useAtom(detalleEntrevistaAtom);    

    const propsHojaVida = {        
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT_RECLUTAMIENTO_Y_SELECCION + "/entrevistas/entrevistador/cargar-hoja-vida",
		headers: {
		Authorization: "Bearer " + LoginService.getJwtToken(),
		[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
		[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
		[Headers.TIPO_USUARIO]: "COLABORADOR",
        [Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
		},
		data: {		
				entrevistaId: entrevistaId,			
		},		
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {				
				message.success(`${info.file.name} se subió exitosamente.`);
                setDetalleEntrevista({ ...detalleEntrevista, cargaHojaVidaCompleto: true }); 
                
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
		  console.log("Dropped files", e.dataTransfer.files);
		},
       
    };
    const propsPsicotest = {        
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT_RECLUTAMIENTO_Y_SELECCION + "/entrevistas/entrevistador/cargar-psicotest",
		headers: {
		Authorization: "Bearer " + LoginService.getJwtToken(),
		[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
		[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
		[Headers.TIPO_USUARIO]: "COLABORADOR",
        [Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
		},
		data: {		
				entrevistaId: entrevistaId,			
		},		
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {	
                message.success(`${info.file.name} se subió exitosamente.`);                
                setDetalleEntrevista({ ...detalleEntrevista, cargaPsicotestCompleto: true });
                
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
		   console.log("Dropped files", e.dataTransfer.files);
		},
       
	};
	const propsEvaluacionConocimiento = {        
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT_RECLUTAMIENTO_Y_SELECCION + "/entrevistas/entrevistador/cargar-evaluacion-conocimiento",
		headers: {
		Authorization: "Bearer " + LoginService.getJwtToken(),
		[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
		[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
		[Headers.TIPO_USUARIO]: "COLABORADOR",
        [Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
		},
		data: {		
				entrevistaId: entrevistaId,			
		},		
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {		
                message.success(`${info.file.name} se subió exitosamente.`);               
                setDetalleEntrevista({ ...detalleEntrevista, cargaEvaluacionConocimientoCompleto: true });
                
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
		  console.log("Dropped files", e.dataTransfer.files);
		},
       
    };
    const onCancel = () => {
        setVisible(false);      
        
    }

    return (
        <Modal
            title="Carga Archivos"			
			centered={true}
			maskClosable={false}
			visible={visible}							
            onCancel={onCancel}
            destroyOnClose
            footer={[
                <Button type="primary" onClick={onCancel}>Cerrar</Button>
            ]}
        
        >
            {indicadorHojaVida && (
                <div style={{marginBottom:"20px",textAlign:"center"}}>
                <Upload
                    {...propsHojaVida}
                    maxCount={1}
					accept={"application/pdf"}
                >
                        <Button icon={<UploadOutlined style={{ color: "#986E07" }} />} style={{ borderColor: "#986E07" }} shape="round">
                           <Typography.Text style={{color:"#986E07"}}>Cargar hoja de vida</Typography.Text>    
                        </Button>
               </Upload>
            </div>
            )}
            
            {indicadorPsicotest && (
                <div style={{marginBottom:"20px",textAlign:"center"}}>
                <Upload
                 {...propsPsicotest}
                    maxCount={1}
					accept={"application/pdf"}
                >
                        <Button icon={<UploadOutlined style={{ color: "#986E07" }} />} style={{ borderColor: "#986E07" }} shape="round">                           
                            <Typography.Text style={{color:"#986E07"}}> Cargar psicotest</Typography.Text>  
                        </Button>
                 </Upload>
            </div>
            )}

            {indicadorEvaluacionConocimiento && (
                 <div style={{marginBottom:"20px",textAlign:"center"}}>
                    <Upload
                        {...propsEvaluacionConocimiento}
                        maxCount={1}
                        accept={"application/pdf"}
                    >
                        <Button icon={<UploadOutlined style={{ color: "#986E07" }} />} style={{ borderColor: "#986E07" }} shape="round">                            
                            <Typography.Text style={{color:"#986E07"}}> Cargar evaluación de conocimiento</Typography.Text>
                        </Button>
                    </Upload>
                </div>
             )
            }          
              
        </Modal>
    )
}
export default ModalCargaArchivos;