import React from "react";
import { Provider as JotaiProvaider } from "jotai";
import { VerificarIdentidadPage } from "./componentes/verificar-identidad";
import { PlantillaAuth } from "./componentes/plantilla-auth";
import { CrearClavePage } from "./componentes";

const AuthLoginPage = () => {
	return (
		<JotaiProvaider>
			<PlantillaAuth>
				<VerificarIdentidadPage />
				<CrearClavePage />
			</PlantillaAuth>
		</JotaiProvaider>
	);
};

export default AuthLoginPage;
