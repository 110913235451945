export const TipoDocumentoIdentidad = Object.freeze({
	DNI: {
		valor: "DNI",
		nombre: "DNI",
	},
	CARNET_EXTRANJERIA: {
		valor: "CARNET_EXTRANJERIA",
		nombre: "CE",
		descripcion: "CARNET EXTRANJERIA",
	},
	CARNET__EXTRANJERIA: {
		valor: "CARNET__EXTRANJERIA",
		nombre: "CE",
		descripcion: "CARNET EXTRANJERIA",
	},
	PASAPORTE: {
		valor: "PASAPORTE",
		nombre: "PASAPORTE",
	},
	OTRO_DOCUMENTO: {
		valor: "OTRO_DOCUMENTO",
		nombre: "OD",
		descripcion: "OTRO DOCUMENTO",
  	},
});
