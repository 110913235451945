import React, { useState } from "react";
import { Table, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useListarSolicitudesDocumentos } from "pages/solicitud-documentos/componentes/commons/hooks";
import { saveAs } from "file-saver";
import { PortalColaboradorService } from "services/PortalColaboradorService";
const TablaSolicitudes = () => {
	const { solicitudes } = useListarSolicitudesDocumentos();

	const onClickDescargarDocumento = async (fila) => {
		const respuesta = await PortalColaboradorService.obtenerUrlSolicitudDocumentoPersonal(fila?.id);
		saveAs(
			respuesta.urlSolicitud,
			fila?.tipoDocumentoNombre + "-" + fila?.colaborador.numeroDocumentoIdentidad + ".pdf"
		);
	};
	const columnas = [
		{
			title: "Nombre",
			dataIndex: "tipoDocumentoNombre",
			align: "center",
		},

		{
			title: "Fecha Solicitud",
			dataIndex: "fechaHoraSolicitud",
			align: "center",
		},

		{
			title: "Acciones",
			key: "acciones",
			align: "center",
			render: (fila) => (
				<Button type="link" onClick={() => onClickDescargarDocumento(fila)}>
					<DownloadOutlined />
					Descargar
				</Button>
			),
		},
	];
	return (
		<Table rowKey={(solicitud) => solicitud.id} columns={columnas} dataSource={solicitudes} pagination={false} />
	);
};
export default TablaSolicitudes;
