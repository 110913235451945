import React from "react";
import { useMediaQuery } from "react-responsive";
import { Provider as JotaiProvider } from "jotai";
import { EntrevistasPageWeb, EntrevistasPageMovil } from "./componentes";

const EntrevistasPage = () => {
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

	return (
		<JotaiProvider>
			{esMovil && <EntrevistasPageMovil />}
			{esDesktop && <EntrevistasPageWeb />}
		</JotaiProvider>
	);
};
export default EntrevistasPage;
