import { notification } from "antd";
import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import EvaluacionService from "services/EvaluacionService";
import {
	evaluacionesPendientesAtom,
	evaluacionPendienteSeleccionadaAtom,
	visibleModalEvaluacionPendienteAtom,
	visibleModalFirmarEvaluacionAtom,
} from "../../BandejaEvaluacionStorage";
import useModalEvaluacion from "./useModalEvaluacion";

const useModalFirmarEvaluacion = () => {
	const [, setVisibleModalEvaluacion] = useAtom(visibleModalEvaluacionPendienteAtom);
	const [evaluacionesPendientes, setEvaluacionesPendientes] = useAtom(evaluacionesPendientesAtom);
	const [evaluacionPendienteSeleccionada] = useAtom(evaluacionPendienteSeleccionadaAtom);
	const [visibleModal, setVisibleModal] = useAtom(visibleModalFirmarEvaluacionAtom);
	const [loading, setLoading] = useState(false);
	const [claveIngresada, setClaveIngresada] = useState("");
	const [mensajeError, setMensajeError] = useState("");

	useEffect(() => {
		if (visibleModal) {
			setClaveIngresada("");
			setMensajeError("");
		}
	}, [visibleModal]);

	const mostrarModal = () => {
		setVisibleModal(true);
	};

	const ocultarModal = () => {
		setVisibleModal(false);
	};

	const onClickBtnFirmar = async () => {
		try {
			setLoading(true);
			evaluacionPendienteSeleccionada.resultado.clave = claveIngresada;
			await EvaluacionService.actualizarResultado(evaluacionPendienteSeleccionada.resultado);
			setEvaluacionesPendientes(
				evaluacionesPendientes.filter((evaluacion) => evaluacion.id !== evaluacionPendienteSeleccionada.id)
			);
			notification["success"]({
				message: "Éxito",
				description: "Evaluación enviada correctamente.",
			});
			setVisibleModal(false);
			setVisibleModalEvaluacion(false);
		} catch (error) {
			setMensajeError(error?.response?.data?.mensaje);
		} finally {
			setLoading(false);
		}

		setLoading(false);
	};

	return {
		visibleModal,
		loading,
		mostrarModal,
		ocultarModal,
		mensajeError,
		setMensajeError,
		claveIngresada,
		setClaveIngresada,
		onClickBtnFirmar,
	};
};

export default useModalFirmarEvaluacion;
