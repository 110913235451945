import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Spin } from "antd";
import { useAtom } from "jotai";
import {
	componentSelectedAtom,
	signatureModalVisibleAtom,
	signaturesMetadataAtom,
} from "../../../../MisDocumentosWebStorage";
import SignaturePad from "react-signature-pad-wrapper";
import produce from "immer";

const SignatureModal = () => {
	const [visible, setVisible] = useAtom(signatureModalVisibleAtom);
	const [signaturesMetadata, setSignaturesMetadata] = useAtom(signaturesMetadataAtom);
	const [componentSelected] = useAtom(componentSelectedAtom);
	const [loading, setLoading] = useState(true);
	const refSignature = useRef();

	useEffect(() => {
		const cargarFirma = () => {
			if (visible) {
				setTimeout(() => {
					setLoading(false);
					if (signaturesMetadata[0]?.base64) {
						refSignature.current.fromDataURL(signaturesMetadata[0]?.base64);
					}
				}, 1000);
			}
		};
		cargarFirma();
	}, [visible]);

	const onClickBtnOk = () => {
		setSignaturesMetadata(
			produce((draft) => {
				const signature = draft.find((s) => s.key === componentSelected.key);
				let base64 = refSignature.current.isEmpty() ? undefined : refSignature.current.toDataURL();
				if (signature) {
					signature.base64 = base64;
				} else {
					draft.push({
						key: componentSelected.key,
						base64: base64,
					});
				}
			})
		);
		setVisible(false);
	};

	const onClickBtnCancelar = () => {
		setVisible(false);
	};

	const onClickBtnLimpiarFirma = () => {
		refSignature.current.clear();
	};

	return (
		<Modal
			title="Firmar"
			open={visible}
			okText="Aceptar"
			cancelText="Cancelar"
			onOk={onClickBtnOk}
			onCancel={onClickBtnCancelar}
			destroyOnClose
			centered
			bodyStyle={{ textAlign: "center" }}
			maskClosable={false}
			afterClose={() => setLoading(true)}
		>
			{loading && <Spin />}
			{!loading && (
				<>
					<SignaturePad
						ref={refSignature}
						key={1}
						// width={"450"}
						// height={"200"}
						redrawOnResize
						canvasProps={{ style: { border: "1px dashed #ccc" } }}
						options={{ minWidth: 2, maxWidth: 3, penColor: "#000" }}
					/>
					<Button type="link" onClick={onClickBtnLimpiarFirma}>
						Limpiar Firma
					</Button>
				</>
			)}
		</Modal>
	);
};

export default SignatureModal;
