import React, { useContext, useEffect, useState } from "react";
import { FileSearchOutlined, FileDoneOutlined } from "@ant-design/icons";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import { useHistory } from "react-router";
import { CategoriaDocumento } from "enum/CategoriaDocumento";
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";
import { useAtom } from "jotai";
import { formularioSeleccionadoAtom, documentoSeleccionadoAtom } from "../MisDocumentosMovilStorage";
import { visibleModalAlertaAtom } from "pages/mis-documentos/storage/MisDocumentosStorage";
import {
	loadingPdfAtom
} from "pages/onboarding/OnboardingStore";

export const ItemDocumento = ({ documento }) => {
	let history = useHistory();
	const [_, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
	const [, setFormularioSeleccionado] = useAtom(formularioSeleccionadoAtom);
	const [classNameEstado, setClassNameEstado] = useState(" loading");
	const [, setVisibleModalAlerta] = useAtom(visibleModalAlertaAtom);
	//const [, setLoadingPdf] = useAtom(loadingPdfAtom);

	const onClickSeleccionarItem = () => {
		// if (documento.permitirVisualizarDocumento === false) {
		// 	setVisibleModalAlerta(true);
		// } else {
		if (documento.permitirVisualizarDocumento) {
			//setLoadingPdf(true);
			if (
				(documento.categoriaDocumentoId === CategoriaDocumento.FORMULARIO ||
					documento.categoriaDocumentoId === CategoriaDocumento.FORMATO_AUSENCIA) &&
				(documento.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE ||
					(documento.estadoAprobacion != null && documento.estadoAprobacion === "RECHAZADO"))
			) {
				setFormularioSeleccionado(documento);
				history.push(`/mis-documentos/visor-formulario-pdf/${documento.formularioId}/${documento.id}`);
			} else if (
				documento.categoriaDocumentoId === CategoriaDocumento.CARGA_DOCUMENTO &&
				(documento.estadoVistaColaborador !== EstadoVistaColaborador.FIRMADO ||
					(documento.estadoAprobacion != null && documento.estadoAprobacion === "RECHAZADO"))
			) {
				setDocumentoSeleccionado(documento);
				history.push(`/mis-documentos/carga-documentos/${documento.id}`);
			} else {
				history.push(`/mis-documentos/visor-pdf/${documento.id}`);
			}
		} else setVisibleModalAlerta(true);

		//}
	};

	const EstadoDocumento = ({ estado, estadoAprobacion }) => {
		if (estadoAprobacion != null && estadoAprobacion === "PENDIENTE" && estado === EstadoVistaColaborador.FIRMADO) {
			return "PENDIENTE APROBACIÓN";
		} else if (estadoAprobacion != null && estadoAprobacion === "RECHAZADO") {
			return "RECHAZADO";
		} else {
			switch (estado) {
				case EstadoVistaColaborador.PENDIENTE:
					return "PENDIENTE";

				case EstadoVistaColaborador.VISUALIZADO:
					return "VISUALIZADO";

				case EstadoVistaColaborador.FIRMADO:
					return "FIRMADO";

				default:
					return "-";
			}
		}
	};

	// const classNameEstado =
	// 	documento.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE ? " pendiente" : " visualizado";

	useEffect(() => {
		if (
			documento.estadoAprobacion !== null &&
			documento.estadoAprobacion === "PENDIENTE" &&
			documento.estadoVistaColaborador === EstadoVistaColaborador.FIRMADO
		) {
			setClassNameEstado(" pendiente-aprobacion");
		} else if (documento.estadoAprobacion !== null && documento.estadoAprobacion === "RECHAZADO") {
			setClassNameEstado(" rechazado");
		} else if (
			documento.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE &&
			documento.permitirVisualizarDocumento === true
		) {
			setClassNameEstado(" pendiente");
		} else if (
			documento.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE &&
			documento.permitirVisualizarDocumento === false
		) {
			setClassNameEstado(" bloqueado");
		} else {
			setClassNameEstado(" visualizado");
		}
	}, [documento]);

	return (
		<div className={"bandeja-entrada__item-documento" + classNameEstado} onClick={onClickSeleccionarItem}>
			<div className={"imagen" + classNameEstado}>
				{documento.estadoVistaColaborador !== EstadoVistaColaborador.PENDIENTE && <FileDoneOutlined />}
				{documento.estadoVistaColaborador == EstadoVistaColaborador.PENDIENTE && <FileSearchOutlined />}
			</div>
			<div className="informacion">
				<span className="item-documento__descripcion">
					<b>{documento.periodo}</b>
				</span>
				<span className="item-documento__tipo">{documento.nombreDocumento}</span>
				<span className={"item-documento__estado" + classNameEstado}>
					<EstadoDocumento
						estado={documento.estadoVistaColaborador}
						estadoAprobacion={documento.estadoAprobacion}
					/>
				</span>
			</div>
		</div>
	);
};
