import { BaseService } from "./BaseService";
import qs from "qs";
//import { seccionAtom } from "pages/seccion/editar/EditarSeccionStore";
const DetalleEnvioService = {
  async listarConsultaDocumentoPortalColaborador(
    empresaId,
    login,
    colaborador,
    categoria,
    documentoIdentidad,
    tipoDocumento,
    cargo,
    periodoDocumento,
    carpeta,
    estadoDocumento,
    sede,
    fechaEnvioInicio,
    fechaEnvioFin,
    pagina,
    cantidad,
    subSede,
    perfil,
    seccion,
    tipoPlanilla,
    codigoColaborador,
    fechaIngresoInicio,
    fechaIngresoFin,
    fechaFirmaInicio,
    fechaFirmaFin) {
    const respuesta = await BaseService.get("/detalleEnvio/portalColaborador/consulta-documento", {
      params: {
        empresaId: empresaId,
        login: login,
        colaborador: colaborador,
        categoria: categoria,
        documentoIdentidad: documentoIdentidad,
        tipoDocumento: tipoDocumento,
        cargo: cargo,
        periodoDocumento: periodoDocumento,
        carpeta: carpeta,
        estadoDocumento: estadoDocumento,
        sede: sede,
        fechaEnvioInicio: fechaEnvioInicio,
        fechaEnvioFin: fechaEnvioFin,
        pagina: pagina,
        cantidad: cantidad,
        subSede: subSede,
        perfil: perfil,
        seccion: seccion,
        tipoPlanilla: tipoPlanilla,
        codigoColaborador: codigoColaborador,
        fechaIngresoInicio: fechaIngresoInicio,
        fechaIngresoFin: fechaIngresoFin,
        fechaFirmaInicio: fechaFirmaInicio,
        fechaFirmaFin: fechaFirmaFin
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta.data;
  },
  async descargarExcelConsultaDocumento(parametros) {
    const respuesta = await BaseService.post("/detalleEnvio/descargarExcelConsultaDocumento", parametros, {
      responseType: "blob",
    });
    return respuesta;
  },
  async descargarPDFConsultaDocumento(parametros) {
    const respuesta = await BaseService.post("/detalleEnvio/descargarPDFConsultaDocumento", parametros, {
      responseType: "blob",
    });
    return respuesta;
  },
  async obtenerUrlDocumentoWord(detalleEnvioId) {
    const respuesta = await BaseService.get(`/detalleEnvio/${detalleEnvioId}/url-word`);
    return respuesta.data;
  },
};

export default DetalleEnvioService;
