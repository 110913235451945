import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Select, Button, notification, InputNumber } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { colaboradorAtom, pasoActualAtom } from "../../VerificarDatosPersonalesStore";
import VerificarDatosPersonalesService from "services/VerificarDatosPersonalesService";

const VerificarStep = () => {
	const [frmVerificar] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [, setPasoActual] = useAtom(pasoActualAtom);
	const [colaborador] = useAtom(colaboradorAtom);

	useEffect(() => {
		
		frmVerificar.setFieldsValue({
			tipoDocumentoIdentidad: colaborador.td,
			numeroDocumentoIdentidad: colaborador.nd,
			apellidoPaterno: colaborador.ap,
			apellidoMaterno: colaborador.am,
			nombres: colaborador.n,
			correoElectronicoVerificado: colaborador.ce,
			celularVerificado: colaborador.c !== 'null'?colaborador.c:undefined,
		});
	}, []);

	const onClickBtnVerificar = () => {
		frmVerificar
			.validateFields()
			.then(async (formulario) => {
				try {
					setLoading(true);
					formulario.empresaId = colaborador.ei;
					formulario.numeroDocumentoIdentidad = colaborador.nd;
					await VerificarDatosPersonalesService.verificar(formulario);
					setLoading(false);
					setPasoActual(2);
				} catch (error) {
					notification.error({
						description: "No se pudo verificar sus datos. Por favor, intenta nuevamente.",
					});
					setLoading(false);
				}
			})
			.catch((info) => {
				console.log("Validate Failed:", info);
			});
	};

	return (
		<div id="step-verificar" style={{ display: "flex", flexDirection: "column" }}>
			<span style={{ paddingTop: "20px", fontSize: "20px", fontWeight: "bold", textAlign: "center" }}>
				<span style={{ fontWeight: "normal" }}>Hola </span>
				{`${colaborador.n} ${colaborador.ap} ${colaborador.am}`}
			</span>
			<span style={{ paddingTop: "5px", fontSize: "18px", textAlign: "center" }}>
				Verifica la siguiente información
			</span>
			<div style={{ paddingTop: "15px" }}>
				<Form layout="vertical" form={frmVerificar}>
					<Row>
						<Col span={24}>
							<Form.Item
								label="Correo Electrónico"
								name="correoElectronicoVerificado"
								rules={[
									{
										required: true,
										message: "Por favor ingrese su correo electrónico!",
									},
									{
										type: 'email',
										message: "Correo electrónico ingresado no es válido",
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<Form.Item
								label="Tipo Operador"
								name="tipoOperador"
								rules={[
									{
										required: true,
										message: "Por favor seleccione su operador!",
									},
								]}
							>
								<Select placeholder="Seleccione el tipo de operador" style={{ width: "90%" }}>
									<Select.Option value="MOVISTAR">Movistar</Select.Option>
									<Select.Option value="CLARO">Claro</Select.Option>
									<Select.Option value="ENTEL">Entel</Select.Option>
									<Select.Option value="BITEL">Bitel</Select.Option>
									<Select.Option value="OTRO">Otro</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Celular"
								name="celularVerificado"
								rules={[
									{	
										required: true,
										message: "Por favor ingresa su número de celular!",
									},
								]}
							>
								<Input onKeyPress={(e) => {
									if (!/[0-9]/.test(e.key)) {
									e.preventDefault();
									}
								}} />
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24} style={{ textAlign: "center" }}>
							<Form.Item>
								<Button
									loading={loading}
									onClick={onClickBtnVerificar}
									type="primary"
									icon={<CheckOutlined />}
									block
									size="large"
								>
									Verificar
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};

export default VerificarStep;
