export const cssVisorPdf = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
    margin: "30px",
    display: "flex",
    flexDirection: "column",
};

export const cssBodyVisorPdf = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
};