import React from "react";
import { usePreguntasCalidad } from "../../hooks";
import { Preguntas } from "../Preguntas";

const PreguntasCalidad = () => {
	const { frmPreguntas, setPuntajeTotal } = usePreguntasCalidad();

	return (
		<Preguntas
			form={frmPreguntas}
			calcularPuntajeTotal={setPuntajeTotal}
			titulo="CALIDAD (1/6)"
			opciones={[
				{ key: "noCometeErroresEnElTrabajo", label: "No comete errores en el trabajo" },
				{ key: "haceUsoRacionalDeLosRecursos", label: "Hace uso racional de los recursos" },
				{ key: "noRequiereDeSupervicionFrecuente", label: "No requiere de supervisión frecuente" },
				{
					key: "cumpleConLosProcedimientoseInstructivosEstablecidoEnElArea",
					label: "Cumple con los procedimientos e instructivos establecidos en el área",
				},
			]}
		/>
	);
};

export default PreguntasCalidad;
