import React from "react";
import { usePreguntasOrientacionResultados } from "../../hooks";
import { Preguntas } from "../Preguntas";

const PreguntasOrientacionResultados = () => {
	const { frmPreguntas, setPuntajeTotal } = usePreguntasOrientacionResultados();

	return (
		<Preguntas
			form={frmPreguntas}
			calcularPuntajeTotal={setPuntajeTotal}
			titulo="ORIENTACIÓN A RESULTADOS (4/6)"
			opciones={[
				{
					key: "terminaSuTrabajoOportunamente",
					label: "Termina su trabajo oportunamente",
				},
				{
					key: "cumpleConLasTareasQueSeLeEncomienda",
					label: "Cumple con las tareas que se le encomienda",
				},
				{
					key: "realizaUnVolumenAdecuadoDeTrabajo",
					label: "Realiza un volumen adecuado de trabajo",
				},
			]}
		/>
	);
};

export default PreguntasOrientacionResultados;
