import { HttpService } from "./HttpService";
import { URL } from "enum";
const URL_API = URL.BASE;

const OnbardingService = {
	async obtenerStatusOnboarding() {
		const respuesta = await HttpService(URL_API).get("/portal-colaborador/onboarding/status");
		return respuesta.data;
	},
	async obtenerInformacionPersonal() {
		const respuesta = await HttpService(URL_API).get("/portal-colaborador/onboarding/informacion-personal");
		return respuesta.data;
	},
	async obtenerDocumentos() {
		const respuesta = await HttpService(URL_API).get("/portal-colaborador/onboarding/documentos");
		return respuesta.data;
	},
	async completarInformacionPersonal(datos) {
		const respuesta = await HttpService(URL_API).patch(
			`/portal-colaborador/onboarding/informacion-personal`,
			datos
		);
		return respuesta.data;
	},
};
export default OnbardingService;
