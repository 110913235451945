import React from "react";
import { Button, Space } from "antd";
import { useAtom } from "jotai";
import logotipo from "assets/imagenes/logotipo.png";
import "./Header.css";
import { visibleModalCrearSolicitudAtom } from "../../../commons/store/MisSolicitudesDocumentosStore";

const Header = () => {
	const [, setVisibleModal] = useAtom(visibleModalCrearSolicitudAtom);

	return (
		<div className="container-movil-pdf-header">
			<div style={{ width: "60%" }}>
				<img src={logotipo} alt="" style={{ height: "36px", marginRight: "10px" }} />
				Solicitudes Documentos
			</div>
			<div style={{ width: "40%", textAlign: "right", paddingRight: "10px" }}>
				<Space>
					<Button type="primary" onClick={() => setVisibleModal(true)}>
						Solicitar
					</Button>
				</Space>
			</div>
		</div>
	);
};
export default Header;
