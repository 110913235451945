import React, { useEffect } from "react";
import { cargosAtom } from "../store/MisSolicitudesPersonalStore";
import { useAtom } from "jotai";
import CargoService from "services/CargoService"

const useListarCargos = () => {
    const [cargos, setCargos] = useAtom(cargosAtom);
    useEffect(() => {
        const cargarDatos = async () => {
            const respuesta = await CargoService.listar("");
            setCargos(respuesta);
        }
        cargarDatos();
    }, []);
    return{cargos}
    
}
export default useListarCargos;