import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Alert } from "antd";
import { useFormContext } from "react-hook-form";
import { erroresFormularioAtom } from "pages/mis-documentos/componentes/MisDocumentosWeb/MisDocumentosWebStorage";

const Errores = () => {
	const [errorMostrado, setErrorMostrado] = useState(undefined);
	const [erroresFormulario] = useAtom(erroresFormularioAtom);
	const { formState } = useFormContext();

	useEffect(() => {
		if (Object.keys(erroresFormulario).length > 0) {
			const keyPrimerError = Object.keys(erroresFormulario)[0];
			let mensaje = "";
			if (Array.isArray(erroresFormulario[keyPrimerError])) {
				const errores = erroresFormulario[keyPrimerError].filter((e) => {
					if (e) {
						return e;
					}
				});
				const key = Object.keys(errores[0])[0];
				mensaje = errores[0][key].message;
			} else {
				mensaje = erroresFormulario[keyPrimerError].message;
			}
			setErrorMostrado({
				key: keyPrimerError,
				mensaje: mensaje,
			});
		}
	}, [erroresFormulario]);

	useEffect(() => {
		const limpiarErrorMostrado = () => {
			if (formState?.errors && errorMostrado) {
				const error = formState.errors[errorMostrado.key];
				if (error === undefined) {
					setErrorMostrado(undefined);
				}
			}
		};
		limpiarErrorMostrado();
	}, [formState]);

	if (errorMostrado === undefined) {
		return "";
	}

	return <div>{errorMostrado && <Alert message={errorMostrado.mensaje} type="error" showIcon />}</div>;
};

export default Errores;
