import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import {
	detalleEntrevistaAtom,
	posicionCompetenciaActualAtom,
	visibleModalCompetenciasLaboralesAtom,
} from "../DetalleEntrevistaStore";
import { message } from "antd";
import produce from "immer";
import useListarCompetencias from "./useListarCompetencias";
import { EntrevistasService } from "services/reclutamiento-y-seleccion";
import { useParams } from "react-router-dom";

const useCompetenciasLaborales = () => {
	const { entrevistaId } = useParams();
	const { competencias, setCompetencias } = useListarCompetencias();
	const [, setVisibleModalCompetenciasLaborales] = useAtom(visibleModalCompetenciasLaboralesAtom);
	const [posicionCompetenciaActual, setPosicionCompetenciaActual] = useAtom(posicionCompetenciaActualAtom);
	const [detalleEntrevista, setDetalleEntrevista] = useAtom(detalleEntrevistaAtom);
	const [deshabilitarBotonAtras, setDeshabilitarBotonAtras] = useState(true);
	const [mostrarBotonSiguiente, setMostrarBotonSiguiente] = useState(true);
	const [mostrarBotonFinalizar, setMostrarBotonFinalizar] = useState(false);

	const totalCompetencias = competencias.length;

	useEffect(() => {
		if (posicionCompetenciaActual === 0) {
			setDeshabilitarBotonAtras(true);
		} else {
			setDeshabilitarBotonAtras(false);
		}

		if (posicionCompetenciaActual === competencias.length - 1) {
			setMostrarBotonFinalizar(true);
			setMostrarBotonSiguiente(false);
		} else {
			setMostrarBotonFinalizar(false);
			setMostrarBotonSiguiente(true);
		}
	}, [posicionCompetenciaActual]);

	const asignarRespuestaCompetencia = (respuesta) => {
		setCompetencias(
			produce((draft) => {
				const competencia = draft.find(
					(competencia) => competencia.id === competencias[posicionCompetenciaActual].id
				);
				competencia.respuesta = respuesta;
			})
		);
	};

	const onClickBtnSiguiente = () => {
		const competenciaActual = competencias.find(
			(competencia) => competencia.id === competencias[posicionCompetenciaActual].id
		);

		if (competenciaActual.respuesta === undefined) {
			message.error("Seleccione una respuesta!");
		} else {
			if (posicionCompetenciaActual < competencias.length - 1) {
				setPosicionCompetenciaActual((posicion) => posicion + 1);
			}
		}
	};

	const onClickBtnAnterior = () => {
		if (posicionCompetenciaActual > 0) {
			setPosicionCompetenciaActual((posicion) => posicion - 1);
		}
	};

	const onClickBtnFinalizar = async () => {
		const competenciaActual = competencias.find(
			(competencia) => competencia.id === competencias[posicionCompetenciaActual].id
		);

		if (competenciaActual.respuesta === undefined) {
			message.error("Seleccione una respuesta!");
		} else {
			const respuesta = EntrevistasService.completarCompetenciasLaborales({
				entrevistaId: entrevistaId,
				competenciasLaborales: competencias,
			});
			setDetalleEntrevista({ ...detalleEntrevista, competenciasLaboralesCompleto: respuesta });
			setVisibleModalCompetenciasLaborales(false);
		}
	};

	const getCompetenciaActual = () => {
		const competenciaActual = competencias[posicionCompetenciaActual];
		return {
			descripcion: competenciaActual?.descripcion,
			respuesta: competenciaActual?.respuesta,
			posicion: posicionCompetenciaActual + 1,
		};
	};

	return {
		asignarRespuestaCompetencia,
		onClickBtnSiguiente,
		onClickBtnAnterior,
		onClickBtnFinalizar,
		getCompetenciaActual,
		totalCompetencias,
		deshabilitarBotonAtras,
		mostrarBotonSiguiente,
		mostrarBotonFinalizar,
	};
};
export default useCompetenciasLaborales;
