import React from "react";
import { useMediaQuery } from "react-responsive";
import { MisDocumentosContextProvider } from "../../context/MisDocumentos/MisDocumentosContextProvider";
import { MisDocumentosMovil } from "./componentes/MisDocumentosMovil";
import { MisDocumentosWeb } from "./componentes/MisDocumentosWeb"

export function MisDocumentosPage() {

    const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
    const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

    return (
        <MisDocumentosContextProvider>
            {esMovil && <MisDocumentosMovil />}
            {esDesktop && <MisDocumentosWeb />}
        </MisDocumentosContextProvider>
    );
}