import { Modal, Select, Form } from "antd";
import React, { useState, useContext } from "react";
import { useAtom } from "jotai";
import { visibleModalCrearSolicitudAtom } from "../../store/MisSolicitudesDocumentosStore";
import { useListarDocumentos, useCargarSolicitudesDocumentos } from "../../hooks";
import SolicituDocumentoService from "services/SolicitudDocumentoService";
import { SecurityContext } from "context/SecurityContextProvider";
const ModalCrearSolicitudDocumento = () => {
	const [visibleModal, setVisibleModal] = useAtom(visibleModalCrearSolicitudAtom);
	const [form] = Form.useForm();
	const { documentos } = useListarDocumentos();
	const [documentoSeleccionado, setDocumentoSleccionado] = useState(undefined);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [loading, setLoading] = useState(false);
	const { cargarSolicitudesDocumentos } = useCargarSolicitudesDocumentos();

	const cerraModal = () => {
		setVisibleModal(false);
		form.resetFields();
		cargarSolicitudesDocumentos();
	};

	const onSelectDocumento = (value, option) => {
		//console.log("doc. seleccionado", option.label);
		const documento = {
			id: option.value,
			nombre: option.children,
			url: option.label,
		};
		setDocumentoSleccionado(documento);
	};
	const onFinishSolicitar = async (formulario) => {
		try {
			setLoading(true);
			const solicitud = {
				numeroDocumentoIdentidadColaborador: getUsuarioAutenticado().numeroDocumentoIdentidad,
				tipoDocumentoId: documentoSeleccionado.id,
				tipoDocumentoNombre: documentoSeleccionado.nombre,
				urlDocumento: documentoSeleccionado.url,
			};
			await SolicituDocumentoService.insertar(solicitud);
			setLoading(false);
			cerraModal();
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};
	return (
		<Modal
			open={visibleModal}
			onCancel={cerraModal}
			okText="Solicitar"
			destroyOnClose={true}
			confirmLoading={loading}
			onOk={() => form.submit()}
		>
			<Form layout="vertical" onFinish={onFinishSolicitar} form={form}>
				<Form.Item label="Seleccione tipo de documento" name="tipoDocumento" rules={[{ required: true }]}>
					<Select placeholder="Seleccione documento" onSelect={onSelectDocumento}>
						{documentos.length > 0 &&
							documentos.map((documento) => {
								return (
									<Select.Option value={documento.id} label={documento.urlPlantillaPdf}>
										{documento.nombre}
									</Select.Option>
								);
							})}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};
export default ModalCrearSolicitudDocumento;
