import React, { useState } from "react";
import { Card } from "antd";
import { DatosPersonales } from "./DatosPersonales";
import { Organigrama } from "./Organigrama";

const CardDatosColaborador = () => {
	const [activeTabKey, setActiveTabKey] = useState("datosPersonales");
	const tabs = [
		{
			key: "datosPersonales",
			tab: "Datos Personales",
		},
		{
			key: "organigrama",
			tab: "Organigrama",
		},
	];

	const listaTabs = {
		datosPersonales: <DatosPersonales />,
		organigrama: <Organigrama />,
	};

	const onTabChange = (key) => {
		setActiveTabKey(key);
	};
	return (
		<div>
			<Card
				tabList={tabs}
				activeTabKey={activeTabKey}
				onTabChange={(key) => {
					onTabChange(key);
				}}
				style={{ marginBottom: "30px" }}
			>
				{listaTabs[activeTabKey]}
			</Card>
		</div>
	);
};
export default CardDatosColaborador;
