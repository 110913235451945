import { useEffect, useState } from "react";
import { useLogging } from "hooks";
import { useParams } from "react-router-dom";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { SolicitudAusenciaService } from "services";
import { formularioSeleccionadoAtom } from "../../MisDocumentosMovilStorage";
import { useAtom } from "jotai";
const useFormularioPdf = () => {
	const { formularioId, detalleEnvioId } = useParams();
	const [urlPdf, setUrlPdf] = useState(undefined);
	const [componentes, setComponentes] = useState(undefined);
	const [variablesFormulario, setVariablesFormulario] = useState(undefined);
	const [datosFormulario, setDatosFormulario] = useState(undefined);
	const [loadingPdf, setLoadingPdf] = useState(false);
	const [totalPaginas, setTotalPaginas] = useState(undefined);
	const [motivoRechazo, setMotivoRechazo] = useState(undefined);
	const { insertarLogErrorCargaPdf } = useLogging();
	const [urlFirmaHolografa, setUrlFirmaHolografa] = useState(undefined);
	const [urlHuellaDactilar, setUrlHuellaDacilar] = useState(undefined);
	const [urlFoto, setUrlFoto] = useState(undefined);
	const [, setFormularioSeleccionado] = useAtom(formularioSeleccionadoAtom);

	const onCargaExitosa = async (successEvent) => {
		setTotalPaginas(successEvent.numPages);
		setLoadingPdf(false);
	};

	const onCargaError = (error) => {
		try {
			insertarLogErrorCargaPdf(error);
		} catch (error) {
			console.error("Se produjero error al enviar el log", error);
		} finally {
			setLoadingPdf(false);
		}
	};

	useEffect(() => {
		const cargarPdf = async () => {
			setLoadingPdf(true);

			const formularioSeleccionado = await PortalColaboradorService.obtenerDocumento(null, detalleEnvioId);
			setFormularioSeleccionado(formularioSeleccionado);

			const respuesta = await PortalColaboradorService.obtenerDatosFormulario(
				formularioId, //formularioId
				detalleEnvioId //detalleEnvioId
			);
			if (respuesta.solicitudAusenciaId) {
				const variablesAusencia = await SolicitudAusenciaService.obtenerDatosSolicitudAusencia({
					solicitudAusenciaId: respuesta.solicitudAusenciaId,
				});
				//console.log("Todas las variables", variablesAusencia);
				Object.assign(respuesta.variablesFormulario, variablesAusencia.data);
			}

			setUrlPdf(respuesta.urlPlantillaPdfFirmada);
			setComponentes(respuesta.componentes);
			setVariablesFormulario(respuesta.variablesFormulario);
			setDatosFormulario(respuesta.datosFormulario);
			setMotivoRechazo(respuesta.motivoRechazo);
			if (respuesta.urlFirmaHolografa !== undefined) setUrlFirmaHolografa(respuesta.urlFirmaHolografa);
			if (respuesta.urlHuellaDactilar !== undefined) setUrlHuellaDacilar(respuesta.urlHuellaDactilar);
			if (respuesta.urlFoto !== undefined) setUrlFoto(respuesta.urlFoto);
		};
		cargarPdf();
	}, []);

	return {
		urlPdf,
		loadingPdf,
		totalPaginas,
		componentes,
		variablesFormulario,
		datosFormulario,
		motivoRechazo,
		onCargaExitosa,
		onCargaError,
		urlFirmaHolografa,
		urlHuellaDactilar,
		urlFoto,
	};
};

export default useFormularioPdf;
