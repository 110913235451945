import React, { useEffect, useState } from "react";
import { Alert, Button, Input, message, Modal } from "antd";
import { useAtom } from "jotai";
import { SolicitudAusenciaService } from "services";
import { ApiResponse } from "enum";
import produce from "immer";
import {
	formularioSolicitarAusenciaAtom,
	solicitudesAusenciaAtom,
	visibleModalCrearSolicitudAusenciaAtom,
	visibleModalFirmarSolicitudAusenciaAtom,
	archivosAdjuntosAtom,
} from "../../store/MisSolicitudesStore";

const ModalFirmarSolicitudAusencia = () => {
	const [formulario] = useAtom(formularioSolicitarAusenciaAtom);
	const [, setSolicitudesAusencia] = useAtom(solicitudesAusenciaAtom);
	const [, setVisibleModalCrearSolicitud] = useAtom(visibleModalCrearSolicitudAusenciaAtom);
	const [visibleModalFirmar, setVisibleModalFirmar] = useAtom(visibleModalFirmarSolicitudAusenciaAtom);
	const [loading, setLoading] = useState(false);
	const [claveIngresada, setClaveIngresada] = useState("");
	const [mensajeError, setMensajeError] = useState("");
	const [adjuntos, setAdjuntos] = useAtom(archivosAdjuntosAtom);

	useEffect(() => {
		if (visibleModalFirmar) {
			setClaveIngresada("");
			setMensajeError("");
		}
	}, [visibleModalFirmar]);

	const onClickBtnFirmar = async () => {
		setLoading(true);
		const _formulario = { ...formulario };

		const formData = new FormData();
		var identificadoresAdjuntos = [];

		//console.log("formulario.adjuntos",formulario.adjuntos);
		if (formulario.adjuntos) {
			for (var posicion = 0; posicion < Object.keys(formulario.adjuntos).length; posicion++) {
				if (Object.values(formulario.adjuntos)[posicion]) {
					formData.append("file", Object.values(formulario.adjuntos)[posicion].file);
					identificadoresAdjuntos.push(Object.keys(formulario.adjuntos)[posicion]);
				}
			}
		}
       
		formData.append(
			"info",
			new Blob(
				[
					JSON.stringify({
						tipoAusenciaId: _formulario.tipoAusenciaId,
						// fechaInicio: _formulario.fechasSolicitud[0].format("DD/MM/YYYY"),
						// fechaFin: _formulario.fechasSolicitud[1].format("DD/MM/YYYY"),
						fechaInicio: _formulario.fechasSolicitud!= null ?_formulario.fechasSolicitud[0].format("DD/MM/YYYY HH:mm:ss"):null,
						fechaFin: _formulario.fechasSolicitud!=null?_formulario.fechasSolicitud[1].format("DD/MM/YYYY HH:mm:ss"):null,
						comentario: _formulario.comentario,
						informacionAdicional: _formulario.informacionAdicional,
						identificadoresAdjuntos: identificadoresAdjuntos,
						clave: claveIngresada,
					}),
				],
				{
					type: "application/json",
				}
			)
		);

		const response = await SolicitudAusenciaService.crear(formData);

		// const response = await SolicitudAusenciaService.crear({
		// 	tipoAusenciaId: _formulario.tipoAusenciaId,
		// 	fechaInicio: _formulario.fechasSolicitud[0].format("DD/MM/YYYY"),
		// 	fechaFin: _formulario.fechasSolicitud[1].format("DD/MM/YYYY"),
		// 	comentario: _formulario.comentario,
		// 	informacionAdicional: _formulario.informacionAdicional,
		// 	clave: claveIngresada,
		// });
		if (response.estado === ApiResponse.EXITO) {
			message.success("Se ha enviado correctamente la solicitud.");
			setVisibleModalFirmar(false);
			setVisibleModalCrearSolicitud(false);
			setSolicitudesAusencia(
				produce((draft) => {
					draft.push(response.data);
				})
			);
		} else {
			setMensajeError(response.mensaje);
		}
		setLoading(false);
	};
	const onClickBtnCancelar = () => {
		setVisibleModalFirmar(false);
	};
	return (
		<Modal
			title="Firmar Solicitud"
			visible={visibleModalFirmar}
			closable={false}
			width={400}
			destroyOnClose={true}
			footer={[
				<Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
					Cancelar
				</Button>,
				<Button
					key="firmar"
					type="primary"
					loading={loading}
					onClick={onClickBtnFirmar}
					style={{ padding: "0px 30px" }}
				>
					Firmar
				</Button>,
			]}
			centered
		>
			{mensajeError && (
				<Alert
					message={mensajeError}
					onClose={() => setMensajeError(undefined)}
					style={{ marginBottom: "4px" }}
					type="error"
					showIcon
					closable
				/>
			)}
			<span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
			<Input.Password
				value={claveIngresada}
				onChange={(e) => {
					setClaveIngresada(e.target.value);
					setMensajeError(undefined);
				}}
				placeholder={"Clave"}
			/>
		</Modal>
	);
};

export default ModalFirmarSolicitudAusencia;
