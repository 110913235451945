import React from "react";
import { useMediaQuery } from "react-responsive";
import { Provider as JotaiProvider } from "jotai";
import { TareasPendientesPageMovil, TareasPendientesPageWeb } from "./componentes";

const TareasPendientesPage = () => {
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

	return (
		<JotaiProvider>
			{esMovil && <TareasPendientesPageMovil />}
			{esDesktop && <TareasPendientesPageWeb />}
		</JotaiProvider>
	);
};

export default TareasPendientesPage;
