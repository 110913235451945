import React,{useEffect,useContext,useState} from "react";
import { Container } from "components/Container";
import { Button, Table,Badge,Alert } from "antd";
import * as atoms from "./BandejaConsultaStorage";
import { useAtom } from "jotai";
import { PlusOutlined } from '@ant-design/icons';
import ModalConsultar from "./ModalConsultar";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { SecurityContext } from "context/SecurityContextProvider";
import { TipoConsulta } from "enum/TipoConsulta";
import ModalVerConsulta from "./ModalVerConsulta";
import MenuAcciones from "./Acciones";
import * as atomsConsulta from "../../../../hooks/consultas/ConsultasStorage";

const BandejaConsultaWeb = () => {
    const [consultas, setConsultas] = useAtom(atoms.consultasAtom);
    const [visibleModalConsulta, setVisibleModalConsulta] = useAtom(atoms.visibleModalConsultaAtom);
    const [visibleModalVerConsulta, setVisibleModalVerConsulta] = useAtom(atoms.visibleModalVerConsultaAtom);
    const [consultaSeleccionada, setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);
    const [, setDetallesConsulta] = useAtom(atoms.detalleConsultaAtom);
    const [numeroRespuestas, setNumeroRespuestas] = useAtom(atoms.numeroRespuestasAtom);
    const [totalRespuestasUsuario, setTotalRespuestasUsuario] = useAtom(atomsConsulta.totalRespuestasUsuarioAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    useEffect(() => {
        const cargarDatos = async() => {
            const _consultas = await PortalColaboradorService.listaConsultas(getUsuarioAutenticado().empresaSeleccionada.id,getUsuarioAutenticado().numeroDocumentoIdentidad);
            setConsultas(_consultas);
            obtenerNumeroDetalleNoVisualizadas(_consultas);                      
           
        }
        cargarDatos();
    }, []);
    const obtenerNumeroDetalleNoVisualizadas =async (consultas) => {
        let listaConsultasId = [];  
        if (consultas.length > 0) {
             consultas.map((consulta) => {
                listaConsultasId.push(consulta.id);
            })
            const respuestasXConsulta = await PortalColaboradorService.listaNumeroRespuestas(listaConsultasId, "respuestaUsuario");
            setNumeroRespuestas(respuestasXConsulta);
            let totalRespuestas = 0;
            respuestasXConsulta.length > 0 && respuestasXConsulta.map((respuesta) => {
                respuesta.numeroRespuestas > 0 && totalRespuestas++;
            })
            setTotalRespuestasUsuario(totalRespuestas);
        }
    }
    const onClickCrearConsulta = () => {
        setVisibleModalConsulta(true);
    }
   /* const onClickVerConsulta = async (fila) => {
        setVisibleModalVerConsulta(true);
        setConsultaSeleccionada(fila);
        const _detallesconsulta = await PortalColaboradorService.listaDetalleConsultas(fila.id);         
        setDetallesConsulta(_detallesconsulta);
        const consultaEnviada = {
            consultaColaboradorId: fila.id,
            tipoDetalle:"respuestaUsuario",
        }
        await PortalColaboradorService.cambiarEstadoVisualizacionDetalleConsulta(consultaEnviada);
        obtenerNumeroDetalleNoVisualizadas(consultas);
        
     }*/
    const columnas = [
        {
            title: "Tipo",
            key: "tipoConsulta",
            align:"center",
            render: (fila) => (fila.tipoConsulta === null && fila.tipoDocumentoId !==null ? ("POR DOCUMENTO") :  
                               fila.tipoConsulta !== null && fila.tipoConsulta === TipoConsulta.POR_DOCUMENTO ?  "POR DOCUMENTO" : 
                                fila.tipoConsulta                            
            )
        },
        {
            title: "Asunto",
            //dataIndex: "asunto",
            key: "asunto",
            align: "center",
            render: (fila) => (fila.asunto !== null ? fila.asunto : fila.documentoNombre+" - "+fila.periodo)
        },
        {
            title: "Fecha Registro",
            key: "fechaRegistro",
            align:"center",
            render: (fila) => (                
                fila.fechaRegistro
            )
        },
        {
            title: "Estado",
           // dataIndex: "estadoConsulta",
            key: "estadoConsulta",
            align:"center",
            render: (fila) => (
                 <React.Fragment>
                     {numeroRespuestas.length>0 && numeroRespuestas.map((respuesta) => {
                         return(
                              fila.id === respuesta.consultaColaboradorId && (
                                 <Badge
                                     count={respuesta.numeroRespuestas} 
                                     offset={[12, 0]}    
                                 >                                     
                                     {/* {fila.estadoConsulta} */}
                                    { fila.estadoConsulta===null ?("ABIERTO"):fila.estadoConsulta}
                                 </Badge>
                               )
                         )
                     })}                                                  
                      
                 </React.Fragment> 
            )
        }, 
        // {
        //     title: "Consulta",
        //     key: "consulta",
        //     render: (fila) => (
        //         <React.Fragment>
        //             {numeroRespuestas.length>0 && numeroRespuestas.map((respuesta) => {
        //                 return(
        //                      fila.id === respuesta.consultaColaboradorId && (
        //                         <Badge
        //                           count={respuesta.numeroRespuestas}                                    
        //                         >
        //                           <Button type="link" onClick={()=>onClickVerConsulta(fila)}>ver</Button>
        //                         </Badge>
        //                       )
        //                 )
        //             })}                                                  
                      
        //          </React.Fragment>                
                
        //     )
        // },   
        {
            title: "Acciones",
            key: "acciones",
            align: "center",
            width: "10%",
            render: (fila) => (<MenuAcciones consulta={fila}/>)               
            
        }
    ]
    return (
        <React.Fragment>
            {totalRespuestasUsuario > 0 &&
                (
                <React.Fragment>
                    <Alert message="Estimado Colaborador, tiene notificaciones pendientes de revisar." type="info" showIcon />
                    <br/>
                </React.Fragment>    
                 )                           
            }            
            
        <Container titulo={"Bandeja Consulta"} botonExtra={<Button type="primary" icon={<PlusOutlined />} onClick={onClickCrearConsulta}>Crear Consulta</Button>}>
            <Table
                rowKey={(consultas)=>consultas.id}
                columns={columnas}
                dataSource={consultas}
            />                
            <ModalConsultar
                visibleModalConsultar={visibleModalConsulta}
                setVisibleConsultar={setVisibleModalConsulta}
            /> 
            <ModalVerConsulta              
            />                         
            
        </Container>
        
        </React.Fragment>
    )
    
}
export default BandejaConsultaWeb;