import React from "react";
import { EstadoVistaColaborador } from "../../../../../../../../../enum/EstadoVistaColaborador";
import { FileSearchOutlined } from "@ant-design/icons";

export function ColumnaDocumento({ fila }) {
	const estilo = {
		documentoBloqueado: {
			color: "#979393",
		},
		documentoPendiente: {
			// color: "#f1746d"
			color: "#faad14",
		},
		documentoRechazado: {
			color: "#f57d76",
		},
		documentoVisualizado: {
			color: "#3bb937",
		},
		periodo: {
			display: "block",
			fontWeight: "bold",
			color: "rgba(0, 0, 0, 0.65)",
		},
		nombreDocumento: {
			display: "block",
		},
		documentoPendienteAprobacion: {
			color: "#1890ff",
		},
	};

	const estiloEstadoImagen = () => {
		if (
			fila.estadoAprobacion != null &&
			fila.estadoAprobacion === "PENDIENTE" &&
			fila.estadoVistaColaborador === "FIRMADO"
		) {
			return estilo.documentoPendienteAprobacion;
		} else if (
			fila.estadoAprobacion != null &&
			fila.estadoAprobacion === "RECHAZADO" &&
			fila.estadoVistaColaborador === EstadoVistaColaborador.FIRMADO
		) {
			return estilo.documentoRechazado;
		} else if (
			fila.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE &&
			fila.permitirVisualizarDocumento === true
		) {
			return estilo.documentoPendiente;
		} else if (
			fila.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE &&
			fila.permitirVisualizarDocumento === false
		) {
			return estilo.documentoBloqueado;
		} else {
			return estilo.documentoVisualizado;
		}
	};

	return (
		<div className="documentos__item-documento">
			<div className="imagen">
				<FileSearchOutlined style={estiloEstadoImagen()} />
			</div>
			<div className="descripcion">
				<span style={estilo.periodo}>{fila.periodo}</span>
				<span style={estilo.nombreDocumento}>{fila.nombreDocumento}</span>
			</div>
		</div>
	);
}
