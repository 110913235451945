import { atom } from "jotai";

export const visibleModalCrearSolicitudAusenciaAtom = atom(false);
export const visibleModalFirmarSolicitudAusenciaAtom = atom(false);
export const visibleModalRecordVacacionalAtom = atom(false);
export const solicitudesAusenciaAtom = atom([]);
export const loadingSolicitudesAusenciaAtom = atom(false);
export const formularioSolicitarAusenciaAtom = atom(undefined);
export const tipoAusenciaSeleccionadaAtom = atom(undefined);
export const ocultarFechasSolicitadasAtom = atom(false);

export const archivosAdjuntosAtom = atom(new Map());

