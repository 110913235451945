import React from "react";
import { Tag } from "antd";
import { EstadoVisualizacion } from "enum/EstadoVisualizacion";
import { TagVisualizado } from "./TagVisualizado";

const EtiquetaEstadoVisualizacion = ({ documento }) => {
    switch (documento.estadoVisualizacion) {
        case EstadoVisualizacion.NO_APLICA:
            return <Tag color="default">No Aplica</Tag>;

        case EstadoVisualizacion.PENDIENTE:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;

        case EstadoVisualizacion.VISUALIZADO:
            return (
                <TagVisualizado documento={documento} color="green" className="milegajo-ant-tag pointer">
                    VISUALIZADO
                </TagVisualizado>
            );

        default:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;
    }
}

export default EtiquetaEstadoVisualizacion;