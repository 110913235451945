import Axios from "axios";
import { BaseService } from "./BaseService";
// import { ActualizarClavePage } from "../pages/cuenta/actualiza-clave/index copy";
// import Base from "antd/lib/typography/Base";

const GestionarClaveService = {
	async validarInformacion(nc, td, nd, subdominio) {
		const respuesta = await Axios.post(
			process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE +
				"/validarInformacion",
			{
				nc: nc,
				td: td,
				nd: nd,
				sd: subdominio,
			}
		);
		return respuesta.data;
	},
	async generarClave(
		subdominio,
		numeroDocumentoIdentidad,
		clave,
		numeroDocumentoIdentidadIngresado
	) {
		const respuesta = await Axios.post(
			process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE + "/generarClave",
			{
				subdominio: subdominio,
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
				clave: clave,
				numeroDocumentoIdentidadIngresado:
					numeroDocumentoIdentidadIngresado,
			}
		);
		return respuesta.data;
	},
	async actualizarClave(
		subdominio,
		tipoDocumentoIdentidad,
		numeroDocumentoIdentidad,
		codigo,
		fechaNacimiento,
		clave
	) {
		const respuesta = await Axios.post(
			process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE + "/actualizarClave",
			{
				subdominio: subdominio,
				tipoDocumentoIdentidad: tipoDocumentoIdentidad,
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
				codigo: codigo,
				fechaNacimiento: fechaNacimiento,
				clave: clave,
			}
		);
		return respuesta.data;
	},
	async recuperarClave(
		subdominio,
		numeroDocumentoIdentidad,
		correoElectronico
	) {
		const respuesta = await Axios.post(
			process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE + "/recuperarClave",
			{
				subdominio: subdominio,
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
				correoElectronico: correoElectronico,
			}
		);
		return respuesta.data;
	},
	async buscarCorreo(subdominio, numeroDocumentoIdentidad) {
		const respuesta = await Axios.post(
			process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE + "/buscarCorreo",
			{
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
				subdominio: subdominio,
			}
		);
		return respuesta.data;
	},
	async crearCodigoSeguridad(
		correoElectronico,
		numeroDocumentoIdentidad,
		subdominio
	) {
		const respuesta = await Axios.post(
			process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE +
				"/crearCodigoSeguridad",
			{
				correoElectronico: correoElectronico,
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
				subdominio: subdominio,
			}
		);
		return respuesta.data;
	},
	async cambiarClave(subdominio, numeroDocumentoIdentidad, clave) {
		// const respuesta = await Axios.post(
		//   process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE + "/cambiarClave",
		//   {
		//     subdominio: subdominio,
		//     numeroDocumentoIdentidad: numeroDocumentoIdentidad,
		//     clave: clave,
		//   }
		// );
		const respuesta = await BaseService.post(
			"/cuenta/colaborador/cambiarClave",
			{
				params: {
					subdominio: subdominio,
					numeroDocumentoIdentidad: numeroDocumentoIdentidad,
					clave: clave,
				},
			}
		);
		return respuesta.data;
	},
	async desencriptarDatosPersonalesColaborador(td, nd, ap, am, n, ce, c, ei,ri) {
		const respuesta = await Axios.post(
			process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE + "/desencriptarDatosPersonales",
			{
				td: td,
				nd: nd,
				ap: ap,
				am: am,
				n: n,
				ce: ce,
				c: c,
				ei: ei,
				ri:ri
			}
		);
		return respuesta.data;
	},
	async obtenerPoliticas(subdominio) {
		const respuesta = await BaseService.post(
			"/colaborador/clave/obtenerPoliticas",
			{
				subdominio: subdominio
			}
		);
		return respuesta.data;
	},
};

export default GestionarClaveService;
