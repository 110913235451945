import React from "react";
import { Button, Space } from "antd";
import { ProfileOutlined, DownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { PortalColaboradorService } from "services/PortalColaboradorService";
export const ItemDocumento = ({ solicitud }) => {
	const onClikDescargar = async () => {
		const respuesta = await PortalColaboradorService.obtenerUrlSolicitudDocumentoPersonal(solicitud?.id);
		saveAs(
			respuesta.urlSolicitud,
			solicitud?.tipoDocumentoNombre + "-" + solicitud?.colaborador.numeroDocumentoIdentidad + ".pdf"
		);
	};
	return (
		<div className={"bandeja-entrada__item-documento visualizado"}>
			<div className={"imagen visualizado"}>
				<ProfileOutlined />
			</div>
			<div className="informacion">
				<span className="item-documento__tipo">
					<Space>{solicitud?.tipoDocumentoNombre}</Space>
				</span>
				<span className="item-documento__tipo">
					<Space>{solicitud?.fechaHoraSolicitud}</Space>
				</span>
				<span className="item-documento__tipo">
					<Space>
						<Button type="link" icon={<DownloadOutlined />} onClick={onClikDescargar}>
							Descargar
						</Button>
					</Space>
				</span>
			</div>
		</div>
	);
};
