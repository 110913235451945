import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { VacacionesService } from "services";
import { visibleModalCrearSolicitudAusenciaAtom } from "../store/MisSolicitudesStore";

const useObtenerDetalleVacacionesColaborador = () => {
	const [visibleModalCrearSolicitudAusencia] = useAtom(visibleModalCrearSolicitudAusenciaAtom);
	const [detalleVacacionesColaborador, setDetalleVacacionesColaborador] = useState(undefined);
	useEffect(() => {
		const cargarDatos = async () => {
			if (visibleModalCrearSolicitudAusenciaAtom) {
				const detalleVacaciones = await VacacionesService.getDetalleVacacionesDelColaborador();
				setDetalleVacacionesColaborador(detalleVacaciones.data);
			}
		};
		cargarDatos();
	}, [visibleModalCrearSolicitudAusencia]);
	return { detalleVacacionesColaborador };
};

export default useObtenerDetalleVacacionesColaborador;
