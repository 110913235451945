import React from "react";
import { ModalCrearSolicitudAusencia, ModalFirmarSolicitudAusencia } from "../common/mis-solicitudes/componentes";
import { ListaSolicitudesAusencias, Header } from "./componentes/mis-solicitudes";
import { ModalRecordVacacional } from "./componentes/mis-solicitudes/ModalRecordVacacional";

const VacacionesPageMovil = () => {
	return (
		<React.Fragment>
			<Header />
			<ListaSolicitudesAusencias />
			<ModalCrearSolicitudAusencia />
			<ModalFirmarSolicitudAusencia />
			<ModalRecordVacacional />
		</React.Fragment>
	);
};

export default VacacionesPageMovil;
