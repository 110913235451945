import React, { useContext } from "react";
import { Descriptions, Modal, Table } from "antd";
import { useAtom } from "jotai";
import { visibleModalRecordVacacionalAtom } from "pages/vacaciones/componentes/common/mis-solicitudes/store/MisSolicitudesStore";
import { useObtenerDetalleVacacionesColaborador } from "pages/vacaciones/componentes/common/mis-solicitudes/hooks";
import { SecurityContext } from "context/SecurityContextProvider";

const ModalRecordVacacional = () => {
	const [visibleModal, setVisibleModal] = useAtom(visibleModalRecordVacacionalAtom);
	const { detalleVacacionesColaborador } = useObtenerDetalleVacacionesColaborador();
	const { getUsuarioAutenticado } = useContext(SecurityContext);

	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;

	return (
		<Modal
			title={"Vacaciones Disponibles"}
			cancelButtonProps={{ style: { display: "none" } }}
			width={600}
			centered
			open={visibleModal}
			onCancel={() => {
				setVisibleModal(false);
			}}
			onOk={() => setVisibleModal(false)}
			okText={"Cerrar"}
		>
			<div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
				<span style={{ fontSize: "20px", fontWeight: "bold", color: "#108ee9", textAlign: "center" }}>
					{isAceros && detalleVacacionesColaborador?.vacacionesDisponiblesSinDiasTruncos}
					{!isAceros && detalleVacacionesColaborador?.vacacionesDisponibles} DÍAS
				</span>
				<span style={{ fontSize: "13px", textAlign: "center" }}>disponibles</span>
			</div>
			<ListaPeriodosVacaciones detalleVacacionesColaborador={detalleVacacionesColaborador} />
		</Modal>
	);
};

const ListaPeriodosVacaciones = ({ detalleVacacionesColaborador }) => {
	if (detalleVacacionesColaborador === null) {
		return "";
	}

	const periodosVacacionales = detalleVacacionesColaborador.periodosVacacionales?.map((fila, index) => (
		<div key={index} style={{ marginBottom: "10px" }}>
			<span>
				Periodo:{" "}
				<b>
					{fila.periodoInicio} - {fila.periodoFin}
				</b>
			</span>
			<Descriptions
				key={index}
				bordered
				size="small"
				layout="vertical"
				column={4}
				labelStyle={{ display: "flex", textAlign: "center" }}
				contentStyle={{ display: "flex", justifyContent: "center" }}
			>
				<Descriptions.Item label="Vacaciones Ganadas">{fila.vacacionesGanadas}</Descriptions.Item>
				<Descriptions.Item label="Vacaciones Gozadas">{fila.vacacionesGozadas}</Descriptions.Item>
				<Descriptions.Item label="Vacaciones Pendientes">{fila.vacacionesPendientes}</Descriptions.Item>
			</Descriptions>
		</div>
	));
	return periodosVacacionales;
};

export default ModalRecordVacacional;
