import React, { useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { Button, Modal, Upload, notification } from 'antd';
import { Headers, TipoTarea } from 'enum';
import LoginService from 'services/LoginService';
// import { TipoTarea } from 'enum/reclutamientoyseleccion';
import { PortalColaboradorService } from 'services/PortalColaboradorService';
import { useMediaQuery } from 'react-responsive';

import {
    PlusOutlined
} from "@ant-design/icons"
import { TareasPendientesService } from 'services';

const SubirImagen = ({ tareaSeleccionada, onSuccess }) => {

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [uid, setUid] = useState("");
    const esMovil = useMediaQuery({ query: "(max-width: 860px)" });

    const propsCarga = {
        name: "file",
        multiple: true,
        action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/cargarDocumentos",
        headers: {
            Authorization: "Bearer " + LoginService.getJwtToken(),
            [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
            [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
            [Headers.TIPO_USUARIO]: "COLABORADOR",
            [Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
        },
        data: {
            detalleEnvioId: tareaSeleccionada.procesoId,
            uid: uid,
        },
        beforeUpload(file) {
            setUid(file.uid);
            return file;
        },
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const onChangeFile = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };

    const onClickCargarDocumento = async () => {
        setUploading(true);

        if (tareaSeleccionada.tipo === TipoTarea.CARGA_DOCUMENTO) {
            const datosCargaDocumento = {
                listaUids: fileList.map((c) => c.uid),
                tareaId: tareaSeleccionada.id,
                detalleEnvioId: tareaSeleccionada.procesoId,
                formato: "IMAGEN"
            };
            const data = await TareasPendientesService.subirDocumento(datosCargaDocumento);

            if (data === true) {
                onSuccess();
                notification.success({
                    description: "Documento Cargado",
                    message: "Documento Cargado Correctamente",
                });
            } else {
                notification.error({
                    description: "Error al cargar",
                    message: "Ha ocurrido un error",
                });
            }
        }

        setUploading(false);
    }

    const handleCancel = () => setPreviewVisible(false);

    return (
        <div>
            <ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
                <Upload
                    {...propsCarga}
                    listTypeCarga="picture-card"
                    fileList={fileList}
                    onChange={onChangeFile}
                    onPreview={handlePreview}
                >
                    <Button icon={<PlusOutlined />} > Agregar Imágen</Button>
                </Upload>
            </ImgCrop>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button
                    type="primary"
                    onClick={onClickCargarDocumento}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    size="large"
                    style={{
                        borderRadius: "5px",
                    }}
                >
                    {uploading ? "Cargando" : "Enviar Documentos"}
                </Button>
            </div>
            <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} destroyOnClose>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
}

export default SubirImagen;