import create from 'zustand'
import { saveAs } from "file-saver";
//import { message } from 'antd';


const useModalVisorPdf = create((set, get) => ({
    loading: false,
    visible: false,
    visibleBtnImprimir: false,
    visibleBtnDescargar: false,
    titulo: undefined,
    urlPdf: undefined,
    blobPdf: undefined,   
    finProceso: false,
    
    mostrarModal: (urlPdf, titulo, visibleBtnImprimir = false, visibleBtnDescargar = false ) => {
        set({ loading: true, visible: true });
        set({ urlPdf, titulo, visibleBtnImprimir, visibleBtnDescargar })
    },
    cerrarModal: () => {
        set({ visible: false});
    },
    imprimirPdf: () => {
        window.open(URL.createObjectURL(get().blobPdf)).print();
    },
    descargarPdf: () => {
        saveAs(get().blobPdf, get().titulo);
    },
    setLoading: (estado) => {
        set({ loading: estado });
    },
    setBlobPdf: (blob) => {
        set({ blobPdf: blob });
    },
    
}));

export default useModalVisorPdf;