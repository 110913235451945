import React from "react";
import { Button, Space } from "antd";
import { useAtom } from "jotai";
import {
	visibleModalCrearSolicitudAusenciaAtom,
	visibleModalRecordVacacionalAtom,
} from "pages/vacaciones/componentes/common/mis-solicitudes/store/MisSolicitudesStore";

const BotonesMisSolicitudes = () => {
	const [, setVisibleModal] = useAtom(visibleModalCrearSolicitudAusenciaAtom);
	const [, setVisibleModalRecordVacacional] = useAtom(visibleModalRecordVacacionalAtom);

	return (
		<div style={{ textAlign: "right" }}>
			<Space>
				<Button onClick={() => setVisibleModalRecordVacacional(true)}>Vacaciones Disponibles</Button>
				<Button type="primary" onClick={() => setVisibleModal(true)}>
					Solicitar Ausencia
				</Button>
			</Space>
		</div>
	);
};

export default BotonesMisSolicitudes;
