import React, { useContext, useState, useEffect } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { ContainerFormulario } from "components/ContainerFormulario";
import { useHistory, useParams } from "react-router";
import { PortalColaboradorService } from "services/PortalColaboradorService";
// import { Form } from "@formio/react";

const CompletarFormularioPage = () => {
	let { documentoId, formularioId } = useParams();

	const { getUsuarioAutenticado } = useContext(SecurityContext);

	const [loading, setLoading] = useState(false);
	const [esquemaJson, setEsquemaJson] = useState(undefined);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const _formulario = await PortalColaboradorService.buscarFormulario(
					formularioId,
					getUsuarioAutenticado().empresaSeleccionada.id
				);
				if (_formulario.esquemaJson) {
					// setFormulario(_formulario);
					setEsquemaJson(JSON.parse(_formulario.esquemaJson));
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, []);

	const onSubmitFormulario = async (datos) => {
		try {
			setLoading(true);
			// const detalleEnvio = {
			//     organizacionId: getUsuarioAutenticado().organizacionId,
			//     empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
			//     formularioId: formularioId,
			//     colaboradorId: getUsuarioAutenticado().id,
			//     nombreFormulario: formulario.nombre,
			//     datos: JSON.stringify(datos.data),
			// };
			// console.log("datos", detalleEnvio);
			// await PortalColaboradorService.registrarDocumentoFormulario(
			//     detalleEnvio
			// );
			// notification.success({
			//     message: "Operación exitosa",
			//     description: "Se ha enviado el formulario de forma exitosa.",
			// });
			// history.push("/mis-documentos");
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<ContainerFormulario titulo="Completar Formulario">
			{/* <Form form={esquemaJson} onSubmit={(data) => onSubmitFormulario(data)} /> */}
		</ContainerFormulario>
	);
};

export default CompletarFormularioPage;
