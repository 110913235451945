import { BaseService } from "./BaseService";

const SolicitudDocumentoService = {
	async insertar(datos) {
		const respuesta = await BaseService.post("/solicitud-documento", datos);
		return respuesta.data;
	},
	async listar(numeroDocumentoIdentidad) {
		const respuesta = await BaseService.get("/solicitud-documento", {
			params: { documentoIdentidad: numeroDocumentoIdentidad },
		});
		return respuesta.data;
	},
};
export default SolicitudDocumentoService;
