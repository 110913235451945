import { atom } from "jotai";

export const visibleModalCrearSolicitudAtom = atom(false);
export const perfilSeleccionadoAtom = atom(undefined);
export const solicitudesPersonalAtom = atom([]);
export const cargosAtom = atom([]);
export const cargoSeleccionadoAtom = atom(undefined);
export const solicitudPersonalIdSeleccionadoAtom = atom(undefined);
export const visibleModalCancelarSolicitudAtom = atom(false);

