import React from "react";
import { Input, List } from "antd";
import { HeaderMovil } from "components/common";
import { useListarTareasPendientes } from "../common/hooks";
import styled from "@emotion/styled";
import { EditOutlined, CalendarOutlined, UserOutlined, FormOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const TareasPendientesPageMovil = () => {
	return <>
		<HeaderMovil titulo="Tareas Pendientes" />
		<InputBusqueda />
		<ListaTareas />
	</>;
};

const InputBusqueda = () => {
	const { onSearchTareasPendientes } = useListarTareasPendientes();

	return (
		<Input.Search
			placeholder="buscar por colaborador..."
			onSearch={onSearchTareasPendientes}
		// style={{
		// 	marginBottom: "5px",
		// }}
		/>
	)
}

const ListaTareas = () => {

	const { tareasPendientes, loading } = useListarTareasPendientes();
	const history = useHistory();

	const onClick = (tareaPendienteId) => {
		history.push(`/movil/tareas-pendientes/${tareaPendienteId}`);
	}

	return (
		<ContainerListaTareas>
			<List
				loading={loading}
				dataSource={tareasPendientes}
				renderItem={(tareaPendiente) => (
					<ItemTareaPendiente
						tareaPendiente={tareaPendiente}
						onClick={() => onClick(tareaPendiente.id)} />
				)}

			/>
		</ContainerListaTareas>
	);

}

const ItemTareaPendiente = ({ tareaPendiente, onClick }) => {
	return (
		<ContainerItemTareaPendiente onClick={onClick}>
			<span style={{ fontWeight: "bold" }}>
				<FormOutlined style={{ fontSize: "14px", marginRight: "8px", color: "#6c757d" }} />
				{tareaPendiente?.descripcion}
			</span>
			<span>
				<UserOutlined style={{ fontSize: "14px", marginRight: "8px", color: "#6c757d" }} />
				{tareaPendiente?.colaborador?.nombreCompleto}
			</span>
			<span>
				<CalendarOutlined style={{ fontSize: "14px", marginRight: "8px", color: "#6c757d" }} />
				{tareaPendiente?.fechaRegistro}
			</span>
		</ContainerItemTareaPendiente>
	)
}

const ContainerListaTareas = styled.div`
	margin-top: 5px;
	border-radius: 5px;
	height: calc(100vh - 155px);
	overflow: auto;
`

const ContainerItemTareaPendiente = styled.span`
    display: flex;
	flex-direction: column;
	min-height: 20px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	margin: 10px 15px;
	padding-top: 8px;
	padding-bottom: 10px;
	padding-left: 15px;
	border-left: 5px solid #faad14;
`

export default TareasPendientesPageMovil;
