import React, { useEffect } from 'react';
import { Button, Card, Row } from 'antd';
import { useAtom } from 'jotai';
import { openModalOmitirAtom, rutaAtom } from './SubirDocumentoStorage';
import styled from '@emotion/styled';
import SubirImagen from './components/SubirImagen';

import {
    FilePdfOutlined,
    FileImageOutlined,
    LeftOutlined
} from "@ant-design/icons"
import SubirPdf from './components/SubirPdf';
import ModalOmitir from './components/ModalOmitir';
import { useDispositivo } from 'hooks';

const SubirDocumento = ({ tareaSeleccionada, onSuccess }) => {

    const [ruta, setRuta] = useAtom(rutaAtom);
    const [, setOpenModalOmitir] = useAtom(openModalOmitirAtom);
    const { isMovil } = useDispositivo();

    const onClickBtnOmitir = () => {
        setOpenModalOmitir(true);
    }

    return (
        <Container>
            <Header>
                <Row style={{ width: "100%" }} justify={ruta !== "home" ? "space-between" : "end"}>
                    {ruta !== "home"
                        && <Button type="link" onClick={() => setRuta("home")}>
                            <LeftOutlined />
                            Retornar
                        </Button>
                    }

                    <Button type="primary" danger onClick={() => onClickBtnOmitir()}>
                        Omitir
                    </Button>
                </Row>
            </Header>
            <Body>
                <div style={{ padding: "10px 0px", fontSize: "16px", fontWeight: "bold" }}>
                    {tareaSeleccionada.descripcion}
                </div>
                {ruta === "home" && <Home />}
                {ruta === "subir-imagen" && <SubirImagen tareaSeleccionada={tareaSeleccionada} onSuccess={onSuccess} />}
                {ruta === "subir-pdf" && <SubirPdf tareaSeleccionada={tareaSeleccionada} onSuccess={onSuccess} />}
                <ModalOmitir tareaSeleccionada={tareaSeleccionada} onSuccess={onSuccess} />
            </Body>
        </Container >
    );
};

const Home = () => {

    const [ruta, setRuta] = useAtom(rutaAtom);

    const onClickSeleccionarImagen = () => {
        setRuta("subir-imagen");

    }

    const onClickSeleccionarPdf = () => {
        setRuta("subir-pdf");
    }

    return (
        <React.Fragment>
            <Card
                hoverable
                onClick={onClickSeleccionarImagen}
                style={{
                    width: "calc(100% - 100px)",
                    height: "150px",
                    borderRadius: "5px",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ContainerItem>
                    <FileImageOutlined style={{ fontSize: "40px", color: "#8e9aaf" }} />
                    <span style={{ fontSize: "16px", marginTop: "10px", color: "#8e9aaf" }}>
                        Seleccionar Imagen
                    </span>
                </ContainerItem>
            </Card>
            <Card
                hoverable
                onClick={onClickSeleccionarPdf}
                style={{
                    width: "calc(100% - 100px)",
                    height: "150px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ContainerItem>
                    <FilePdfOutlined style={{ fontSize: "40px", color: "#8e9aaf" }} />
                    <span style={{ fontSize: "16px", marginTop: "10px", color: "#8e9aaf" }}>
                        Seleccionar PDF
                    </span>
                </ContainerItem>
            </Card>
        </React.Fragment>
    )
}

const Container = styled.div`
	height: calc(100% - 50px);
`;

const Body = styled.div`
	border: 1px solid #ccc;
	border-radius: 0 0 10px 10px;
	height: 100%;
	width: 100%;
	position: relative;
	// padding: 0px 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Header = styled.div`
	min-height: 50px;
	max-height: 50px;
	display: flex;
	background: white;
	padding: 0px 10px;
	border: 1px solid #ccc;
	border-radius: 10px 10px 0 0;
	border-bottom: 0px;
	align-items: center;
`;

const ContainerItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export default SubirDocumento;