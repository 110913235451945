import { atom } from "jotai";

export const loadingAtom = atom(true);
export const pasoActualAtom = atom(0);
export const statusOnboardingAtom = atom(undefined);
export const estadoFirmaOnboardingAtom = atom(undefined);
export const estadoFirmaEnviadoValidacionOnboardingAtom = atom(false);
export const estadoHuellaOnboardingAtom = atom(undefined);
export const informacionPersonalCompletadoAtom = atom(undefined);
export const estadoHuellaEnviadoValidacionOnboardingAtom = atom(false);
export const informacionPersonalAtom = atom(undefined);
export const documentosAtom = atom(undefined);
//export const documentoSeleccionadoIdAtom = atom(undefined);
export const informacionVisorAtom = atom(undefined);
export const documentoSeleccionadoAtom = atom(undefined);
export const visibleModalFirmarAtom = atom(false);
export const datosFormularioAtom = atom(undefined);
export const visibleVisorAtom = atom(true);
export const visibleSubirDocumentosAtom = atom(false);
export const rutaSubirDocumentoAtom = atom("home");
export const departamentoAtom = atom([]);
export const provinciaAtom = atom([]);
export const distritoAtom = atom([]);
export const mostrarCamposPorPaisAtom = atom(false);
export const departamentoNacimientoAtom = atom([]);
export const provinciaNacimientoAtom = atom([]);
export const distritoNacimientoAtom = atom([]);
export const loadingPdfAtom = atom(true);
export const segmentoSeleccionadoAtom = atom("PENDIENTES");

export const visibleModalOmitirAtom = atom(false);
