import { atom } from "jotai";

export const zoomVisorFormularioAtom = atom(1);
export const urlPdfAtom = atom(undefined);
export const componentesAtom = atom(undefined);
export const totalPaginasAtom = atom(undefined);

export const signatureModalVisibleAtom = atom(false);
export const signaturesMetadataAtom = atom([]);
export const componentSelectedAtom = atom(undefined);
export const variablesGlobalesAtom = atom(undefined);
export const datosFormularioAtom = atom(undefined);
export const errorMostradoAtom = atom(undefined);
export const motivoRechazoAtom = atom(undefined);
export const formAtom = atom(undefined);
export const loadingAtom = atom(false);
export const urlFirmaHolografaAtom = atom(undefined);
export const urlHuellaDactilarAtom = atom(undefined);
export const urlFotoAtom = atom(undefined);

export const visibilidadComponentesAtom = atom({});
export const typeAtom = atom("pdf");