import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { MiPortalPageWeb } from './componentes/web';
import { Provider as JotaiProvider } from "jotai";

const MiPortalPage = () => {
    const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
    const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });
    return (
        <JotaiProvider>
            {esDesktop && <MiPortalPageWeb />}
            {/* {esMovil && <VacacionesPageMovil />} */}
        </JotaiProvider>
    );
};

export default MiPortalPage;