import React from "react";
import { Descriptions } from "antd";
import {
	usePreguntasCalidad,
	usePreguntasLimpiezaSeguridad,
	usePreguntasOrientacionResultados,
	usePreguntasProactividad,
	usePreguntasRelacionesInterpersonales,
	usePreguntasTrabajoEnEquipo,
	useResultadoEvaluacion,
} from "../../hooks";

const FactoresEvaluacion = () => {
	const { puntajeTotal: puntajeTotalCalidad } = usePreguntasCalidad();
	const { puntajeTotal: puntajeTotalProactividad } = usePreguntasProactividad();
	const { puntajeTotal: puntajeTotalTrabajoEnEquipo } = usePreguntasTrabajoEnEquipo();
	const { puntajeTotal: puntajeTotalOrientacionResultados } = usePreguntasOrientacionResultados();
	const { puntajeTotal: puntajeTotalRelacionesInterpersonales } = usePreguntasRelacionesInterpersonales();
	const { puntajeTotal: puntajeTotalLimpiezaSeguridad } = usePreguntasLimpiezaSeguridad();
	const { puntajeTotalEvaluacion } = useResultadoEvaluacion();
	return (
		<div style={{ marginBottom: "20px" }}>
			<div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px" }}>FACTORES DE EVALUACIÓN</div>
			<Descriptions bordered size="small" column={1} labelStyle={{ width: "70%" }}>
				<Descriptions.Item label="CALIDAD">{puntajeTotalCalidad}</Descriptions.Item>
				<Descriptions.Item label="PROACTIVIDAD">{puntajeTotalProactividad}</Descriptions.Item>
				<Descriptions.Item label="TRABAJO EN EQUIPO">{puntajeTotalTrabajoEnEquipo}</Descriptions.Item>
				<Descriptions.Item label="ORIENTACIÓN A RESULTADOS">
					{puntajeTotalOrientacionResultados}
				</Descriptions.Item>
				<Descriptions.Item label="RELACIONES INTERPERSONALES">
					{puntajeTotalRelacionesInterpersonales}
				</Descriptions.Item>
				<Descriptions.Item label="LIMPIEZA Y SEGURIDAD">{puntajeTotalLimpiezaSeguridad}</Descriptions.Item>
				<Descriptions.Item label="TOTAL">{puntajeTotalEvaluacion}</Descriptions.Item>
			</Descriptions>
		</div>
	);
};

export default FactoresEvaluacion;
