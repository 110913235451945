import React, { useEffect, useState } from "react";
import { Alert, Button, Input, message, Modal } from "antd";
import { useAtom } from "jotai";
import { SolicitudAusenciaService } from "services";
import { ApiResponse } from "enum";
import produce from "immer";
import {
	solicitudAusenciaSeleccionadaAtom,
	solicitudesAusenciaPendientesAprobarAtom,
	visibleModalAprobarSolicitudAusenciaAtom,
} from "../../SolicitudesPendientesAprobarStore";

const ModalAprobarSolicitudAusencia = () => {
	const [, setSolicitudesPendientesAprobar] = useAtom(solicitudesAusenciaPendientesAprobarAtom);
	const [solicitudAusenciaSeleccionada] = useAtom(solicitudAusenciaSeleccionadaAtom);
	const [visibleModalAprobar, setVisibleModalAprobar] = useAtom(visibleModalAprobarSolicitudAusenciaAtom);
	const [loading, setLoading] = useState(false);
	const [claveIngresada, setClaveIngresada] = useState("");
	const [mensajeError, setMensajeError] = useState("");

	useEffect(() => {
		if (visibleModalAprobar) {
			setClaveIngresada("");
			setMensajeError("");
		}
	}, [visibleModalAprobar]);

	const onClickBtnAprobar = async () => {
		setLoading(true);
		const response = await SolicitudAusenciaService.aprobarPorJefeInmediato({
			solicitudAusenciaId: solicitudAusenciaSeleccionada.id,
			clave: claveIngresada,
		});
		if (response.estado === ApiResponse.EXITO) {
			message.success("Se ha aprobado la solicitud correctamente.");
			setVisibleModalAprobar(false);
			setSolicitudesPendientesAprobar(
				produce((draft) => {
					const index = draft.findIndex((solicitud) => solicitud.id === solicitudAusenciaSeleccionada.id);
					draft.splice(index, 1);
				})
			);
		} else {
			setMensajeError(response.mensaje);
		}
		setLoading(false);
	};
	const onClickBtnCancelar = () => {
		setVisibleModalAprobar(false);
	};
	return (
		<Modal
			title={
				<div>
					Aprobar Solicitud de: <b>{solicitudAusenciaSeleccionada?.colaborador.nombreCompleto}</b>
				</div>
			}
			visible={visibleModalAprobar}
			closable={false}
			width={400}
			destroyOnClose={true}
			footer={[
				<Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
					Cancelar
				</Button>,
				<Button
					key="aprobar"
					type="primary"
					loading={loading}
					onClick={onClickBtnAprobar}
					style={{ padding: "0px 30px" }}
				>
					Aprobar
				</Button>,
			]}
			centered
		>
			{mensajeError && (
				<Alert
					message={mensajeError}
					onClose={() => setMensajeError(undefined)}
					style={{ marginBottom: "4px" }}
					type="error"
					showIcon
					closable
				/>
			)}
			<span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
			<Input.Password
				value={claveIngresada}
				onChange={(e) => {
					setClaveIngresada(e.target.value);
					setMensajeError(undefined);
				}}
				placeholder={"Clave"}
			/>
		</Modal>
	);
};

export default ModalAprobarSolicitudAusencia;
