import React from "react";
import { useListarSolicitudPersonal } from "pages/solicitud-personal/componentes/common/hooks";
import { ItemDocumento } from "./ItemDocumento";

const ListaSolicitudesPersonal = () => {
    const { solicitudesPersonal } = useListarSolicitudPersonal()
    return (
        <div className="bandeja-entrada__container-documentos">
            <MisSolicitudes solicitudes={solicitudesPersonal}/>
        </div>
    )
};
const MisSolicitudes = ({ solicitudes }) => {
	const items = solicitudes.map((solicitud, index) => <ItemDocumento key={index} solicitud={solicitud} />);
	return items;
};
export default ListaSolicitudesPersonal;