import React, { useEffect, useState } from "react";
import { CalendarOutlined, FileOutlined, EditOutlined, FileSearchOutlined,UnorderedListOutlined, FileSyncOutlined,FieldTimeOutlined,CloseCircleOutlined, ExclamationCircleOutlined, CaretDownOutlined,CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import { Space, Menu, Dropdown, Modal, Button, message } from "antd";
import { SolicitudAusenciaService } from "services";
import { ApiResponse } from "enum";
import { useCargarSolicitudesAusencia } from "pages/vacaciones/componentes/common/mis-solicitudes/hooks";

export const ItemDocumento = ({ solicitud }) => {
	const [classNameEstado, setClassNameEstado] = useState(" loading");
	const { cargarDatos } = useCargarSolicitudesAusencia();
	useEffect(() => {
		if (solicitud?.estado === "RECHAZADO") {
			setClassNameEstado(" rechazado");
		} else if (solicitud?.estado === "PENDIENTE") {
			setClassNameEstado(" pendiente");
		} else {
			setClassNameEstado(" visualizado");
		}
	}, [solicitud]);

	const cancelarSolicitud = async () => {
		console.log("cancelar solicitud");
		const response = await SolicitudAusenciaService.cancelarSolicitud({ solicitudAusenciaId: solicitud.id });
		if (response.estado === ApiResponse.EXITO) {
			message.success("Se ha cancelado la solicitud correctamente.");

			cargarDatos();
		} else {
			message.error(response.mensaje);
		}
	};

	const onClickCancelarSolicitud = () => {
		console.log("movilllll");
		Modal.confirm({
			icon: <ExclamationCircleOutlined />,
			content: "¿Está seguro de cancelar la solicitud ?",
			onOk() {
				cancelarSolicitud();
			},
			onCancel() {},
			okText: "Si",
			cancelText: "No",
		});
	}

	const onClickMenu = (opcion) => {
		
		switch (opcion.key) {
			case "cancelar-solicitud":
				onClickCancelarSolicitud();
				break;

			default:
				console.error("No se encuentra esa opción");
		}
	}
	const menu = () => {
		return (
			<Menu onClick={onClickMenu} >
				<Menu.Item key="cancelar-solicitud">
					<CloseOutlined />Cancelar Solicitud
				</Menu.Item>
			</Menu>
		);
	};

	const onClick = ({ key }) => {
		switch (key) {
			case "cancelar-solicitud":
				onClickCancelarSolicitud();
				break;
			default:
				break;
		}
	};

	const items = [
		{
			label: "Cancelar Solicitud",
			key: "cancelar-solicitud",
			icon: <CloseCircleOutlined style={{ fontSize: "16px" }} />,
		},
	];

	return (
		<div className={"bandeja-entrada__item-documento" + classNameEstado}>
			<div className={"imagen" + classNameEstado}>
				{solicitud?.estado === "PENDIENTE" && <FileSyncOutlined />}
				{solicitud?.estado !== "PENDIENTE" && <FileSearchOutlined />}
			</div>
			<div className="informacion">
				<span className="item-documento__tipo">
					{/* <Button type="primary" onClick={handleOnClickCancelarSolicitud}>
						Cancelar solicitud
					</Button> */}
					{
						solicitud?.estado == "PENDIENTE" && (
							<div style={{ textAlign: "end" }}>                  
								{/* <Dropdown menu={{ items, onClick }}>
									<Button type="link" className="ant-dropdown-link">
										<UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
										<CaretDownOutlined
											style={{
												paddingLeft: "2px",
												fontSize: "12px",
												fontWeight: "bold",
												color: "#1678c1",
											}}
										/>
									</Button>
								</Dropdown>	 */}
								<Dropdown overlay={menu} trigger={["click"]} placement="bottomRight" arrow>
									<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
										<UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
										<CaretDownOutlined
											style={{ paddingLeft: "2px", fontSize: "12px", fontWeight: "bold", color: "#1678c1" }}
										/>
									</a>
								</Dropdown>
							</div>
						)
					}
				</span>
				<span className="item-documento__descripcion">
					<Space>
						<CalendarOutlined />
						<b>
							{moment(solicitud?.fechaInicioAusencia).format("DD/MM/YYYY") +
								" - " +
								moment(solicitud?.fechaFinAusencia).format("DD/MM/YYYY")}
						</b>
						{` (${solicitud?.diasSolicitados} días)`}
					</Space>
				</span>
				{solicitud.tipo.contabilizarHoras &&
					<span className="item-documento__descripcion">
						<Space>
							<FieldTimeOutlined />
							<b>
								{moment(solicitud?.fechaInicioAusencia).format("hh:mm a") +
									" - " +
									moment(solicitud?.fechaFinAusencia).format("hh:mm a")}
							</b>
						
						</Space>
					</span>
				}
				<span className="item-documento__tipo">
					<Space>
						<FileOutlined />
						{solicitud?.tipo?.descripcion}
					</Space>
				</span>
				<span className="item-documento__tipo">
					<Space>
						<EditOutlined />
						{solicitud?.jefeInmediato?.nombreCompleto}
					</Space>
				</span>

				<span className={"item-documento__estado" + classNameEstado}>{solicitud?.estado}</span>
				
			</div>
		</div>
	);
};
