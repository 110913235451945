import { cargoSeleccionadoAtom } from "../store/MisSolicitudesPersonalStore";
import CargoService from "services/CargoService";
import { useAtom } from "jotai";

const useSeleccionarCargo = () => {
    
    const [cargo, setCargo] = useAtom(cargoSeleccionadoAtom);

    const onChangeCargo = async (cargoId) => {
        const respuesta = await CargoService.buscarPorId(cargoId);
		setCargo(respuesta);		
	};

	const limpiarCargo = () => {
		setCargo(undefined);
	};

	return { cargo, limpiarCargo, onChangeCargo };
    
}
export default useSeleccionarCargo;