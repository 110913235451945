import React from "react";
import { FileSearchOutlined } from "@ant-design/icons";

export function ItemDocumentoPendiente({ documento, onClick }) {
	function EtiquetaEstado() {
		if (
			documento.estadoProcesoId === 1 &&
			(!documento.envio.tipoDocumentoFirmaConformidadColaborador ||
				documento.envio.tipoDocumentoFirmaConformidadColaborador ===
				null)
		) {
			// return <Tag color="red">PENDIENTE</Tag>;
			return "PENDIENTE";
		} else if (
			documento.estadoProcesoId === 1 &&
			documento.envio.tipoDocumentoFirmaConformidadColaborador
		) {
			return "PENDIENTE DE FIRMA";
		} else if (documento.estadoProcesoId === 7) {
			return "PENDIENTE DE FIRMA";
		} else if (documento.estadoProcesoId === 3) {
			return "PENDIENTE DE CARGA";
		} else {
			return "";
		}
	}

	return (
		<div
			className="bandeja-entrada__item-documento-pendiente"
			onClick={onClick}
		>
			<div className="imagen">
				<FileSearchOutlined />
			</div>
			<div className="informacion">
				<span className="item-documento__descripcion">
					<b>{documento.envio.descripcion}</b>
				</span>
				<span className="item-documento__tipo">
					{documento.envio.tipoDocumentoNombre}
				</span>
				<span className="item-documento__estado-pendiente">
					<EtiquetaEstado />
				</span>
			</div>
			{/* <div className="acciones">
				<EtiquetaEstado />
			</div> */}
		</div>
	);
}
