import React, { useEffect, useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";
import { ItemDocumento } from "./ItemDocumento";
import { LoadingDocumentos } from "components/LoadingDocumentos";
import { Alert } from "antd";
import * as atomsConsulta from "../../../../hooks/consultas/ConsultasStorage";
import { useAtom } from "jotai";
import { PortalColaboradorService } from "services/PortalColaboradorService";

const ListaDocumentos = () => {
	const { loading, documentos, getDocumentos } = useContext(MisDocumentosContext);

	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [totalRespuestasUsuario, setTotalRespuestasUsuario] = useAtom(atomsConsulta.totalRespuestasUsuarioAtom);

	useEffect(() => {
		getDocumentos(getUsuarioAutenticado().empresaSeleccionada.id, getUsuarioAutenticado().numeroDocumentoIdentidad);
		obtenerNumeroDetalleNoVisualizadas();
	}, []);

	const obtenerNumeroDetalleNoVisualizadas = async () => {
		const consultas = await PortalColaboradorService.listaConsultas(
			getUsuarioAutenticado().empresaSeleccionada.id,
			getUsuarioAutenticado().numeroDocumentoIdentidad
		);
		let listaConsultasId = [];
		if (consultas.length > 0) {
			consultas.map((consulta) => {
				listaConsultasId.push(consulta.id);
			});
			const respuestasXConsulta = await PortalColaboradorService.listaNumeroRespuestas(
				listaConsultasId,
				"respuestaUsuario"
			);
			let totalRespuestas = 0;
			respuestasXConsulta.length > 0 &&
				respuestasXConsulta.map((respuesta) => {
					respuesta.numeroRespuestas > 0 && totalRespuestas++;
				});
			setTotalRespuestasUsuario(totalRespuestas);
		}
	};
	const Documentos = () => {
		const items = documentos.map((documento, index) => <ItemDocumento key={index} documento={documento} />);
		return items;
	};

	return (
		<div className="bandeja-entrada__container-documentos">
			{totalRespuestasUsuario > 0 && (
				<Alert
					message="Estimado Colaborador, tiene notificaciones pendientes de revisar."
					type="info"
					showIcon
				/>
			)}
			{loading && <LoadingDocumentos items={5} />}
			{!loading && <Documentos />}
		</div>
	);
};

export default ListaDocumentos;
