export const bodyStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
}
export const styleIcono = {
    fontSize: "40px",
    color: "#519251",
};

export const styleTitulo = {
    fontSize: "16px",
    color: "#188018",
    marginTop: "8px"
}

export const styleMensaje = {
    color: "#908f8f"
}

export const styleBoton = {
    marginTop: "10px"
}