import React, { useEffect, useState } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Document } from "react-pdf";
import {
	formularioSeleccionadoAtom,
	urlFirmaHolografaAtom,
	urlHuellaDactilarAtom,
	urlFotoAtom,
	loadingPdfAtom,
} from "pages/mis-documentos/componentes/MisDocumentosWeb/MisDocumentosWebStorage";
import { useAtom } from "jotai";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import PaginaPdf from "./PaginaPdf";
import BarraHerramientas from "./BarraHerramientas";
import { Alert } from "antd";
import { SolicitudAusenciaService } from "services";

export function Body({ control }) {
	const [formularioSeleccionado] = useAtom(formularioSeleccionadoAtom);
	const [totalPaginas, setTotalPaginas] = useState(undefined);
	const [urlPdf, setUrlPdf] = useState(undefined);
	const [componentes, setComponentes] = useState(undefined);
	const [variablesFormulario, setVariablesFormulario] = useState(undefined);
	const [datosFormulario, setDatosFormulario] = useState(undefined);
	const [loading, setLoading] = useState(false);

	const [, setUrlFirmaHolografa] = useAtom(urlFirmaHolografaAtom);
	const [, setUrlHuellaDacilar] = useAtom(urlHuellaDactilarAtom);
	const [, setUrlFoto] = useAtom(urlFotoAtom);
	const [loadingPdf, setLoadingPdf] = useAtom(loadingPdfAtom);
	useEffect(() => {
		const obtenerUrlPlantillaPdf = async () => {
			setLoading(true);
			const respuesta = await PortalColaboradorService.obtenerDatosFormulario(
				formularioSeleccionado.formularioId,
				formularioSeleccionado.id
			);

			if (respuesta.solicitudAusenciaId) {
				const variablesAusencia = await SolicitudAusenciaService.obtenerDatosSolicitudAusencia({
					solicitudAusenciaId: respuesta.solicitudAusenciaId,
				});
				//console.log("Todas las variables", variablesAusencia);
				Object.assign(respuesta.variablesFormulario, variablesAusencia.data);
			}
			setUrlPdf(respuesta.urlPlantillaPdfFirmada);
			setComponentes(respuesta.componentes);
			setVariablesFormulario(respuesta.variablesFormulario);
			setDatosFormulario(respuesta.datosFormulario);
			if (respuesta.urlFirmaHolografa !== undefined) setUrlFirmaHolografa(respuesta.urlFirmaHolografa);
			if (respuesta.urlHuellaDactilar !== undefined) setUrlHuellaDacilar(respuesta.urlHuellaDactilar);
			if (respuesta.urlFoto !== undefined) setUrlFoto(respuesta.urlFoto);
		};
		obtenerUrlPlantillaPdf();
	}, [formularioSeleccionado]);

	const onLoadSuccess = async (successEvent) => {
		setTotalPaginas(successEvent.numPages);
		setLoading(false);
		setLoadingPdf(false);
	};

	return (
		<div
			style={{
				height: formularioSeleccionado?.motivoRechazo !== null ? "calc(90% - 64px)" : "calc(100% - 64px)",
			}}
		>
			{loading && (
				<div className="visor-formulario-loading">
					<Loading3QuartersOutlined spin className="loading" />
				</div>
			)}
			<BarraHerramientas />
			{formularioSeleccionado?.motivoRechazo !== null && (
				<div style={{ zIndex: 0 }}>
					<Alert
						message="Formulario Rechazado"
						description={"Motivo de Rechazo : " + formularioSeleccionado?.motivoRechazo}
						type="warning"
						showIcon
					//closable
					/>
				</div>
			)}
			<div className="visor-formulario-body">
				<Document file={urlPdf} onLoadSuccess={onLoadSuccess} loading={null}>
					{Array.from(new Array(totalPaginas), (el, index) => (
						<PaginaPdf
							key={`page_${index + 1}`}
							numeroPagina={index + 1}
							componentes={componentes}
							variablesFormulario={variablesFormulario}
							datosFormulario={datosFormulario}
							control={control}
						/>
					))}
				</Document>
			</div>
		</div>
	);
}
