import React, { useEffect, useContext } from "react";
import { useAtom } from "jotai";
import { solicitudesDocumentosAtom, loadingAtom } from "../store/MisSolicitudesDocumentosStore";
import SolicitudDocumentoService from "services/SolicitudDocumentoService";
import { SecurityContext } from "context/SecurityContextProvider";

const useListarSolicitudesDocumentos = () => {
	const [solicitudes, setSolicitudes] = useAtom(solicitudesDocumentosAtom);
	const [loading, setLoading] = useAtom(loadingAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	useEffect(() => {
		const cargarDatos = async () => {
			setLoading(true);
			const solicitudes_ = await SolicitudDocumentoService.listar(
				getUsuarioAutenticado().numeroDocumentoIdentidad
			);
			setSolicitudes(solicitudes_);
			setLoading(false);
		};
		cargarDatos();
	}, []);
	return { solicitudes, loading };
};
export default useListarSolicitudesDocumentos;
