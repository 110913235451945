import React from "react";
import { Alert, Button, Card, Col, Descriptions, Form, Input, Modal, Row, Select, Tabs } from "antd";
import useModalEvaluacion from "../../hooks/useModalEvaluacion";
import PreguntasCalidad from "./PreguntasCalidad";
import PreguntasProactividad from "./PreguntasProactividad";
import PreguntasTrabajoEnEquipo from "./PreguntasTrabajoEnEquipo";
import PreguntasOrientacionResultados from "./PreguntasOrientacionResultados";
import PreguntasRelacionesInterpersonales from "./PreguntasRelacionesInterpersonales";
import PreguntasLimpiezaSeguridad from "./PreguntasLimpiezaSeguridad";
import FactoresEvaluacion from "./FactoresEvaluacion";
import ResultadoEvaluacion from "./ResultadoEvaluacion";
import ObservacionResultado from "./ObservacionResultado";
import PlanAccion from "./PlanAccion";
import Renovacion from "./Renovacion";

const ModalEvaluacion = () => {
	const { visibleModalEvaluacion, onClickBtnEnviar, ocultarModal } = useModalEvaluacion();

	return (
		<Modal
			title="FICHA DE EVALUACIÓN DE DESEMPEÑO LABORAL"
			visible={visibleModalEvaluacion}
			onCancel={ocultarModal}
			onOk={onClickBtnEnviar}
			okText="Evaluar"
			destroyOnClose={true}
			width={800}
		>
			<PreguntasCalidad />
			<PreguntasProactividad />
			<PreguntasTrabajoEnEquipo />
			<PreguntasOrientacionResultados />
			<PreguntasRelacionesInterpersonales />
			<PreguntasLimpiezaSeguridad />
			<FactoresEvaluacion />
			<ObservacionResultado />
			<ResultadoEvaluacion />
			<PlanAccion />
			<Renovacion />
		</Modal>
	);
};

const Instrucciones = () => {
	return (
		<Alert
			message="Instrucciones"
			description={
				<div style={{ fontSize: "12px" }}>
					<span style={{ display: "block" }}>
						- Para realizar la valoración de desempeño del personal, lea con entendimiento las funciones,
						competencias e indicadores.
					</span>
					<span style={{ display: "block" }}>
						- De manera objetiva, asigne a cada indicador la valoración que considere adecuada, seleccionado
						la puntuación correspondiente.
					</span>
					<span style={{ display: "block" }}>
						- Recuerde que, en la escala, cada valoración corresponde a un nivel, como a continuación se
						detalla.
					</span>
					<span style={{ display: "block" }}>
						<div style={{ paddingLeft: "10px" }}>
							<b>DEFICIENTE</b>: raramente realiza las tareas y obligaciones inherentes a su puesto.
						</div>
						<div style={{ paddingLeft: "10px" }}>
							<b>REGULAR</b> : frecuentemente presenta dificultades en el desempeño.
						</div>
						<div style={{ paddingLeft: "10px" }}>
							<b>BUENO</b> : se desempeña de acuerdo a lo esperado.
						</div>
						<div style={{ paddingLeft: "10px" }}>
							<b>MUY BUENO</b>: se desempeña por encima de lo esperado.{" "}
						</div>
						<div style={{ paddingLeft: "10px" }}>
							<b>EXCELENTE</b>: constantemente supera de manera excepcional su desempeño.
						</div>
					</span>
				</div>
			}
			type="info"
			showIcon
		/>
	);
};

export default ModalEvaluacion;
