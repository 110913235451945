import React,{useState,useEffect} from "react";
import { Modal, Typography, Comment, List, Form, Input, Button, Checkbox,Tag } from "antd";
import * as atoms from "../../BandejaConsultaMovilStorage";
import { useAtom } from "jotai";
import { InfoCircleTwoTone, UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { DetalleConsultaService } from "services/DetalleConsultaService";
import { EstadoConsulta } from "enum/EstadoConsulta";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { ConsultaService } from "services/ConsultaService";


const ModalVerConsulta = () => {
    
    const [visibleModalVerConsulta,setVisibleModalVerConsulta] = useAtom(atoms.visibleModalVerConsultaAtom);
    const [consultaSeleccionada, setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);
    const [detalleConsulta, setDetalleConsulta] = useAtom(atoms.detalleConsultaAtom);  
    const [consultas, setConsultas] = useAtom(atoms.consultasAtom);
    const [comentario, setComentario] = useState("");
    const [loading, setLoading] = useState(false);
    const [consultaResuelta, setConsultaResuelta] = useState(false);
    const { TextArea } = Input;
    const [formComentario] = Form.useForm();  
    
    const onCancelModal = async () => {
        formComentario.setFieldsValue({ valor: "" });
        setVisibleModalVerConsulta(false);    
        if (consultaResuelta) {
            await ConsultaService.cambiarEstadoConsulta(consultaSeleccionada.id);
            setConsultas(consultas.map((consulta) => {
            if (consulta.id === consultaSeleccionada.id) {
                return { ...consulta, estadoConsulta: "RESUELTA" };
            }
            return consulta;
        }))
        }

    } 
    
    const handleOnChangeComentario = (e) => {
        
        setComentario(e.target.value);
    }
    const handleOnClickAgregar = async () => {        
    
        if (comentario.trim()) {
            try {

                setLoading(true);  
                const detalleConsultaAgregada =
                {
                    consultaColaboradorId:consultaSeleccionada.id,
                    nombreCompletoColaborador:consultaSeleccionada.nombreCompletoColaborador,
                    mensaje:comentario.trim(),		
                }
                await  DetalleConsultaService.insertar(detalleConsultaAgregada);
                detalleConsultaAgregada.fechaRegistroMensaje=new Date();
                setDetalleConsulta([...detalleConsulta, detalleConsultaAgregada]);
                formComentario.setFieldsValue({ valor: "" });
                setComentario("");
                setLoading(false);

            } catch (error) {
                setLoading(false);
                console.log(error);
            }                    
            
        }
        
    }
    const onChangeCerrarConsulta = (e) => {
        setConsultaResuelta(e.target.checked);
        
  }
    return (
        <Modal
         visible={visibleModalVerConsulta}
         //   visible={true}
        onCancel={onCancelModal}
        footer={null}
        title={<span><InfoCircleTwoTone style={{ fontSize: '20px' }} /> {""}{consultaSeleccionada!==undefined && consultaSeleccionada.asunto}</span>}
        width={800}
        destroyOnClose={true}
        >            
             {consultaSeleccionada!==undefined && consultaSeleccionada.estadoConsulta===EstadoConsulta.RESUELTA  &&(
                            <Tag color="#F7914F " icon={<ExclamationCircleOutlined />} >
                                Esta solicitud ya se encuentra cerrada y no podrá
                                <br/>
                                agregar más comentarios.                                               
                            </Tag>
                        )}
            {detalleConsulta.length>0 &&(
            <List
                    className="comment-list"                   
                    itemLayout="horizontal"
                    dataSource={detalleConsulta}
                    renderItem={item => (
                    <li>
                        <Comment
                            //actions={item.actions}
                            author={item.nombreCompletoColaborador}
                            avatar={<UserOutlined style={{ fontSize: '16px', color: '#F5860F' }}/>}
                            content={item.mensaje}
                            datetime={new Date(item.fechaRegistroMensaje).toLocaleString()}
                            >                           
                               
                                {(item.detalleConsultaRespuesta != null && item.detalleConsultaRespuesta.length > 0) && (                                    
                                    item.detalleConsultaRespuesta.map((detalleRespuesta)=>{
                                      return(
                                        <Comment
                                        //actions={item.actions}
                                        author={detalleRespuesta.usuarioNombreCompleto}
                                        avatar={<UserOutlined style={{ fontSize: '16px', color: '#099359' }}/>}
                                        content={detalleRespuesta.respuesta}
                                        datetime={new Date(detalleRespuesta.fechaRegistroRespuesta).toLocaleString()}
                                            />
                                      )  
                                })        
                              
                            )}
                         </Comment>   
                     </li>
                        
                    )}
                />
                )}
            
            {consultaSeleccionada!==undefined && consultaSeleccionada.estadoConsulta===EstadoConsulta.ABIERTO   &&(
                <Comment                
                    content={                    
                        <Form form={formComentario}>
                        <Form.Item name="valor">
                            <TextArea rows={4} onChange={handleOnChangeComentario} value={comentario} />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" onClick={handleOnClickAgregar} loading={loading} type="primary"> Agregar comentario</Button>
                            </Form.Item>
                        {/* <Checkbox onChange={onChangeCerrarConsulta} >¿Se llegó a solucionar su consulta?</Checkbox> */}
                    </Form>
                    }
            />
            )}
             {/* Se agrega este código por el momento hasta rediseñar el modelo de consultas antiguas */}
            {detalleConsulta.length === 0 && (                   
                        <Comment
                           author={consultaSeleccionada?.nombreCompleto}
                            avatar={<UserOutlined style={{ fontSize: '16px', color: '#F5860F' }}/>}
                            content={consultaSeleccionada?.mensaje}
                            datetime={consultaSeleccionada?.fechaRegistro}
                        />                      
                   
                )              
            }
           {/* hasta aqui va el codigo agregado */}
        </Modal>
    )
}
export default ModalVerConsulta;