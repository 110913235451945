import React, { useState } from 'react';
import { Button, Upload, notification } from 'antd';

import {
    PlusOutlined
} from "@ant-design/icons"
import { ModalVisorFilePDF } from 'components/ModalVisorFilePDF';
import { Headers } from 'enum';
import { PortalColaboradorService } from 'services/PortalColaboradorService';
import LoginService from 'services/LoginService';
import { TareasPendientesService } from 'services';

const SubirPdf = ({ tareaSeleccionada, onSuccess }) => {

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uid, setUid] = useState("");
    const [previewOpen, setPreviewOpen] = useState(false);
    const [file, setFile] = useState(undefined);

    const propsUpload = {
        name: "file",
        multiple: true,
        action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/cargarDocumentos",
        headers: {
            Authorization: "Bearer " + LoginService.getJwtToken(),
            [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
            [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
            [Headers.TIPO_USUARIO]: "COLABORADOR",
            [Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
        },
        data: {
            detalleEnvioId: tareaSeleccionada.procesoId,
            uid: uid,
        },
        beforeUpload(file) {
            setUid(file.uid);
            return file;
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
    };

    const onChangeCarga = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onClickCargarDocumento = async () => {
        setUploading(true);

        if (tareaSeleccionada.tipo === "CARGA_DOCUMENTO") {
            const datosCargaDocumento = {
                listaUids: fileList.map((c) => c.uid),
                detalleEnvioId: tareaSeleccionada.procesoId,
                formato: "PDF",
                tareaId: tareaSeleccionada.id
            };
            const data = await TareasPendientesService.subirDocumento(datosCargaDocumento);
            if (data === true) {
                onSuccess();
                notification.success({
                    description: "Documento Cargado",
                    message: "Documento Cargado Correctamente",
                });
            } else {
                notification.error({
                    description: "Error al cargar",
                    message: "Ha ocurrido un error",
                });
            }
        }

        setUploading(false);
    }




    const handlePreview = async (file) => {
        console.log(file)
        setFile(file.originFileObj)
        setPreviewOpen(true);
    };

    return (
        <div style={{ width: "calc(100% - 150px)" }}>
            <div>
                <Upload.Dragger listType="picture" {...propsUpload} onChange={onChangeCarga} fileList={fileList} accept="application/pdf"
                    onPreview={(item) => handlePreview(item)}>
                    <p className="ant-upload-drag-icon">
                        <PlusOutlined />
                    </p>
                    <p>Seleccionar Archivo PDF</p>
                </Upload.Dragger>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                    }}
                >
                    <Button
                        type="primary"
                        onClick={onClickCargarDocumento}
                        disabled={fileList.length === 0}
                        loading={uploading}
                        size="large"
                        style={{ borderRadius: "5px" }}
                    >
                        {uploading ? "Cargando" : "Enviar Documentos"}
                    </Button>
                </div>
                <ModalVisorFilePDF file={file} previewOpen={previewOpen} setPreviewOpen={setPreviewOpen} />
            </div>

        </div>
    );
};

export default SubirPdf;