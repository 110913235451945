import React from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useAtomValue } from "jotai";
import { loadingAtom } from "../../VisorFormularioStorage";
import styled from "@emotion/styled";

const Loading = () => {
	const loading = useAtomValue(loadingAtom);

	if (!loading) {
		return "";
	}

	return (
		<Container>
			<Loading3QuartersOutlined spin style={{ fontSize: "40px", color: "#2b81de" }} />
		</Container>
	);
};

const Container = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background: #f6f6f6;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default Loading;
