import { useEffect } from "react";
import { useAtom } from "jotai";
import { puntajeTotalEvaluacionAtom } from "../../BandejaEvaluacionStorage";
import usePreguntasCalidad from "./usePreguntasCalidad";
import usePreguntasProactividad from "./usePreguntasProactividad";
import usePreguntasOrientacionResultados from "./usePreguntasOrientacionResultados";
import usePreguntasRelacionesInterpersonales from "./usePreguntasRelacionesInterpersonales";
import usePreguntasLimpiezaSeguridad from "./usePreguntasLimpiezaSeguridad";
import usePreguntasTrabajoEnEquipo from "./usePreguntasTrabajoEnEquipo";
import { useState } from "react";

const useResultadoEvaluacion = () => {
	const { puntajeTotal: puntajeTotalCalidad } = usePreguntasCalidad();
	const { puntajeTotal: puntajeTotalProactividad } = usePreguntasProactividad();
	const { puntajeTotal: puntajeTotalTrabajoEnEquipo } = usePreguntasTrabajoEnEquipo();
	const { puntajeTotal: puntajeTotalOrientacionResultados } = usePreguntasOrientacionResultados();
	const { puntajeTotal: puntajeTotalRelacionesInterpersonales } = usePreguntasRelacionesInterpersonales();
	const { puntajeTotal: puntajeTotalLimpiezaSeguridad } = usePreguntasLimpiezaSeguridad();
	const [puntajeTotalEvaluacion, setPuntajeTotalEvaluacion] = useAtom(puntajeTotalEvaluacionAtom);

	const [noAptoStyle, setNoAptoSyle] = useState("");
	const [aptoConObservacionesStyle, setAptoConObservaciones] = useState("");
	const [buenoStyle, setBuenoStyle] = useState("");
	const [destacadoStyle, setDestacadoStyle] = useState("");
	const [excelenteStyle, setExcelenteStyle] = useState("");

	const itemSeleccionado = {
		background: "#faad14",
	};

	useEffect(() => {
		setPuntajeTotalEvaluacion(
			puntajeTotalCalidad +
				puntajeTotalProactividad +
				puntajeTotalTrabajoEnEquipo +
				puntajeTotalOrientacionResultados +
				puntajeTotalRelacionesInterpersonales +
				puntajeTotalLimpiezaSeguridad
		);
	}, [
		puntajeTotalCalidad,
		puntajeTotalProactividad,
		puntajeTotalTrabajoEnEquipo,
		puntajeTotalOrientacionResultados,
		puntajeTotalRelacionesInterpersonales,
		puntajeTotalLimpiezaSeguridad,
	]);

	useEffect(() => {
		setNoAptoSyle(puntajeTotalEvaluacion >= 0 && puntajeTotalEvaluacion <= 50 ? itemSeleccionado : "");
		setAptoConObservaciones(puntajeTotalEvaluacion >= 51 && puntajeTotalEvaluacion <= 75 ? itemSeleccionado : "");
		setBuenoStyle(puntajeTotalEvaluacion >= 76 && puntajeTotalEvaluacion <= 85 ? itemSeleccionado : "");
		setDestacadoStyle(puntajeTotalEvaluacion >= 86 && puntajeTotalEvaluacion <= 95 ? itemSeleccionado : "");
		setExcelenteStyle(puntajeTotalEvaluacion >= 96 && puntajeTotalEvaluacion <= 100 ? itemSeleccionado : "");
	}, [puntajeTotalEvaluacion]);

	return {
		puntajeTotalEvaluacion,
		noAptoStyle,
		aptoConObservacionesStyle,
		buenoStyle,
		destacadoStyle,
		excelenteStyle,
	};
};

export default useResultadoEvaluacion;
