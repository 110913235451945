import React,{useEffect} from "react";
import { Header } from "./Header";
import { ListaConsultas } from "./ListaConsultas";

const BandejaConsultaMovil = () => {    
    
    return (
        <React.Fragment>
            <Header />
            <ListaConsultas/>

        </React.Fragment>
    )
    
}
export default BandejaConsultaMovil;