import React, { useRef, useContext, useState, useEffect, useCallback } from "react";
import { Alert, Input, Button, Modal, notification } from "antd";
import { Plataforma } from "enum/Plataforma";
import { documentoSeleccionadoAtom, visibleModalDeclaracionAtom } from "../../MisDocumentosMovilStorage";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { useMediaQuery } from "react-responsive";

export function ModalValidarDeclaracion({ visibleModalDeclaracion, setVisibleModalDeclaracion}) {
	let history = useHistory();
	const [claveIngresada, setClaveIngresada] = useState("");
	const [mensajeError, setMensajeError] = useState("");
	const [loading, setLoading] = useState(false);
	//const [visibleModalDeclaracion, setVisibleModalDeclaracion] = useAtom(visibleModalDeclaracionAtom);
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });
	const [documentoSeleccionado, ] = useAtom(documentoSeleccionadoAtom);
	// const {
	// 	cerrarModalUpload,
	// 	getDocumentos,
	// 	documentoSeleccionado,
	// } = useContext(MisDocumentosContext);
//const {getDocumentos } = useContext(MisDocumentosContext);
	function onClickBtnCancelar() {
		setMensajeError(undefined);
		setClaveIngresada("");
		setVisibleModalDeclaracion(false);
	}

	const onClickBtnDeclarar = async () => {
		if (claveIngresada === "") {
			setMensajeError("Ingresa tu clave");
			return;
		}

		// setLoading(true);
		// 	// const firmaExitosa = await firmarDocumento(
		// 	// 	getUsuarioAutenticado().empresaSeleccionada.id,
		// 	// 	documentoSeleccionado.id,
		// 	// 	claveIngresada,
		// 	// 	plataforma,
		// 	// 	base64ImagenFirmaColaborador
		// 	// );
		// 	// if (firmaExitosa === true) {
		// 	// 	setMensajeError(undefined);
		// 	// 	setClaveIngresada("");
		// 	// 	setReloadPdf(true);
		// // }
		
		// setLoading(false);

		setLoading(true);
		const datosSubida = {
            detalleEnvioId: documentoSeleccionado.id,
			declaracion: documentoSeleccionado?.declaracion,
			formularioAsociadoId: documentoSeleccionado?.formularioAsociadoId,
			clave:claveIngresada
        };
        //agregarDeclaracionYobtenerUltimoDocumento
		const data = await PortalColaboradorService.agregarDeclaracionYobtenerUltimoDocumento(datosSubida);
		if (data.respuesta === "OK") {
			notification.success({
				description: "Documento Cargado",
				message: "Documento Cargado Correctamente",
			});
			
            //getDocumentos();
			//onCerrarModal();
			//cerrarModalUpload();
			onClickBtnCancelar();
			history.push(`v3/mis-pendientes`);
		} else {
			// notification.error({
			// 	//description: "Advertencia",
			// 	message: data.mensaje,
			// });
			setMensajeError(data.mensaje);
		}
		setLoading(false);
	};

	return (
		<Modal
			title="Aceptar Declaracion"
			open={visibleModalDeclaracion}
			closable={false}
			width={400}
			destroyOnClose={true}
			footer={[
				<Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
					Cancelar
				</Button>,
				<Button
					key="declarar"
					type="primary"
					loading={loading}
					onClick={onClickBtnDeclarar}
					style={{ padding: "0px 30px" }}
				>
					Declarar
				</Button>,
			]}
			centered
		>
			{mensajeError && (
				<Alert
					message={mensajeError}
					onClose={() => setMensajeError(undefined)}
					style={{ marginBottom: "4px" }}
					type="error"
					showIcon
					closable
				/>
			)}

			<span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
			<Input.Password
				value={claveIngresada}
				onChange={(e) => {
					setClaveIngresada(e.target.value);
					setMensajeError(undefined);
				}}
				placeholder={"Clave"}
			/>
		</Modal>
	);
}
