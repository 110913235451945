import React, { useState } from "react";
import { Modal, Button, Table, Typography, List, Descriptions, Collapse } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { visibleModalEntrevistasRealizadasAtom } from "pages/detalle-entrevista/DetalleEntrevistaStore";
import { useAtom } from "jotai";
import { saveAs } from "file-saver";
import { SpinLoading } from "components/SpinLoading";

const columnasCompetencias=[
        {
            title:"Habilidad",
            dataIndex:"descripcion",           
            width:"15%",
        },
        // {
        //     title:"Esperado",
        //     dataIndex:"nivelRequerido",
        //     align:"center",
        //     width:"15%",
        // },
        {
            title:"Obtenido",
            dataIndex:"nivelAlcanzado",
            align:"center",
            width:"15%",
        }
    ]

const ModalVerEntrevista=({entrevistasRealizadas})=>{
    const { Panel } = Collapse;
    const [modalEntrevistaRealizada, setModalEntrevistaRealizada] = useAtom(visibleModalEntrevistasRealizadasAtom);
    const [loading, setLoading] = useState(false)
    
    const handlePanelChange = (key) => {
        if (key) {
            const container = document.getElementById('collapse-container');
            if (container) {
                container.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    return(
        <Modal 
          title="Entrevistas Realizadas"
          visible={modalEntrevistaRealizada}
            onCancel={() => { setLoading(false);  setModalEntrevistaRealizada(false); }}
          footer={
            <Button type="primary" onClick={()=>setModalEntrevistaRealizada(false)}>
                Cerrar
            </Button>
          }
            centered
            width={"max(70vw,500px)"}
          destroyOnClose
          bodyStyle={{ overflowY: "scroll", minHeight: "calc(100vh - 220px)", maxHeight: "calc(100vh - 220px)" }}
          
        >
            <SpinLoading loadingPage={loading} styleCustom={{position:"fixed"}}>
                <div id="collapse-container" style={{ overflowY: 'auto'}}>
                <Collapse accordion defaultActiveKey={0} onChange={handlePanelChange}>
                    {entrevistasRealizadas && entrevistasRealizadas.map((entrevistaSeleccionada, index) => {
                        return <Panel header={<span><UserOutlined /> {entrevistaSeleccionada?.entrevistador.nombreCompleto}</span>}
                            key={index}>
                            <DetalleEntrevista entrevistaSeleccionada={entrevistaSeleccionada} setLoading={setLoading} />
                        </Panel>
                    })}
                </Collapse>             
                </div>
            </SpinLoading>
        </Modal>
    )

}

    const DetalleEntrevista = ({ entrevistaSeleccionada, setLoading }) => {
    
        const handleDownload = (url, tipoName) => {
            setLoading(true)
        // const newWindow = window.open('', '_blank');
        // newWindow.location.href = url;
            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    saveAs(blob, tipoName);
                     setLoading(false)
                })
                .catch((error) => {
                    console.error("Error al descargar el archivo:", error);
                     setLoading(false)
                });
        };
    return <React.Fragment>
    {
                entrevistaSeleccionada?.competenciasLaborales.length>0 &&
                <React.Fragment>
                    <Typography.Title level={5} style={{fontWeight:"bold"}}>Competencias Laborales</Typography.Title>
                    <Table
                    rowKey={(row)=>row.id}
                    dataSource={entrevistaSeleccionada?.competenciasLaborales}
                    columns={columnasCompetencias}
                    pagination={false}
                    bordered={true}
                    />
                    <br/>
                </React.Fragment>
                

            }
           
           {
             entrevistaSeleccionada?.preguntas.length>0 &&
             <React.Fragment>
                <Typography.Title level={5} style={{fontWeight:"bold"}}>Preguntas</Typography.Title>
                <List
                    rowKey={(row)=>row.id}
                    itemLayout="horizontal"
                    footer={entrevistaSeleccionada.comentarioPreguntas?<span><b>Comentarios : </b> {entrevistaSeleccionada.comentarioPreguntas}</span>:null}
                    dataSource={entrevistaSeleccionada?.preguntas}
                    bordered={true}
                    renderItem={(item) => (
                    <List.Item  key={item.id}>
                        <List.Item.Meta                      
                        title={item.descripcion}
                        description={item.respuestaObtenida}
                       
                        />
                    </List.Item>
                    )}
                />
                <br/>
             </React.Fragment>
           }
           {
            entrevistaSeleccionada?.experienciasLaborales.length > 0 && <React.Fragment>
                 <Typography.Title level={5} style={{fontWeight:"bold"}}>Experiencias Laborales</Typography.Title>
            {entrevistaSeleccionada.experienciasLaborales.map((experiencia, index) => {
                  return(
                        <Descriptions key={index} bordered={true} style={{marginBottom:"15px"}}>
                         <Descriptions.Item label="Empresa" span={3}>{experiencia.empresa}</Descriptions.Item>
                         <Descriptions.Item label="Puesto" span={3}>{experiencia.puesto}</Descriptions.Item>
                         <Descriptions.Item label="Fecha inicio" span={3}>{experiencia.fechaInicio}</Descriptions.Item>
                         <Descriptions.Item label="Fecha fin" span={3}>{experiencia.fechaFin}</Descriptions.Item>
                         <Descriptions.Item label="Tiempo" span={3}>{experiencia.tiempo}</Descriptions.Item>
                         <Descriptions.Item label="Personas a cargo" span={3}>{experiencia.numeroPersonasCargo}</Descriptions.Item>
                         <Descriptions.Item label="Motivo Salida" span={3}>{experiencia.motivoSalida}</Descriptions.Item>
                         <Descriptions.Item label="Remuneración" span={3}>{experiencia.remuneracion}</Descriptions.Item>
                         <Descriptions.Item label="Bonos" span={3}>{experiencia.bonos}</Descriptions.Item>
                         <Descriptions.Item label="contacto de referencia" span={3}>{experiencia.contactoReferencia}</Descriptions.Item>  
                       </Descriptions>                   
                     
                  )                
                })  }     
            </React.Fragment>
        }
        {
            entrevistaSeleccionada && <Descriptions title="Documentos Adjuntos" bordered={true} style={{marginBottom:"15px"}}>
                <Descriptions.Item label="Hoja de Vida" span={3}>{entrevistaSeleccionada?.urlHojaVida?<Button type="link" onClick={()=>handleDownload(entrevistaSeleccionada?.urlHojaVida, "CV_")}> Descargar</Button>:"-"}</Descriptions.Item>
                <Descriptions.Item label="Evaluación de Conocimiento" span={3}>{entrevistaSeleccionada?.urlEvaluacionConocimiento?<Button type="link" onClick={() => handleDownload(entrevistaSeleccionada?.urlEvaluacionConocimiento, "EvaluacionConocimiento_")}> Descargar</Button>:"-"}</Descriptions.Item>
                <Descriptions.Item label="Psicotest" span={3}>{entrevistaSeleccionada?.urlPsicotest?<Button type="link" onClick={() => handleDownload(entrevistaSeleccionada?.urlPsicotest, "Psicotest_")} > Descargar</Button>:"-"}</Descriptions.Item>
                       </Descriptions>                   
          
        }
        </React.Fragment>
}

export default ModalVerEntrevista;