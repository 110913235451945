import { useContext } from "react";
import { useAtom } from "jotai";
import { documentosAtom, filtrosSeleccionadosAtom, loadingAtom } from "../ConsultaDocumentoStore";
import { SecurityContext } from "context/SecurityContextProvider";
import DetalleEnvioService from "services/DetalleEnvioService";

const useDocumentos = () => {
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [, setLoading] = useAtom(loadingAtom);
    const [filtrosSeleccionados] = useAtom(filtrosSeleccionadosAtom);
    const [documentos, setDocumentos] = useAtom(documentosAtom);

    const cargarDocumentos = async (pagina) => {
        setLoading(true);
        const respuestaConsultaDocumentos = await DetalleEnvioService.listarConsultaDocumentoPortalColaborador(
            getUsuarioAutenticado().empresaSeleccionada.id,
            getUsuarioAutenticado().numeroDocumentoIdentidad,
            filtrosSeleccionados?.colaboradorDni,
            filtrosSeleccionados?.categoria,
            filtrosSeleccionados?.documentoIdentidad,
            filtrosSeleccionados?.tipoDocumento,
            filtrosSeleccionados?.cargo,
            filtrosSeleccionados?.periodoDocumento === null
                ? null
                : filtrosSeleccionados?.periodoDocumento.format("MM/YYYY"),
            filtrosSeleccionados?.carpeta,
            filtrosSeleccionados?.estadoDocumento,
            filtrosSeleccionados?.sede,
            filtrosSeleccionados?.fechaEnvio === null ? null : filtrosSeleccionados?.fechaEnvio[0]?.format("DD/MM/YYYY"),
            filtrosSeleccionados?.fechaEnvio === null ? null : filtrosSeleccionados?.fechaEnvio[1]?.format("DD/MM/YYYY"),
            pagina != undefined ? pagina : 0,
            30,
            filtrosSeleccionados?.subSede,
            filtrosSeleccionados?.perfil,
            filtrosSeleccionados?.seccion,
            filtrosSeleccionados?.tipoPlanilla,
            filtrosSeleccionados?.codigoColaborador,
            filtrosSeleccionados?.fechaIngreso === null ? null : filtrosSeleccionados?.fechaIngreso[0]?.format("DD/MM/YYYY"),
            filtrosSeleccionados?.fechaIngreso === null ? null : filtrosSeleccionados?.fechaIngreso[1]?.format("DD/MM/YYYY"),
            filtrosSeleccionados?.fechaFirma === null ? null : filtrosSeleccionados?.fechaFirma[0]?.format("DD/MM/YYYY"),
            filtrosSeleccionados?.fechaFirma === null ? null : filtrosSeleccionados?.fechaFirma[1]?.format("DD/MM/YYYY"),
        );

        setDocumentos({
            ...documentos,
            data: respuestaConsultaDocumentos.detalles,
            paginaActual: respuestaConsultaDocumentos.paginaActual,
            totalDocumentos: respuestaConsultaDocumentos.totalColaboradores,
        });
        setLoading(false)
    };

    return {
        cargarDocumentos
    }

}

export default useDocumentos;