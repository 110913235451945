import React, { useEffect } from "react";

import styled from "@emotion/styled";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
	documentoSeleccionadoAtom,
	informacionVisorAtom,
	rutaSubirDocumentoAtom,
	visibleSubirDocumentosAtom,
	visibleVisorAtom,
	datosFormularioAtom,
	visibleModalFirmarAtom,
	loadingPdfAtom,
	visibleModalOmitirAtom,
} from "pages/onboarding/OnboardingStore";
import { CategoriaDocumento } from "enum/CategoriaDocumento";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import { Plataforma } from "enum/Plataforma";
import { Button, Collapse, Space, Spin, Tag } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { VisorFormulario } from "components/common";
import { useState } from "react";
import { useListarDocumentos } from "pages/onboarding/componentes/OnboardingPageMovil/hooks";
import { SubirDocumentos } from "../SubirDocumentos";
import { EstadoAprobacion } from "enum/EstadoAprobacion";

const { Panel } = Collapse;

const ListaDocumentos = () => {
	const setInformacionVisor = useSetAtom(informacionVisorAtom);
	const setVisibleVisor = useSetAtom(visibleVisorAtom);
	const setVisibleSubirDocumentos = useSetAtom(visibleSubirDocumentosAtom);
	const { documentos } = useListarDocumentos();
	const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
	const informacionVisor = useAtomValue(informacionVisorAtom);
	const visibleVisor = useAtomValue(visibleVisorAtom);
	const visibleSubirDocumentos = useAtomValue(visibleSubirDocumentosAtom);
	const setVisibleModalFirmar = useSetAtom(visibleModalFirmarAtom);
	const setDatosFormulario = useSetAtom(datosFormularioAtom);
	const [loading, setLoading] = useState(false);
	const frmVisor = useForm();
	const [loadingPdf, setLoadingPdf] = useAtom(loadingPdfAtom);
	const setVisibleModalOmitir = useSetAtom(visibleModalOmitirAtom);

	const onSubmit = (datos) => {
		console.log("datos", datos);
		setDatosFormulario(datos);
		setVisibleModalFirmar(true);
	};

	const onClickBtnFirmar = () => {
		frmVisor.handleSubmit(onSubmit)();
	};

	useEffect(() => {
		const obtenerInformacion = async () => {
			setLoading(true);
			setVisibleVisor(false);
			setVisibleSubirDocumentos(false);
			frmVisor.reset();
			if (documentoSeleccionado) {
				if (
					documentoSeleccionado.estadoVistaColaborador === EstadoVistaColaborador.FIRMADO &&
					documentoSeleccionado?.estadoAprobacion !== EstadoAprobacion.RECHAZADO
				) {
					const respuesta = await PortalColaboradorService.obtenerUrl(
						undefined,
						documentoSeleccionado.id,
						Plataforma.WEB
					);
					setInformacionVisor({
						type: "visor",
						urlPlantillaPdfFirmada: respuesta.urlDocumento,
						componentes: [],
						variablesGlobales: {},
						datosFormulario: {},
					});
					setVisibleVisor(true);
					setVisibleSubirDocumentos(false);
				} else {
					if (documentoSeleccionado.categoriaDocumentoId === CategoriaDocumento.FORMULARIO) {
						const respuesta = await PortalColaboradorService.obtenerDatosFormulario(
							documentoSeleccionado.formularioId,
							documentoSeleccionado.id
						);
						setInformacionVisor({
							type: "form",
							urlPlantillaPdfFirmada: respuesta.urlPlantillaPdfFirmada,
							componentes: respuesta.componentes,
							variablesGlobales: respuesta.variablesFormulario,
							datosFormulario: respuesta.datosFormulario,
							urlHuellaDactilar: respuesta.urlHuellaDactilar,
							urlFirmaHolografa: respuesta.urlFirmaHolografa,
							urlFoto: respuesta.urlFoto,
						});
						setVisibleVisor(true);
						setVisibleSubirDocumentos(false);
					} else if (documentoSeleccionado.categoriaDocumentoId === CategoriaDocumento.CARGA_DOCUMENTO) {
						setVisibleVisor(false);
						setVisibleSubirDocumentos(true);
					}
				}
			}
			setLoading(false);
		};

		obtenerInformacion();
	}, [documentoSeleccionado]);

	const onClickBtnOmitir = () => {
		setVisibleModalOmitir(true);
	};

	return (
		<Container style={{ height: "calc(100vh - 260px)" }}>
			<Collapse
				defaultActiveKey={["0"]}
				accordion
				onChange={(index) => setDocumentoSeleccionado(documentos[index])}
			>
				{documentos &&
					documentos.map((documento, index) => {
						return (
							<Panel
								header={
									<div>
										<span className="item-documento__descripcion">
											<b>{documento.periodo}</b>
										</span>
										<span className="item-documento__tipo">{documento.nombreDocumento}</span>
									</div>
								}
								key={index}
								extra={
									<EstadoDocumento
										estado={documento.estadoVistaColaborador}
										estadoAprobacion={documento.estadoAprobacion}
									/>
								}
							>
								{!loading && (
									<ContainerVisor>
										{!visibleSubirDocumentos && (
											<React.Fragment>
												{documentoSeleccionado?.categoriaDocumentoId ===
													CategoriaDocumento.FORMULARIO &&
													(documentoSeleccionado?.estadoVistaColaborador ===
														EstadoVistaColaborador.PENDIENTE ||
														documentoSeleccionado?.estadoAprobacion ===
															EstadoAprobacion.RECHAZADO) && (
														<Header>
															<Acciones>
																<Space>
																	{documentoSeleccionado?.opcional && (
																		<Button
																			type="danger"
																			onClick={onClickBtnOmitir}
																			loading={loading}
																			disabled={loading}
																		>
																			Omitir
																		</Button>
																	)}
																	<Button
																		type="primary"
																		onClick={onClickBtnFirmar}
																		loading={loadingPdf}
																		disabled={loadingPdf}
																	>
																		Firmar Documento
																	</Button>
																</Space>
															</Acciones>
														</Header>
													)}
												<Body
													withHeader={
														documentoSeleccionado?.categoriaDocumentoId ===
															CategoriaDocumento.FORMULARIO &&
														documentoSeleccionado?.estadoVistaColaborador ===
															EstadoVistaColaborador.PENDIENTE
															? true
															: false
													}
												>
													{visibleVisor && (
														<FormProvider {...frmVisor}>
															<VisorFormulario
																type={informacionVisor?.type}
																form={frmVisor}
																urlPdf={informacionVisor?.urlPlantillaPdfFirmada}
																componentes={informacionVisor?.componentes}
																variablesGlobales={informacionVisor?.variablesGlobales}
																datosFormulario={informacionVisor?.datosFormulario}
																urlHuellaDactilar={informacionVisor?.urlHuellaDactilar}
																urlFirmaHolografa={informacionVisor?.urlFirmaHolografa}
																urlFoto={informacionVisor?.urlFoto}
																setLoadingPdf={setLoadingPdf}
															/>
														</FormProvider>
													)}
												</Body>
											</React.Fragment>
										)}
										{visibleSubirDocumentos && <SubirDocumentos />}
									</ContainerVisor>
								)}
							</Panel>
						);
					})}
			</Collapse>
		</Container>
	);
};

const EstadoDocumento = ({ estado, estadoAprobacion }) => {
	if (estadoAprobacion != null && estadoAprobacion === "RECHAZADO") {
		return (
			<Tag className="tag_movil" color="red">
				PENDIENTE
			</Tag>
		);
	} else {
		switch (estado) {
			case EstadoVistaColaborador.PENDIENTE:
				return (
					<Tag className="tag_movil" color="#faad14">
						PENDIENTE
					</Tag>
				);

			case EstadoVistaColaborador.VISUALIZADO:
				return (
					<Tag className="tag_movil" color="blue">
						VISUALIZADO
					</Tag>
				);

			case EstadoVistaColaborador.FIRMADO:
				return (
					<Tag className="tag_movil" color="#3bb937">
						FIRMADO
					</Tag>
				);

			default:
				return "-";
		}
	}
};

const Container = styled.div`
	flex: 1 1 30%;
	margin: 10px;
	overflow: auto;
`;

const ContainerVisor = styled.div`
	flex: 1 1 70%;
	margin: 10px;
`;

const Header = styled.div`
	min-height: 50px;
	max-height: 50px;
	display: flex;
	background: white;
	padding: 0px 10px;
	border: 1px solid #ccc;
	border-bottom: 0px;
`;

const Body = styled.div`
	//height: calc(100% - 50px);
	height: ${(props) => (props.withHeader === true ? "calc(100% - 50px)" : "100%")};
`;

const Acciones = styled.div`
	flex: 1 1 50%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export default ListaDocumentos;
