import { useAtom } from "jotai";
import React from "react";
import { informacionPlataformaAtom } from "../../AuthLoginStore";

const PlantillaAuth = ({ children }) => {
	return (
		<Container>
			<Header />
			<Body>{children}</Body>
		</Container>
	);
};

const Container = ({ children }) => {
	return <div style={{ background: "#EFF1F4", minHeight: "100vh" }}>{children}</div>;
};

const Header = () => {
	const [infoApp] = useAtom(informacionPlataformaAtom);

	return (
		<div
			id="header"
			style={{
				background: "#fff",
				borderBottom: "1px solid #DADCDF",
				alignItems: "center",
				display: "flex",
				justifyContent: "center",
				padding: "10px 0px",
			}}
		>
			<img style={{ maxHeight: "42px", maxWidth: "220px" }} src={infoApp?.urlImagenLogotipo} />
		</div>
	);
};

const Body = ({ children }) => {
	return (
		<div
			id="body"
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				minHeight: "calc(100vh - 72px)",
			}}
		>
			<div id="bodyContainer" style={{ padding: "30px 30px" }}>
				<div
					id="form"
					style={{
						background: "#fff",
						boxShadow: "0 2px 3px 0 #dadcdf",
						maxWidth: "460px",
					}}
				>
					{children}
				</div>
			</div>
		</div>
	);
};

export default PlantillaAuth;
