import React, { useContext, useState } from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";
import { saveAs } from "file-saver";
import { SecurityContext } from "context/SecurityContextProvider";
import { ModalConsultar } from "../ModalConsultar";
import { ConsultaService } from "services/ConsultaService";
import { EstadoConsulta } from "enum/EstadoConsulta";
import { useHistory } from "react-router-dom";
import * as atoms from "../VisorStorage";
import { useAtom } from "jotai";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import * as atomsConsulta from "../../../../../hooks/consultas/ConsultasStorage";

export function PanelBotones() {
	const { documentoSeleccionado, cerrarVisorPdf } = useContext(MisDocumentosContext);

	return (
		<div className="acciones">
			{documentoSeleccionado?.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE && (
				<BotonesEstadoPendiente />
			)}

			{documentoSeleccionado?.estadoVistaColaborador !== EstadoVistaColaborador.PENDIENTE && (
				<BotonesEstadoFirmado />
			)}

			<BotonCerrar onClick={cerrarVisorPdf} />
		</div>
	);
}

function BotonesEstadoPendiente() {
	const { loadingPdf, mostrarModalFirmar, mostrarModalConsultar, documentoSeleccionado } =
		useContext(MisDocumentosContext);

	const [, setRespuestaConsultaAbierta] = useAtom(atoms.respuestaConsultaAbiertaAtom);
	const [, setDetallesConsulta] = useAtom(atoms.detallesConsultaAtom);
	const [, setNumeroRespuestas] = useAtom(atoms.numeroRespuestasAtom);
	const [, setTotalRespuestasUsuario] = useAtom(atomsConsulta.totalRespuestasUsuarioAtom);
	const [, setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	let history = useHistory();

	async function handleOnClickConsultar() {
		mostrarModalConsultar();

		const consultas = await ConsultaService.buscarConsultaPorDocumento(
			documentoSeleccionado.id,
			EstadoConsulta.ABIERTO
		);
		setRespuestaConsultaAbierta(consultas);
		if (consultas.length > 0) {
			const _detallesconsulta = await PortalColaboradorService.listaDetalleConsultas(consultas[0].id);
			setDetallesConsulta(_detallesconsulta);
			setConsultaSeleccionada(consultas[0]);

			//agregado para que actualice el total respuestas de usuario(verificar)
			const consultaEnviada = {
				consultaColaboradorId: consultas[0].id,
				tipoDetalle: "respuestaUsuario",
			};
			await PortalColaboradorService.cambiarEstadoVisualizacionDetalleConsulta(consultaEnviada);
			const consultaslistadas = await PortalColaboradorService.listaConsultas(
				getUsuarioAutenticado().empresaSeleccionada.id,
				getUsuarioAutenticado().numeroDocumentoIdentidad
			);
			let listaConsultasId = [];
			if (consultaslistadas.length > 0) {
				consultaslistadas.map((consulta) => {
					listaConsultasId.push(consulta.id);
				});
				const respuestasXConsulta = await PortalColaboradorService.listaNumeroRespuestas(
					listaConsultasId,
					"respuestaUsuario"
				);
				setNumeroRespuestas(respuestasXConsulta);
				let totalRespuestas = 0;
				respuestasXConsulta.length > 0 &&
					respuestasXConsulta.map((respuesta) => {
						respuesta.numeroRespuestas > 0 && totalRespuestas++;
					});
				setTotalRespuestasUsuario(totalRespuestas);
			}
		}
	}

	return (
		<React.Fragment>
			{!documentoSeleccionado?.ocultarBotonConsultarRecursosHumanos && (
				<Button className="consultar-rrhh" disabled={loadingPdf} onClick={handleOnClickConsultar}>
					Consultar
				</Button>
			)}
			<Button type="primary" className="firmar" disabled={loadingPdf} onClick={mostrarModalFirmar}>
				Firmar Documento
			</Button>
		</React.Fragment>
	);
}

function BotonesEstadoFirmado() {
	const { loadingPdf, blobPdf, documentoSeleccionado } = useContext(MisDocumentosContext);
	const { getUsuarioAutenticado } = useContext(SecurityContext);

	function onClickBtnImprimir() {
		window.open(URL.createObjectURL(blobPdf)).print();
	}

	function onClickBtnDescargar() {
		const nombreDocumento = documentoSeleccionado.nombreDocumento.replace(" ", "-");
		const documentoIdentidad = getUsuarioAutenticado().numeroDocumentoIdentidad;
		const nombreDescarga = nombreDocumento + "_" + documentoIdentidad + ".pdf";
		saveAs(blobPdf, nombreDescarga);
	}

	return (
		<React.Fragment>
			{!documentoSeleccionado?.ocultarBotonImprimir && (
				<Button className="visor-pdf-header-botones" disabled={loadingPdf} onClick={onClickBtnImprimir}>
					Imprimir
				</Button>
			)}
			{!documentoSeleccionado?.ocultarBotonDescarga && (
				<Button className="visor-pdf-header-botones" disabled={loadingPdf} onClick={onClickBtnDescargar}>
					Descargar
				</Button>
			)}
		</React.Fragment>
	);
}

function BotonCerrar() {
	const { cerrarVisorPdf } = useContext(MisDocumentosContext);

	return (
		<React.Fragment>
			<div className="visor-pdf-header-separador" />
			<Button type="text" className="cerrar" onClick={cerrarVisorPdf}>
				<CloseOutlined style={{ fontSize: "16px" }} />
			</Button>
		</React.Fragment>
	);
}
