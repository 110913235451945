import { useContext } from "react";
import { useAtom } from "jotai";
import { TiposAusenciaService } from "services/VacacionesService";
import { tipoAusenciaSeleccionadaAtom, ocultarFechasSolicitadasAtom } from "../store/MisSolicitudesStore";
import { SecurityContext } from "context/SecurityContextProvider";

const useSeleccionarTipoAusencia = () => {
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [tipoAusencia, setTipoAusencia] = useAtom(tipoAusenciaSeleccionadaAtom);
	const [ocultarFechasSolicitadas, setOcultarFechasSolicitadas] = useAtom(ocultarFechasSolicitadasAtom);
	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;
	const onChangeTipoAusencia = async (tipoAusenciaId) => {
		const respuesta = await TiposAusenciaService.buscarPorId(tipoAusenciaId);
		setTipoAusencia(respuesta.data);
		//console.log("Tipo ausencia:", respuesta.data);
		if (respuesta.data && isAceros && respuesta.data.descripcion == "Compensacion de Horas") {
			setOcultarFechasSolicitadas(true);
		} else {
			setOcultarFechasSolicitadas(false);
		}
	};

	const limpiarTipoAusencia = () => {
		setTipoAusencia(undefined);
	};

	return { tipoAusencia, limpiarTipoAusencia, onChangeTipoAusencia, ocultarFechasSolicitadas };
};

export default useSeleccionarTipoAusencia;
