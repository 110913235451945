import React, { useContext, useEffect } from "react";
import {Divider, Empty, Form, Spin, Steps, Result, Button, Typography, Upload, message, Tag } from "antd";
import {
	estadoFirmaOnboardingAtom, estadoHuellaOnboardingAtom,
	estadoFirmaEnviadoValidacionOnboardingAtom, estadoHuellaEnviadoValidacionOnboardingAtom
} from "pages/onboarding/OnboardingStore";
import { LoadingOutlined, SyncOutlined, CloseCircleOutlined,CheckOutlined } from "@ant-design/icons";
import { useAtom, useAtomValue } from "jotai";
import { useHistory } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import styled from "@emotion/styled";
import { SecurityContext } from "context/SecurityContextProvider";
import { OnboardingService } from "services";
import LoginService from "services/LoginService";

const ValidarArchivosSubidosPageWeb = () => {

	const { Paragraph, Text } = Typography;
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	const [estadoFirma, setEstadoFirma] = useAtom(estadoFirmaOnboardingAtom);
	const [estadoHuella, setEstadoHuella] = useAtom(estadoHuellaOnboardingAtom);
	const [estadoFirmaEnviadoValidacion, setEstadoFirmaEnviadoValidacion] = useAtom(estadoFirmaEnviadoValidacionOnboardingAtom);
	const [estadoHuellaEnviadoValidacion, setEstadoHuellaEnviadoValidacion] = useAtom(estadoHuellaEnviadoValidacionOnboardingAtom);
	const history = useHistory();

	useEffect(() => {
		const cargarDatos = async () => {
			const respuesta = await OnboardingService.obtenerInformacionPersonal();
			console.log("respuesta:::", respuesta);
			setEstadoFirma(respuesta.estadoImagenFirma);
			setEstadoHuella(respuesta.estadoImagenHuella);
		};
		cargarDatos();
	}, [estadoHuella, estadoFirma]);

	const propsFirma = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "colaborador/actualizar/estado-firma-holografa",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
            [Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
		},
		data: {
			colaborador: new Blob(
				[
					JSON.stringify({
						numeroDocumentoIdentidad: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
					}),
				],
				{ type: "application/json" }
			),
		},
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				//cargarColaborador();
				setEstadoFirmaEnviadoValidacion(true);
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	const propsHuella = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "colaborador/actualizar/estado-huella",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
            [Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
		},
		data: {
			colaborador: new Blob(
				[
					JSON.stringify({
						numeroDocumentoIdentidad: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
					}),
				],
				{ type: "application/json" }
			),
		},
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				//setEstadoHuella("PENDIENTE");
				setEstadoHuellaEnviadoValidacion(true);
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	async function handleOnRefrescar() {
		console.log("handleOnRefrescar::");
		const respuesta = await OnboardingService.obtenerInformacionPersonal();
		console.log("handleOnRefrescar:::", respuesta);
		setEstadoFirma(respuesta.estadoImagenFirma);
		setEstadoHuella(respuesta.estadoImagenHuella);
		
		setEstadoFirmaEnviadoValidacion(false);
		setEstadoHuellaEnviadoValidacion(false);
		if ((respuesta.estadoImagenFirma != null && respuesta.estadoImagenFirma == "APROBADO") &&
		(respuesta.estadoImagenHuella != null && respuesta.estadoImagenHuella == "APROBADO")) { 
			history.push("/mis-documentos");
		}
	}

	if (isExplomin) { 

		if (estadoFirma=="RECHAZADO" || estadoHuella == "RECHAZADO") { 
			return (
					<Result
						status="error"
						title="Archivos rechazados"
						extra={[
						<Button type="primary" key="btnRechazar" onClick={handleOnRefrescar}><SyncOutlined /> Refrescar</Button>
						]}
					>
						<div className="desc">
						<Paragraph>
							<Text
							strong
							style={{
								fontSize: 16,
							}}
							>
							Actualizar los siguientes archivos para su validación:
							</Text>
						</Paragraph>
						{estadoFirma == "RECHAZADO" && (
							<React.Fragment>
								{!estadoFirmaEnviadoValidacion && (
									<Paragraph>
										<CloseCircleOutlined style={{ color: "red" }} />
										<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
												<Upload {...propsFirma} maxCount={1} accept={["image/*"]}>
													<Button block type="link">
														Cambiar Firma Hológrafa
													</Button>
												</Upload>
										</ImgCrop>
									</Paragraph>
								)} 
								{estadoFirmaEnviadoValidacion && (
									<Paragraph>
										<Tag color="green">
											<CheckOutlined /> Firma enviada para su validación ...
										</Tag>
									</Paragraph>
								)} 
							</React.Fragment>
							
						)}
						
						{estadoHuella == "RECHAZADO" && (
							<React.Fragment>
								{!estadoHuellaEnviadoValidacion && (
									<Paragraph>
											<CloseCircleOutlined style={{ color: "red" }} />
											<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
													<Upload {...propsHuella} maxCount={1} accept={["image/*"]}>
														<Button block type="link">
															Cambiar Huella Dactilar
														</Button>
													</Upload>
												</ImgCrop>
									</Paragraph>
								)}
								{estadoHuellaEnviadoValidacion && (
									<Paragraph>
										<Tag color="green">
											<CheckOutlined /> Huella enviada para su validación ...
										</Tag>
									</Paragraph>
								)} 
							</React.Fragment>
						) }
						
						</div>
					</Result>
				);
		}

		if (estadoFirma=="PENDIENTE" || estadoHuella == "PENDIENTE") { 
			return (
					<Result
						status="404"
						title="En proceso de validación de su firma y huella cargada"
						subTitle="Los encargados de la validación son del área de Reclutamiento y Selección."
						extra={<Button type="primary" onClick={handleOnRefrescar}><SyncOutlined /> Refrescar</Button>}
					/>
				);
		}
	}
	return (
		<div></div>
	)
};

const Container = styled.div`
	display: flex;
	flex-wrap: "wrap";
	justify-content: space-between;
	height: calc(100vh - 190px);
`;

const ContainerDocumentos = styled.div`
	flex: 1 1 30%;
	margin: 10px;
	overflow: auto;
`;

const ContainerVisor = styled.div`
	border: 1px solid #ccc;
	flex: 1 1 70%;
	margin: 10px;
	background-color: white;
`;

export default ValidarArchivosSubidosPageWeb;
