import React, { useState, useEffect } from "react";
import { BarChartOutlined, IdcardOutlined, FormOutlined, QuestionCircleOutlined,UploadOutlined } from "@ant-design/icons";
import { Container } from "components/Container";
import { useAtom } from "jotai";
import {
	visibleModalAgregarExperienciaLaboralAtom,
	visibleModalCompetenciasLaboralesAtom,
	visibleModalCompletarInformacionPersonalAtom,
	visibleModalConfirmarEntrevistaAtom,
	visibleModalPreguntasAtom,	
	visibleModalCargaArchivosAtom,
	indicadorCargarHojaVidaAtom,
	indicadorCargarPsicotestAtom,
	indicadorCargarEvaluacionConocimientoAtom,
	visibleModalEntrevistasRealizadasAtom,
	loadingEntrevistaAtom,
} from "pages/detalle-entrevista/DetalleEntrevistaStore";
import { useDetalleEntrevista } from "pages/detalle-entrevista/hooks";
import { Alert, Button, Spin } from "antd";
import {
	Item,
	ModalAgregarExperienciaLaboral,
	ModalCompetenciasLaborales,
	ModalCompletarInformacionPersonal,
	ModalConfirmarEntrevista,
	ModalPreguntas,
	ModalCargaArchivos,
	ModalVerEntrevista,
} from "../common";
import { useHistory } from "react-router-dom";
import { SpinLoading } from "components/SpinLoading";

const DetalleEntrevistaPageWeb = () => {
	const [, setVisibleModalCompletarInformacionPersonal] = useAtom(visibleModalCompletarInformacionPersonalAtom);
	const [, setVisibleModalAgregarExperienciaLaboral] = useAtom(visibleModalAgregarExperienciaLaboralAtom);
	const [, setVisibleModalCompetenciasLaborales] = useAtom(visibleModalCompetenciasLaboralesAtom);
	const [, setVisibleModalPreguntas] = useAtom(visibleModalPreguntasAtom);
	const [, setVisibleModalConfirmarEntrevista] = useAtom(visibleModalConfirmarEntrevistaAtom);
	const [, setVisibleModalCargaArchivos] = useAtom(visibleModalCargaArchivosAtom);
	const [habilitarBotonEnviarInformacionEntrevista, setHabilitarBotonEnviarInformacionEntrevista] = useState(false);
	const { detalleEntrevista,setDetalleEntrevista, entrevistasRealizadas } = useDetalleEntrevista();	
	const [, setIndicadorHojaVida] = useAtom(indicadorCargarHojaVidaAtom);
	const [, setIndicadorPsicotest] = useAtom(indicadorCargarPsicotestAtom);
	const [, setIndicadorEvaluacionConocimiento] = useAtom(indicadorCargarEvaluacionConocimientoAtom);
	const [botonCargaArchivoCompletado, setBotonCargaArchivoCompletado] = useState(false);
	const [, setModalEntrevistaRealizada] = useAtom(visibleModalEntrevistasRealizadasAtom);

	const [loading, ] = useAtom(loadingEntrevistaAtom);

	const history = useHistory();
    
	useEffect(() => {
		let experienciasLaboralesCompleto = detalleEntrevista?.indicadorRegistrarExperienciasLaborales
			? detalleEntrevista?.experienciasLaboralesCompleto
			: true;

		let competenciasLaboralesCompleto = detalleEntrevista?.indicadorRegistrarCompetenciasLaborales
			? detalleEntrevista?.competenciasLaboralesCompleto
			: true;

		let preguntasLaboralesCompleto = detalleEntrevista?.indicadorRegistrarPreguntasLaborales
			? detalleEntrevista?.preguntasCompleto
			: true;
		let hojaVidaCompleto = detalleEntrevista?.indicadorCargarHojaVida
			? detalleEntrevista?.cargaHojaVidaCompleto
			: true;
		let psicotestCompleto = detalleEntrevista?.indicadorCargarPsicotest
			? detalleEntrevista?.cargaPsicotestCompleto
			: true;
		let evaluacionConocimientoCompleto = detalleEntrevista?.indicadorCargarEvaluacionConocimiento
			? detalleEntrevista?.cargaEvaluacionConocimientoCompleto
			: true;
		if (
			detalleEntrevista?.informacionPersonalCompleto &&
			experienciasLaboralesCompleto &&
			competenciasLaboralesCompleto &&
			preguntasLaboralesCompleto &&
			hojaVidaCompleto &&
			psicotestCompleto &&
			evaluacionConocimientoCompleto

		) {
			setHabilitarBotonEnviarInformacionEntrevista(true);
		} else {
			setHabilitarBotonEnviarInformacionEntrevista(false);
		}

		if(hojaVidaCompleto && psicotestCompleto && evaluacionConocimientoCompleto) {
			setBotonCargaArchivoCompletado(true);			
		}
		else {
			setBotonCargaArchivoCompletado(false);
		}
	}, [detalleEntrevista]);

	const onClickCompletarInformacionPersonal = () => {
		setVisibleModalCompletarInformacionPersonal(true);
	};

	const onClickAgregarExperienciaLaboral = () => {
		setVisibleModalAgregarExperienciaLaboral(true);
	};

	const onClickCompetenciasLaborales = () => {
		setVisibleModalCompetenciasLaborales(true);
	};

	const onClickPreguntas = () => {
		setVisibleModalPreguntas(true);
	};

	const onClickBtnEnviarInformacionEntrevista = () => {
		setVisibleModalConfirmarEntrevista(true);
	};
	const onClickCargarArchivos = () => {

		setVisibleModalCargaArchivos(true);
		setIndicadorHojaVida(detalleEntrevista?.indicadorCargarHojaVida);
		setIndicadorPsicotest(detalleEntrevista?.indicadorCargarPsicotest);
		setIndicadorEvaluacionConocimiento(detalleEntrevista?.indicadorCargarEvaluacionConocimiento);
	}  
		
	return (
		<Container titulo="Detalle Entrevista" onClickRetornar={() => history.push("/entrevistas")}>
			{entrevistasRealizadas && <Alert
						style={{ marginBottom: "15px" }}
						message={`El Postulante tiene ${entrevistasRealizadas.length} entrevista(s) realizada(s)`}
						//description={`El Postulante ya ha sido evaluado por ${entrevista.entrevistador.nombreCompleto}`}
						type="info"
						showIcon
						action={
							<Button size="small" type="text" onClick={() => setModalEntrevistaRealizada(true)}>
								Ver Resultados
							</Button>
						}
					//closable
					/>
					}
			<SpinLoading loadingPage={loading}>
			<div
				style={{
					paddingLeft: "30%",
					paddingRight: "30%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Item
					icono={
						<IdcardOutlined
							style={{
								fontSize: "28px",
								color: detalleEntrevista?.informacionPersonalCompleto ? "#389e0d" : "#d48806",
							}}
						/>
					}
					descripcion="Completar Información Personal"
					isCompleto={detalleEntrevista?.informacionPersonalCompleto}
					onClick={onClickCompletarInformacionPersonal}
				/>

				{detalleEntrevista?.indicadorRegistrarExperienciasLaborales && (
					<Item
						icono={
							<FormOutlined
								style={{
									fontSize: "28px",
									color: detalleEntrevista?.experienciasLaboralesCompleto ? "#389e0d" : "#d48806",
								}}
							/>
						}
						descripcion="Agregar Experiencia Laboral"
						isCompleto={detalleEntrevista?.experienciasLaboralesCompleto}
						onClick={onClickAgregarExperienciaLaboral}
					/>
				)}

				{detalleEntrevista?.indicadorRegistrarCompetenciasLaborales && (
					<Item
						icono={
							<BarChartOutlined
								style={{
									fontSize: "28px",
									color: detalleEntrevista?.competenciasLaboralesCompleto ? "#389e0d" : "#d48806",
								}}
							/>
						}
						descripcion="Registrar Competencias Laborales"
						isCompleto={detalleEntrevista?.competenciasLaboralesCompleto}
						onClick={onClickCompetenciasLaborales}
					/>
				)}
				

				 {detalleEntrevista?.indicadorRegistrarPreguntasLaborales && (
					<Item
						icono={
							<QuestionCircleOutlined
								style={{
									fontSize: "28px",
									color: detalleEntrevista?.preguntasCompleto ? "#389e0d" : "#d48806",
								}}
							/>
						}
						descripcion="Contestar Preguntas"
						isCompleto={detalleEntrevista?.preguntasCompleto}
						onClick={onClickPreguntas}
					/>
				)}
				{
					(detalleEntrevista?.indicadorCargarHojaVida ||
					detalleEntrevista?.indicadorCargarPsicotest ||
					detalleEntrevista?.indicadorCargarEvaluacionConocimiento) && (
					<Item
						icono={
							<UploadOutlined
								style={{
									fontSize: "28px",
										color: (botonCargaArchivoCompletado) ? "#389e0d" : "#d48806",
								}}
							/>
						}
						descripcion="Cargar Archivos"
						isCompleto={botonCargaArchivoCompletado}
						onClick={onClickCargarArchivos}
					/>
					)
				}
				
				<Button
					type="primary"
					size="large"
					style={{ borderRadius: "20px", width: "70%", marginTop: "15px" }}
					onClick={onClickBtnEnviarInformacionEntrevista}
					disabled={!habilitarBotonEnviarInformacionEntrevista}
				>
					Enviar Información Entrevista
				</Button>
				</div>
				</SpinLoading>
			<ModalCompletarInformacionPersonal />
			<ModalAgregarExperienciaLaboral />
			<ModalCompetenciasLaborales />
			<ModalPreguntas />
			<ModalConfirmarEntrevista />
			<ModalCargaArchivos />
			<ModalVerEntrevista entrevistasRealizadas={entrevistasRealizadas} />
		</Container>
	);
};

export default DetalleEntrevistaPageWeb;
