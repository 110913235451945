import React from "react";
import { Page } from "react-pdf";
import { useAtomValue } from "jotai";
import { zoomVisorFormularioAtom } from "../../../../VisorFormularioStorage";
import styled from "@emotion/styled";
import { ListaControlesPdf } from "../ListaControlesPdf";

const PaginaPdf = ({ numeroPagina }) => {
	const zoom = useAtomValue(zoomVisorFormularioAtom);

	return (
		<React.Fragment>
			<div style={{position:"relative"}}>
				<Page pageNumber={numeroPagina} loading={null} scale={zoom} />
				<ListaControlesPdf pageNumber={numeroPagina} />
			</div>
			<SeparadorPaginas />
		</React.Fragment>
	);
};

const Container = styled.div`
	position: relative;
	backgroundColor:red;
`;

const SeparadorPaginas = () => {
	return (
		<div style={{ width: "100%", position: "relative" }}>
			<div style={{ height: "2em" }}></div>
		</div>
	);
};

export default PaginaPdf;
