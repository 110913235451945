import React from "react";
import { Modal } from "antd";
import { cssBodyVisorPdf, cssVisorPdf } from "./VisorFormularioStyles";
import {
	componentSelectedAtom,
	erroresFormularioAtom,
	signaturesMetadataAtom,
	visibleVisorFormularioAtom,
} from "../../MisDocumentosWebStorage";
import { useAtom } from "jotai";
import { Header, Body, Errores, SignatureModal } from "./componentes";
import { FormProvider, useForm } from "react-hook-form";

const VisorFormulario = ({ loadingPdf }) => {
	const [visibleVisorFormulario, setVisibleVisorFormulario] = useAtom(visibleVisorFormularioAtom);
	const [, setErroresFormulario] = useAtom(erroresFormularioAtom);
	const [, setComponentSelectedAtom] = useAtom(componentSelectedAtom);
	const [, setSignaturesMetadata] = useAtom(signaturesMetadataAtom);
	const frmVisor = useForm();

	const onClickBtnCancelar = () => {
		setVisibleVisorFormulario(false);
	};

	const limpiarVariablesGlobales = () => {
		frmVisor.reset();
		setErroresFormulario({});
		setSignaturesMetadata([]);
		setComponentSelectedAtom(undefined);
	};

	return (
		<Modal
			title={null}
			open={visibleVisorFormulario}
			onCancel={onClickBtnCancelar}
			bodyStyle={cssBodyVisorPdf}
			style={cssVisorPdf}
			closable={false}
			footer={null}
			destroyOnClose={true}
			afterClose={limpiarVariablesGlobales}
			className="milegajo-ant-modal"
			width="calc(100vw - 60px)"
			height="calc(100vh - 60px)"
		>
			<FormProvider {...frmVisor}>
				<Header loadingPdf={loadingPdf} />
				<Errores />
				<SignatureModal />
				<Body />
			</FormProvider>
		</Modal>
	);
};

export default VisorFormulario;
