import React from "react";
import { Button, Row, Col, Card, Typography, Space } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Text } = Typography;

export function Container({ icono, titulo, children, botonExtra, onClickRetornar }) {
	return (
		<Row>
			<Col span={24}>
				<Card
					title={
						<React.Fragment>
							{onClickRetornar && (
								<Button
									type="link"
									onClick={onClickRetornar}
									style={{ padding: "0", margin: 0, paddingRight: "10px" }}
								>
									<ArrowLeftOutlined style={{ color: "#333333", fontSize: "20px" }} />
								</Button>
							)}
							<Text>
								<Space>
									{icono}
									{titulo}
								</Space>
							</Text>
						</React.Fragment>
					}
					headStyle={{ fontWeight: "400" }}
					extra={botonExtra}
				>
					{children}
				</Card>
			</Col>
		</Row>
	);
}
