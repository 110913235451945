import { BaseService } from "./BaseService";

export const ContratoService = {
  async getUrlDocumento(empresaId, detalleEnvioId) {
    const respuesta = await BaseService.get(
      `/contrato/empresa/${empresaId}/detalle-envio/${detalleEnvioId}/url-pdf`
    );
    return respuesta.data;
  },
};
