import { atom } from "jotai";

export const contratosAtom = atom([]);
export const visibleModalRenovarContratoAtom = atom(false);
export const contratoSeleccionadoAtom = atom(undefined);

export const visibleModalRechazarRenovacionAtom = atom(false);
export const urlPdfContratoAtom = atom(undefined);
export const visibleModalVisorPdfAtom = atom(false);
export const visibleBtnRenovarContratoAtom = atom(false);
export const visibleModalActualizarContratoAtom = atom(false);
export const visibleBtnActualizarContratoAtom = atom(false);
export const visibleModalActualizarVisorPdfAtom = atom(false);
export const urlPdfActualizarContratoAtom = atom(undefined);

export const visibleModalOrigenPdfAtom = atom(undefined);