import React, { useContext, useEffect } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { VersionApiColaborador } from "enum/VersionApiColaborador";
import { useHistory } from "react-router";
import { Loading3QuartersOutlined } from "@ant-design/icons";

const RedireccionarPaginaPrincipal = () => {
	const history = useHistory();
	const { getVersionApiColaborador } = useContext(SecurityContext);

	useEffect(() => {
		const cargarDatosIniciales = () => {
			console.log("redireccionando......")
			if (getVersionApiColaborador() === VersionApiColaborador.v1) {
				history.push("/bandejaEntrada");
			} else if (getVersionApiColaborador() === VersionApiColaborador.v2) {
				history.push("/mis-documentos");
			} else if (getVersionApiColaborador() === VersionApiColaborador.v3) {
				history.push("/v3/mis-pendientes");
			} else {
				history.push("/bandejaEntrada");
			}
		};
		cargarDatosIniciales();
	}, []);

	return (
		<div style={styleContainerLogin}>
			<span className="milegajo-primary-color">
				<Loading3QuartersOutlined spin={true} style={{ fontSize: "32px" }} />
			</span>
		</div>
	);
};

const styleContainerLogin = {
	width: "100%",
	height: "calc(100vh - 130px)",
	// background: "white",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};

export default RedireccionarPaginaPrincipal;
