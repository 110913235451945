import React, { useEffect, useState, useContext } from 'react';
import { Breadcrumb, Divider, Empty, List, Space, Table, Button } from 'antd';
import { useAtom } from 'jotai';
import { carpetaIdSeleccionadaAtom, documentoSeleccionadoAtom, documentosAtom, urlDocumentoAtom } from 'storage/MisDocumentosPage';
import { MisDocumentosService } from 'services/v3';
import { FcDocument, FcFolder } from 'react-icons/fc';
import styled from '@emotion/styled';
import { TipoArchivo } from 'enum';
import { useParams, useHistory } from 'react-router-dom';
import { VisorFormulario } from 'components/common';
import { EstadoVistaColaborador } from 'enum/EstadoVistaColaborador';
import {
    HomeOutlined,
    ArrowLeftOutlined,
    FileDoneOutlined
} from "@ant-design/icons"
import { LoadingDocumentos } from 'components/LoadingDocumentos';
import { SecurityContext } from "context/SecurityContextProvider";
import { saveAs } from "file-saver";
import { loadingAtom } from "../../../../../components/common/VisorFormulario/VisorFormularioStorage";
import { useAtomValue } from "jotai";

const MisDocumentosPageWeb = () => {
    return (
        <Container>
            <SeccionDocumentos>
                <Ruta />
                <ListaDocumentos />
            </SeccionDocumentos>
            <SeccionVisor>
                <VisorPdf />
            </SeccionVisor>
        </Container>
    );

};

const VisorPdf = () => {
    const [documentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const [urlDocumento, setUrlDocumento] = useAtom(urlDocumentoAtom);

    useEffect(() => {
        const cargarPdf = async () => {
            if (documentoSeleccionado) {
                const response = await MisDocumentosService.obtenerUrlDocumento(documentoSeleccionado.id);
                setUrlDocumento(response.url);
            }
        }
        cargarPdf();
    }, [documentoSeleccionado]);

    if (documentoSeleccionado) {
        return (
            <div>
                <Header/>
                <VisorFormulario
                    type="pdf"
                    urlPdf={urlDocumento}
                />
            </div>
            
        )
    }
    return (
        <div style={{
            backgroundColor: "white",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px"
        }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Seleccione un documento" />
        </div>)
}

const ListaDocumentos = () => {
    const [loading, setLoading] = useState(true);
    const [documentos, setDocumentos] = useAtom(documentosAtom);
    const [, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const { carpetaId } = useParams();
    const history = useHistory();


    useEffect(() => {
        const cargarDatos = async () => {
            setLoading(true);
            const response = await MisDocumentosService.listar({ carpetaId });
            setDocumentos(response);
            setLoading(false);
        }
        cargarDatos();
    }, [carpetaId]);

    const onClickSeleccionarDocumento = async (documento) => {
        if (documento.tipo === TipoArchivo.CARPETA) {
            setDocumentoSeleccionado(undefined);
            history.push(`/v3/mis-documentos/carpeta/${documento.id}`)
        } else {
            setDocumentoSeleccionado(documento);
        }
    }

    if (loading) {
        return <LoadingDocumentos items={5} />
    }

    return (
        <div style={{ marginTop: "5px", borderRadius: "5px", height: "calc(100vh - 155px)", overflow: "auto" }}>
            <List
                loading={loading}
                dataSource={documentos}
                renderItem={(documento) => (
                    <ItemDocumento documento={documento} onClick={onClickSeleccionarDocumento} />
                )}

            />
        </div>
    );

}

const ItemDocumento = ({ documento, onClick }) => {

    const [documentoSeleccionado] = useAtom(documentoSeleccionadoAtom);

    if (documento.tipo === "CARPETA") {
        return (
            <ItemContainerCarpeta onClick={() => onClick(documento)}>
                <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <FcFolder size={"2em"} />
                </span>
                <span style={{ display: "flex", flexDirection: "column", paddingLeft: "5px", justifyContent: "center" }}>
                    <span >{documento.nombre}</span>
                </span>
            </ItemContainerCarpeta>
        )
    }

    return (
        <ItemContainerDocumento onClick={() => onClick(documento)} isSelected={documento?.id === documentoSeleccionado?.id}>
            <span style={{ display: "flex" }}>
                <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <FileDoneOutlined style={{ fontSize: "2em", color: "#8abf60" }} />
                </span>
                <span style={{ display: "flex", flexDirection: "column", paddingLeft: "5px" }}>
                    <span>{documento.periodo}</span>
                    <span>{documento.nombre}</span>
                    {/* <span>
                        <Tag color={documento.estado === EstadoVistaColaborador.PENDIENTE ? "warning" : "green"}
                            style={{ fontSize: "10px", borderRadius: "10px" }}>
                            {documento.estado}
                        </Tag>
                    </span> */}
                </span>
            </span>
        </ItemContainerDocumento>
    )

}

const Header = () => {
    const [documentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const loading = useAtomValue(loadingAtom);

    const onClickBtnImprimir = async () =>{
        const response = await MisDocumentosService.obtenerUrlDocumento(documentoSeleccionado.id);
        fetch(response.url)
        .then(response => response.blob())
            .then(blob => {
                window.open(URL.createObjectURL(blob)).print();
            })
        .catch(error => {
            console.error('Error al obtener el recurso:', error);
        });
    }

    const onClickBtnDescargar = async () => {
		const nombreDocumento = documentoSeleccionado.nombre.replace(" ", "-");
		const documentoIdentidad = getUsuarioAutenticado().numeroDocumentoIdentidad;
        const nombreDescarga = nombreDocumento + "_" + documentoIdentidad + ".pdf";
        
        const response = await MisDocumentosService.obtenerUrlDocumento(documentoSeleccionado.id);
        
        fetch(response.url)
        .then(response => response.blob())
            .then(blobPdf => {
                 saveAs(blobPdf, nombreDescarga);
            })
        .catch(error => {
            console.error('Error al obtener el recurso:', error);
        }); 
	}


    return (
        // <div className="visor-pdf-header">
        //     <div className="acciones">
        
        <HeaderContainer>

            <ButtonContainer>
                <Space>
                    {!documentoSeleccionado?.ocultarBotonImprimir && (
                    <Button  disabled={loading} onClick={onClickBtnImprimir}>
                        Imprimir
                    </Button>
                )}
                {!documentoSeleccionado?.ocultarBotonDescarga && (
                        <Button disabled={loading} onClick={onClickBtnDescargar}>
                        Descargar
                    </Button>
                )}
                </Space>  
      
    </ButtonContainer>
  </HeaderContainer>
                
     
        //     </div>
        // </div>
            
            
        )
    
}

const HeaderContainer = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: white;
  padding: 10px 10px 10px 10px;
  border: 1px solid #ccc;
  margin-bottom: 2px;
  border-radius: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

const ItemContainerCarpeta = styled.span`
    display: flex;
    cursor: pointer;
    background: ${(props) => (props.isSelected === true ? "#caf0f8" : "white")};
    padding: 10px 0px 10px 10px;
    border: 1px solid #ccc;
    // border: 1px solid #ccc;
    margin-bottom: 5px;
    border-radius: 5px;
    border-left: 5px solid #faad14;
`

const ItemContainerDocumento = styled.span`
    display: flex;
    cursor: pointer;
    background: ${(props) => (props.isSelected === true ? "#caf0f8" : "white")};
    padding: 10px 0px 10px 10px;
    border: 1px solid #ccc;
    // border: 1px solid #ccc;
    margin-bottom: 5px;
    border-radius: 5px;
    border-left: 5px solid #8abf60;
`


const Ruta = () => {
    const [, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const { carpetaId } = useParams();
    const history = useHistory();

    const onClickRetornar = () => {
        setDocumentoSeleccionado(undefined);
        history.goBack();
    }

    if (!carpetaId) {
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/v3/mis-documentos">
                    <HomeOutlined /> Mis Documentos
                </Breadcrumb.Item>
            </Breadcrumb>
        )
    }
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item onClick={onClickRetornar} style={{ cursor: "pointer" }}>
                    <ArrowLeftOutlined /> Retornar
                </Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`

const SeccionDocumentos = styled.div`
    width: 30%;
`

const SeccionVisor = styled.div`
    width: 70%;
    margin-left: 15px;
    height: calc(100vh - 130px);
`

export default MisDocumentosPageWeb;