import React from "react";
import { Container } from "components/Container";
import { TablaFormularios } from "./TablaFormularios";

const ListaFormulariosWeb = () => {
    return (
        <Container titulo="Formularios">
            <TablaFormularios />
        </Container>
    );
}

export default ListaFormulariosWeb;