import React from "react";
import { Form, Input } from "antd";
import { useObservacionResultado } from "../../hooks";

const ObservacionResultado = () => {
	const { frmObservacionResultados } = useObservacionResultado();

	return (
		<Form form={frmObservacionResultados} style={{ marginBottom: "20px" }}>
			<div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px" }}>
				OBSERVACIÓN DEL RESULTADO DEL CALIFICADOR
			</div>
			<Form.Item name="observacion" style={{ marginBottom: "0px" }}>
				<Input.TextArea rows={3} />
			</Form.Item>
		</Form>
	);
};

export default ObservacionResultado;
