import React, { useContext } from "react";
import { Table, Tag } from "antd";
import moment from "moment";
import { useListarSolicitudesAusenciaPorJefeInmediato } from "../../hooks";
import { SecurityContext } from "context/SecurityContextProvider";

const ListaHistoricoSolicitudes = () => {
	const { solicitudesAusenciaHistorico } = useListarSolicitudesAusenciaPorJefeInmediato();
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isMaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const isMaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;
	const columnas = [
		{
			title: "Colaborador",
			dataIndex: ["colaborador", "nombreCompleto"],
			width: "20%",
		},
		{
			title: "Tipo Ausencia",
			dataIndex: ["tipo", "descripcion"],
			width: "15%",
			align: "center",
		},
		{
			title: "Fecha Inicio",
			dataIndex: "fechaInicioAusencia",
			width: "15%",
			align: "center",
			render: (text, fila) => moment(fila.fechaInicioAusencia).format("DD/MM/YYYY hh:mm a"),
		},
		{
			title: "Fecha Fin",
			dataIndex: "fechaFin",
			width: "15%",
			align: "center",
			render: (text, fila) => moment(fila.fechaFinAusencia).format("DD/MM/YYYY hh:mm a"),
		},
		{
			title: "Dias",
			dataIndex: "diasSolicitados",
			width: "5%",
			align: "center",
		},
		{
			title: "Fecha de Solicitud",
			dataIndex: "fechaHoraSolicitud",
			width: "15%",
			align: "center",
			render: (text, fila) => moment(fila.fechaHoraSolicitud).format("DD/MM/YYYY hh:mm a"),
		},
		{
			title: "Estado",
			//dataIndex: ["jefeInmediato","estadoAprobacion"],
			width: "15%",
			align: "center",
			// render: (text, fila) => (fila.jefeInmediato?.estadoAprobacion === "APROBADO" ? <Tag color="success">{fila.jefeInmediato.estadoAprobacion} </Tag> :
			// 	<Tag color="error">{fila.jefeInmediato?.estadoAprobacion}</Tag>)

			render: (text, fila) => {
				if (fila.jefeInmediato !== null && fila.jefeInmediato.estadoAprobacion !== "PENDIENTE") {
					if (fila.jefeInmediato?.estadoAprobacion === "APROBADO")
						return <Tag color="success">{fila.jefeInmediato.estadoAprobacion} </Tag>;
					else return <Tag color="error">{fila.jefeInmediato?.estadoAprobacion}</Tag>;
				} else {
					if (
						fila.responsableOtrosCargos !== null &&
						fila.responsableOtrosCargos.estadoAprobacion === "APROBADO"
					)
						return <Tag color="success">{fila.responsableOtrosCargos.estadoAprobacion} </Tag>;
					else return <Tag color="error">{fila.responsableOtrosCargos.estadoAprobacion}</Tag>;
				}
			},
		},
		// {
		// 	title: "Acciones",
		// 	align: "center",
		// 	width: "15%",
		// 	//render: (text, fila) => <MenuAcciones solicitud={fila} />,
		// },
	];
	return (
		<Table
			rowKey={(value) => value.id}
			columns={columnas}
			dataSource={solicitudesAusenciaHistorico}
			expandable={{
				expandedRowRender: (fila) => <Comentario registro={fila} />,
				rowExpandable: (fila) =>
					((isMaccaferriConstruction || isMaccaferriDePeru) &&
						fila.comentario !== null &&
						fila.comentario !== undefined &&
						fila.comentario !== "") ||
					(isAceros && fila.informacionAdicional !== null),
			}}
		/>
	);
};
const Comentario = ({ registro }) => {
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isMaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const isMaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;

	return (
		<React.Fragment>
			{(isMaccaferriDePeru || isMaccaferriConstruction) && (
				<p style={{ margin: 5 }}>{"EMPLEADO DESIGNADO: " + registro.comentario}</p>
			)}
			{isAceros && (
				<div style={{ marginLeft: 50 }}>
					<p>
						<strong>Fecha sobre tiempo: </strong>
						{moment(registro.informacionAdicional.fechaSobreTiempo).format("DD/MM/YYYY")}
					</p>
					<p>
						<strong>Día de compensación: </strong>{" "}
						{moment(registro.informacionAdicional.diaCompensacion).format("DD/MM/YYYY")}
					</p>
					<p>
						<strong>Cantidad de horas: </strong> {registro.informacionAdicional.cantidadHoras}
					</p>
					<p>
						<strong>Horas compensadas: </strong> {registro.informacionAdicional.horasCompensadas}
					</p>
				</div>
			)}
		</React.Fragment>
	);
};

export default ListaHistoricoSolicitudes;
