import React from "react";
import { ListaDocumentos, SubirDocumentos } from "./componentes";
import styled from "@emotion/styled";
import { FormProvider, useForm } from "react-hook-form";
import { VisorFormulario } from "components/common";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
	datosFormularioAtom,
	documentoSeleccionadoAtom,
	informacionVisorAtom,
	visibleModalFirmarAtom,
	visibleSubirDocumentosAtom,
	visibleVisorAtom,
	loadingPdfAtom,
	visibleModalOmitirAtom,
} from "pages/onboarding/OnboardingStore";
import { Button, Empty, Space } from "antd";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import { EstadoAprobacion } from "enum/EstadoAprobacion";
import { CategoriaDocumento } from "enum/CategoriaDocumento";
import LoginService from "services/LoginService";

const CompletarDocumentos = () => {
	const documentoSeleccionado = useAtomValue(documentoSeleccionadoAtom);
	const informacionVisor = useAtomValue(informacionVisorAtom);
	const visibleVisor = useAtomValue(visibleVisorAtom);
	const visibleSubirDocumentos = useAtomValue(visibleSubirDocumentosAtom);
	const setVisibleModalFirmar = useSetAtom(visibleModalFirmarAtom);
	const setDatosFormulario = useSetAtom(datosFormularioAtom);
	const [loading, setLoading] = useAtom(loadingPdfAtom);
	const setVisibleModalOmitir = useSetAtom(visibleModalOmitirAtom);
	const frmVisor = useForm();

	const isLaredo = LoginService.getUsuarioAutenticado().empresaSeleccionada?.ruc === "20132377783" ? true : false;

	const onSubmit = (datos) => {
		setDatosFormulario(datos);
		setVisibleModalFirmar(true);
	};

	const onClickBtnFirmar = () => {
		frmVisor.handleSubmit(onSubmit)();
	};

	const onClickBtnOmitir = () => {
		setVisibleModalOmitir(true);
	};

	return (
		<Container>
			<ListaDocumentos />
			<ContainerVisor>
				{!documentoSeleccionado && (
					<div
						style={{
							borderRadius: "10px",
							height: "100%",
							width: "100%",
							background: "white",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Empty description="Seleccione un documento" />
					</div>
				)}

				{documentoSeleccionado && (
					<>
						{!visibleSubirDocumentos && (
							<React.Fragment>
								{documentoSeleccionado?.categoriaDocumentoId === CategoriaDocumento.FORMULARIO &&
									(documentoSeleccionado?.estadoVistaColaborador ===
										EstadoVistaColaborador.PENDIENTE ||
										documentoSeleccionado?.estadoAprobacion === EstadoAprobacion.RECHAZADO) && (
										<Header>
											<Acciones>
												<Space>
													{documentoSeleccionado?.opcional && (
														<Button
															type="danger"
															onClick={onClickBtnOmitir}
															loading={loading}
															disabled={loading}
														>
															Omitir
														</Button>
													)}
													<Button
														type="primary"
														onClick={onClickBtnFirmar}
														loading={loading}
														disabled={loading}
													>
														Firmar Documento
													</Button>
												</Space>
											</Acciones>
										</Header>
									)}
								<Body
									withHeader={
										documentoSeleccionado?.categoriaDocumentoId === CategoriaDocumento.FORMULARIO &&
											documentoSeleccionado?.estadoVistaColaborador ===
											EstadoVistaColaborador.PENDIENTE
											? true
											: false
									}
								>
									{visibleVisor && (
										<FormProvider {...frmVisor}>
											<VisorFormulario
												type={informacionVisor?.type}
												form={frmVisor}
												urlPdf={informacionVisor?.urlPlantillaPdfFirmada}
												componentes={informacionVisor?.componentes}
												variablesGlobales={informacionVisor?.variablesGlobales}
												datosFormulario={informacionVisor?.datosFormulario}
												urlHuellaDactilar={informacionVisor?.urlHuellaDactilar}
												urlFirmaHolografa={informacionVisor?.urlFirmaHolografa}
												urlFoto={informacionVisor?.urlFoto}
												setLoadingPdf={setLoading}
												motivoRechazo={informacionVisor?.motivoRechazo}
												centrarPdf={isLaredo ? false : true}
											/>
										</FormProvider>
									)}
								</Body>
							</React.Fragment>
						)}
						{visibleSubirDocumentos && <SubirDocumentos />}
					</>
				)}
			</ContainerVisor>
		</Container>
	);
};

//revisar --> coflicto con el zoom
const Container = styled.div`
	display: flex;
	flex-wrap: "wrap";
	justify-content: space-between;
	height: calc(100vh - 190px);
`;

const ContainerVisor = styled.div`
	flex: 1 1 70%;
	margin: 10px;
`;

const Header = styled.div`
	min-height: 50px;
	max-height: 50px;
	display: flex;
	background: white;
	padding: 0px 10px;
	border: 1px solid #ccc;
	border-bottom: 0px;
`;

const Body = styled.div`
	height: ${(props) => (props.withHeader === true ? "calc(100% - 50px)" : "100%")};
`;

const Acciones = styled.div`
	flex: 1 1 50%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export default CompletarDocumentos;
