import React, { useEffect } from "react";
import "./App.less";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SecurityContextProvider from "./context/SecurityContextProvider";
import { LoginPage, LoginEmpresaPage, LoginGenericoPage, LoginActiveDirectoryPage, LoginActiveDirectoryValidationPage } from "./pages";
import { RutaCortaPage } from "pages/ruta-corta";
import { ActualizarClavePage } from "./pages/cuenta/actualiza-clave";
import { GenerarClavePage } from "./pages/cuenta/generar-clave";
import { PrivateRoute } from "./components/PrivateRouter";
import { RutasApp } from "./router/RutasApp.js";
import { pdfjs } from "react-pdf";
import locale from "antd/lib/locale/es_ES";
import { ConfigProvider } from "antd";
import { VerificarDatosPersonalesPage } from "pages/verificar-datos-personales";
import { AuthLoginPage } from "pages/auth";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

function App() {

	/* Nota: Para que funciona pdfjs con es5 react-pdf debe estar en la version 4.2.0 */
	/* Nota: Se debe invocar al pdf.worker.js local porque está modificado para mostrar la firma digital */
	console.log("pdfjs.version: " + pdfjs.version);
	// pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + "/pdf.worker.5.js";
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

	return (
		<SecurityContextProvider>
			<ConfigProvider locale={locale} input={{ autoComplete: "off" }}>
				<BrowserRouter>
					<AppRouter />
					{/* <URLChangeListener />
					<Switch>
						<Route exact path="/login" component={LoginPage} />
						<Route exact path="/e/login" component={LoginEmpresaPage} />
						<Route exact path="/g/login" component={LoginGenericoPage} />
						<Route exact path="/ad/login" component={LoginActiveDirectoryPage} />
						<Route exact path="/ad/login/validation" component={LoginActiveDirectoryValidationPage} />

						<Route exact path="/r/:hashUrl" component={RutaCortaPage} />
						<Route exact path="/actualizarClave" component={ActualizarClavePage} />
						<Route exact path="/generarClave" component={GenerarClavePage} />
						<Route exact path="/verificarDatosPersonales" component={VerificarDatosPersonalesPage} />
						<Route exact path="/auth/login" component={AuthLoginPage} />
						<PrivateRoute exact component={RutasApp} />
					</Switch> */}
				</BrowserRouter>
			</ConfigProvider>
		</SecurityContextProvider>
	);
}

const AppRouter = () => {

	const location = useLocation();

	useEffect(() => {
		console.log("URL actual:", location.pathname); // Log cada cambio de URL
	}, [location]);

	// const history = useHistory();
	// const location = useLocation();

	// useEffect(() => {
	// 	// Configurar el listener para cada cambio en `location.pathname`
	// 	const unlisten = history.listen((location) => {
	// 		console.log("URL cambió a:", location.pathname);
	// 	});

	// 	// Llamar al listener una vez al montar el componente para detectar la URL inicial
	// 	console.log("URL inicial***:", location.pathname);

	// 	// Limpia el listener al desmontar
	// 	return () => unlisten();
	// }, [history, location]);

	return (
		<Switch>
			<Route exact path="/login" component={LoginPage} />
			<Route exact path="/e/login" component={LoginEmpresaPage} />
			<Route exact path="/g/login" component={LoginGenericoPage} />
			<Route exact path="/ad/login" component={LoginActiveDirectoryPage} />
			<Route exact path="/ad/login/validation" component={LoginActiveDirectoryValidationPage} />

			<Route exact path="/r/:hashUrl" component={RutaCortaPage} />
			<Route exact path="/actualizarClave" component={ActualizarClavePage} />
			<Route exact path="/generarClave" component={GenerarClavePage} />
			<Route exact path="/verificarDatosPersonales" component={VerificarDatosPersonalesPage} />
			<Route exact path="/auth/login" component={AuthLoginPage} />
			{/* <Route exact path="/publico/convocatorias" component={ConvocatoriasPage} />
						<Route exact path="/publico/registro-postulante/:cargo" component={FormularioPostulantePage} /> */}
			<PrivateRoute exact component={RutasApp} />
		</Switch>
	)

}

const URLChangeListener = () => {
	const history = useHistory();

	useEffect(() => {
		// Escucha cambios de URL
		const unlisten = history.listen((location) => {
			console.log("URL cambió a:", location.pathname); // Imprime cada vez que cambia la URL
		});

		// Limpia el listener al desmontar
		return () => unlisten();
	}, [history]);

	return null; // No renderiza nada, solo escucha cambios en la URL
}

export default App;
