import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { colaboradorAtom } from "pages/perfil/PerfilStore";
import { PortalColaboradorService } from "services/PortalColaboradorService";
const useCargarColaborador = () => {
	const [colaborador, setColaborador] = useAtom(colaboradorAtom);

	const cargarColaborador = async () => {
		const respuesta = await PortalColaboradorService.buscarColaborador();
		setColaborador(respuesta);
		//console.log("colaborador", respuesta);
	};

	return { cargarColaborador };
};
export default useCargarColaborador;
