import React, { useContext,useEffect, useState } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";
import { ItemConsulta } from "./ItemConsulta";
import { LoadingDocumentos } from "components/LoadingDocumentos";
import * as atoms from "../BandejaConsultaMovilStorage";
import { useAtom } from "jotai";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import ModalVerConsulta from "./ModalVerConsulta";
import { Alert } from "antd";
import * as atomsConsulta from "../../../../../hooks/consultas/ConsultasStorage";

const ListaConsultas = () => {
    //const { consultas, getConsultas, loading } = useContext(MisDocumentosContext);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [consultas, setConsultas] = useAtom(atoms.consultasAtom);
    const [loading, setLoading] = useState(false);
    const [numeroRespuestas, setNumeroRespuestas] = useAtom(atoms.numeroRespuestasAtom);
    const [totalRespuestasUsuario, setTotalRespuestasUsuario] = useAtom(atomsConsulta.totalRespuestasUsuarioAtom);

    useEffect(() => {
        // getConsultas(
        //     getUsuarioAutenticado().empresaSeleccionada.id,
        //     getUsuarioAutenticado().numeroDocumentoIdentidad
        // );
        
        const cargarDatos =async() => {
            const _consultas = await PortalColaboradorService.listaConsultas(getUsuarioAutenticado().empresaSeleccionada.id,
                getUsuarioAutenticado().numeroDocumentoIdentidad);
            setConsultas(_consultas);
            let listaConsultasId = [];       
            if (_consultas.length > 0) {
                _consultas.map((consulta) => {
                    listaConsultasId.push(consulta.id);
                })
                const respuestasXConsulta = await PortalColaboradorService.listaNumeroRespuestas(listaConsultasId, "respuestaUsuario");
                setNumeroRespuestas(respuestasXConsulta);
                let totalRespuestas = 0;
                respuestasXConsulta.length > 0 && respuestasXConsulta.map((respuesta) => {    
                respuesta.numeroRespuestas > 0 && totalRespuestas++;
                  })
                setTotalRespuestasUsuario(totalRespuestas);
            }
           
        }
        cargarDatos();
    }, []);

    const Consultas = () => {
		const items = consultas.map((consulta, index) => (
			<ItemConsulta key={consulta.id} consulta={consulta} />
		));
		return items;
	};
    
    return (
        <React.Fragment>
            {totalRespuestasUsuario > 0 &&
                ( <Alert message="Estimado Colaborador, tiene notificaciones pendientes de revisar." type="info" showIcon />  )                           
            }         
           
           <div className="bandeja-entrada__container-documentos">
                {/* {loading && <LoadingDocumentos items={5} />} */}
                {!loading && <Consultas />}
            </div>
           <ModalVerConsulta/>
       </React.Fragment>
    )
}
export default ListaConsultas;