import React from "react";
import { PanelInformacion } from "./PanelInformacion";
import { PanelBotones } from "./PanelBotones";
import { ModalConsultar } from "./ModalConsultar";

export function Header() {
	return (
		<div className="visor-pdf-header">
			<PanelInformacion />
			<PanelBotones />
		</div>
	);
}
