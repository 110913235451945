import React from "react";
import { usePreguntasTrabajoEnEquipo } from "../../hooks";
import { Preguntas } from "../Preguntas";

const PreguntasTrabajoEnEquipo = () => {
	const { frmPreguntas, setPuntajeTotal } = usePreguntasTrabajoEnEquipo();

	return (
		<Preguntas
			form={frmPreguntas}
			calcularPuntajeTotal={setPuntajeTotal}
			titulo="TRABAJO EN EQUIPO (3/6)"
			opciones={[
				{
					key: "muestraAptitudParaIntegrarseAlEquipo",
					label: "Muestra actitud para integrarse al equipo",
				},
				{ key: "seMuestraRespetuosoYAmableEnElTrato", label: "Se muestra respetuoso y amable en el trato" },
				{
					key: "seIdentificaFacilmenteConLosObjetivosDelEquipo",
					label: "Se identifica fácilmente con los objetivos del equipo",
				},
			]}
		/>
	);
};

export default PreguntasTrabajoEnEquipo;
