import React, { useEffect } from "react";
import { documentosSolicitadosAtom } from "../store/MisSolicitudesDocumentosStore";
import { useAtom } from "jotai";
import TipoDocumentoService from "services/TipoDocumentoService";
import { CategoriaDocumento } from "enum/CategoriaDocumento";

const useListarDocumentos = () => {
	const [documentos, setDocumentos] = useAtom(documentosSolicitadosAtom);
	useEffect(() => {
		const cargarDatos = async () => {
			const documentosPersonales = await TipoDocumentoService.listarDocumentosPersonales(
				"",
				CategoriaDocumento.DOCUMENTO_PERSONAL
			);

			setDocumentos(documentosPersonales);
		};
		cargarDatos();
	}, []);
	return { documentos };
};
export default useListarDocumentos;
