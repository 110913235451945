import React from "react";
import { Select,Modal,Form } from "antd";
import { DownOutlined,ExclamationCircleOutlined  } from "@ant-design/icons";
import { SecurityContext } from "context/SecurityContextProvider";
import { useContext,useState } from "react";
import { VersionApiColaborador } from "enum/VersionApiColaborador"
import { useHistory } from "react-router-dom";

const SeleccionarEmpresa = () => {
	
	const { getVersionApiColaborador, getUsuarioAutenticado, setEmpresaSeleccionada } = useContext(SecurityContext);	
	const [form] = Form.useForm();	
	let history = useHistory();

	function handleOnChangeEmpresa(idEmpresa) {
		setEmpresaSeleccionada(idEmpresa);
		if (getVersionApiColaborador() === VersionApiColaborador.v1) {
			history.push("/bandejaEntrada");
		} else {
			history.push("/mis-documentos");
			window.location.reload();
		}
		
	}
	const handleOnCancel = () => {		
		
		form.setFieldsValue({
			empresa:getUsuarioAutenticado().empresaSeleccionada.id
		    })
	}
	const changeEmpresa = (idEmpresa) => {
		Modal.confirm({
			title: 'Confirmar empresa',
			icon: <ExclamationCircleOutlined />,
			content: '¿Está seguro de cambiar de empresa?',
			okText: 'Si',
			cancelText: 'No',
			centered: true,
			width:"100",
			onOk() { handleOnChangeEmpresa(idEmpresa) },
			onCancel(){handleOnCancel()}
		})
	}
	return(		
		<Form form={form}>
			<div style={{textAlign:"center",marginTop:"50%"}}>
				<div>Selecciona tu empresa</div>
				   <Form.Item name="empresa" style={{margin:"5px 30px"}}>
					  <Select
								className="texto-menu-perfil"
								suffixIcon={<DownOutlined style={{ color: "#1678c1" }} />}
								defaultValue={getUsuarioAutenticado().empresaSeleccionada.id}								
								dropdownMatchSelectWidth={false}				               
				                onChange={changeEmpresa}
				
							>
								{getUsuarioAutenticado().empresas.map((empresa) => (
									<Select.Option key={empresa.id} value={empresa.id}>
										{empresa.razonSocial}
									</Select.Option>
								))}
							</Select>
				   </Form.Item>
							
		    </div>
		</Form>
		
	)
};

export default SeleccionarEmpresa;
