import React, { useEffect } from "react";
import { Space, Button, Modal, Form, Input, Divider } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import {
	detalleEntrevistaAtom,
	visibleModalAgregarExperienciaLaboralAtom,
} from "pages/detalle-entrevista/DetalleEntrevistaStore";
import { useParams } from "react-router-dom";
import { EntrevistasService } from "services/reclutamiento-y-seleccion";
import { useState } from "react";

const ModalAgregarExperienciaLaboral = () => {
	const [visible, setVisible] = useAtom(visibleModalAgregarExperienciaLaboralAtom);
	const [detalleEntrevista, setDetalleEntrevista] = useAtom(detalleEntrevistaAtom);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const { entrevistaId } = useParams();

	useEffect(() => {
		const cargarDatos = async () => {
			if (visible) {
				const experienciasLaborales = await EntrevistasService.obtenerExperienciasLaborales(entrevistaId);
				form.setFieldsValue({
					...experienciasLaborales,
				});
			}
		};
		cargarDatos();
	}, [visible]);

	const onClickBtnGuardar = () => {
		form.validateFields()
			.then(async (experienciasLaborales) => {
				setLoading(true);
				const respuesta = await EntrevistasService.agregarExperienciasLaborales({
					entrevistaId: entrevistaId,
					...experienciasLaborales,
				});
				setDetalleEntrevista({ ...detalleEntrevista, experienciasLaboralesCompleto: respuesta });
				setLoading(false);
				setVisible(false);
			})
			.catch((info) => {
				setLoading(false);
				console.log("Validate Failed:", info);
			});
	};

	return (
		<Modal
			title="Experiencia Laboral"
			bodyStyle={{ overflowY: "scroll", minHeight: "calc(100vh - 220px)", maxHeight: "calc(100vh - 220px)" }}
			centered={true}
			maskClosable={false}
			visible={visible}
			okText="Guardar"
			cancelText="Cancelar"
			confirmLoading={loading}
			onOk={onClickBtnGuardar}
			onCancel={() => setVisible(false)}
		>
			<Form form={form} layout="vertical">
				<Form.List name="experienciasLaborales" initialValue={[{ empresa: "" }]}>
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (
								<div key={key}>
									{key > 0 && (
										<Divider>
											<Space>
												<span>Experiencia Laboral</span>
												<Button
													type="danger"
													size="small"
													shape="circle"
													icon={<CloseOutlined />}
													onClick={() => remove(name)}
												/>
											</Space>
										</Divider>
									)}
									<Form.Item
										{...restField}
										name={[name, "empresa"]}
										label="Empresa"
										rules={[{ required: true }]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										{...restField}
										name={[name, "puesto"]}
										label="Puesto"
										rules={[{ required: true }]}
									>
										<Input />
									</Form.Item>
									<Space>
										<Form.Item
											{...restField}
											name={[name, "fechaInicio"]}
											label="Fecha Inicio"
											rules={[{ required: true }]}
										>
											<Input />
										</Form.Item>
										<Form.Item
											{...restField}
											name={[name, "fechaFin"]}
											label="Fecha Fin"
											rules={[{ required: true }]}
										>
											<Input />
										</Form.Item>
									</Space>
									<Space>
										<Form.Item
											{...restField}
											name={[name, "tiempo"]}
											label="Tiempo"
											rules={[{ required: true }]}
										>
											<Input />
										</Form.Item>
										<Form.Item
											{...restField}
											name={[name, "numeroPersonasCargo"]}
											label="Personas a cargo"
											rules={[{ required: true }]}
										>
											<Input />
										</Form.Item>
									</Space>
									<Form.Item
										{...restField}
										name={[name, "motivoSalida"]}
										label="Motivo de salida"
										rules={[{ required: true }]}
									>
										<Input />
									</Form.Item>
									<Space>
										<Form.Item
											{...restField}
											name={[name, "remuneracion"]}
											label="Remuneración"
											rules={[{ required: true }]}
										>
											<Input />
										</Form.Item>
										<Form.Item
											{...restField}
											name={[name, "bonos"]}
											label="Bonos"
											rules={[{ required: true }]}
										>
											<Input />
										</Form.Item>
									</Space>
									<Form.Item
										{...restField}
										name={[name, "contactoReferencia"]}
										label="Contacto de referencia"
										rules={[{ required: true }]}
									>
										<Input />
									</Form.Item>
								</div>
								//</Space>
							))}
							<Form.Item>
								<Button type="dashed" shape="round" onClick={() => add()} block icon={<PlusOutlined />}>
									Agregar Experiencia Laboral
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
			</Form>
		</Modal>
	);
};

export default ModalAgregarExperienciaLaboral;
