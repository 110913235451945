import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { colaboradorAtom } from "pages/perfil/PerfilStore";
import { PortalColaboradorService } from "services/PortalColaboradorService";
const useBuscarColaborador = () => {
	const [colaborador, setColaborador] = useAtom(colaboradorAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			const respuesta = await PortalColaboradorService.buscarColaborador();
			setColaborador(respuesta);
			//console.log("colaborador", respuesta);
		};
		cargarDatos();
	}, []);

	return { colaborador };
};
export default useBuscarColaborador;
