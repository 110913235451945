import React from "react";
import { Alert, Button, Input } from "antd";
import { useAtom } from "jotai";
import { numeroDocumentoIdentidadAtom, visibleCrearClavePageAtom } from "../../AuthLoginStore";
import { useState } from "react";
import { AuthLoginService } from "services";
import LoginService from "services/LoginService";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { VersionApiColaborador } from "enum/VersionApiColaborador";
import { useHistory } from "react-router-dom";

const subdominio = window.location.host.split(".")[0];
const parametros = new URLSearchParams(window.location.search);

const SEPARADOR_DOMINIO = "__";

const CrearClavePage = () => {
	const history = useHistory();

	const [visible] = useAtom(visibleCrearClavePageAtom);
	const [documentoIdentidad] = useAtom(numeroDocumentoIdentidadAtom);
	const [clave, setClave] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(undefined);

	const { getVersionApiColaborador, setSesionUsuario } = useContext(SecurityContext);

	const onClickBtnCrearCuenta = async () => {
		try {
			setLoading(true);
			const creacionClaveExitosa = await AuthLoginService.crearClave({
				subdominio: subdominio,
				token: parametros.get("token"),
				documentoIdentidad: documentoIdentidad,
				clave: clave,
			});

			if (creacionClaveExitosa) {
				try {
					const respuesta = await LoginService.autenticar(
						subdominio + SEPARADOR_DOMINIO + documentoIdentidad,
						clave
					);

					if (respuesta.estado === "ok") {
						const sesionUrl = parametros.get("sesionUrl");
						const sesionTab = parametros.get("sesionTab");
						setSesionUsuario(respuesta);
						if (sesionUrl) {
							const urlParametros = sesionTab ? "?sesionTab=" + sesionTab : "";
							history.push(sesionUrl + urlParametros);
						} else if (getVersionApiColaborador() === VersionApiColaborador.v1) {
							history.push("/bandejaEntrada");
						} else if (getVersionApiColaborador() === VersionApiColaborador.v2) {
							history.push("/mis-documentos");
						} else {
							history.push("/bandejaEntrada");
						}
					} else if (respuesta.error === "auth-1") {
						setLoading(false);
						setError("Usuario y/o clave son incorrectos");
					}
				} catch (error) {
					if (error.response && error.response.data.mensaje) {
						setError(error.response.data.mensaje);
					}
					setLoading(false);
				}
			}

			// setLoading(false);
		} catch (error) {
			switch (error?.response?.data?.error) {
				case "AUTH-LOGIN-02":
					setError("Documento de identidad incorrecto");
					break;

				default:
					setError("Token inválido");
			}
			setLoading(false);
		}
	};

	const onCloseError = () => {
		setError(undefined);
	};

	if (!visible) {
		return "";
	}

	return (
		<div style={{ padding: "3rem" }}>
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
				<img src="https://storage.googleapis.com/milegajodigital.com/_default/imagenes/crear-clave.png" />
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					fontSize: "1.2rem",
					fontWeight: "600",
					paddingTop: "10px",
					textAlign: "center",
				}}
			>
				Crea una clave segura
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					textAlign: "center",
					paddingTop: "10px",
					paddingBottom: "10px",
					color: "rgb(144, 145, 152)",
				}}
			>
				Crear una clave segura para ingresar a la plataforma
			</div>

			{error && <Alert message={error} type="error" showIcon closable onClose={onCloseError} />}

			<div style={{ paddingTop: "20px", paddingBottom: "5px", fontWeight: "bold" }}>Clave</div>

			<div>
				<Input.Password size="large" onChange={(e) => setClave(e.target.value)} />
			</div>

			<div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
				<Button
					type="primary"
					size="large"
					shape="round"
					block
					loading={loading}
					onClick={onClickBtnCrearCuenta}
				>
					Crear Cuenta
				</Button>
			</div>
		</div>
	);
};

export default CrearClavePage;
