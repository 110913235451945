import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Breadcrumb, List, Empty } from 'antd';
import styled from '@emotion/styled';
import { TipoArchivo } from 'enum';
import { useParams, useHistory } from 'react-router-dom';
import { MisDocumentosService } from 'services/v3';
import { FcDocument, FcFolder } from 'react-icons/fc';
import { carpetaIdSeleccionadaAtom, documentoSeleccionadoAtom, documentosAtom, urlDocumentoAtom } from 'storage/MisDocumentosPage';
import logotipo from "../../../../../assets/imagenes/logotipo.png";
import { VisorFormulario } from 'components/common';

import {
    HomeOutlined,
    ArrowLeftOutlined,
    FileDoneOutlined
} from "@ant-design/icons"
import { LoadingDocumentos } from 'components/LoadingDocumentos';


const MisDocumentosPageMovil = () => {
    return (
        <Container>
            <SeccionDocumentos>
                <Ruta />
                <ListaDocumentos />
            </SeccionDocumentos>
        </Container>
    );

};

const ListaDocumentos = () => {

    const [loading, setLoading] = useState(true);
    const [documentos, setDocumentos] = useAtom(documentosAtom);
    const [, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const { carpetaId } = useParams();
    const history = useHistory();


    useEffect(() => {
        const cargarDatos = async () => {
            setLoading(true);
            const response = await MisDocumentosService.listar({ carpetaId });
            setDocumentos(response);
            setLoading(false);
        }
        cargarDatos();
    }, [carpetaId]);

    const onClickSeleccionarDocumento = async (documento) => {
        if (documento.tipo === TipoArchivo.CARPETA) {
            setDocumentos([]);
            history.push(`/v3/mis-documentos/carpeta/${documento.id}`)
        } else {
            setDocumentoSeleccionado(documento);
        }
    }

    if (loading) {
        return <LoadingDocumentos items={5} />
    }

    return (
        <div style={{ marginTop: "5px", borderRadius: "5px", height: "calc(100vh - 120px)", overflow: "auto" }}>
            <List
                loading={loading}
                dataSource={documentos}
                renderItem={(documento) => (
                    <ItemDocumento documento={documento} onClick={onClickSeleccionarDocumento} />
                )}

            />
        </div>
    );

}

const ItemDocumento = ({ documento, onClick }) => {
    let history = useHistory();
    const [documentoSeleccionado] = useAtom(documentoSeleccionadoAtom);

    const onClickSeleccionarItem = () => {
        history.push(`/mis-documentos/visor-pdf/${documento.id + "__v3"}`);
    }

    if (documento.tipo === "CARPETA") {
        return (
            <ItemContainerCarpeta onClick={() => onClick(documento)}>
                <span style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "10px" }}>
                    <FcFolder size={"2em"} />
                </span>
                <span style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "10px" }}>
                    <span>{documento.nombre}</span>
                </span>
            </ItemContainerCarpeta>
        )
    }

    return (
        <ItemContainerDocumento
            onClick={() => onClick(onClickSeleccionarItem)}
            isSelected={documento?.id === documentoSeleccionado?.id}>
            <span style={{ display: "flex" }}>
                <span style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "10px" }}>
                    {/* <FcDocument size={"2em"} /> */}
                    <FileDoneOutlined style={{ fontSize: "2em", color: "#8abf60" }} />
                </span>
                <span style={{ display: "flex", flexDirection: "column", paddingLeft: "10px" }}>
                    <span>
                        {documento.periodo}
                    </span>
                    <span>
                        {documento.nombre}
                    </span>
                </span>
            </span>
        </ItemContainerDocumento>
    )

}

const ItemContainerCarpeta = styled.span`
    display: flex;
	min-height: 20px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	margin: 10px 15px;
	padding-top: 8px;
	padding-bottom: 10px;
	border-left: 5px solid #faad14;
`

const ItemContainerDocumento = styled.span`
    display: flex;
	min-height: 20px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	margin: 10px 15px;
	padding-top: 8px;
	padding-bottom: 10px;
	border-left: 5px solid #8abf60;
`

const Ruta = () => {
    const [, setDocumentos] = useAtom(documentosAtom);
    const { carpetaId } = useParams();
    const history = useHistory();

    const onClickBtnRetornar = () => {
        setDocumentos([]);
        history.goBack();
    }

    if (!carpetaId) {
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/v3/mis-documentos">
                    <SeccionHeader>
                        <div style={{ width: "calc(100vw - 0px)" }}>
                            <img
                                src={logotipo}
                                alt=""
                                style={{ height: "36px", marginRight: "10px" }}
                            />
                            Mis Documentos
                        </div>

                    </SeccionHeader>
                </Breadcrumb.Item>
            </Breadcrumb>
        )
    }
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item onClick={onClickBtnRetornar} style={{ cursor: "pointer" }}>
                    <ArrowLeftOutlined /> Retornar
                </Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}

const SeccionHeader = styled.div`
	height: 60px;
	max-height: 60px;
	display: flex;
	align-items: center;
	padding-left: 15px;
	color: #1c74b3;
	font-size: 15px;
	border-bottom: 1px solid #cccccc;
	font-weight: bold;
	box-shadow: 0px 2px 10px #ccc;
	width: 100%;
	z-index: 100;
`

const Container = styled.div`
    width: 100%;
`

const SeccionDocumentos = styled.div`
    width: 100%;
`
const SeccionVisor = styled.div`
    width: 70%;
    margin-left: 15px;
    height: calc(100vh - 130px);
`
export default MisDocumentosPageMovil;