import React, { useContext, useState } from "react";
import { Alert, Input, Button, Modal, message, notification } from "antd";
import { Plataforma } from "enum/Plataforma";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
	datosFormularioAtom,
	tareaPendienteSeleccionadaAtom,
	tareaSeleccionadaIdAtom,
	tareasPendientesAtom,
	visibleModalFirmarAtom,
} from "pages/tareas-pendientes/TareasPendientesStorage";
import produce from "immer";
import { TareasPendientesService } from "services";

const ModalFirmar = ({ plataforma = Plataforma.WEB }) => {
	const [visible, setVisible] = useAtom(visibleModalFirmarAtom);

	const setTareaSeleccionadaId = useSetAtom(tareaSeleccionadaIdAtom);
	const setTareaPendienteSeleccionada = useSetAtom(tareaPendienteSeleccionadaAtom);
	const setTareasPendientes = useSetAtom(tareasPendientesAtom);

	const [datosFormulario, setDatosFormulario] = useAtom(datosFormularioAtom);
	const [loading, setLoading] = useState(false);
	const [mensajeError, setMensajeError] = useState(undefined);
	const [claveIngresada, setClaveIngresada] = useState("");

	const onClickBtnCancelar = () => {
		setDatosFormulario(undefined);
		setMensajeError(undefined);
		setClaveIngresada("");
		setVisible(false);
	};

	const eliminarTareaPendiente = () => {
		setTareasPendientes(
			produce((draft) => {
				const index = draft.findIndex((tarea) => tarea.id === datosFormulario.tareaPendienteId);
				draft.splice(index, 1);
			})
		);
		setTareaSeleccionadaId(undefined);
		setTareaPendienteSeleccionada(undefined);
	};

	const onClickBtnFirmar = async () => {
		try {
			setLoading(true);
			const respuesta = await TareasPendientesService.firmarDocumento({
				...datosFormulario,
				clave: claveIngresada,
			});
			if (respuesta) {
				eliminarTareaPendiente();
				notification.success({
					message: "Éxito",
					description: "Documento firmado exitosamente.",
				});
				setVisible(false);
			}
		} catch (error) {
			setMensajeError(error?.response?.data?.mensaje);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			title="Firmar Documento"
			open={visible}
			closable={false}
			width={400}
			destroyOnClose={true}
			footer={[
				<Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
					Cancelar
				</Button>,
				<Button
					key="firmar"
					type="primary"
					loading={loading}
					onClick={onClickBtnFirmar}
					style={{ padding: "0px 30px" }}
				>
					Firmar
				</Button>,
			]}
			centered
		>
			{mensajeError && (
				<Alert
					message={mensajeError}
					onClose={() => setMensajeError(undefined)}
					style={{ marginBottom: "4px" }}
					type="error"
					showIcon
					closable
				/>
			)}

			<span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
			<Input.Password
				value={claveIngresada}
				onChange={(e) => {
					setClaveIngresada(e.target.value);
					setMensajeError(undefined);
				}}
				placeholder={"Clave"}
			/>
		</Modal>
	);
};

export default ModalFirmar;
