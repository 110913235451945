import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Button, Alert, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import LoginService from "services/LoginService";
import { SizeContextProvider } from "antd/lib/config-provider/SizeContext";

const subdominio = window.location.host.split(".")[0];
const parametros = window.location.search;

const LoginActiveDirectoryPage = () => {
	const [urlFondo, setUrlFondo] = useState("");
	const [urlLogotipo, setUrlLogotipo] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			const datos = await LoginService.cargarDatosIniciales(subdominio);
			setUrlFondo(datos.urlImagenFondo);
			setUrlLogotipo(datos.urlImagenLogotipo);
			const sesionExpirada = localStorage.getItem("sesionExpirada");
			if (sesionExpirada === "si") {
				message.info("Su sesión ha expirado.", 8);
				localStorage.removeItem("sesionExpirada");
			}
		};
		fetchData();
	}, []);


	const onClickBtnIngresar = async () => {

		const url = await LoginService.getUrlActiveDirectory(subdominio);
		window.location.href = url;

		// //dev
		// const devAd = {
		// 	tenantId: "a2419879-fd94-4d6a-a5d6-0b78a75ce72c",
		// 	// clientId: "d4a38384-03a5-402a-9247-1a0de2edece3",
		// 	clientId: "086650af-9d2d-464f-83e3-2806260a3998",
		// 	redirectUri: "http://localhost:3001/ad/login/validation",
		// 	scope: "openid profile offline_access",
		// 	state: "123456"
		// }

		// //prod
		// const prodAd = {
		// 	tenantId: "4338a276-32bd-4426-b924-78edeeef8cc1",
		// 	clientId: "927c4e73-7255-46ff-9cd0-8086311eeefd",
		// 	redirectUri: "https://maccaferri.milegajodigital.com/ad/login/validation",
		// 	scope: "openid profile offline_access",
		// 	state: "123456"
		// }

		// //prod
		// // const tenantId = "4338a276-32bd-4426-b924-78edeeef8cc1";
		// // const clientId = "927c4e73-7255-46ff-9cd0-8086311eeefd";
		// // const redirectUri = isProd
		// // 	? "https://maccaferri.milegajodigital.com/ad/login/validation"
		// // 	: "http://maccaferri-dev.milegajodigital.com:3001/ad/login/validation";
		// // const scope = "openid profile offline_access";
		// // const state = "123456"

		// // // const tenantId = "a2419879-fd94-4d6a-a5d6-0b78a75ce72c";
		// // // const clientId = "d4a38384-03a5-402a-9247-1a0de2edece3";
		// // // const redirectUri = "http://localhost:3001/ad/login/validation";
		// // // const scope = "openid profile offline_access";
		// // // const state = "123456"

		// const isProd = false;
		// const ad = isProd ? prodAd : devAd;

		// window.location.href =
		// 	`https://login.microsoftonline.com/${ad.tenantId}/oauth2/v2.0/authorize`
		// 	+ `?client_id=${ad.clientId}`
		// 	+ `&redirect_uri=${ad.redirectUri}`
		// 	+ `&response_type=code`
		// 	+ `&scope=${ad.scope}`
		// 	+ `&state=${ad.state}`;
	}

	return (
		<div className="container-login">
			<div className="container-login--fondo" style={{ backgroundImage: "url(" + urlFondo + ")" }} />

			<div className="container-login--wrapper">
				<img className="container-login--wrapper__logotipo" src={urlLogotipo} width="180px" alt="Logotipo" />

				<div className="container-login--wrapper__titulo">
					<span>Iniciar Sesión</span>
				</div>

				<div>
					<Button
						style={{ width: "100%" }}
						type="primary"
						size="large"
						onClick={onClickBtnIngresar}
					>
						Ingresar
					</Button>
				</div>
				{/* {autenticacionFallida && (
					<Alert
						style={{
							marginBottom: 24,
						}}
						message={mensajeError}
						type="error"
						showIcon
					/>
				)} */}

				{/* <Form name="frmLogin" className="" onFinish={onClickBtnIniciarSesion}>
					<Form.Item
						name="usuario"
						rules={[
							{
								required: true,
								message: "Por favor, ingresa tu número de documento de identidad.",
							},
						]}
					>
						<Input
							size="large"
							maxLength={32}
							prefix={<UserOutlined className="site-form-item-icon" />}
							placeholder="Número de documento de identidad"
						/>
					</Form.Item>
					<Form.Item
						name="clave"
						rules={[
							{
								required: true,
								message: "Por favor, ingresa tu clave.",
							},
						]}
					>
						<Input.Password
							size="large"
							prefix={<LockOutlined className="site-form-item-icon" />}
							type="password"
							placeholder="Clave"
						/>
					</Form.Item>
					<Form.Item>
						<a href="#/" style={{ float: "right" }} onClick={onClickLinkOlvideClave}>
							¿Olvidaste tu clave?
						</a>
					</Form.Item>

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							loading={loading}
							style={{ width: "100%" }}
						>
							Ingresar
						</Button>
					</Form.Item>
				</Form> */}
			</div>
		</div>
	);
};

export default LoginActiveDirectoryPage;
