import { useEffect, useState } from "react";
import { ApiResponse } from "enum";
import { TiposAusenciaService } from "services/VacacionesService";

const useListarTiposAusencia = () => {
	const [tiposAusencia, setTiposAusencia] = useState([]);
	useEffect(() => {
		const cargarDatos = async () => {
			const tiposAusencia = await TiposAusenciaService.listar();
			if (tiposAusencia.estado === ApiResponse.EXITO) {
				setTiposAusencia(tiposAusencia.data);
			}
		};
		cargarDatos();
	}, []);
	return { tiposAusencia };
};

export default useListarTiposAusencia;
