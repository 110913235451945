import { useEffect } from "react";
import { useAtom } from "jotai";
import { CompetenciasService } from "services";
import {
	competenciasAtom,
	detalleEntrevistaAtom,
	visibleModalCompetenciasLaboralesAtom,
} from "../DetalleEntrevistaStore";
import { EntrevistasService } from "services/reclutamiento-y-seleccion";
import { useParams } from "react-router-dom";

const useListarCompetencias = () => {
	const { entrevistaId } = useParams();
	const [competencias, setCompetencias] = useAtom(competenciasAtom);
	const [detalleEntrevista] = useAtom(detalleEntrevistaAtom);
	const [visibleModalCompetenciasLaborales] = useAtom(visibleModalCompetenciasLaboralesAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			if (competencias.length === 0 && visibleModalCompetenciasLaborales) {
				if (detalleEntrevista?.competenciasLaboralesCompleto) {
					const competencias = await EntrevistasService.obtenerCompetenciasLaborales(entrevistaId);
					setCompetencias(competencias);
				} else {
					const competencias = await CompetenciasService.listar();
					setCompetencias(
						competencias.map((competencia) => {
							return {
								id: competencia.id,
								descripcion: competencia.descripcion,
								respuesta: undefined,
							};
						})
					);
				}
			}
		};
		cargarDatos();
	}, []);

	return { competencias, setCompetencias };
};
export default useListarCompetencias;
