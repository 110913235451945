import { BaseService } from "./BaseService";
export const ConsultaService = {
    async buscarConsultaPorDocumento(detalleEnvioId,estadoConsulta) {
        const respuesta = await BaseService.get(
            "/consultaColaborador/buscarConsultaPorDocumentoYEstado", {
                params: {
                    detalleEnvioId: detalleEnvioId,
                    estadoConsulta:estadoConsulta,
                }
            }           
		);
		return respuesta.data;
    },
    async cambiarEstadoConsulta(consultaId) {
    const respuesta = await BaseService.post(`/consultaColaborador/${consultaId}`);
    return respuesta.data;
  },   
    
};