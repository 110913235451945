import React from "react";
import { ItemDocumentoVisualizado } from "../ItemDocumentoVisualizado";

export function ListaHistorialDocumentos({ loading, documentos, onClick }) {
	function ListaDocumentos() {
		const itemsDocumentos = documentos.map((documento) => (
			<ItemDocumentoVisualizado
				key={documento.envio.id}
				documento={documento}
				onClick={() => onClick(documento)}
			/>
		));
		return itemsDocumentos;
	}

	// function ListaVacia() {
	// 	return "";
	// }

	function Lista() {
		if (documentos != null && documentos.length > 0) {
			return <ListaDocumentos />;
		}
		return "";
	}

	return <Lista />;
}
