import React, { useEffect } from "react";
import { Alert, Button, Input } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import LoginService from "services/LoginService";
import {
	informacionPlataformaAtom,
	numeroDocumentoIdentidadAtom,
	visibleCrearClavePageAtom,
	visibleVerificarIdentidadPageAtom,
} from "../../AuthLoginStore";
import { useState } from "react";
import { AuthLoginService } from "services";

const subdominio = window.location.host.split(".")[0];
const parametros = new URLSearchParams(window.location.search);

const VerificarIdentidadPage = () => {
	const [, setInformacionPlataforma] = useAtom(informacionPlataformaAtom);
	const [visibleVerificarIdentidadPage, setVisibleVerificarIdentidadPage] = useAtom(
		visibleVerificarIdentidadPageAtom
	);
	const [, setVisibleCrearClavePage] = useAtom(visibleCrearClavePageAtom);
	const [loading, setLoading] = useState(false);
	const [documentoIdentidad, setDocumentoIdentidad] = useAtom(numeroDocumentoIdentidadAtom);
	const [error, setError] = useState(undefined);

	useEffect(() => {
		const cargarDatosIniciales = async () => {
			const informacionPlataforma = await LoginService.cargarDatosIniciales(subdominio);
			setInformacionPlataforma(informacionPlataforma);
		};
		cargarDatosIniciales();
	}, []);

	const onClickBtnSiguiente = async () => {
		try {
			setLoading(true);
			await AuthLoginService.verificarIdentidad({
				subdominio: subdominio,
				token: parametros.get("token"),
				documentoIdentidad: documentoIdentidad,
			});
			setLoading(false);
			setVisibleCrearClavePage(true);
			setVisibleVerificarIdentidadPage(false);
		} catch (error) {
			switch (error?.response?.data?.error) {
				case "AUTH-LOGIN-02":
					setError("Documento de identidad incorrecto");
					break;

				default:
					setError("Token inválido");
			}
			setLoading(false);
		}
	};

	const onCloseError = () => {
		setError(undefined);
	};

	if (!visibleVerificarIdentidadPage) {
		return "";
	}

	return (
		<div style={{ padding: "3rem" }}>
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
				<img src="https://storage.googleapis.com/milegajodigital.com/_default/imagenes/verificar-identidad.png" />
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					fontSize: "1.2rem",
					fontWeight: "600",
					paddingTop: "10px",
					textAlign: "center",
				}}
			>
				Verificación de identidad
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					textAlign: "center",
					paddingTop: "10px",
					paddingBottom: "10px",
					color: "rgb(144, 145, 152)",
				}}
			>
				Ingresa el número de tu DNI, Carnet de Extranjería ó Pasaporte
			</div>

			{error && <Alert message={error} type="error" showIcon closable onClose={onCloseError} />}

			<div style={{ paddingTop: "20px", paddingBottom: "5px", fontWeight: "bold" }}>
				Número de documento de identidad
			</div>
			<div>
				<Input size="large" shape="round" onChange={(e) => setDocumentoIdentidad(e.target.value)} />
			</div>
			<div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
				<Button type="primary" size="large" shape="round" block loading={loading} onClick={onClickBtnSiguiente}>
					Siguiente <RightOutlined />
				</Button>
			</div>
		</div>
	);
};

export default VerificarIdentidadPage;
