import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Spin } from "antd";
import { useAtom } from "jotai";
import SignaturePad from "react-signature-pad-wrapper";
import produce from "immer";
import {
	componentSelectedAtom,
	signatureModalVisibleAtom,
	signaturesMetadataAtom,
} from "../../MisDocumentosMovilStorage";

const SignatureModal = () => {
	const [loading, setLoading] = useState(true);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [visible, setVisible] = useAtom(signatureModalVisibleAtom);
	const [signaturesMetadata, setSignaturesMetadata] = useAtom(signaturesMetadataAtom);
	const [componentSelected] = useAtom(componentSelectedAtom);
	const refSignature = useRef();

	useEffect(() => {
		const cargarFirma = () => {
			if (visible) {
				setTimeout(() => {
					setLoading(false);
					if (signaturesMetadata[0]?.base64) {
						refSignature.current.fromDataURL(signaturesMetadata[0]?.base64);
					}
				}, 1000);
			}
		};
		cargarFirma();
	}, [visible]);

	const onClickBtnOk = () => {
		setConfirmLoading(true);
		setTimeout(() => {
			setSignaturesMetadata(
				produce((draft) => {
					const signature = draft.find((s) => s.key === componentSelected.key);
					let base64 = refSignature.current.isEmpty() ? undefined : refSignature.current.toDataURL();
					if (signature) {
						signature.base64 = base64;
					} else {
						draft.push({
							key: componentSelected.key,
							base64: base64,
						});
					}
				})
			);
			setConfirmLoading(false);
			setVisible(false);
		}, 1000);
	};

	const onClickBtnCancelar = () => {
		setVisible(false);
	};

	const onClickBtnLimpiarFirma = () => {
		refSignature.current.clear();
	};

	return (
		<Modal
			title="Firmar"
			open={visible}
			okText="Aceptar"
			cancelText="Cancelar"
			onOk={onClickBtnOk}
			confirmLoading={confirmLoading}
			onCancel={onClickBtnCancelar}
			centered
			bodyStyle={{ textAlign: "center" }}
			maskClosable={false}
		>
			{loading && <Spin />}
			{!loading
				&& (
					<>
						<SignaturePad
							ref={refSignature}
							canvasProps={{ style: { border: "1px dashed #ccc" } }}
							options={{ minWidth: 2, maxWidth: 3, penColor: "#000" }}
						/>
						<Button type="link" onClick={onClickBtnLimpiarFirma}>
							Limpiar Firma
						</Button>
					</>
				)}
		</Modal>
	);
};

export default SignatureModal;
