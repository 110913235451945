import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

export function SpinLoading({ children, loadingPage, styleCustom }) {

	return (
		<Spin style={{...styleCustom}} spinning={loadingPage} tip="Loading..." indicator={<LoadingOutlined style={{ fontSize: 36, }} spin />}>
            {children}
        </Spin>
	);
}
