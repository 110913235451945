import React from "react";
import { Divider, Form, Input, Modal, Select, message } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import {
	contratoSeleccionadoAtom,
	contratosAtom,
	visibleModalRechazarRenovacionAtom,
} from "pages/contratos/renovacion/RenovacionContratosStore";
import { RenovacionContratosService } from "services/contratos";
import LoginService from "services/LoginService";
import produce from "immer";

const ModalRechazarRenovacion = () => {
	const [, setContratos] = useAtom(contratosAtom);
	const [contratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
	const [visible, setVisible] = useAtom(visibleModalRechazarRenovacionAtom);
	const [form] = Form.useForm();

	const onClickBtnRechazar = async () => {
		form.validateFields()
			.then(async (values) => {
				try {
					if (LoginService.isJefeProyecto()) {
						await RenovacionContratosService.rechazarRenovacionContratoPorJefeProyecto({
							seguimientoContratoId: contratoSeleccionado.id,
							...values,
							});
					} else if (LoginService.isAdministradorObra()) {
						await RenovacionContratosService.rechazarRenovacionContratoPorAdministradorObra({
							seguimientoContratoId: contratoSeleccionado.id,
							...values,
							});
					}
					
					setContratos(
						produce((draft) => {
							const index = draft.findIndex((contrato) => contrato.id === contratoSeleccionado.id);
							draft.splice(index, 1);
						})
					);
					message.success("El contrato fue rechazado.");
					setVisible(false);
				} catch (error) {
					message.error("No se pudo realizar la operación requerida. Intente nuevamente!");
				}
			})
			.catch((info) => {
				console.log("Validate Failed:", info);
			});
	};

	const onClickBtnCancelar = () => {
		setVisible(false);
		form.setFieldsValue({
			motivoRechazo: "",
		});
	};

	return (
		<Modal
			title="Rechazar Renovacion Contrato"
			open={visible}
			onOk={onClickBtnRechazar}
			onCancel={onClickBtnCancelar}
			okText="Rechazar"
			destroyOnClose={true}
		>
			<div style={{ textAlign: "center", marginBottom: "10px" }}>
				<QuestionCircleOutlined style={{ fontSize: "40px", color: "#ebc334" }} />
			</div>
			<div style={{ textAlign: "center", fontSize: "18px" }}>
				¿Está seguro de rechazar el contrato seleccionado?
			</div>
			<Divider />
			<div>
				<Form layout="vertical" form={form}>
					<Form.Item label="Motivo de Rechazo" name="motivoRechazo" rules={[{ required: true }]}>
						<Select
							style={{
								width: "120",
							}}
							options={[
								{
									value: "Término de proyecto",
									label: "Término de proyecto",
								},
								{
									value: "Término de contrato",
									label: "Término de contrato",
								},
								{
									value: "Renuncia Voluntaria",
									label: "Renuncia Voluntaria",
								},
								{
									value: "Transferencia",
									label: "Transferencia",
								},
							]}
						/>
					</Form.Item>
					<Form.Item label="Detalle" name="detalleRechazo">
						<Input.TextArea rows={4} />
					</Form.Item>
				</Form>
			</div>
		</Modal>
	);
};

export default ModalRechazarRenovacion;
