import React, { useContext } from "react";
import { Header } from "../common";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { Descriptions, Typography, Row, Col, Image, Upload, message, Button } from "antd";
import { useState } from "react";
import LoginService from "services/LoginService";
import { UploadOutlined } from "@ant-design/icons";
import { Headers } from "enum";
import ImgCrop from "antd-img-crop";
import { SecurityContext } from "context/SecurityContextProvider";
import {
	visibleModalDomicilioAtom,
	visibleModalDatosNacimientoAtom,
	visibleModalContactoEmergenciaAtom,
} from "pages/movil/PerfilMovilStore";
import { useAtom } from "jotai";
import { ModalEditarDomicilio } from "pages/movil/detalle-perfil/EditarDomicilio";
import { ModalEditarDatosNacimiento } from "./EditarDatosNacimiento";
import { ModalEditarcontactoEmergencia } from "./EditarContactoEmergencia";
const DetallePerfilMovilPage = () => {
	const history = useHistory();
	const [informacion, setInformacion] = useState(undefined);

	useEffect(() => {
		const cargarInformacion = async () => {
			const response = await PortalColaboradorService.buscarColaborador();
			//console.log("response", response);
			setInformacion(response);
		};

		cargarInformacion();
	}, []);

	const onClickBtnRetornar = () => {
		history.push("/movil/perfil");
	};

	return (
		<>
			<Header titulo="Mi Perfil" onClickBtnRetornar={onClickBtnRetornar} />
			<Container>
				<InformacionBasica informacion={informacion} setInformacion={setInformacion} />
				<InformacionDomicilio informacion={informacion} />
				<InformacionRegimenPensionario informacion={informacion} />
				<CuentaBancaria informacion={informacion} />
				<CuentaCTS informacion={informacion} />
				<DatosNacimiento informacion={informacion} />
				<ContactoEmergencia informacion={informacion} />
				<ModalEditarDomicilio colaborador={informacion} setColaborador={setInformacion} />
				<ModalEditarDatosNacimiento colaborador={informacion} setColaborador={setInformacion} />
				<ModalEditarcontactoEmergencia colaborador={informacion} setColaborador={setInformacion} />
			</Container>
		</>
	);
};

const InformacionBasica = ({ informacion, setInformacion }) => {
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	const cargarInformacionColaborador = async () => {
		const response = await PortalColaboradorService.buscarColaborador();
		setInformacion(response);
	};
	const propsFirma = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/actualizar-url-firma-holografa",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
		},

		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				cargarInformacionColaborador();
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};
	const propsHuella = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/actualizar-url-huella",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
		},

		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				cargarInformacionColaborador();
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};
	return (
		<div>
			<span style={{ fontSize: "16px", fontWeight: "bold" }}>Información Básica</span>
			<Descriptions
				bordered
				size="small"
				style={{ background: "white", marginTop: "5px" }}
				column={{
					xxl: 4,
					xl: 3,
					lg: 3,
					md: 3,
					sm: 2,
					xs: 1,
				}}
			>
				<Descriptions.Item label="Documento Identidad">{`${informacion?.tipoDocumentoIdentidad} - ${informacion?.numeroDocumentoIdentidad}`}</Descriptions.Item>
				<Descriptions.Item label="Apellido Paterno">{informacion?.apellidoPaterno}</Descriptions.Item>
				<Descriptions.Item label="Apellido Materno">{informacion?.apellidoMaterno}</Descriptions.Item>
				<Descriptions.Item label="Nombres">{informacion?.nombres}</Descriptions.Item>
				<Descriptions.Item label="Género">{informacion?.sexoColaborador}</Descriptions.Item>
				<Descriptions.Item label="Fecha de Nacimiento">{informacion?.fechaNacimiento}</Descriptions.Item>
				<Descriptions.Item label="Estado Civil">{informacion?.estadoCivil}</Descriptions.Item>
				<Descriptions.Item label="Firma Hológrafa">
					<Row gutter={16}>
						<Col>
							{informacion?.urlFirmaFirmado != null ? (
								<Image src={informacion?.urlFirmaFirmado} width={100} />
							) : (
								"Pendiente"
							)}
						</Col>
						{isExplomin && informacion?.estadoImagenFirma !== "APROBADO" && (
							<Col>
								<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
									<Upload {...propsFirma} maxCount={1} accept={["image/*"]}>
										<Button block type="link">
											Cambiar Firma
										</Button>
									</Upload>
								</ImgCrop>
							</Col>
						)}
						{!isExplomin && (
							<Col>
								<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
									<Upload {...propsFirma} maxCount={1} accept={["image/*"]}>
										<Button block type="link">
											Cambiar Firma
										</Button>
									</Upload>
								</ImgCrop>
							</Col>
						)}
					</Row>
				</Descriptions.Item>
				<Descriptions.Item label="Huella Dactilar">
					<Row gutter={16}>
						<Col>
							{informacion?.urlHuellaFirmado != null ? (
								<Image src={informacion?.urlHuellaFirmado} width={100} />
							) : (
								"Pendiente"
							)}
						</Col>
						{isExplomin && informacion?.estadoImagenHuella !== "APROBADO" && (
							<Col>
								<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
									<Upload {...propsHuella} maxCount={1} accept={["image/*"]}>
										<Button block type="link">
											Cambiar Huella
										</Button>
									</Upload>
								</ImgCrop>
							</Col>
						)}
						{!isExplomin && (
							<Col>
								<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
									<Upload {...propsHuella} maxCount={1} accept={["image/*"]}>
										<Button block type="link">
											Cambiar Huella
										</Button>
									</Upload>
								</ImgCrop>
							</Col>
						)}
					</Row>
				</Descriptions.Item>
			</Descriptions>
		</div>
	);
};

const InformacionDomicilio = ({ informacion }) => {
	const [, setVisibleModalDomicilio] = useAtom(visibleModalDomicilioAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isPSP = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20601257280" ? true : false;
	const onClickEditarDomicilio = () => {
		setVisibleModalDomicilio(true);
	};
	return (
		<div style={{ marginTop: "15px" }}>
			<span style={{ fontSize: "16px", fontWeight: "bold" }}>Información Domicilio</span>
			<Descriptions
				bordered
				size="small"
				style={{ background: "white", marginTop: "5px" }}
				column={{
					xxl: 4,
					xl: 3,
					lg: 3,
					md: 3,
					sm: 2,
					xs: 1,
				}}
				extra={
					isPSP && (
						<Button type={"link"} onClick={onClickEditarDomicilio}>
							Actualizar
						</Button>
					)
				}
			>
				<Descriptions.Item label="Dirección">{informacion?.domicilioActual?.direccion}</Descriptions.Item>
				<Descriptions.Item label="Departamento">{informacion?.domicilioActual?.departamento}</Descriptions.Item>
				<Descriptions.Item label="Provincia">{informacion?.domicilioActual?.provincia}</Descriptions.Item>
				<Descriptions.Item label="Distrito">{informacion?.domicilioActual?.distrito}</Descriptions.Item>
			</Descriptions>
		</div>
	);
};
const InformacionRegimenPensionario = ({ informacion }) => {
	return (
		<div style={{ marginTop: "15px" }}>
			<span style={{ fontSize: "16px", fontWeight: "bold" }}>Régimen Pensionario</span>
			<Descriptions
				bordered
				size="small"
				style={{ background: "white", marginTop: "5px" }}
				column={{
					xxl: 4,
					xl: 3,
					lg: 3,
					md: 3,
					sm: 2,
					xs: 1,
				}}
			>
				<Descriptions.Item label="Tipo">{informacion?.regimenPensionario?.tipo}</Descriptions.Item>
				<Descriptions.Item label="Cuspp AFP">{informacion?.regimenPensionario?.cusppAfp}</Descriptions.Item>
				<Descriptions.Item label="Nombre AFP">{informacion?.regimenPensionario?.nombreAfp}</Descriptions.Item>
			</Descriptions>
		</div>
	);
};
const CuentaBancaria = ({ informacion }) => {
	return (
		<div style={{ marginTop: "15px" }}>
			<span style={{ fontSize: "16px", fontWeight: "bold" }}>Cuenta Bancaria</span>
			<Descriptions
				bordered
				size="small"
				style={{ background: "white", marginTop: "5px" }}
				column={{
					xxl: 4,
					xl: 3,
					lg: 3,
					md: 3,
					sm: 2,
					xs: 1,
				}}
			>
				<Descriptions.Item label="Banco">{informacion?.cuentaHaberes?.banco}</Descriptions.Item>
				<Descriptions.Item label="Moneda">{informacion?.cuentaHaberes?.tipoMoneda}</Descriptions.Item>
				<Descriptions.Item label="N° Cuenta">{informacion?.cuentaHaberes?.nroCuenta}</Descriptions.Item>
			</Descriptions>
		</div>
	);
};

const CuentaCTS = ({ informacion }) => {
	return (
		<div style={{ marginTop: "15px" }}>
			<span style={{ fontSize: "16px", fontWeight: "bold" }}>Cuenta CTS</span>
			<Descriptions
				bordered
				size="small"
				style={{ background: "white", marginTop: "5px" }}
				column={{
					xxl: 4,
					xl: 3,
					lg: 3,
					md: 3,
					sm: 2,
					xs: 1,
				}}
			>
				<Descriptions.Item label="Banco">{informacion?.cuentaCTS?.banco}</Descriptions.Item>
				<Descriptions.Item label="Moneda">{informacion?.cuentaCTS?.tipoMoneda}</Descriptions.Item>
				<Descriptions.Item label="N° Cuenta">{informacion?.cuentaCTS?.nroCuenta}</Descriptions.Item>
			</Descriptions>
		</div>
	);
};

const DatosNacimiento = ({ informacion }) => {
	const [, setVisibleModalDatosNacimiento] = useAtom(visibleModalDatosNacimientoAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isPSP = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20601257280" ? true : false;
	const onClickEditarDatosNacimiento = () => {
		setVisibleModalDatosNacimiento(true);
	};
	return (
		<div style={{ marginTop: "15px" }}>
			<span style={{ fontSize: "16px", fontWeight: "bold" }}>Datos de Nacimiento</span>
			<Descriptions
				bordered
				size="small"
				style={{ background: "white", marginTop: "5px" }}
				column={{
					xxl: 4,
					xl: 3,
					lg: 3,
					md: 3,
					sm: 2,
					xs: 1,
				}}
				extra={
					isPSP && (
						<Button type={"link"} onClick={onClickEditarDatosNacimiento}>
							Actualizar
						</Button>
					)
				}
			>
				<Descriptions.Item label="País">{informacion?.lugarNacimiento?.pais}</Descriptions.Item>
				<Descriptions.Item label="Nacionalidad">{informacion?.lugarNacimiento?.nacionalidad}</Descriptions.Item>
				<Descriptions.Item label="Fecha">{informacion?.fechaNacimiento}</Descriptions.Item>
				<Descriptions.Item label="Departamento">{informacion?.lugarNacimiento?.departamento}</Descriptions.Item>
				<Descriptions.Item label="Provincia">{informacion?.lugarNacimiento?.provincia}</Descriptions.Item>
				<Descriptions.Item label="Distrito">{informacion?.lugarNacimiento?.distrito}</Descriptions.Item>
				<Descriptions.Item label="Dirección">{informacion?.lugarNacimiento?.direccion}</Descriptions.Item>
			</Descriptions>
		</div>
	);
};

const ContactoEmergencia = ({ informacion }) => {
	const [, setVisibleModalContactoEmergencia] = useAtom(visibleModalContactoEmergenciaAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isPSP = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20601257280" ? true : false;
	const onClickEditarContactoEmergencia = () => {
		setVisibleModalContactoEmergencia(true);
	};
	return (
		<div style={{ marginTop: "15px" }}>
			<span style={{ fontSize: "16px", fontWeight: "bold" }}>Contacto Emergencia</span>
			<Descriptions
				bordered
				size="small"
				style={{ background: "white", marginTop: "5px" }}
				column={{
					xxl: 4,
					xl: 3,
					lg: 3,
					md: 3,
					sm: 2,
					xs: 1,
				}}
				extra={
					isPSP && (
						<Button type={"link"} onClick={onClickEditarContactoEmergencia}>
							Actualizar
						</Button>
					)
				}
			>
				<Descriptions.Item label="Nombre">{informacion?.contactoEmergencia?.nombres}</Descriptions.Item>
				<Descriptions.Item label="Teléfono">{informacion?.contactoEmergencia?.telefono}</Descriptions.Item>
			</Descriptions>
		</div>
	);
};

const Container = styled.div`
	height: calc(100% - 60px);
	padding: 10px 10px;
	overflow: auto;
`;

export default DetallePerfilMovilPage;
