import React,{useContext} from "react"
import { Button, Col, Input, Modal, Row, Space, Form, Typography, notification } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { useState } from "react"
import { SecurityContext } from "context/SecurityContextProvider";
import { TipoConsulta } from "enum/TipoConsulta";
import { useAtom } from "jotai";
import * as atoms from "../BandejaConsultaStorage";

const ModalConsultar = ({visibleModalConsultar, setVisibleConsultar}) => {
    const { Text } = Typography;
    const { TextArea } = Input;
    const [loading, setLoading] = useState(false);
    const [, setConsultas] = useAtom(atoms.consultasAtom);
    const [numeroRespuestas, setNumeroRespuestas] = useAtom(atoms.numeroRespuestasAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);

    async function handleOnclickAceptarConsultar(formulario) {
        try {           
            formulario.tipoConsulta = TipoConsulta.GENERAL;
			setLoading(true);
			let data = await PortalColaboradorService.consultarRRHH(formulario);
			notification.success({
				message: "Operación exitosa",
				description: "Se envío su consulta correctamente.",
			});

			setLoading(false);
            onClickCerrarModal();
            let _consultas= await PortalColaboradorService.listaConsultas(getUsuarioAutenticado().empresaSeleccionada.id,getUsuarioAutenticado().numeroDocumentoIdentidad)
            setConsultas(_consultas);
            let listaConsultasId = [];
            if (_consultas.length > 0) {
                for (let i = 0; i < _consultas.length; i++) {
                    listaConsultasId.push(_consultas[i].id);                   
                }
            }                    
            const _numeroRespuestas = await PortalColaboradorService.listaNumeroRespuestas(listaConsultasId,"respuestaUsuario");           
            setNumeroRespuestas(_numeroRespuestas);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
    }
    function onClickCerrarModal() {
        setVisibleConsultar(false);
    }
    return (
        <Modal
            title={
            <Space>
                <ExclamationCircleOutlined
                    style={{ color: "#1678c1" }}
                />
                <Text strong>Consultar</Text>
            </Space>
        }
        visible={visibleModalConsultar}
        onCancel={onClickCerrarModal}
        footer={null}
        destroyOnClose={true}
        >
            <Form
            layout="vertical"
            onFinish={handleOnclickAceptarConsultar}
            > 
                <Row>
                    <Col span={24}> 
                        <Form.Item
                            label="Asunto"
                            name="asunto"
                            rules={[
                                {
                                    required: true,
                                    message: "Porfavor ingrese asunto ", 
                                }
                            ]}
                        >
                        <Input/>
                        </Form.Item>
                    </Col>
                </Row>     
            <Row justify="center">
                 <Col span={24}>
                <Form.Item
                    label="Detalle"
                    name="consulta"
                    rules={[
                        {
                            required: true,
                            message: "Porfavor ingrese mensaje",
                        },
                    ]}
                >
                   
                        <TextArea  size="large" rows={5} />
                        
                </Form.Item>
                </Col>
            </Row>
            <Row justify="center">
                <Space>
                    <Col>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Aceptar
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item>
                            <Button
                                onClick={onClickCerrarModal}
                            >
                                {" "}
                                Cancelar
                            </Button>
                        </Form.Item>
                    </Col>
                </Space>
            </Row>
        </Form>
        </Modal>
    )
}
export default ModalConsultar;