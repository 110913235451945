import React, { useContext, useEffect, useState } from 'react';
import { SecurityContext } from 'context/SecurityContextProvider';
import { VersionApiColaborador } from 'enum/VersionApiColaborador';
import { useHistory } from "react-router";
import LoginService from 'services/LoginService';
import { Loading3QuartersOutlined } from "@ant-design/icons"
import { Alert, Button, Space, Typography } from 'antd';

const url = new URL(window.location.href);

const code = url.searchParams.get('code');
let subdominio = window.location.host.split(".")[0] === "localhost:3001" ? "maccaferri-dev" : window.location.host.split(".")[0];

const parametros = window.location.search;
const buscadorParametros = new URLSearchParams(parametros);
const sesionUrl = buscadorParametros.get("sesionUrl");
const sesionTab = buscadorParametros.get("sesionTab");

const LoginActiveDirectoryValidationPage = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [mensaje, setMensaje] = useState("No se ha podido verificar su identidad. Intentelo nuevamente.")
    const { getVersionApiColaborador, getUsuarioAutenticado, setSesionUsuario } = useContext(SecurityContext);

    useEffect(() => {
        const autenticar = async () => {

            try {
                const response = await LoginService.autenticarActiveDirectory({ subdominio: subdominio, code: code });
                setSesionUsuario(response);
                if (sesionUrl) {
                    const urlParametros = sesionTab ? "?sesionTab=" + sesionTab : "";
                    history.push(sesionUrl + urlParametros);
                } else if (getVersionApiColaborador() === VersionApiColaborador.v1) {
                    history.push("/bandejaEntrada");
                } else if (
                    getVersionApiColaborador() === VersionApiColaborador.v2
                    && getUsuarioAutenticado().empresaSeleccionada.colaborador.estado !== "ONBOARDING") {
                    history.push("/mis-documentos");
                } else if (
                    getVersionApiColaborador() === VersionApiColaborador.v3
                    && getUsuarioAutenticado().empresaSeleccionada.colaborador.estado !== "ONBOARDING") {
                    history.push("/v3/mis-pendientes");
                } else if (getVersionApiColaborador() === VersionApiColaborador.v2
                    && getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ONBOARDING") {
                    history.push("/onboarding");
                } else if (getVersionApiColaborador() === VersionApiColaborador.v3
                    && getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ONBOARDING") {
                    history.push("/onboarding");
                } else {
                    history.push("/bandejaEntrada");
                }
            } catch (error) {
                if (error.response && error.response?.data?.error === "AUTH-LOGIN-20") {
                    setMensaje(error.response.data.mensaje);
                }
                setLoading(false);
            }

        }
        autenticar();
    }, [])

    const onClickBtnIngresar = async () => {
        setLoading(true);
        const url = await LoginService.getUrlActiveDirectory(subdominio);
        window.location.href = url;
    }

    return (
        <div style={{ height: "100%", background: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {loading &&
                <Space>
                    <Loading3QuartersOutlined spin style={{ fontSize: "18px", color: "#0077b6" }} />
                    <span style={{ fontSize: "18px", color: "#4a4e69" }}>Verificando identidad, espere un momento...</span>
                </Space>
            }

            {!loading &&
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {/* <span style={{ marginBottom: "10px", fontSize: "14px", color: "" }}>
                        El código de verificación ha expirado. Favor de autenticarse nuevamente
                    </span> */}
                    <Alert
                        message={mensaje}
                        type="warning"
                        showIcon
                    />

                    <Button type='primary' onClick={onClickBtnIngresar} style={{ marginTop: "15px" }} loading={loading}>
                        Iniciar Sesión
                    </Button>



                </div>

            }
        </div>
    );
};

export default LoginActiveDirectoryValidationPage;