import React, { useEffect, useState } from "react";
import { CalendarOutlined, FileOutlined,FileSearchOutlined, FileSyncOutlined,UserAddOutlined,EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button, Space,Dropdown,Menu,Row,Col } from "antd";
import { EstadoSolicituPersonal } from "enum/EstadoSolicitudPersonal";
import { solicitudPersonalIdSeleccionadoAtom,visibleModalCancelarSolicitudAtom } from "../../../common/store/MisSolicitudesPersonalStore";
import { useAtom } from "jotai";

export const ItemDocumento = ({ solicitud }) => {
	const [classNameEstado, setClassNameEstado] = useState(" loading");
	const [, setVisibleModalCancelarSolicitud] = useAtom(visibleModalCancelarSolicitudAtom);
	const [, setSolicitudPersonalIdSeleccionado] = useAtom(solicitudPersonalIdSeleccionadoAtom);
	
	useEffect(() => {
		if (solicitud?.estado === EstadoSolicituPersonal.RECHAZADO) {
			setClassNameEstado(" rechazado");
		} else if (solicitud?.estado === EstadoSolicituPersonal.PENDIENTE) {
			setClassNameEstado(" pendiente");
		} else if (solicitud?.estado === EstadoSolicituPersonal.CERRADO) {
			setClassNameEstado(" cerrado");	
		} else if (solicitud?.estado === EstadoSolicituPersonal.CANCELADO) {
			setClassNameEstado(" cancelado");		
		} else {
			setClassNameEstado(" visualizado");
		}
	}, [solicitud]);

	
	const onClickMenu = (opcion) => {
		
		switch (opcion.key) {
			case "cancelar-solicitud":
				setVisibleModalCancelarSolicitud(true);
			    setSolicitudPersonalIdSeleccionado(solicitud.id);
				break;

			default:
				console.error("No se encuentra esa opción");
		}
	}

	const menu = () => {
		return (
			<Menu onClick={onClickMenu} >
				<Menu.Item key="cancelar-solicitud">
					Cancelar Solicitud
				</Menu.Item>
			</Menu>
		);
	};

	return (
		<div className={"bandeja-entrada__item-documento" + classNameEstado}> 
			<div className={"imagen" + classNameEstado}>
				{solicitud?.estado === "PENDIENTE" && <FileSyncOutlined />}
				{solicitud?.estado !== "PENDIENTE" && <FileSearchOutlined />}
			</div>
			<div className="informacion">
				<span className="item-documento__descripcion">
					<Space>
						<CalendarOutlined />
						<b>                            
                            {solicitud?.fechaInicioLabores}
						</b>
						
					</Space>
				</span>
				<span className="item-documento__tipo">
					<Space>
						<FileOutlined />
						{solicitud?.perfilPuesto?.descripcion}
					</Space>
				</span>
				<span className="item-documento__tipo">
					<Space>
						<UserAddOutlined />
						{solicitud?.cantidad}
					</Space>
				</span>

				<span className={"item-documento__estado" + classNameEstado}>
					
					<Row >
						<Col span={12} >
							{solicitud?.estado}
						</Col>
						<Col span={11} >
							{solicitud?.estado === EstadoSolicituPersonal.INICIADO &&
							<div style={{textAlign:"end"}}>                  
								<Dropdown overlay={menu}>
											<a onClick={(e) => e.preventDefault()}>
												<EllipsisOutlined style={{ fontSize: '23px' }}/>
											</a>
								</Dropdown>		
							</div> 
							}
						</Col>
					</Row>
					
										
				</span>
				
			</div>
		</div>
	);
};
