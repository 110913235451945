import React, { useContext, useEffect } from "react";
import { Button, Card, Modal, Row, Alert, Col, Checkbox } from "antd";
//import { cssVisorPdf, cssBodyVisorPdf } from "./estilos"
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState } from "react";
import { SeleccionarTipoCarga } from "./SeleccionarTipoCarga";
import { CargarImagen } from "./CargarImagen";
import { CargarPDF } from "./CargarPDF";
import SubirWord from "./SubirWord/SubirWord";
import { Header } from "./Header";
import { documentoSeleccionadoAtom } from "../MisDocumentosMovilStorage";
import { useAtom } from "jotai";
import { ModalValidarDeclaracion } from "./ModalValidarDeclaracion";

export function CargarDocumentosPageV3() {

    const [ documentoSeleccionado,_ ] = useAtom(documentoSeleccionadoAtom)
    const [isWord, setIsWord] = useState(undefined)
    const [seleccionado, setSeleccionado] = useState(false)
    const [isImage, setIsImage] = useState(undefined)
    const [habilitarCarga, setHabilitarCarga] = useState(true);
    const [mostrarBtnDeclaracion, setMostrarBtnDeclaracion] = useState(false);
    const [visibleModalDeclaracion, setVisibleModalDeclaracion] = useState(false);
    const [mostrar, setMostrar] = useState(false);

    useEffect(() => {
        console.log(documentoSeleccionado)
        if (documentoSeleccionado?.mostrarlink) {
			setHabilitarCarga(false);
		}else {
			setHabilitarCarga(true);
        }
        setMostrarBtnDeclaracion(false);
        if (documentoSeleccionado?.mostrarDeclaracion) {
			setMostrar(true);
		} else {
			setMostrar(false);
        }
    }, [documentoSeleccionado])
    
    const onRetornar = () => {
        setSeleccionado(false);
        setIsImage(undefined);
        setIsWord(undefined);
    }

    const onClickBtnLink = () => {
		setHabilitarCarga(true);
    };
    
    const onChangeDeclaracion = (e) => {
        setMostrarBtnDeclaracion(e.target.checked); 
    }

    const onAceptarModal = async () => {
        setVisibleModalDeclaracion(true);
    };
    
    return (
        <React.Fragment>
                <Header seleccionado={seleccionado} onRetornar={onRetornar} />
                {!habilitarCarga && documentoSeleccionado?.mostrarlink && (
                    <Card extra={<Button
                                    onClick={onClickBtnLink}
                                    type="link"
                                    href={documentoSeleccionado?.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                   Ver Tutorial
                                </Button>}>
                        <Alert message='Para poder cargar sus documentos debe de ver el Tutorial' type="warning" showIcon />
                    </Card>
                    )
                }
                {habilitarCarga && !seleccionado && <SeleccionarTipoCarga setSeleccionado={setSeleccionado} setIsImage={setIsImage} documentoSeleccionado={documentoSeleccionado} setIsWord={setIsWord} setMostrar={setMostrar}/>}
                {seleccionado && isImage !== undefined && isImage && <CargarImagen />}
            {seleccionado && isImage !== undefined && !isImage && (isWord == undefined || !isWord) && <CargarPDF />}
            {seleccionado && isImage!== undefined && !isImage && isWord !== undefined && isWord && <SubirWord />}
            { mostrar &&(
                <div style={{padding: "0px 20px"}}>
                    <Checkbox onChange={onChangeDeclaracion} >
                        <strong>
                            <em>
                                {documentoSeleccionado?.declaracion}
                            </em>
                        </strong>
                    </Checkbox>
                    
                </div>
            )}
            {mostrarBtnDeclaracion && (
                <div style={{ padding: "15px", display: "flex", justifyContent: "center" }}>
                    <Button key="aceptar" type="primary" onClick={onAceptarModal}>
                    Aceptar
                    </Button>
                </div> 
                )  
            }
            <ModalValidarDeclaracion visibleModalDeclaracion={visibleModalDeclaracion} setVisibleModalDeclaracion={ setVisibleModalDeclaracion} /> 
        </React.Fragment >
    )
}