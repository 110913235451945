import React from "react";
import { Descriptions, Form, Input, Select } from "antd";
import { useRenovacion } from "../../hooks";

const Renovacion = () => {
	const { frmRenovacion, mostrarMesesRenovacion, onRenovarContratoChange } = useRenovacion();

	return (
		<Form form={frmRenovacion} style={{ marginBottom: "20px" }}>
			<div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px" }}>RENOVACIÓN</div>
			<Descriptions bordered size="small" column={1} labelStyle={{ width: "60%" }}>
				<Descriptions.Item label="¿Renovar Contrato?">
					<Form.Item name="renovarContrato" initialValue={true} style={{ marginBottom: "0px" }}>
						<Select onChange={onRenovarContratoChange}>
							<Select.Option value={true}>SI</Select.Option>
							<Select.Option value={false}>NO</Select.Option>
						</Select>
					</Form.Item>
				</Descriptions.Item>
				{mostrarMesesRenovacion && (
					<Descriptions.Item label="¿Cuantos meses se renueva?">
						<Form.Item
							name="mesesRenovacion"
							initialValue="3"
							style={{ marginBottom: "0px" }}
							rules={[
								{
									required: true,
									message: "Campo obligatorio",
								},
							]}
						>
							<Input />
						</Form.Item>
					</Descriptions.Item>
				)}
			</Descriptions>
		</Form>
	);
};

export default Renovacion;
