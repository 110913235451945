import React from "react";
import { useModalVisorPdf } from "store/global";
import { Button, Popconfirm } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export const Botones = ({ botonesExtra }) => {
	const loading = useModalVisorPdf((state) => state.loading);

	const visibleBtnImprimir = useModalVisorPdf((state) => state.visibleBtnImprimir);
	const visibleBtnDescargar = useModalVisorPdf((state) => state.visibleBtnDescargar);
	const imprimirPdf = useModalVisorPdf((state) => state.imprimirPdf);
	const descargarPdf = useModalVisorPdf((state) => state.descargarPdf);
	const cerrarVisorPdf = useModalVisorPdf((state) => state.cerrarModal);

	const BotonImprimir = () => {
		if (visibleBtnImprimir) {
			return (
				<Button className="milegajo-visor-pdf-header-botones" disabled={loading} onClick={imprimirPdf}>
					Imprimir
				</Button>
			);
		}
		return null;
	};

	const BotonDescargar = () => {
		if (visibleBtnDescargar) {
			return (
				<Button className="milegajo-visor-pdf-header-botones" disabled={loading} onClick={descargarPdf}>
					Descargar
				</Button>
			);
		}
		return null;
	};

	const BotonCerrar = () => {
		return (
			<Button type="text" className="cerrar" onClick={cerrarVisorPdf}>
				<CloseOutlined style={{ fontSize: "16px" }} />
			</Button>
		);
	};

	const Separador = () => {
		return <div className="milegajo-visor-pdf-header-separador" />;
	};

	return (
		<div className="acciones">
			{botonesExtra}

			<BotonImprimir />

			<BotonDescargar />

			<Separador />

			<BotonCerrar />
		</div>
	);
};
