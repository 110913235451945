import React, { useEffect, useState } from "react";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import { useAtom, useSetAtom } from "jotai";
import {
	documentoSeleccionadoAtom,
	rutaSubirDocumentoAtom,
	loadingPdfAtom,
} from "pages/onboarding/OnboardingStore";

const ItemDocumento = ({ documento }) => {
	const [classNameEstado, setClassNameEstado] = useState(" loading");
	const setRutaSubirDocumentos = useSetAtom(rutaSubirDocumentoAtom);
	const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(
		documentoSeleccionadoAtom
	);
	// const [documentoSeleccionadoId, setDocumentoSeleccionadoId] = useAtom(documentoSeleccionadoIdAtom);
	const [, setLoadingPdf] = useAtom(loadingPdfAtom);

	const onClickSeleccionarItem = () => {
		if (documento?.id !== documentoSeleccionado?.id) {
			setDocumentoSeleccionado(documento);
			setRutaSubirDocumentos("home");
			setLoadingPdf(true);
		}
	};

	useEffect(() => {
		if (
			documento.estadoVistaColaborador ===
			EstadoVistaColaborador.PENDIENTE
		) {
			setClassNameEstado(" pendiente");
		} else if (
			documento.estadoAprobacion === EstadoVistaColaborador.RECHAZADO
		) {
			setClassNameEstado(" rechazado");
		} else {
			setClassNameEstado(" visualizado");
		}
	}, [documento]);

	return (
		<div
			className={"bandeja-entrada__item-documento" + classNameEstado}
			style={{
				cursor: "pointer",
				background:
					documentoSeleccionado &&
					documentoSeleccionado.id === documento.id
						? "#ebf2fa"
						: "white",
			}}
			onClick={onClickSeleccionarItem}
		>
			<div className="informacion" style={{ padding: "0px 20px" }}>
				<span className="item-documento__descripcion">
					<b>{documento.periodo}</b>
				</span>
				<span className="item-documento__tipo">
					{documento.nombreDocumento}
				</span>
				<span className={"item-documento__estado" + classNameEstado}>
					<EstadoDocumento
						estado={documento.estadoVistaColaborador}
						estadoAprobacion={documento.estadoAprobacion}
					/>
				</span>
			</div>
		</div>
	);
};

const EstadoDocumento = ({ estado, estadoAprobacion }) => {
	if (estadoAprobacion != null && estadoAprobacion === "RECHAZADO") {
		return "RECHAZADO";
	} else {
		switch (estado) {
			case EstadoVistaColaborador.PENDIENTE:
				return "PENDIENTE";

			case EstadoVistaColaborador.VISUALIZADO:
				return "VISUALIZADO";

			case EstadoVistaColaborador.FIRMADO:
				return "FIRMADO";

			default:
				return "-";
		}
	}
};

export default ItemDocumento;
