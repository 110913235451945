import React from "react";
import { Radio, Modal, Button, Space } from "antd";
import { LeftOutlined, RightOutlined, CheckOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { visibleModalCompetenciasLaboralesAtom } from "pages/detalle-entrevista/DetalleEntrevistaStore";
import { useCompetenciasLaborales } from "pages/detalle-entrevista/hooks";
import styled from "@emotion/styled";
import { useEffect } from "react";

const style = {
	modal: {
		overflowY: "scroll",
		minHeight: "calc(100vh - 280px)",
		maxHeight: "calc(100vh - 280px)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	radioGroup: {
		width: "100%",
	},
	radio: {
		border: "1px solid #ddd",
		borderRadius: "10px",
		width: "100%",
		padding: "10px 8px",
	},
	botonFinalizar: {
		backgroundColor: "rgb(135, 208, 104)",
		borderColor: "transparent",
	},
};

const ContainerTitulo = styled.div`
	text-align: center;
	color: #0077b6;
	font-size: 18px;
	font-weight: bold;
	margin: 20px 0px;
	margin-top: 5px;
`;

const ContainerSubtitulo = styled.div`
	font-size: 16px;
	font-weight: bold;
	color: #bc6c25;
`;

const BotonesFooter = () => {
	const {
		onClickBtnFinalizar,
		onClickBtnSiguiente,
		onClickBtnAnterior,
		deshabilitarBotonAtras,
		mostrarBotonSiguiente,
		mostrarBotonFinalizar,
	} = useCompetenciasLaborales();

	return (
		<div style={{ display: "flex", justifyContent: "space-between" }}>
			<Button onClick={onClickBtnAnterior} disabled={deshabilitarBotonAtras}>
				<LeftOutlined />
				Atrás
			</Button>

			{mostrarBotonSiguiente && (
				<Button type="primary" onClick={onClickBtnSiguiente}>
					Siguiente <RightOutlined />
				</Button>
			)}

			{mostrarBotonFinalizar && (
				<Button type="primary" style={style.botonFinalizar} onClick={onClickBtnFinalizar}>
					<CheckOutlined />
					Finalizar
				</Button>
			)}
		</div>
	);
};

const ModalCompetenciasLaborales = () => {
	const [visible, setVisible] = useAtom(visibleModalCompetenciasLaboralesAtom);

	const { asignarRespuestaCompetencia, getCompetenciaActual, totalCompetencias } = useCompetenciasLaborales();

	const opciones = [
		{ descripcion: "Alto", valor: 3 },
		{ descripcion: "Suficiente", valor: 2 },
		{ descripcion: "Regular", valor: 1 },
		{ descripcion: "No Aplica", valor: 0 },
	];

	return (
		<Modal
			title="Competencias Laborales"
			bodyStyle={style.modal}
			centered={true}
			maskClosable={false}
			visible={visible}
			footer={<BotonesFooter />}
			onCancel={() => setVisible(false)}
		>
			<ContainerSubtitulo>
				<span>{getCompetenciaActual().posicion}</span> / {totalCompetencias}
			</ContainerSubtitulo>

			<ContainerTitulo>{getCompetenciaActual().descripcion}</ContainerTitulo>

			<Radio.Group
				style={{ width: "100%" }}
				onChange={(e) => asignarRespuestaCompetencia(e.target.value)}
				value={getCompetenciaActual().respuesta}
			>
				<Space direction="vertical" style={{ width: "100%" }}>
					{opciones.map((opcion) => {
						return (
							<Radio key={opcion.valor} style={style.radio} value={opcion.valor}>
								{opcion.descripcion}
							</Radio>
						);
					})}
				</Space>
			</Radio.Group>
		</Modal>
	);
};

export default ModalCompetenciasLaborales;
