import React from "react";
import { Radio, Modal, Button, Space, Input } from "antd";
import { LeftOutlined, RightOutlined, CheckOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { visibleModalPreguntasAtom } from "pages/detalle-entrevista/DetalleEntrevistaStore";
import styled from "@emotion/styled";
import { usePreguntas } from "pages/detalle-entrevista/hooks";
import { useState } from "react";

const style = {
	modal: {
		overflowY: "scroll",
		minHeight: "calc(100vh - 200px)",
		maxHeight: "calc(100vh - 200px)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	radioGroup: {
		width: "100%",
	},
	radio: {
		border: "1px solid #ddd",
		borderRadius: "10px",
		width: "100%",
		padding: "10px 8px",
	},
	botonFinalizar: {
		backgroundColor: "rgb(135, 208, 104)",
		borderColor: "transparent",
	},
};

const ContainerPregunta = styled.div`
	text-align: center;
	// color: #0077b6;
	font-size: 14px;
	// font-weight: bold;
	margin: 20px 0px;
	margin-top: 5px;
`;

const ContainerNumeroPregunta = styled.div`
	font-size: 14px;
	font-weight: bold;
	color: #bc6c25;
`;

const BotonesFooter = ({comentario}) => {
	const {
		onClickBtnFinalizar,
		onClickBtnSiguiente,
		onClickBtnAnterior,
		deshabilitarBotonAtras,
		mostrarBotonSiguiente,
		mostrarBotonFinalizar,
	} = usePreguntas();

	return (
		<div style={{ display: "flex", justifyContent: "space-between" }}>
			<Button onClick={onClickBtnAnterior} disabled={deshabilitarBotonAtras}>
				<LeftOutlined />
				Atrás
			</Button>

			{mostrarBotonSiguiente && (
				<Button type="primary" onClick={onClickBtnSiguiente}>
					Siguiente <RightOutlined />
				</Button>
			)}

			{mostrarBotonFinalizar && (
				<Button type="primary" style={style.botonFinalizar} onClick={()=>onClickBtnFinalizar(comentario)}>
					<CheckOutlined />
					Finalizar
				</Button>
			)}
		</div>
	);
};

const ModalPreguntas = () => {
	const { TextArea } = Input;
	const [visible, setVisible] = useAtom(visibleModalPreguntasAtom);
	const [comentario, setComentario] = useState("");

	const { asignarRespuestaPregunta, getPreguntaActual, totalPreguntas, mostrarBotonFinalizar } = usePreguntas();

	const opciones = [
		{ descripcion: "Excelente", valor: 5 },
		{ descripcion: "Muy Bueno", valor: 4 },
		{ descripcion: "Bueno", valor: 3 },
		{ descripcion: "Regular", valor: 2 },
		{ descripcion: "Malo", valor: 1 },
	];

	return (
		<Modal
			title="Preguntas"
			bodyStyle={style.modal}
			centered={true}
			maskClosable={false}
			visible={visible}
			footer={<BotonesFooter comentario={comentario} />}
			onCancel={() => setVisible(false)}
		>
			<ContainerNumeroPregunta>
				<span>{getPreguntaActual().posicion}</span> / {totalPreguntas+1}
			</ContainerNumeroPregunta>

			<ContainerPregunta>{mostrarBotonFinalizar?"Escriba un comentario":"¿"+getPreguntaActual().descripcion+"?"}</ContainerPregunta>

			{!mostrarBotonFinalizar && <Radio.Group
				style={{ width: "100%" }}
				onChange={(e) => asignarRespuestaPregunta(e.target.value)}
				value={getPreguntaActual().respuesta}
			>
				<Space direction="vertical" style={{ width: "100%" }}>
					{opciones.map((opcion) => {
						return (
							<Radio key={opcion.valor} style={style.radio} value={opcion.valor}>
								{opcion.descripcion}
							</Radio>
						);
					})}
				</Space>
			</Radio.Group>}

			{mostrarBotonFinalizar && <TextArea
				value={comentario}
				onChange={(e) => setComentario(e.target.value)}
				placeholder="Ingrese un comentario"
				autoSize={{
					minRows: 4,
					maxRows: 5,
				}}
			/>}

		</Modal>
	);
};

export default ModalPreguntas;
