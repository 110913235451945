import React from "react";

const EnvioDocumentosPage = () => {
	return (
		<div>
			<div>Envío Documentos v4.0</div>
			<div>Probando actualización automatica....</div>
			<div>no-cache</div>
		</div>
	);
};

export default EnvioDocumentosPage;
