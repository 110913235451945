import React, { useContext, useEffect } from "react";
import { Table, Button } from "antd";
import { useAtom } from "jotai";
import * as atoms from "../BandejaEvaluacionStorage";
import EvaluacionService from "services/EvaluacionService";
import { SecurityContext } from "context/SecurityContextProvider";
import { EstadoAprobacionEvaluacion } from "enum/EstadoAprobacionEvaluacion";
import { useModalVisorPdf } from "store/global";

const TablaHistoricos = () => {
	const [evaluacionesHistoricos, setEvaluacionesHistoricos] = useAtom(atoms.evaluacionesHistoricosAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
	useEffect(() => {
		const cargarDatos = async () => {
			const evaluaciones = await EvaluacionService.listarPorJefeInmediato(
				getUsuarioAutenticado().empresaSeleccionada.id,
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				EstadoAprobacionEvaluacion.APROBADO
			);
			setEvaluacionesHistoricos(evaluaciones);
		};
		cargarDatos();
	}, []);

	const onClickVerDocumento = async (documento) => {
		const respuesta = await EvaluacionService.obtenerUrlDocumento(documento.id);
		//console.log("Respuesta", respuesta);
		mostrarModalVisorPdf(respuesta.url, documento.tipoDocumentoNombre, true, true);
	};

	const columnasEvaluacionesHistoricos = [
		{
			title: "Periodo",
			dataIndex: "periodo",
			align: "center",
		},
		{
			title: "Numero Documento Identidad",
			dataIndex: ["colaborador", "numeroDocumentoIdentidad"],
			align: "center",
		},
		{
			title: "Nombre Colaborador",
			dataIndex: ["colaborador", "nombreCompleto"],
			align: "center",
		},
		{
			title: "Puntaje Final",
			//dataIndex: "resultadoFinal",
			align: "center",
			render: (fila) => fila.resultado.puntajeTotal + "%",
		},
		{
			title: "Estado",
			//dataIndex: "estado",
			align: "center",
			render: (fila) =>
				fila.estado === EstadoAprobacionEvaluacion.PENDIENTE_APROBACION_GH
					? "PENDIENTE APROBACIÓN"
					: fila.estado,
		},
		{
			title: "Acciones",
			key: "acciones",
			align: "center",
			render: (fila) =>
				fila.estado === EstadoAprobacionEvaluacion.APROBADO ? (
					<Button type="link" onClick={() => onClickVerDocumento(fila)}>
						Ver Documento
					</Button>
				) : (
					"-"
				),
		},
	];
	return (
		<Table
			rowKey={(evaluacion) => evaluacion.id}
			dataSource={evaluacionesHistoricos}
			columns={columnasEvaluacionesHistoricos}
		/>
	);
};
export default TablaHistoricos;
