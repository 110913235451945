import React, { useState, useEffect, useContext, useRef } from "react";
import { SecurityContext } from "../../context/SecurityContextProvider";
import { useMediaQuery } from "react-responsive";
import {
	// Tabs,
	Alert,
	Table,
	Space,
	notification,
	Button,
	Input,
	Tag,
	Card,
	Row,
	Col,
	Typography,
	Modal,
	Form,
	Comment,
	List,
} from "antd";
import {
	SearchOutlined,
	UploadOutlined,
	EyeOutlined,
	EyeInvisibleOutlined,
	ToTopOutlined,
	ExclamationCircleOutlined,
	EditOutlined,
	UserOutlined,
} from "@ant-design/icons";
import LegajoService from "../../services/LegajoService";
import PersonaService from "../../services/PersonaService";
import { ModalPDF } from "../../components/PDFViewer";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router-dom";

// import { Tabs } from "../../components/Tabs";
// import { Tab } from "../../components/Tabs/components/Tab";
// import { ListaDocumentosPendientes } from "./componentes/ListaDocumentosPendientes";
// import { ListaHistorialDocumentos } from "./componentes/ListaHistorialDocumentos";

import { MovilBandejaEntrada } from "./componentes/MovilBandejaEntrada";
import { EstadoDocumento } from "../../enum/EstadoDocumento";
import { PortalColaboradorService } from "../../services/PortalColaboradorService";
import { TipoConsulta } from "enum/TipoConsulta";
import { ConsultaService } from "../../services/ConsultaService";
import { EstadoConsulta } from "enum/EstadoConsulta";
import { DetalleConsultaService } from "../../services/DetalleConsultaService";
import * as atomsConsulta from "../hooks/consultas/ConsultasStorage";
import { useAtom } from "jotai";
// const { TabPane } = Tabs;

export function BandejaEntradaPage() {
	// const [comunicaciones, setComunicaciones] = useState([]);
	const [legajosPendientes, setLegajosPendientes] = useState([]);
	const [legajosVisualizados, setLegajosVisualizados] = useState([]);
	const { getUsuarioAutenticado, cerrarSesion, actualizarEstadoCambiarClaveIniciarSesion } =
		useContext(SecurityContext);
	const [url, setUrl] = useState("");
	const [visible, setVisible] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const searchInput = useRef(null);
	const [selectedRowKeys, setSelectedRowsKeys] = useState([]);
	const [selectionType, setSelectionType] = useState("checkbox");
	const [loading, setLoading] = useState("");
	const [loadingDocumentos, setLoadingDocumentos] = useState(false);
	const [filaSeleccionada, setFilaSeleccionada] = useState("");
	const [firmaConformidad, setFirmaConformidad] = useState(false);
	const [visibleModalClave, setVisibleModalClave] = useState(false);
	const [documentoNombre, setDocumentoNombre] = useState("");
	const [fechaFirma, setFechaFirma] = useState("");
	const [representanteLegal, setRepresentanteLegal] = useState("");
	const [nombreColaborador, setNombreColaborador] = useState("");
	const [fechaFirmaColaborador, setFechaFirmaColaborador] = useState("");
	const [estadoDetalleEnvio, setEstadoDetalleEnvio] = useState();
	const [visibleModalConsultar, setVisibleModalConsultar] = useState(false);
	const [mensajeErrorCambiarClave, setMensajeErrorCambiarClave] = useState("");
	const [isModalCambiarClaveVisible, setIsModalCambiarClaveVisible] = useState(false);
	const [respuestaConsultaAbierta, setRespuestaConsultaAbierta] = useState([]);
	const [detallesConsulta, setDetallesConsulta] = useState([]);
	const [consultaSeleccionada, setConsultaSeleccionada] = useState(undefined);
	const [comentario, setComentario] = useState("");
	const [totalRespuestasUsuario, setTotalRespuestasUsuario] = useAtom(atomsConsulta.totalRespuestasUsuarioAtom);
	const { Text } = Typography;
	const { TextArea } = Input;
	const [formComentario] = Form.useForm();
	let history = useHistory();

	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

	useEffect(() => {
		async function cargarDatos() {
			setLoadingDocumentos(true);

			// const comunicaciones = await LegajoService.listarComunicaciones(
			// 	getUsuarioAutenticado().numeroDocumentoIdentidad,
			// 	getUsuarioAutenticado().empresaSeleccionada.id
			// );

			// setComunicaciones(comunicaciones);

			const legajoPendientes = await LegajoService.listarPorVisualizados(
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				getUsuarioAutenticado().empresaSeleccionada.id,
				"No"
			);
			setLegajosPendientes(legajoPendientes);

			const legajoVisualizados = await LegajoService.listarPorVisualizados(
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				getUsuarioAutenticado().empresaSeleccionada.id,
				"Si"
			);
			setLegajosVisualizados(legajoVisualizados);

			setLoadingDocumentos(false);

			setIsModalCambiarClaveVisible(getUsuarioAutenticado().cambiarClaveAlIniciarSesion);
			obtenerNumeroDetalleNoVisualizadas();
		}

		cargarDatos();
	}, [getUsuarioAutenticado().empresaSeleccionada.id]);

	const obtenerNumeroDetalleNoVisualizadas = async () => {
		const consultas = await PortalColaboradorService.listaConsultas(
			getUsuarioAutenticado().empresaSeleccionada.id,
			getUsuarioAutenticado().numeroDocumentoIdentidad
		);
		let listaConsultasId = [];
		if (consultas.length > 0) {
			consultas.map((consulta) => {
				listaConsultasId.push(consulta.id);
			});
			const respuestasXConsulta = await PortalColaboradorService.listaNumeroRespuestas(
				listaConsultasId,
				"respuestaUsuario"
			);
			let totalRespuestas = 0;
			respuestasXConsulta.length > 0 &&
				respuestasXConsulta.map((respuesta) => {
					respuesta.numeroRespuestas > 0 && totalRespuestas++;
				});
			setTotalRespuestasUsuario(totalRespuestas);
		}
	};
	var adicionarColumnasFiltro = function getColumns(dataIndex, placeholder, propiedad) {
		return {
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
				<div style={{ padding: 8 }}>
					<Input
						ref={searchInput}
						placeholder={placeholder !== undefined ? placeholder : `Buscar por ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
						onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
						style={{
							width: 188,
							marginBottom: 8,
							display: "block",
						}}
					/>
					<Space>
						<Button
							type="primary"
							onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
							icon={<SearchOutlined />}
							size="small"
							style={{ width: 90 }}
						>
							Buscar
						</Button>
						<Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
							Limpiar
						</Button>
					</Space>
				</div>
			),
			filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
			onFilter: (value, record) =>
				propiedad === undefined
					? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
					: record[dataIndex][propiedad].toString().toLowerCase().includes(value.toLowerCase()),
			onFilterDropdownVisibleChange: (visible) => {
				if (visible) {
					setTimeout(() => searchInput.current.select());
				}
			},
			render: (text) =>
				searchedColumn === dataIndex ? (
					<Highlighter
						highlightStyle={{
							backgroundColor: "#ffc069",
							padding: 0,
						}}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={propiedad === undefined ? text.toString() : text[propiedad].toString()}
					/>
				) : propiedad === undefined ? (
					text
				) : (
					text[propiedad]
				),
		};
	};

	function handleSearch(selectedKeys, confirm, dataIndex) {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	}

	function handleReset(clearFilters) {
		clearFilters();
		setSearchText("");
	}
	async function handleOnClickVerDocumentoPendiente(detalleEnvio) {
		try {
			setLoading(true);
			setFilaSeleccionada(detalleEnvio.id);
			let firma = detalleEnvio.envio.tipoDocumentoFirmaConformidadColaborador;
			setFirmaConformidad(firma ? firma : false);
			setEstadoDetalleEnvio(detalleEnvio.estadoProcesoId);
			const formData = new FormData();
			formData.append("detalleEnvioId", detalleEnvio.id);
			//1 es web
			formData.append("codigoPlataforma", 1);
			formData.append("numeroDocumentoIdentidad", getUsuarioAutenticado().numeroDocumentoIdentidad);

			formData.append("visualizado", detalleEnvio.visualizado);

			formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);

			let data = await LegajoService.verPDF(formData);
			console.log("data", data);
			if (data.existe) {
				notification.warning({
					message: "Advertencia",
					description: "Usted aun cuenta con documentos pendientes de visualizar, porfavor revisarlos.",
				});
				setLoading(false);
			} else {
				setUrl(data.urlEncriptada);
				setVisible(true);
				setDocumentoNombre(detalleEnvio.envio.tipoDocumentoNombre);
				setRepresentanteLegal(detalleEnvio.representanteLegalNombre);
				setFechaFirma(detalleEnvio.fechaEnvio);
				setNombreColaborador(detalleEnvio.colaborador.nombreCompleto);
				setFechaFirmaColaborador(detalleEnvio.colaborador.fechaFirma);
				setLoading(false);
				listarLegajos();
			}
		} catch (error) {
			setLoading(false);
		}
	}

	async function listarLegajos() {
		const legajoPendientes = await LegajoService.listarPorVisualizados(
			getUsuarioAutenticado().numeroDocumentoIdentidad,
			getUsuarioAutenticado().empresaSeleccionada.id,
			"No"
		);
		setLegajosPendientes(legajoPendientes);
		const legajoVisualizados = await LegajoService.listarPorVisualizados(
			getUsuarioAutenticado().numeroDocumentoIdentidad,
			getUsuarioAutenticado().empresaSeleccionada.id,
			"Si"
		);
		setLegajosVisualizados(legajoVisualizados);

		// const comunicaciones = await LegajoService.listarComunicaciones(
		// 	getUsuarioAutenticado().numeroDocumentoIdentidad,
		// 	getUsuarioAutenticado().empresaSeleccionada.id
		// );
		// setComunicaciones(comunicaciones);
	}

	async function handleOnClickVerDocumentoVisualizado(detalleEnvio) {
		try {
			setLoading(true);
			setFilaSeleccionada(detalleEnvio.id);
			setEstadoDetalleEnvio(detalleEnvio.estadoProcesoId);
			const formData = new FormData();
			formData.append("detalleEnvioId", detalleEnvio.id);
			//1 es web
			formData.append("codigoPlataforma", 1);
			formData.append("numeroDocumentoIdentidad", getUsuarioAutenticado().numeroDocumentoIdentidad);

			formData.append("visualizado", detalleEnvio.visualizado);
			formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);

			formData.append("estadoProcesoId", detalleEnvio.estadoProcesoId);

			let data = await LegajoService.verPDF(formData);

			if (data.existe) {
				notification.warning({
					message: "Advertencia",
					description: "Usted aun cuenta con documentos pendientes de visualizar, porfavor revisarlos.",
				});
				setLoading(false);
			} else {
				setUrl(data.urlEncriptada);
				setVisible(true);
				setDocumentoNombre(detalleEnvio.envio.tipoDocumentoNombre);
				setRepresentanteLegal(detalleEnvio.representanteLegalNombre);
				setFechaFirma(detalleEnvio.fechaEnvio);
				setNombreColaborador(detalleEnvio.colaborador.nombreCompleto);
				setFechaFirmaColaborador(detalleEnvio.colaborador.fechaFirma);

				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
		}
	}

	const columnasPendientes = [
		{
			title: "Documento",
			dataIndex: "envio",
			align: "center",
			...adicionarColumnasFiltro("envio", "Buscar por Tipo Documento", "tipoDocumentoNombre"),
		},
		{
			title: "Descripción",
			dataIndex: "envio",
			align: "center",
			...adicionarColumnasFiltro("envio", "Buscar por Descripción Documento", "descripcion"),
		},

		{
			title: "Estado",
			dataIndex: "estadoProcesoNombre",
			align: "center",

			render: function (text, fila) {
				if (
					fila.estadoProcesoId === 1 &&
					(!fila.envio.tipoDocumentoFirmaConformidadColaborador ||
						fila.envio.tipoDocumentoFirmaConformidadColaborador === null)
				) {
					return (
						<Tag color="red">
							<EyeInvisibleOutlined /> PENDIENTE
						</Tag>
					);
				} else if (fila.estadoProcesoId === 1 && fila.envio.tipoDocumentoFirmaConformidadColaborador) {
					return (
						<Tag color="blue">
							<EyeInvisibleOutlined /> PENDIENTE DE FIRMA
						</Tag>
					);
				} else if (fila.estadoProcesoId === 7) {
					return (
						<Tag color="blue">
							<EditOutlined /> PENDIENTE DE FIRMA
						</Tag>
					);
				} else if (fila.estadoProcesoId === 3) {
					return (
						<Tag color="volcano">
							<ToTopOutlined /> PENDIENTE DE CARGA
						</Tag>
					);
				} else if (fila.estadoDocumento === EstadoDocumento.PENDIENTE_VISUALIZAR) {
					return (
						<Tag color="red">
							<EyeInvisibleOutlined /> PENDIENTE
						</Tag>
					);
				}
			},
		},
		{
			title: "Fecha Envío",
			dataIndex: "fechaEnvio",
			align: "center",
			...adicionarColumnasFiltro("fechaEnvio", "Buscar por Fecha Envio"),
		},
		{
			title: "Acciones",
			key: "acciones",
			align: "center",
			render: (text, fila) => {
				if (fila.estadoProcesoId === 3) {
					return (
						<Space size={8}>
							<Button
								type="link"
								loading={filaSeleccionada === fila.id && loading}
								onClick={() => handleOnClickCargarDocumentos(fila)}
							>
								Cargar
							</Button>
						</Space>
					);
				} else {
					return (
						<Space size={8}>
							<Button
								type="link"
								loading={filaSeleccionada === fila.id && loading}
								onClick={() => handleOnClickVerDocumentoPendiente(fila)}
							>
								Ver
							</Button>
						</Space>
					);
				}
			},
		},
	];

	const columnasVisualizados = [
		{
			title: "Documento",
			dataIndex: "envio",
			align: "center",
			...adicionarColumnasFiltro("envio", "Buscar por Tipo Documento", "tipoDocumentoNombre"),
		},
		{
			title: "Descripción",
			dataIndex: "envio",
			align: "center",
			...adicionarColumnasFiltro("envio", "Buscar por Descripción Documento", "descripcion"),
		},

		{
			title: "Estado",
			dataIndex: "estadoProcesoNombre",
			align: "center",
			render: function (text, fila) {
				if (fila.estadoProcesoId === 8) {
					return (
						<Tag color="gold">
							<EyeOutlined /> VISUALIZADO Y FIRMADO
						</Tag>
					);
				} else if (fila.estadoProcesoId === 4) {
					return (
						<Tag color="blue">
							<UploadOutlined /> CARGADO
						</Tag>
					);
				} else if (
					fila.estadoProcesoNombre &&
					fila.estadoProcesoNombre.toLowerCase() === "notificado" &&
					fila.visualizado === "Si"
				) {
					return (
						<Tag color="green">
							<EyeOutlined /> VISUALIZADO
						</Tag>
					);
				} else if (fila.estadoDocumento === EstadoDocumento.VISUALIZADO) {
					return (
						<Tag color="green">
							<EyeOutlined /> VISUALIZADO
						</Tag>
					);
				}
			},
		},
		{
			title: "Fecha Envío",
			dataIndex: "fechaEnvio",
			align: "center",
			...adicionarColumnasFiltro("fechaEnvio", "Buscar por Fecha Envio"),
		},
		{
			title: "Acciones",
			key: "acciones",
			align: "center",
			render: (text, fila) => (
				<Space size={8}>
					<Button
						type="link"
						loading={filaSeleccionada === fila.id && loading}
						onClick={() => handleOnClickVerDocumentoVisualizado(fila)}
					>
						Ver
					</Button>
				</Space>
			),
		},
	];

	function handleOnOkModal() {
		setVisible(false);
		listarLegajos();
	}
	function handleOnclickCancelar() {
		setVisibleModalClave(false);
	}
	function handleOnClickFirmar() {
		setVisibleModalClave(true);
	}
	async function handleOnClickConsultar() {
		setVisibleModalConsultar(true);
		const consultas = await ConsultaService.buscarConsultaPorDocumento(filaSeleccionada, EstadoConsulta.ABIERTO);
		setRespuestaConsultaAbierta(consultas);
		if (consultas.length > 0) {
			const _detallesconsulta = await PortalColaboradorService.listaDetalleConsultas(consultas[0].id);
			setDetallesConsulta(_detallesconsulta);
			setConsultaSeleccionada(consultas[0]);
			const consultaEnviada = {
				consultaColaboradorId: consultas[0].id,
				tipoDetalle: "respuestaUsuario",
			};
			await PortalColaboradorService.cambiarEstadoVisualizacionDetalleConsulta(consultaEnviada);
		}
	}
	function cerrarModalConsultar() {
		setVisibleModalConsultar(false);
	}

	async function handleOnClickAceptar(formulario) {
		setLoading(true);
		formulario.detalleEnvioId = filaSeleccionada;

		let data = await LegajoService.firmarColaborador(formulario);

		if (data.claveCorrecta) {
			setVisibleModalClave(false);
			setUrl(data.urlDocumentoFirmado);
			setFirmaConformidad(false);
			setLoading(false);

			const newlegajoPendiente = [...legajosPendientes];
			const legajo = newlegajoPendiente.find((item) => item.id === filaSeleccionada);
			setEstadoDetalleEnvio(8);
			setNombreColaborador(legajo.colaborador.nombreCompleto);
			setFechaFirmaColaborador(data.fechaFirma);
		} else {
			notification.error({
				message: "Clave incorrecta",
				description: "La clave ingresada no coincide con la que se tiene registrada",
			});
			setLoading(false);
		}
	}
	function handleOnClickCargarDocumentos(documento) {
		if (documento.estadoProcesoId !== undefined) {
			var datosDocumento = [];
			datosDocumento = documento.envio;
			var tipoDocumentoId = datosDocumento.tipoDocumentoId;
			var tipoDocumentoNombre = datosDocumento.tipoDocumentoNombre;
			var tipoDocumentoFormato = datosDocumento.formatoCargaNombre;
		} else {
			var tipoDocumentoId = documento.tipoDocumentoId;
			var tipoDocumentoFormato = documento.tipoDocumentoFormato;
			var tipoDocumentoNombre = documento.tipoDocumentoNombre;
		}
		history.push(
			`/bandejaEntrada/cargarDocumento/${tipoDocumentoId}&${tipoDocumentoFormato}&${tipoDocumentoNombre}`
		);
	}
	const expanded = {
		expandedRowRender: (record) => {
			return (
				<React.Fragment>
					{record.estadoProcesoNombre === "Cargado" ? (
						<p style={{ margin: 0 }}>
							<span style={{ color: "#1678c1", fontWeight: "500" }}>Fecha Carga: </span>
							{record.fechaCargaDocumento}
						</p>
					) : (
						<p style={{ margin: 0 }}>
							<span style={{ color: "#1678c1", fontWeight: "500" }}>Fecha Visualización: </span>
							{record.visualizacionFecha}
						</p>
					)}
				</React.Fragment>
			);
		},
	};

	async function handleOnclickAceptarConsultar(formulario) {
		try {
			setLoading(true);
			formulario.detalleEnvioId = filaSeleccionada;
			formulario.tipoConsulta = TipoConsulta.POR_DOCUMENTO;
			//	let data = await LegajoService.consultarColaborador(formulario);
			let data = await PortalColaboradorService.consultarRRHH(formulario);
			notification.success({
				message: "Operación exitosa",
				description: "Se envío su consulta correctamente.",
			});

			setLoading(false);
			setVisibleModalConsultar(false);
		} catch (error) {
			console.error(error);
			notification.error({
				message: "No se puede guardar la consulta",
				description: "Error-> " + error.response.data.mensaje,
			});
			setLoading(false);
		}
	}

	async function handleOnClickCambiarClave(datosCambiarClave) {
		try {
			setLoading(true);
			datosCambiarClave.personaId = getUsuarioAutenticado().id;

			let respuesta = await PersonaService.cambiarClave(datosCambiarClave);

			if (respuesta.estado === "OK") {
				notification.success({
					message: "Operación exitosa",
					description: "Se cambió su clave correctamente.",
				});
				actualizarEstadoCambiarClaveIniciarSesion(false);
				setIsModalCambiarClaveVisible(false);
			} else {
				setMensajeErrorCambiarClave(respuesta.descripcion);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}

	function handleOnClickCerrarVentanaCambiarClave() {
		cerrarSesion();
	}

	const handleOnChangeComentario = (e) => {
		setComentario(e.target.value);
	};
	const handleOnClickAgregar = async () => {
		if (comentario.trim()) {
			try {
				setLoading(true);
				const detalleConsultaAgregada = {
					consultaColaboradorId: consultaSeleccionada.id,
					nombreCompletoColaborador: consultaSeleccionada.nombreCompletoColaborador,
					mensaje: comentario.trim(),
				};
				await DetalleConsultaService.insertar(detalleConsultaAgregada);
				detalleConsultaAgregada.fechaRegistroMensaje = new Date();
				setDetallesConsulta([...detallesConsulta, detalleConsultaAgregada]);
				formComentario.setFieldsValue({ valor: "" });
				setComentario("");
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error);
			}
		}
	};
	return (
		<React.Fragment>
			{esDesktop && (
				<Row gutter={15} justify="center">
					<ModalPDF
						ruta={url}
						visible={visible}
						handleOnOkModal={handleOnOkModal}
						handleOnClickFirmar={handleOnClickFirmar}
						firmaConformidad={firmaConformidad}
						documentoNombre={documentoNombre}
						representanteLegal={representanteLegal}
						fechaFirma={fechaFirma}
						nombreColaborador={nombreColaborador}
						fechaFirmaColaborador={fechaFirmaColaborador}
						estadoDetalleEnvio={estadoDetalleEnvio}
						handleOnClickConsultar={handleOnClickConsultar}
					/>
					<Col span={24}>
						{totalRespuestasUsuario > 0 && (
							<React.Fragment>
								<Alert
									message="Estimado Colaborador, tiene notificaciones pendientes de revisar."
									type="info"
									showIcon
								/>
								<br />
							</React.Fragment>
						)}

						{legajosPendientes.length !== 0 && legajosVisualizados.length !== 0 && (
							<Row style={{ paddingBottom: "20px" }}>
								<Card title="Pendientes" style={{ width: "100%" }} bordered={false}>
									<Table
										rowKey={(legajo) => legajo.id}
										columns={columnasPendientes}
										dataSource={legajosPendientes}
										pagination={{
											pageSize: [10],
										}}
									/>
								</Card>
							</Row>
						)}
						{legajosPendientes.length !== 0 && legajosVisualizados.length === 0 && (
							<Row>
								<Card title="Pendientes" style={{ width: "100%" }} bordered={false}>
									<Table
										rowKey={(legajo) => legajo.id}
										columns={columnasPendientes}
										dataSource={legajosPendientes}
										pagination={{
											pageSize: [10],
										}}
									/>
								</Card>
							</Row>
						)}

						{legajosVisualizados.length !== 0 && (
							<Row>
								<Card title="Documentos" style={{ width: "100%" }} bordered={false}>
									<Table
										rowKey={(legajo) => legajo.id}
										columns={columnasVisualizados}
										dataSource={legajosVisualizados}
										pagination={{
											pageSize: [10],
										}}
										expandable={{
											...expanded,
										}}
									/>
								</Card>
							</Row>
						)}
					</Col>
					<Modal
						title={
							<Space>
								<ExclamationCircleOutlined style={{ color: "#1678c1" }} />
								<Text strong>Firmar Documento</Text>
							</Space>
						}
						visible={visibleModalClave}
						onCancel={handleOnclickCancelar}
						footer={null}
						destroyOnClose={true}
					>
						<Form layout="vertical" onFinish={handleOnClickAceptar}>
							<Row justify="center">
								<Form.Item
									label="Ingrese su clave"
									name="clave"
									rules={[
										{
											required: true,
											message: "Porfavor ingrese su clave",
										},
									]}
								>
									<Input.Password placeholder="Ingrese su clave" />
								</Form.Item>
							</Row>
							<Row justify="center">
								<Space>
									<Col>
										<Form.Item>
											<Button type="primary" htmlType="submit" loading={loading}>
												Aceptar
											</Button>
										</Form.Item>
									</Col>
									<Col>
										<Form.Item>
											<Button onClick={handleOnclickCancelar}> Cancelar</Button>
										</Form.Item>
									</Col>
								</Space>
							</Row>
						</Form>
					</Modal>
					<Modal
						title={
							<Space>
								<ExclamationCircleOutlined style={{ color: "#1678c1" }} />
								<Text strong>Consultar</Text>
							</Space>
						}
						visible={visibleModalConsultar}
						onCancel={cerrarModalConsultar}
						footer={null}
						width={respuestaConsultaAbierta.length > 0 ? 800 : 400}
						destroyOnClose={true}
					>
						{respuestaConsultaAbierta.length > 0 ? (
							<React.Fragment>
								{detallesConsulta.length > 0 && (
									<List
										className="comment-list"
										itemLayout="horizontal"
										dataSource={detallesConsulta}
										renderItem={(item) => (
											<li>
												<Comment
													//actions={item.actions}
													author={item.nombreCompletoColaborador}
													avatar={
														<UserOutlined style={{ fontSize: "16px", color: "#F5860F" }} />
													}
													content={item.mensaje}
													datetime={new Date(item.fechaRegistroMensaje).toLocaleString()}
												>
													{item.detalleConsultaRespuesta != null &&
														item.detalleConsultaRespuesta.length > 0 &&
														item.detalleConsultaRespuesta.map((detalleRespuesta) => {
															console.log("detalleREspuesta", detalleRespuesta);
															return (
																<Comment
																	//actions={item.actions}
																	author={detalleRespuesta.usuarioNombreCompleto}
																	avatar={
																		<UserOutlined
																			style={{
																				fontSize: "16px",
																				color: "#099359",
																			}}
																		/>
																	}
																	content={detalleRespuesta.respuesta}
																	datetime={new Date(
																		detalleRespuesta.fechaRegistroRespuesta
																	).toLocaleString()}
																/>
															);
														})}
												</Comment>
											</li>
										)}
									/>
								)}
								{consultaSeleccionada !== undefined &&
									consultaSeleccionada.estadoConsulta === EstadoConsulta.ABIERTO && (
										<Comment
											content={
												<Form form={formComentario}>
													<Form.Item name="valor">
														<TextArea
															rows={4}
															onChange={handleOnChangeComentario}
															value={comentario}
														/>
													</Form.Item>
													<Form.Item>
														<Button
															htmlType="submit"
															onClick={handleOnClickAgregar}
															loading={loading}
															type="primary"
														>
															{" "}
															Agregar comentario
														</Button>
													</Form.Item>
													{/* <Checkbox >¿Se llegó a solucionar su consulta?</Checkbox> */}
												</Form>
											}
										/>
									)}
							</React.Fragment>
						) : (
							<Form layout="vertical" onFinish={handleOnclickAceptarConsultar}>
								<Row justify="center">
									<Col span={24}>
										<Form.Item
											label="Ingrese"
											name="consulta"
											rules={[
												{
													required: true,
													message: "Porfavor ingrese ",
												},
											]}
										>
											<TextArea rows={4} />
										</Form.Item>
									</Col>
								</Row>
								<Row justify="center">
									<Space>
										<Col>
											<Form.Item>
												<Button type="primary" htmlType="submit" loading={loading}>
													Aceptar
												</Button>
											</Form.Item>
										</Col>
										<Col>
											<Form.Item>
												<Button onClick={cerrarModalConsultar}> Cancelar</Button>
											</Form.Item>
										</Col>
									</Space>
								</Row>
							</Form>
						)}
					</Modal>
				</Row>
			)}

			{esMovil && (
				<MovilBandejaEntrada
					loading={loadingDocumentos}
					documentosPendientes={legajosPendientes}
					documentosVisualizados={legajosVisualizados}
				/>
			)}
		</React.Fragment>
	);
}
