import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { SolicitudAusenciaService } from "services";
import { ApiResponse } from "enum";
import { loadingSolicitudesAusenciaAtom, solicitudesAusenciaAtom } from "../store/MisSolicitudesStore";

const useListarSolicitudesAusencia = () => {
	const [solicitudesAusencia, setSolicitudesAusencia] = useAtom(solicitudesAusenciaAtom);
	const [loading, setLoading] = useAtom(loadingSolicitudesAusenciaAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			setLoading(true);
			const solicitudesAusencia = await SolicitudAusenciaService.listar();
			if (solicitudesAusencia.estado === ApiResponse.EXITO) {
				setSolicitudesAusencia(solicitudesAusencia.data);				
			}
			setLoading(false);
		};
		cargarDatos();
	}, []);
	return { loading, solicitudesAusencia };
};

export default useListarSolicitudesAusencia;
