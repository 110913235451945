import React, { useContext, useEffect } from "react";
import { Button, Card, Modal, Row, Checkbox } from "antd";
//import { cssVisorPdf, cssBodyVisorPdf } from "./estilos"
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState } from "react";
import { SeleccionarTipoCarga } from "./SeleccionarTipoCarga";
import { CargarImagen } from "./CargarImagen";
import { CargarPDF } from "./CargarPDF";
import { Header } from "./Header";
import { documentoSeleccionadoAtom } from "../MisDocumentosMovilStorage";
import { useAtom } from "jotai";
import { ModalValidarDeclaracion } from "./ModalValidarDeclaracion";

export function CargarDocumentosPage() {

    const [ documentoSeleccionado,_ ] = useAtom(documentoSeleccionadoAtom)

    const [seleccionado, setSeleccionado] = useState(false)
    const [isImage, setIsImage] = useState(undefined)
    const [mostrarBtnDeclaracion, setMostrarBtnDeclaracion] = useState(false);
    const [visibleModalDeclaracion, setVisibleModalDeclaracion] = useState(false);
    const [mostrar, setMostrar] = useState(false);

    useEffect(() => {
        setMostrarBtnDeclaracion(false);
        if (documentoSeleccionado?.mostrarDeclaracion) {
			setMostrar(true);
		} else {
			setMostrar(false);
        }
      console.log(documentoSeleccionado)
    }, [documentoSeleccionado])
    
    const onRetornar = () => {
        setSeleccionado(false);
        setIsImage(undefined);
    }

    const onChangeDeclaracion = (e) => {
        setMostrarBtnDeclaracion(e.target.checked); 
    }

    const onAceptarModal = async () => {
        setVisibleModalDeclaracion(true);
    };

    return (
        <React.Fragment>
                <Header seleccionado={seleccionado} onRetornar={onRetornar} />

                {!seleccionado && <SeleccionarTipoCarga setSeleccionado={setSeleccionado} setIsImage={setIsImage} setMostrar={setMostrar}/>}
                {seleccionado && isImage !== undefined && isImage && <CargarImagen />}
                {seleccionado && isImage !== undefined && !isImage && <CargarPDF />}
            
                { mostrar &&(
                    <div style={{padding: "0px 20px"}}>
                        <Checkbox onChange={onChangeDeclaracion} >
                            <strong>
                                <em>
                                    {documentoSeleccionado?.declaracion}
                                </em>
                            </strong>
                        </Checkbox>
                        
                    </div>
                )}
                {mostrarBtnDeclaracion && (
                    <div style={{ padding: "15px", display: "flex", justifyContent: "center" }}>
                        <Button key="aceptar" type="primary" onClick={onAceptarModal}>
                        Aceptar
                        </Button>
                    </div> 
                    )  
            }
            <ModalValidarDeclaracion visibleModalDeclaracion={visibleModalDeclaracion} setVisibleModalDeclaracion={ setVisibleModalDeclaracion} /> 
        </React.Fragment >
    )
}