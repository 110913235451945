import React from "react";
import { Steps, Spin } from "antd";
import { VerificarStep, AutenticarStep, ResumenStep, LinkExpirado } from "./componentes";
import { pasoActualAtom, linkValidoAtom,loadingAtom } from "./VerificarDatosPersonalesStore";
import { useAtom } from "jotai";


const VerificarDatosPersonalesPage = () => {
	const [pasoActual] = useAtom(pasoActualAtom);
	const [linkValido] = useAtom(linkValidoAtom);
	

	return (
		<ContainerVerificarDatos>
			{linkValido && <React.Fragment><Cabecera>
				<span style={{ fontSize: "24px" }}>Verificación de Datos Personales</span>
			</Cabecera>
			<Pasos>
				<Steps current={pasoActual}>
					<Steps.Step key="autenticar" title="Autenticar" />
					<Steps.Step key="informacion" title="Verificación" />
					<Steps.Step key="resumen" title="Resúmen" />
				</Steps>
			</Pasos>
			<Informacion>
				{pasoActual === 0 && <AutenticarStep />}
				{pasoActual === 1 && <VerificarStep />}
				{pasoActual === 2 && <ResumenStep />}
				</Informacion></React.Fragment>}
			{!linkValido && <React.Fragment>
				<LinkExpirado/>
			</React.Fragment>}

		</ContainerVerificarDatos>
	);
};

const Informacion = ({ children }) => {
	return (
		<div id="informacion" className="verificar-colaborador-informacion">
			{children}
		</div>
	);
};

const Pasos = ({ children }) => {
	return (
		<div
			id="pasos"
			style={{
				height: "120px",
				background: "#f5f5f5",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div style={{ width: "80%" }}>{children}</div>
		</div>
	);
};

const Cabecera = ({ children }) => {
	return (
		<div
			id="titulo"
			style={{
				height: "80px",
				textAlign: "center",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{children}
		</div>
	);
};

const ContainerVerificarDatos = ({ children }) => {
	const [loading] = useAtom(loadingAtom);
	return (
		<div
			id="container"
			style={{
				background: "#fff",
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
			}}
		>
			<Spin spinning={loading}>
				{children}
			</Spin>
		</div>
	);
};

export default VerificarDatosPersonalesPage;
