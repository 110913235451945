import React from "react";
import { Progress, Tag } from "antd";

const styles = {
	containerPendiente: {
		border: "1px solid #ffe58f",
		display: "flex",
		width: "85%",
		padding: "2% 2%",
		borderRadius: "10px",
		marginTop: "5px",
		marginBottom: "5px",
		cursor:"pointer"
	},
	containerCompletado: {
		border: "1px solid #389e0d",
		display: "flex",
		width: "85%",
		padding: "2% 2%",
		borderRadius: "10px",
		marginTop: "5px",
		marginBottom: "5px",
		cursor:"pointer"
	},
	icono: {
		width: "15%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
};

const Item = ({ icono, descripcion, isCompleto, onClick }) => {
	return (
		<div style={isCompleto ? styles.containerCompletado : styles.containerPendiente} onClick={onClick}>
			<div style={styles.icono}>{icono}</div>
			<div style={{ width: "85%", display: "flex", flexDirection: "column" }}>				
				<span style={{ fontWeight: "bold" }}>{descripcion}</span>
				<span style={{ paddingTop: "5px" }}>
					<Tag color={isCompleto ? "green" : "orange"} style={{ borderRadius: "10px" }}>
						{isCompleto ? "COMPLETADO" : "PENDIENTE"}
					</Tag>
				</span>
				<span>
					<Progress percent={isCompleto ? 100 : 0} size="small" />
				</span>
			</div>
		</div>
	);
};

export default Item;
