import React, { useContext } from "react";
import { useAtom } from "jotai";
import { solicitudesDocumentosAtom } from "../store/MisSolicitudesDocumentosStore";
import SolicitudDocumentoService from "services/SolicitudDocumentoService";
import { SecurityContext } from "context/SecurityContextProvider";

const useCargarSolicitudesDocumentos = () => {
	const [solicitudes, setSolicitudes] = useAtom(solicitudesDocumentosAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);

	const cargarSolicitudesDocumentos = async () => {
		const solicitudes_ = await SolicitudDocumentoService.listar(getUsuarioAutenticado().numeroDocumentoIdentidad);
		setSolicitudes(solicitudes_);
	};
	return { cargarSolicitudesDocumentos };
};
export default useCargarSolicitudesDocumentos;
