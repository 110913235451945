import React, { useEffect, useRef, useCallback, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import LegajoService from "../../services/LegajoService";
import { useState } from "react";
import {
	ArrowLeftOutlined,
	LoadingOutlined,
	CheckCircleOutlined,
	RightOutlined,
	LeftOutlined,
	DownloadOutlined,
} from "@ant-design/icons";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import PrismaZoom from "react-prismazoom";
import { LogVisorPdfService } from "services/LogVisorPdfService";
import { SecurityContext } from "context/SecurityContextProvider";
import { Button, Tag } from "antd";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { ConsultaService } from "services/ConsultaService";

import {
	osVersion,
	osName,
	fullBrowserVersion,
	browserVersion,
	browserName,
	mobileVendor,
	mobileModel,
	engineName,
	engineVersion,
} from "react-device-detect";
import { EstadoDetalleEnvio } from "enum/EstadoDetalleEnvio";
import { ModalFirmar } from "./ModalFirmar";
import { ModalConsultar } from "./ModalConsultar";
import { useAtom } from "jotai";
import * as atoms from "./BandejaEntregaDetalleStorage";
import { EstadoConsulta } from "enum/EstadoConsulta";
import { Plataforma } from "enum/Plataforma";
export function BandejaEntregaDetallePage() {
	let history = useHistory();
	let { documentoDigitalId } = useParams();
	let { numeroDocumentoIdentidad } = useParams();
	let { visualizado } = useParams();
	let { empresaId } = useParams();
	let { estadoProcesoId } = useParams();

	const [estadoDetalleEnvioId, setEstadoDetalleEnvioId] = useState(estadoProcesoId);
	const [visibleModalFirmar, setVisibleModalFirmar] = useState(false);
	const [url, setUrl] = useState("");
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const documentWrapperRef = useRef(null);
	const [scale, setScale] = useState(1);
	const [zoomIn, setZoomIn] = useState(false);
	const [visibleModalConsultar, setVisibleConsultar] = useState(false);
	const [buttonVisible, setButtonVisible] = useState(1);
	const imgRef = useRef();
	const pinchZoomRef = useRef();
	const onUpdate = useCallback(({ x, y, scale }) => {
		const { current: div } = documentWrapperRef;

		if (div) {
			const svg = div.querySelector("svg");

			if (svg) {
				const value = make3dTransformValue({ x, y, scale });

				svg.style.setProperty("transform", value);
			}
		}
	}, []);

	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [respuestaConsultaAbierta, setRespuestaConsultaAbierta] = useAtom(atoms.respuestaConsultaAbiertaAtom);
	const [detallesConsulta, setDetallesConsulta] = useAtom(atoms.detallesConsultaAtom);
	const [consultaSeleccionada, setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);

	useEffect(() => {
		async function fetchData() {
			const formData = new FormData();
			formData.append("detalleEnvioId", documentoDigitalId);
			formData.append("codigoPlataforma", 1);
			formData.append("numeroDocumentoIdentidad", numeroDocumentoIdentidad);
			formData.append("visualizado", visualizado);
			formData.append("empresaId", empresaId);
			formData.append("estadoProcesoId", estadoProcesoId);
			let data = await LegajoService.verPDF(formData);
			if (!data.existe) {
				//Porque debe ser no existe???
				setUrl(data.urlEncriptada);
			}
		}
		fetchData();
	}, []);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	function onDocumentLoadError(error) {
		try {
			const log = {
				organizacionId: getUsuarioAutenticado().organizacionId,
				empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
				colaboradorId: getUsuarioAutenticado().id,
				colaboradorNumeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
				colaboradorNombreCompleto: getUsuarioAutenticado().nombreCompleto,
				detalleEnvioId: documentoDigitalId,
				sistemaOperativoVersion: osVersion,
				sistemaOperativoNombre: osName,
				navegadorWebVersion: browserVersion,
				navegadorWebVersionCompleta: fullBrowserVersion,
				navegadorWebNombre: browserName,
				movilFabricante: mobileVendor,
				movilModelo: mobileModel,
				engineVersion: engineVersion,
				engineName: engineName,
				error: error?.message,
			};
			LogVisorPdfService.insertar(log);
		} catch (error) {
			console.error("Se produjero error al enviar el log", error);
		}
	}

	function onClickZoomPdf() {
		setZoomIn(!zoomIn);
		zoomIn ? setScale(1) : setScale(2);
	}

	function onClickRetornar() {
		history.push("/bandejaEntrada");
	}

	const onClickBtnFirmar = () => {
		setVisibleModalFirmar(true);
	};

	const onClickBtnConsultar = async () => {
		setVisibleConsultar(true);
		const consultas = await ConsultaService.buscarConsultaPorDocumento(documentoDigitalId, EstadoConsulta.ABIERTO);
		setRespuestaConsultaAbierta(consultas);
		if (consultas.length > 0) {
			const _detallesconsulta = await PortalColaboradorService.listaDetalleConsultas(consultas[0].id);
			setDetallesConsulta(_detallesconsulta);
			setConsultaSeleccionada(consultas[0]);
			const consultaEnviada = {
				consultaColaboradorId: consultas[0].id,
				tipoDetalle: "respuestaUsuario",
			};
			await PortalColaboradorService.cambiarEstadoVisualizacionDetalleConsulta(consultaEnviada);
		}
	};

	const onClickBtnDescargar = async() => {
		const plataforma = localStorage.getItem("plataforma");

		if (plataforma === Plataforma.MOVIL_IOS || plataforma === Plataforma.MOVIL_ANDROID) {
			const respuesta = await PortalColaboradorService.obtenerUrlDescargaMovil(
				getUsuarioAutenticado().empresaSeleccionada.id, documentoDigitalId);

			window.location.href = `${respuesta.urlDocumento}`;			
		}
	}
	const onClickBtnAnterior = () => {
		setPageNumber(pageNumber - 1);
	};
	const onClickBtnSiguiente = () => {
		setPageNumber(pageNumber + 1);
	};
	return (
		<React.Fragment>
			<div className="container-movil">
				<div className="container-movil-pdf-header">
					<div style={{ width: "100%" }} onClick={onClickRetornar}>
						<ArrowLeftOutlined style={{ fontSize: "20px", marginRight: "8px" }} /> Retornar
					</div>

					{estadoDetalleEnvioId !== EstadoDetalleEnvio.VISUALIZADO_FIRMADO ? (
						<div style={{ width: "100%", textAlign: "right", paddingRight: "10px" }}>
							<Button type="primary" onClick={onClickBtnConsultar}>
								Consultar
							</Button>
						</div>
					) : (
					   <div style={{ width: "100%", textAlign: "right", paddingRight: "10px" }}>
							<Button icon={<DownloadOutlined />} onClick={onClickBtnDescargar}>
								Descargar
							</Button>
						</div>		
					)}
				</div>
				{/* oka */}
				{/* <div className="container-movil-pdf-body">
				<PinchZoomPan doubleTapBehavior="zoom" zoomButtons={false} >
					<img src="http://1.bp.blogspot.com/-WgOHfsG5vKw/UfNNoqa9E-I/AAAAAAAAAsg/zVPOWPohlIo/s1600/Boleta+de+venta.png" alt="test" />
				</PinchZoomPan>
			</div > */}

				<div ref={documentWrapperRef} className="container-movil-pdf-body">
					{url && (
						<Document
							file={url}
							onLoadSuccess={onDocumentLoadSuccess}
							onLoadError={onDocumentLoadError}
							loading={
								<div
									style={{
										textAlign: "center",
										color: "#1c74b3",
									}}
								>
									<LoadingOutlined style={{ fontSize: "32px" }} />
									<br />
									Cargando Documento
								</div>
							}
						>
							{/* <QuickPinchZoom ref={pinchZoomRef} onUpdate={onUpdate}> */}
							<div onClick={() => setButtonVisible(buttonVisible == 1 ? 0 : 1)}>
								<PrismaZoom className="App-zoom" topBoundary={60}>
									{/* {	Array.apply(null, Array(numPages))
									.map((x, i)=>i+1)
									.map(page => <Page key={page} width={documentWrapperRef.current?.getBoundingClientRect().width || undefined} renderMode="canvas" pageNumber={page}/>)} */}
									<Page
										width={documentWrapperRef.current?.getBoundingClientRect().width || undefined}
										pageNumber={pageNumber}
										renderMode="canvas"
										// scale={scale}
									/>
								</PrismaZoom>
							</div>
							<div
								onMouseLeave={() => setButtonVisible(1)}
								style={{
									position: "absolute",
									bottom: 70,
									visibility: "visible",
									opacity: buttonVisible,
									background: "rgba(255, 255, 255, 0.7)",
									borderRadius: "25px",
									boxShadow: "5px 5px 18px #888888",
									transition: "visibility 0s linear 0s, opacity 400ms",
								}}
							>
								<Button
									icon={<LeftOutlined />}
									disabled={pageNumber <= 1}
									onClick={onClickBtnAnterior}
									shape="circle"
								/>
								<span>{`Pagina ${pageNumber || (numPages ? 1 : "--")} de ${numPages || "--"}`}</span>
								<Button
									icon={<RightOutlined />}
									disabled={pageNumber >= numPages}
									onClick={onClickBtnSiguiente}
									shape="circle"
								/>
							</div>
							{/* </QuickPinchZoom> */}
						</Document>
					)}
				</div>
				<div className="container-movil-pdf-footer">
					{(estadoDetalleEnvioId === EstadoDetalleEnvio.VISUALIZADO_PENDIENTE_FIRMAR ||
						estadoDetalleEnvioId === EstadoDetalleEnvio.NOTIFICACION_EN_PROCESO ||
						estadoDetalleEnvioId === EstadoDetalleEnvio.NOTIFICACION_CORRECTA) && (
						<Button type="primary" block onClick={onClickBtnFirmar}>
							Firmar Documento
						</Button>
					)}

					{/* {estadoDetalleEnvioId === EstadoDetalleEnvio.NOTIFICACION_CORRECTA && (
						<React.Fragment>
							<span style={{ display: "block", with: "100%" }}>
								<Tag color="green" icon={<CheckCircleOutlined />}>
									VISUALIZADO
								</Tag>
							</span>
						</React.Fragment>
					)} */}

					{estadoDetalleEnvioId === EstadoDetalleEnvio.VISUALIZADO_FIRMADO && (
						<React.Fragment>
							<span style={{ display: "block", with: "100%" }}>
								<Tag color="green" icon={<CheckCircleOutlined />}>
									FIRMADO
								</Tag>
							</span>
						</React.Fragment>
					)}
				</div>
			</div>

			<ModalFirmar
				visible={visibleModalFirmar}
				setVisible={setVisibleModalFirmar}
				detalleEnvioId={documentoDigitalId}
				setUrl={setUrl}
				setEstadoDetalleEnvioId={setEstadoDetalleEnvioId}
			/>
			<ModalConsultar
				documentoSeleccionado={documentoDigitalId}
				setVisibleConsultar={setVisibleConsultar}
				visibleModalConsultar={visibleModalConsultar}
			/>
		</React.Fragment>
	);
}
