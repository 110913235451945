import { useAtom } from "jotai";
import { notification } from "antd";
import EvaluacionService from "services/EvaluacionService";
import {
	evaluacionesPendientesAtom,
	evaluacionPendienteSeleccionadaAtom,
	visibleModalEvaluacionPendienteAtom,
} from "../../BandejaEvaluacionStorage";
import useObservacionResultado from "./useObservacionResultado";
import usePlanAccion from "./usePlanAccion";
import usePreguntasCalidad from "./usePreguntasCalidad";
import usePreguntasLimpiezaSeguridad from "./usePreguntasLimpiezaSeguridad";
import usePreguntasOrientacionResultados from "./usePreguntasOrientacionResultados";
import usePreguntasProactividad from "./usePreguntasProactividad";
import usePreguntasRelacionesInterpersonales from "./usePreguntasRelacionesInterpersonales";
import usePreguntasTrabajoEnEquipo from "./usePreguntasTrabajoEnEquipo";
import useRenovacion from "./useRenovacion";
import useResultadoEvaluacion from "./useResultadoEvaluacion";
import useModalFirmarEvaluacion from "./useModalFirmarEvaluacion";

const useModalEvaluacion = () => {
	const [visibleModalEvaluacion, setVisibleModalEvaluacion] = useAtom(visibleModalEvaluacionPendienteAtom);

	const { mostrarModal: mostrarModalFirmarEvaluacion } = useModalFirmarEvaluacion();

	const [evaluacionPendienteSeleccionada, setEvaluacionPendienteSeleccionada] = useAtom(
		evaluacionPendienteSeleccionadaAtom
	);
	const [evaluacionesPendientes, setEvaluacionesPendientes] = useAtom(evaluacionesPendientesAtom);

	const { frmPreguntas: frmPreguntasCalidad } = usePreguntasCalidad();
	const { frmPreguntas: frmPreguntasProactividad } = usePreguntasProactividad();
	const { frmPreguntas: frmPreguntasTrabajoEnEquipo } = usePreguntasTrabajoEnEquipo();
	const { frmPreguntas: frmPreguntasOrientacionResultados } = usePreguntasOrientacionResultados();
	const { frmPreguntas: frmPreguntasRelacionesInterpersonales } = usePreguntasRelacionesInterpersonales();
	const { frmPreguntas: frmPreguntasLimpiezaSeguridad } = usePreguntasLimpiezaSeguridad();
	const { frmObservacionResultados } = useObservacionResultado();
	const { frmPlanAccion } = usePlanAccion();
	const { frmRenovacion } = useRenovacion();
	const { puntajeTotalEvaluacion } = useResultadoEvaluacion();

	const onClickBtnEnviar = async () => {
		try {
			await frmPreguntasCalidad.validateFields();
			await frmPreguntasProactividad.validateFields();
			await frmPreguntasTrabajoEnEquipo.validateFields();
			await frmPreguntasOrientacionResultados.validateFields();
			await frmPreguntasRelacionesInterpersonales.validateFields();
			await frmPreguntasLimpiezaSeguridad.validateFields();
			await frmObservacionResultados.validateFields();
			await frmObservacionResultados.validateFields();
			await frmPlanAccion.validateFields();
			await frmRenovacion.validateFields();

			const resultado = {
				evaluacionId: evaluacionPendienteSeleccionada.id,
				preguntasCalidad: getPreguntas(frmPreguntasCalidad),
				preguntasProactividad: getPreguntas(frmPreguntasProactividad),
				preguntasTrabajoEnEquipo: getPreguntas(frmPreguntasTrabajoEnEquipo),
				preguntasOrientadoResultados: getPreguntas(frmPreguntasOrientacionResultados),
				preguntasRelacionesInterpersonales: getPreguntas(frmPreguntasRelacionesInterpersonales),
				preguntasLimpiezaSeguridad: getPreguntas(frmPreguntasLimpiezaSeguridad),
				puntajeTotal: puntajeTotalEvaluacion,
				observacion: frmObservacionResultados.getFieldValue("observacion"),
				resultado: getResultado(puntajeTotalEvaluacion),
				planesAccion: frmPlanAccion.getFieldValue("planAccion"),
				renovarContrato: frmRenovacion.getFieldValue("renovarContrato"),
				mesesRenovacion: frmRenovacion.getFieldValue("mesesRenovacion"),
			};

			setEvaluacionPendienteSeleccionada({ ...evaluacionPendienteSeleccionada, resultado: resultado });
			mostrarModalFirmarEvaluacion();
			// await EvaluacionService.actualizarResultado(resultado);

			// setEvaluacionesPendientes(
			// 	evaluacionesPendientes.filter((evaluacion) => evaluacion.id !== evaluacionPendienteSeleccionada.id)
			// );

			// notification["success"]({
			// 	message: "Éxito",
			// 	description: "Evaluación enviada correctamente.",
			// });

			//ocultarModal();
		} catch (error) {
			console.log("errores", error);
		}
	};

	const getPreguntas = (frmPreguntas) => {
		const entradas = Object.entries(frmPreguntas.getFieldsValue());
		let data = entradas.map(([pregunta, puntuacion]) => {
			return {
				pregunta: pregunta,
				puntuacion: puntuacion,
			};
		});
		return data;
	};

	const getResultado = (puntajeTotal) => {
		if (puntajeTotal >= 0 && puntajeTotal <= 50) {
			return "No Apto";
		} else if (puntajeTotal >= 51 && puntajeTotal <= 75) {
			return "Apto con Observaciones (Plan de acción)";
		} else if (puntajeTotal >= 76 && puntajeTotal <= 85) {
			return "Bueno (Plan de Acción opcional)";
		} else if (puntajeTotal >= 86 && puntajeTotal <= 95) {
			return "Destacado";
		} else if (puntajeTotal >= 96 && puntajeTotal <= 100) {
			return "Excelente";
		}
	};

	const mostrarModal = (evaluacion) => {
		setEvaluacionPendienteSeleccionada(evaluacion);
		setVisibleModalEvaluacion(true);
	};

	const ocultarModal = () => {
		setVisibleModalEvaluacion(false);
	};

	return {
		mostrarModal,
		ocultarModal,
		// evaluacionPendienteSeleccionada,
		visibleModalEvaluacion,
		onClickBtnEnviar,
	};
};

export default useModalEvaluacion;
