import React from "react";
import { Button, Card, Row, Space, Tooltip,Badge, Alert} from "antd";
import {
	FileImageOutlined,
	FilePdfOutlined,
	LeftOutlined,
	SearchOutlined
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { SubirImagen, SubirPdf } from "./componentes";
import {
	documentoSeleccionadoAtom,
	rutaSubirDocumentoAtom,
	visibleModalOmitirAtom,
} from "pages/onboarding/OnboardingStore";
import { saveAs } from "file-saver";
import { useState } from "react";
import { useEffect } from "react";
import { PortalColaboradorService } from "services/PortalColaboradorService";

const SubirDocumentos = () => {
	const [rutaSubirDocumentos, setRutaSubirDocumentos] = useAtom(
		rutaSubirDocumentoAtom
	);
	const documentoSeleccionado = useAtomValue(documentoSeleccionadoAtom);
	const [urlPlantilla, setUrlPlantilla] = useState("");
	const setVisibleModalOmitir = useSetAtom(visibleModalOmitirAtom);
	const [habilitarCarga, setHabilitarCarga] = useState(true);

	const onClickSeleccionarImagen = () => {
		setRutaSubirDocumentos("subir-imagen");
	};

	const onClickSeleccionarPdf = () => {
		setRutaSubirDocumentos("subir-pdf");
	};

	const onClicBtnRetornar = () => {
		setRutaSubirDocumentos("home");
	};

	useEffect(() => {
		const cargarUrl = async () => {
			const respuesta =
				await PortalColaboradorService.obtenerFormatoCarga(
					documentoSeleccionado?.tipoDocumentoId
				);
			setUrlPlantilla(respuesta.urlDocumento);
		};

		if (documentoSeleccionado?.mostrarlink) {
			setHabilitarCarga(false);
		}else {
			setHabilitarCarga(true);
		}
		
		if (documentoSeleccionado != null) cargarUrl();
	}, [documentoSeleccionado]);

	const onClickDescargarPlantilla = () => {
		saveAs(
			urlPlantilla,
			"Formato Carga - " + documentoSeleccionado?.nombreDocumento + ".pdf"
		);
	};

	const onClickBtnOmitir = () => {
		setVisibleModalOmitir(true);
	};

	const onClickBtnLink = () => {
		setHabilitarCarga(true);
	};

	return (
		<Container>
			<Header>
				{/* <Row style={{width:"100%"}} justify={"space-between"}>
                    {rutaSubirDocumentos !== "home" ? <Button type="link" onClick={onClicBtnRetornar}>
						<LeftOutlined />
						Retornar
					</Button> : <span></span>}
					{urlPlantilla!=null && urlPlantilla!= "" && <Button type="primary" key="descargar-plantilla" onClick={()=>onClickDescargarPlantilla()}>
                        Descargar Plantilla
                    </Button>}
				</Row> */}

				<Row style={{ width: "100%", justifyContent: "space-between" }}>
					<div>
						{rutaSubirDocumentos !== "home" && (
							<Button type="link" onClick={onClicBtnRetornar}>
								<LeftOutlined />
								Retornar
							</Button>
						)}
					</div>

					<div>
						<Space>
							{documentoSeleccionado?.opcional && (
								<Button
									onClick={onClickBtnOmitir}
									type="danger"
								>
									Omitir
								</Button>
							)}
							{urlPlantilla !== undefined &&
								urlPlantilla !== null &&
								urlPlantilla !== "" && (
									<Button
										type="primary"
										onClick={() =>
											onClickDescargarPlantilla()
										}
									>
										Descargar Plantilla
									</Button>
								)}
							
							{!habilitarCarga && documentoSeleccionado?.mostrarlink && (
										<Button
											onClick={onClickBtnLink}
											type="link"
											href={documentoSeleccionado?.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											Tutorial
										</Button> 
							)}
						</Space>
					</div>
						
				</Row>
			</Header>
			{!habilitarCarga && documentoSeleccionado?.mostrarlink && (
				<ContainerItemMessage>
					<Alert message='Para poder cargar sus documentos debe de ver el Tutorial' type="warning" showIcon />
				</ContainerItemMessage>
				)
			}
			<Body>
				{habilitarCarga && rutaSubirDocumentos === "home" && (
					<React.Fragment>
						<Card
							hoverable
							onClick={onClickSeleccionarImagen}
							style={{
								width: "60vw",
								height: "150px",
								borderRadius: "5px",
								margin: "20px 0 0 0",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<ContainerItem>
								<FileImageOutlined
									style={{
										fontSize: "40px",
										color: "#8e9aaf",
									}}
								/>
								<span
									style={{
										fontSize: "16px",
										marginTop: "10px",
										color: "#8e9aaf",
									}}
								>
									Seleccionar Imagen
								</span>
							</ContainerItem>
						</Card>
						<Card
							hoverable
							onClick={onClickSeleccionarPdf}
							style={{
								width: "60vw",
								height: "150px",
								borderRadius: "5px",
								margin: "20px 0",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<ContainerItem>
								<FilePdfOutlined
									style={{
										fontSize: "40px",
										color: "#8e9aaf",
									}}
								/>
								<span
									style={{
										fontSize: "16px",
										marginTop: "10px",
										color: "#8e9aaf",
									}}
								>
									Seleccionar PDF
								</span>
							</ContainerItem>
						</Card>
					</React.Fragment>
				)}
				{rutaSubirDocumentos === "subir-imagen" && <SubirImagen />}
				{rutaSubirDocumentos === "subir-pdf" && <SubirPdf />}
			</Body>
		</Container>
	);
};

const Container = styled.div`
	height: calc(100% - 50px);
`;

const Body = styled.div`
	border: 1px solid #ccc;
	height: 100%;
	width: 100%;
	position: relative;
	padding: 0px 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Header = styled.div`
	min-height: 50px;
	max-height: 50px;
	display: flex;
	background: white;
	padding: 0px 10px;
	border: 1px solid #ccc;
	border-bottom: 0px;
	align-items: center;
`;

const ContainerItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const ContainerItemMessage = styled.div`
	border: 1px solid #ccc;
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
export default SubirDocumentos;
