import { atom } from "jotai";

export const evaluacionesHistoricosAtom = atom([]);
//export const evaluacionPendienteSeleccionadaAtom = atom();

//pendientes evaluacion
export const evaluacionesPendientesAtom = atom([]);
export const evaluacionPendienteSeleccionadaAtom = atom(undefined);
export const visibleModalEvaluacionPendienteAtom = atom(false);
export const visibleModalFirmarEvaluacionAtom = atom(false);

export const frmPreguntasCalidadAtom = atom(undefined);
export const frmPreguntasProactividadAtom = atom(undefined);
export const frmPreguntasTrabajoEnEquipoAtom = atom(undefined);
export const frmPreguntasOrientacionResultadosAtom = atom(undefined);
export const frmPreguntasRelacionesInterpersonalesAtom = atom(undefined);
export const frmPreguntasLimpiezaSeguridadAtom = atom(undefined);
export const frmObservacionResultadosAtom = atom(undefined);
export const frmPlanAccionAtom = atom(undefined);
export const frmRenovacionAtom = atom(undefined);

export const puntajeTotalCalidadAtom = atom(0);
export const puntajeTotalProactividadAtom = atom(0);
export const puntajeTotalTrabajoEnEquipoAtom = atom(0);
export const puntajeTotalOrientacionResultadosAtom = atom(0);
export const puntajeTotalRelacionesInterpersonalesAtom = atom(0);
export const puntajeTotalLimpiezaSeguridadAtom = atom(0);
export const puntajeTotalEvaluacionAtom = atom(0);

//plan de Accion
export const planesAccionAtom = atom([]);
export const planAccionSeleccionadaAtom = atom(undefined);
export const visibleModalActualizarPlanAccionAtom = atom(false);

