import { useEffect, useState } from "react";
import { PortalColaboradorService } from "services/PortalColaboradorService";

const useObtenerJefeInmediato = () => {
	const [jefeInmediato, setJefeInmediato] = useState(undefined);
	useEffect(() => {
		const cargarDatos = async () => {
			const jefeInmediato = await PortalColaboradorService.obtenerJefeInmediato();
			setJefeInmediato(jefeInmediato);
		};
		cargarDatos();
	}, []);
	return { jefeInmediato };
};

export default useObtenerJefeInmediato;
