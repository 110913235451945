import React from 'react';
import { RightOutlined } from "@ant-design/icons"
import styled from '@emotion/styled';

const Boton = ({ icono, nombre, onClick }) => {
    return (
        <Container onClick={onClick} >
            <SeccionIcono>
                {icono}
            </SeccionIcono>
            <SeccionNombre>
                {nombre}
            </SeccionNombre>
            <SeccionEnlace>
                <RightOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#8e9aaf" }} />
            </SeccionEnlace>
        </Container >
    );
};

const SeccionEnlace = styled.div`
    display: flex;
    align-items: center;
    flex: 10%; 
    justify-content: center;
`


const SeccionNombre = styled.div`
    flex: 80%; 
    font-size: 14px; 
    display: flex; 
    align-items: center;
`

const SeccionIcono = styled.div`
    flex: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px 0px 15px;
`

const Container = styled.div`
    display: flex;
    margin-bottom: 5px;
    height: 50px;
    border-radius: 5px;
    background: white;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02);
`

export default Boton;