import React from "react";
import { useModalEvaluacion } from "../../hooks";
import usePreguntasLimpiezaSeguridad from "../../hooks/usePreguntasLimpiezaSeguridad";
import { Preguntas } from "../Preguntas";

const PreguntasLimpiezaSeguridad = () => {
	const { frmPreguntas, setPuntajeTotal } = usePreguntasLimpiezaSeguridad();

	return (
		<Preguntas
			form={frmPreguntas}
			calcularPuntajeTotal={setPuntajeTotal}
			titulo="LIMPIEZA Y SEGURIDAD (6/6)"
			opciones={[
				{
					key: "haceUsoDeSusEppDeManeraAdecuada",
					label: "Hace uso de sus EPP de manera adecuada / Cumple código de vestimenta",
				},
				{
					key: "mantieneElOrdenYLimpiezaEnSuLigarDeTrabajo",
					label: "Mantiene el orden y Limpieza en su lugar de trabajo",
				},
				{
					key: "imagenEHigienePersonal",
					label: "Imagen e higiene personal",
				},
			]}
		/>
	);
};

export default PreguntasLimpiezaSeguridad;
