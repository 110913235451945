import React from "react";
import { Header } from "./Header";
import { ListaDocumentos } from "./ListaDocumentos";
import { ModalFirmar } from "../ModalFirmar";
import { ModalMensajeAlerta } from "../ModalMensajeAlerta";
import { ModalSubirArchivo } from "../ModalSubirArchivo/ModalSubirArchivo";

const MisDocumentosMovil = () => {
	return (
		<React.Fragment>
			<Header />
			<ListaDocumentos />
			<ModalFirmar />
			<ModalMensajeAlerta />
			<ModalSubirArchivo />
		</React.Fragment>
	);
};

export default MisDocumentosMovil;
