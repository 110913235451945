import { BaseService } from "./BaseService";
const ColaboradorService = {
	async listarPorNombreCompletoODni(data) {
		const respuesta = await BaseService.get("/colaborador/listar-por-nombre-o-dni", {
			params: {
				...data,
			},
		});
		return respuesta.data;
	},

	async listarPorFiltros(params) {
		const respuesta = await BaseService.get("/colaborador/listar-por-nombre-o-dni-y-filtros", {
			params: {
				...params,
			},
		});
		return respuesta.data;
	}

};
export default ColaboradorService;
