import React, { useState } from "react";
import { Upload, Form, Input, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { archivosAdjuntosAtom } from "../../store/MisSolicitudesStore";
import { useAtom } from "jotai";

const Archivo = ({ info }) => {
	const [archivos, setArchivos] = useAtom(archivosAdjuntosAtom);
	const acceptedFileTypes = ["image/jpeg", "image/png", "application/pdf"];
	//var mapArchivos = new Map();

	const props = {
		onRemove: (file) => {
			//setArchivos([]);
			archivos.delete(info.key);
			setArchivos(archivos);
			// console.log("info.key::", info.key);
			// const nuevoArchivo = new Map(archivos); // Crear una copia del Map actual
			// nuevoArchivo.delete(info.key); // Eliminar la entrada con la clave especificada

			// setArchivos(nuevoArchivo);
			return {
				//archivos: [],
				archivos,
			};
		},
		beforeUpload: (file) => {
			//console.log("ingrese carga de archivo");
			const isArchivoValido = acceptedFileTypes.includes(file.type);
			if (!isArchivoValido) {
				message.error(`${file.name} no es un archivo png, jpeg ó pdf`);
				return isArchivoValido || Upload.LIST_IGNORE;
			} else {
				// setArchivos(file);
				archivos.set(info.key, file);
				setArchivos(archivos);
				return false;
			}
		},
		archivos,
	};

	return (
		<Form.Item
			label={info.label}
			name={["adjuntos", info.key]}
			labelAlign="left"
			//rules={[{ required: info.required }]}
			rules={[
				{
					validator: async (rule, value) => {
						if (
							(archivos.get(info.key) === undefined || archivos.get(info.key) === null) &&
							info.required
						) {
							return Promise.reject("Suba el archivo solicitado");
						}
						return Promise.resolve();
					},
				},
				{ required: info.required },
			]}
		>
			<Upload {...props} maxCount={1}>
				<Button icon={<UploadOutlined />}> Subir </Button>
			</Upload>
		</Form.Item>
	);
};

export default Archivo;
