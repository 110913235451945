import React from "react";
import { useMediaQuery } from "react-responsive";
import { Provider as JotaiProvider } from "jotai";
import { SolicitudPersonalPageWeb,SolicitudPersonalPageMovil } from "./componentes";

const SolicitudPersonalPage = () => {
    const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
    const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });
    return (
        <JotaiProvider>
            {esMovil &&  <SolicitudPersonalPageMovil/>}
            {esDesktop && <SolicitudPersonalPageWeb/>} 
        </JotaiProvider>
    )
    
}
export default SolicitudPersonalPage;

