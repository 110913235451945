import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LoginService from "services/LoginService";
import { useHistory } from "react-router-dom";

const useValidarUrl = () => {

    const location = useLocation();
    let history = useHistory();


    useEffect(() => {
        if (LoginService.getUsuarioAutenticado()?.cambiarClaveAlIniciarSesion
            && location.pathname !== "/cambiarClave") {
            history.push("/cambiarClave?mensaje=es obligatorio el cambio de clave")
        }
    }, [location])

    return { location };
};

export default useValidarUrl;
