import React, { useEffect, useContext } from "react";
import { Table, Button } from "antd";
import { useAtom } from "jotai";
import * as atoms from "../BandejaEvaluacionStorage";
import EvaluacionService from "services/EvaluacionService";
import { SecurityContext } from "context/SecurityContextProvider";
import { EstadoAprobacionEvaluacion } from "enum/EstadoAprobacionEvaluacion";
import { ModalEvaluacion } from "./componentes/ModalEvaluacion";
import { useModalEvaluacion } from "./hooks";
import { ModalFirmarEvaluacion } from "./componentes/ModalFirmar";

const TablaPendientes = () => {
	const [evaluacionesPendientes, setEvaluacionesPendientes] = useAtom(atoms.evaluacionesPendientesAtom);
	const { mostrarModal } = useModalEvaluacion();

	const { getUsuarioAutenticado } = useContext(SecurityContext);

	useEffect(() => {
		const cargarDatos = async () => {
			const evaluaciones = await EvaluacionService.listarPorJefeInmediato(
				getUsuarioAutenticado().empresaSeleccionada.id,
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				EstadoAprobacionEvaluacion.PENDIENTE
			);
			setEvaluacionesPendientes(evaluaciones);
		};
		cargarDatos();
	}, []);

	const columnasEvaluacionesPendientes = [
		{
			title: "Periodo",
			dataIndex: "periodo",
			align: "center",
		},
		{
			title: "Numero Documento Identidad",
			dataIndex: ["colaborador", "numeroDocumentoIdentidad"],
			align: "center",
		},
		{
			title: "Nombre Colaborador",
			dataIndex: ["colaborador", "nombreCompleto"],
			align: "center",
		},
		{
			title: "Acciones",
			key: "acciones",
			align: "center",
			render: (registro) => (
				<Button type="link" onClick={() => mostrarModal(registro)}>
					Calificar
				</Button>
			),
		},
	];

	return (
		<React.Fragment>
			<Table
				rowKey={(evaluacion) => evaluacion.id}
				dataSource={evaluacionesPendientes}
				columns={columnasEvaluacionesPendientes}
			/>
			<ModalEvaluacion />
			<ModalFirmarEvaluacion />
		</React.Fragment>
	);
};
export default TablaPendientes;
