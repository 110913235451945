import axios from "axios";
import LoginService from "./LoginService";
import { Headers } from "enum";

const BaseService = axios.create({
	baseURL: process.env.REACT_APP_ENDPOINT,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
	responseType: "json",
});

BaseService.interceptors.request.use(function (config) {
	const token = LoginService.getJwtToken();
	config.headers.Authorization = `Bearer ${token}`;
	config.headers[Headers.ORGANIZACION_ID] =
		LoginService.getUsuarioAutenticado().organizacionId;
	config.headers[Headers.EMPRESA_ID] =
		LoginService.getUsuarioAutenticado().empresaSeleccionada.id;
	config.headers[Headers.TIPO_USUARIO] = "COLABORADOR";
	config.headers[Headers.NUMERO_DOCUMENTO_IDENTIDAD] =
		LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad;

	return config;
});

BaseService.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response && error.response.status === 401) {
			if (
				error.response.data?.error === "token-1" ||
				error.response.data?.error === "token-2" ||
				error.response.data?.error === "token-3" ||
				error.response.data?.error === "token-4"
			) {
				LoginService.cerrarSesion();
				localStorage.setItem("sesionExpirada", "si");
				window.location.href = "/";
			}
		}

		return Promise.reject(error);
	}
);

export { BaseService };
