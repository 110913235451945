import React from "react";
import { useMediaQuery } from "react-responsive";
import { ListaFormulariosWeb } from "./lista-formularios-web";

const MisFormulariosPage = () => {
	//const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

	return <React.Fragment>{esDesktop && <ListaFormulariosWeb />}</React.Fragment>;
};

export default MisFormulariosPage;
