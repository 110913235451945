import React, { useContext } from "react";

import { Menu, Dropdown, Avatar, Space } from "antd";

import { UserOutlined, LogoutOutlined, SettingOutlined } from "@ant-design/icons";

import { SecurityContext } from "../../../../context/SecurityContextProvider";
import { useHistory } from "react-router-dom";
export function MenuPerfil() {
	const { getUsuarioAutenticado, cerrarSesion } = useContext(SecurityContext);
	let history = useHistory();
	const menu = (
		<Menu className="antd-pro-components-global-header-index-menu" selectedKeys={[]} onClick={onMenuClick}>
			<Menu.Item key="perfil" className="texto-menu-perfil">
				<Space>
					<UserOutlined />
					Ver Perfil
				</Space>
			</Menu.Item>

			<Menu.Item key="clave" className="texto-menu-perfil">
				<Space>
					<SettingOutlined />
					Cambiar Clave
				</Space>
			</Menu.Item>

			<Menu.Divider />

			<Menu.Item key="logout" className="texto-menu-perfil">
				<Space>
					<LogoutOutlined />
					Cerrar Sesión
				</Space>
			</Menu.Item>
		</Menu>
	);

	function onMenuClick(event) {
		const { key } = event;

		if (key === "logout") {
			return cerrarSesion();
		} else if (key === "clave") {
			return cambiarClave();
		} else if (key === "perfil") {
			return perfilColaborador();
		}
	}
	function cambiarClave() {
		history.push("/cambiarClave");
	}
	const perfilColaborador = () => {
		history.push("/perfil");
	};

	return (
		<Dropdown overlay={menu}>
			<Space>
				<Avatar style={{ backgroundColor: "#1678c1", color: "#fff" }} icon={<UserOutlined />} size="small" />
				<span className="texto-menu-perfil">{getUsuarioAutenticado().nombreCompleto}</span>
			</Space>
		</Dropdown>
	);
}
