import React from "react";
import { FileImageFilled, FilePdfFilled, FileWordOutlined } from '@ant-design/icons';
import { Card, Modal, Row, Typography } from "antd";

export function SeleccionarTipoCarga({ setSeleccionado, setIsImage, documentoSeleccionado, setIsWord, setMostrar }) {
    const { Meta } = Card
    const { Title } = Typography;

    const gridStyle = {
        width: '50%',
        textAlign: 'center',
        color: "#1c74b3"
    };

    const textStyle = {
        //color: "#1c74b3",
        fontSize: '12px'
    };

    return (
        <div style={{padding: "70px 50px"}}>
            <Row>
                {(!documentoSeleccionado.requiereFormatoCarga
                    || (documentoSeleccionado.requiereFormatoCarga
                        && documentoSeleccionado.formatosCarga.includes("imagen")))
                    &&
                    <Card
                        hoverable
                        style={gridStyle}
                        cover={<FileImageFilled style={{ fontSize: '50px', marginTop: "20px" }} />}
                        onClick={() => { setSeleccionado(true); setIsImage(true); setMostrar(false) }}
                    >
                        <Meta title={<Title style={textStyle} > Subir Imagen</Title>} />
                    </Card>
                }
                {(!documentoSeleccionado.requiereFormatoCarga
                    || (documentoSeleccionado.requiereFormatoCarga
                        && documentoSeleccionado.formatosCarga.includes("pdf")))
                    &&
                    <Card
                        hoverable
                        style={gridStyle}
                        cover={<FilePdfFilled style={{ fontSize: '50px', marginTop: "20px" }} />}
                        onClick={() => { setSeleccionado(true); setIsImage(true); setIsImage(false); setMostrar(false) }}
                    >
                        <Meta title={<Title style={textStyle} >Subir PDF</Title>} />
                    </Card>
                }

                {documentoSeleccionado.requiereFormatoCarga
                    && documentoSeleccionado.formatosCarga.includes("word")
                    && <Card
                        hoverable
                        style={gridStyle}
                        cover={ <FileWordOutlined style={{ fontSize: '50px', marginTop:"20px" }} />}
                        onClick={() => { setSeleccionado(true); setIsImage(false); setIsWord(true); setMostrar(false) }} 
                    >
                        <Meta title={<Title style={textStyle} >Subir WORD</Title>} />
                    </Card> 
                }
            </Row>
        </div>
    )

}