import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Space, notification } from "antd";
import { ListaTareasPendientes } from "./componentes";
import { SubirDocumento, VisorFormulario } from "components/common";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import {
	datosFormularioAtom,
	formInstanceAtom,
	tareaPendienteSeleccionadaAtom,
	tareaSeleccionadaIdAtom,
	tareasPendientesAtom,
	visibleModalFirmarAtom,
} from "pages/tareas-pendientes/TareasPendientesStorage";

import styled from "@emotion/styled";
import { FormProvider, useForm } from "react-hook-form";
import { TareasPendientesService } from "services";
import { Plataforma } from "enum/Plataforma";
import { ModalFirmar } from "../common/componentes";
import { TipoTarea } from "enum";
import produce from "immer";

import {
	ExclamationCircleOutlined
} from "@ant-design/icons"

const TareasPendientesPageWeb = () => {
	const setFormInstance = useSetAtom(formInstanceAtom);
	const frmVisor = useForm();

	useEffect(() => {
		setFormInstance(frmVisor);
	}, []);

	return (
		<Container>
			<ListaTareasPendientes />
			<ContainerVisor>
				<VisorDocumento />
				<CargarDocumento />
				<AprobarDocumento />
			</ContainerVisor>
			<ModalFirmar />
		</Container>
	);
};

const CargarDocumento = () => {
	const [tareaPendienteSeleccionada, setTareaPendienteSeleccionada] = useAtom(tareaPendienteSeleccionadaAtom);
	const setTareasPendientes = useSetAtom(tareasPendientesAtom);
	const setTareaSeleccionadaId = useSetAtom(tareaSeleccionadaIdAtom);

	if (!tareaPendienteSeleccionada || tareaPendienteSeleccionada.tipo !== TipoTarea.CARGA_DOCUMENTO) {
		return "";
	}

	const eliminarTareaPendiente = () => {
		setTareasPendientes(
			produce((draft) => {
				const index = draft.findIndex((tarea) => tarea.id === tareaPendienteSeleccionada.id);
				draft.splice(index, 1);
			})
		);
		setTareaSeleccionadaId(undefined);
		setTareaPendienteSeleccionada(undefined);
	};

	return (
		<SubirDocumento
			tareaSeleccionada={tareaPendienteSeleccionada}
			onSuccess={eliminarTareaPendiente}
		/>
	)
}

const VisorDocumento = () => {
	const tareaPendienteSeleccionada = useAtomValue(tareaPendienteSeleccionadaAtom);
	const formInstance = useAtomValue(formInstanceAtom);
	const setDatosFormulario = useSetAtom(datosFormularioAtom);
	const setVisibleModalFirmar = useSetAtom(visibleModalFirmarAtom);


	const onSubmit = async (datosFormulario) => {
		setDatosFormulario({
			tareaPendienteId: tareaPendienteSeleccionada.id,
			datos: datosFormulario,
			plataforma: Plataforma.WEB,
		});
		setVisibleModalFirmar(true);
	};

	const onClickBtnFirmar = () => {
		formInstance.handleSubmit(onSubmit)();
	};

	if (!tareaPendienteSeleccionada || tareaPendienteSeleccionada.tipo !== TipoTarea.DETALLE_ENVIO) {
		return "";
	}

	return (
		<>
			<Header>
				<Acciones>
					<Button type="primary" onClick={onClickBtnFirmar}>
						Firmar Documento
					</Button>
				</Acciones>
			</Header>
			<Body withHeader={tareaPendienteSeleccionada ? true : false}>
				<FormProvider {...formInstance}>
					<VisorFormulario
						type={"form"}
						urlPdf={tareaPendienteSeleccionada?.urlPlantillaPdfFirmada}
						form={formInstance}
						componentes={tareaPendienteSeleccionada?.componentes}
						variablesGlobales={tareaPendienteSeleccionada?.variablesGlobales}
						datosFormulario={tareaPendienteSeleccionada?.datosFormulario}
						urlFirmaHolografa={tareaPendienteSeleccionada?.urlFirmaHolografa}
						urlHuellaDactilar={tareaPendienteSeleccionada?.urlHuellaDactilar}
					/>
				</FormProvider>
			</Body>
		</>
	)
}

const AprobarDocumento = () => {
	const setTareasPendientes = useSetAtom(tareasPendientesAtom);
	const setTareaSeleccionadaId = useSetAtom(tareaSeleccionadaIdAtom);
	// const tareaPendienteSeleccionada = useAtomValue(tareaPendienteSeleccionadaAtom);
	const [tareaPendienteSeleccionada, setTareaPendienteSeleccionada] = useAtom(tareaPendienteSeleccionadaAtom);
	// const formInstance = useAtomValue(formInstanceAtom);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();


	const eliminarTareaPendiente = () => {
		setTareasPendientes(
			produce((draft) => {
				const index = draft.findIndex((tarea) => tarea.id === tareaPendienteSeleccionada.id);
				draft.splice(index, 1);
			})
		);
		setTareaSeleccionadaId(undefined);
		setTareaPendienteSeleccionada(undefined);
	};

	const onClickBtnAprobar = () => {
		Modal.confirm({
			title: '¿Está seguro de aprobar el documento?',
			icon: <ExclamationCircleOutlined />,
			// content: 'Some descriptions',
			okText: "Si, Aprobar!",
			cancelButtonProps: { disabled: loading },
			onOk: async () => {
				console.log("tarea", tareaPendienteSeleccionada);

				setLoading(true);

				await TareasPendientesService.aprobarDocumento({
					tareaId: tareaPendienteSeleccionada.id,
					detalleEnvioId: tareaPendienteSeleccionada.procesoId,
				});

				eliminarTareaPendiente();

				notification.success({
					message: "Documento Aprobado",
					description: "Se realizó la acción correctamente.",
				});

				setLoading(false);
				// setLoading(false);
			},
		});
	}

	const onClickBtnRechazar = () => {
		Modal.confirm({
			title: '¿Está seguro de rechazar el documento?',
			icon: <ExclamationCircleOutlined />,
			content:
				<Form form={form} layout="vertical">
					<Form.Item name="motivo" label="Ingrese un motivo" rules={[{ required: true, message: "Ingrese un motivo." }]}>
						<Input.TextArea />
					</Form.Item>
				</Form>,
			okText: "Si, Rechazar!",
			cancelButtonProps: { disabled: loading },
			onOk: async () => {

				return form.validateFields()
					.then(async (datosFormulario) => {
						setLoading(true);

						await TareasPendientesService.rechazarDocumento({
							tareaId: tareaPendienteSeleccionada.id,
							detalleEnvioId: tareaPendienteSeleccionada.procesoId,
							...datosFormulario
						});

						eliminarTareaPendiente();

						notification.success({
							message: "Documento Rechazado",
							description: "Se realizó la acción correctamente.",
						});

						setLoading(false);
					})
					.catch((info) => {
						console.log("Validate Failed:", info);
						// return Promise.reject();
						return new Promise((resolve, reject) => reject());
					});



			},
		});
	}

	if (!tareaPendienteSeleccionada || tareaPendienteSeleccionada.tipo !== TipoTarea.APROBACION) {
		return "";
	}

	return (
		<>
			<Header>
				<Acciones>
					<Space>
						<Button type="primary" onClick={onClickBtnAprobar}>
							Aprobar
						</Button>
						<Button type="danger" onClick={onClickBtnRechazar}>
							Rechazar
						</Button>
					</Space>
				</Acciones>
			</Header>
			<Body withHeader={tareaPendienteSeleccionada ? true : false}>
				<VisorFormulario
					type="pdf"
					urlPdf={tareaPendienteSeleccionada?.urlPlantillaPdfFirmada}
					componentes={tareaPendienteSeleccionada?.componentes}
				/>
			</Body>
		</>
	)
}

const Container = styled.div`
	display: flex;
	flex-wrap: "wrap";
	justify-content: space-between;
	height: calc(100vh - 130px);
`;

const ContainerVisor = styled.div`
	flex: 1 1 70%;
	margin: 10px;
`;

const Header = styled.div`
	min-height: 50px;
	max-height: 50px;
	display: flex;
	background: white;
	padding: 0px 10px;
	border: 1px solid #ccc;
	border-bottom: 0px;
`;

// const Body = styled.div`
// 	height: calc(100% - 50px);
// `;

const Body = styled.div`
	height: ${(props) => (props.withHeader === true ? "calc(100% - 50px)" : "100%")};
`;

const Acciones = styled.div`
	flex: 1 1 50%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export default TareasPendientesPageWeb;
