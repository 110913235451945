import { useEffect } from "react";
import { Form } from "antd";
import { useAtom } from "jotai";
import { frmPlanAccionAtom } from "../../BandejaEvaluacionStorage";

const usePlanAccion = () => {
	const [frmPlanAccion, setFrmPlanAccion] = useAtom(frmPlanAccionAtom);
	const [form] = Form.useForm();

	useEffect(() => {
		if (!frmPlanAccion) {
			setFrmPlanAccion(form);
		}
	}, []);

	return { frmPlanAccion };
};

export default usePlanAccion;
