import React, { Fragment, useContext, useEffect, useState } from "react";
import { FileImageFilled, FilePdfFilled } from "@ant-design/icons";
import { Button, Modal, notification, Upload, Form } from "antd";
import ImgCrop from "antd-img-crop";
import { MisDocumentosContext } from "context/v3/MisDocumentosContext";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { Headers } from "enum";
import LoginService from "services/LoginService";
import { useMediaQuery } from "react-responsive";

export function CargarImagen({ onCerrarModal }) {
	const [fileList, setFileList] = useState([]);
	const [uidList, setUidList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const { documentoSeleccionadoId, getDocumentos } = useContext(MisDocumentosContext);
	const [texto, setTexto] = useState("");
	const datos = {
		detalleEnvioId: documentoSeleccionadoId,
		uid: texto,
	};
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });

	const props = {
		name: "file",
		multiple: true,
		action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/cargarDocumentos",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
		},
		data: datos,
		beforeUpload(file) {
			setTexto(file.uid);
			return file;
		},
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const onChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
	};

	const onClickCargarDocumento = async () => {
		setUploading(true);
		const datosSubida = {
			listaUids: fileList.map((c) => c.uid),
			detalleEnvioId: documentoSeleccionadoId,
			tipoCarga: "IMAGEN",
		};
		const data = await PortalColaboradorService.generarPDFArchivosSubidos(datosSubida);
		if (data === true) {
			notification.success({
				description: "Documento Cargado",
				message: "Documento Cargado Correctamente",
			});
			getDocumentos();
			onCerrarModal();
		} else
			notification.error({
				description: "Error al cargar",
				message: "Ha ocurrido un error",
			});
		setUploading(false);
	};

	const handleCancel = () => setPreviewVisible(false);

	return (
		<React.Fragment>
			<ImgCrop rotate minZoom={0.5} aspect={2 / 1} cropperProps={{ restrictPosition: false }} grid>
				<Upload
					{...props}
					listType="picture-card"
					fileList={fileList}
					onChange={onChange}
					onPreview={handlePreview}
					accept={esMovil ? "application/pdf" : undefined}
				>
					{"+ Subir"}
				</Upload>
			</ImgCrop>

			<Button
				type="primary"
				onClick={onClickCargarDocumento}
				disabled={fileList.length === 0}
				block
				loading={uploading}
				style={{
					marginTop: 16,
				}}
			>
				{uploading ? "Cargando" : "Enviar Documentos"}
			</Button>
			<Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} destroyOnClose>
				<img
					alt="example"
					style={{
						width: "100%",
					}}
					src={previewImage}
				/>
			</Modal>
		</React.Fragment>
	);
}
