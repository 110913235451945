import React from "react";
import { Modal,Form,DatePicker,Input,Button,Col,Row } from "antd";
import { useModalPlanAccion } from "../../hooks";
import { useEffect } from "react";
import { useAtom } from "jotai";

const ModalActualizarPlanAccion = () => {
    const { visibleModalPlanAccion, cerrarModal, onFinishActualizarPlanAccion } = useModalPlanAccion();  
    const [formPlanAccion] = Form.useForm(); 
         
    const onClickAfterClose = () => {
        formPlanAccion.resetFields();
    }
    return (
        <Modal
            title="Actualizar Plan de Acción"
            visible={visibleModalPlanAccion}
            onCancel={cerrarModal}
            okText="Actualizar"
            onOk={()=>formPlanAccion.submit()}
            afterClose={onClickAfterClose}
        >
            
            <Form
                form={formPlanAccion}
                onFinish={onFinishActualizarPlanAccion}
                layout="vertical"
                // initialValues={{
                //     fechaEjecucion: undefined,
                //     observacion:"1",
                // }}
            >                               
                <Form.Item
                    label="Fecha Ejecución"
                    name="fechaEjecucion"
                    rules={[{
                        required:true,
                        message:"Selecciona la fecha ejecución "
                    }]}
                >
                          <DatePicker/>
                </Form.Item>
                   
                <Form.Item label="Observación" name="observacion">
                      <Input.TextArea rows={4}/>
               </Form.Item>               
                               
            </Form>

        </Modal>
    )
    
}
export default ModalActualizarPlanAccion;