import React from "react";
import { OnboardingPageMovil, OnboardingPageWeb } from "./componentes";
import { useMediaQuery } from "react-responsive";
import { Provider as JotaiProvider } from "jotai";

const OnboardingPage = () => {
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

	return (
		<JotaiProvider>
			{esMovil && <OnboardingPageMovil />}
			{esDesktop && <OnboardingPageWeb />}
		</JotaiProvider>
	);
};

export default OnboardingPage;
