import React, { useRef, useEffect } from "react";
import { Modal, Button, Spin } from "antd";
import { useAtom, useSetAtom, useAtomValue } from "jotai";
import { componentSelectedAtom, signatureModalVisibleAtom, signaturesMetadataAtom } from "../../VisorFormularioStorage";
import SignaturePad from "react-signature-pad-wrapper";
import produce from "immer";
import { useState } from "react";

const SignatureModal = () => {
	const [visible, setVisible] = useAtom(signatureModalVisibleAtom);
	const setSignaturesMetadata = useSetAtom(signaturesMetadataAtom);
	const signaturesMetadata = useAtomValue(signaturesMetadataAtom);
	const componentSelected = useAtomValue(componentSelectedAtom);
	const refSignature = useRef();
	const [loading, setLoading] = useState(true);

	const onClickBtnOk = () => {
		setSignaturesMetadata(
			produce((draft) => {
				const signature = draft.find((s) => s.key === componentSelected.key);
				let base64 = refSignature.current.isEmpty() ? undefined : refSignature.current.toDataURL();
				if (signature) {
					signature.base64 = base64;
				} else {
					draft.push({
						key: componentSelected.key,
						base64: base64,
					});
				}
			})
		);
		setVisible(false);
	};

	useEffect(() => {
		const cargarFirma = () => {
			if (visible) {
				setTimeout(() => {
					setLoading(false);
					if (signaturesMetadata[0]?.base64) {
						refSignature.current.fromDataURL(signaturesMetadata[0]?.base64);
					}
				}, 1000);
			}
		};
		cargarFirma();
	}, [visible]);

	const onClickBtnCancelar = () => {
		setVisible(false);
	};

	const onClickBtnLimpiarFirma = () => {
		refSignature.current.clear();
	};

	return (
		<Modal
			title="Firmar"
			open={visible}
			okText="Aceptar"
			cancelText="Cancelar"
			onOk={onClickBtnOk}
			onCancel={onClickBtnCancelar}
			centered
			maskClosable={false}
			afterClose={() => setLoading(true)}
		>
			<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
				{loading && <Spin />}
				{!loading && (
					<>
						<SignaturePad
							ref={refSignature}
							canvasProps={{ style: { border: "1px dashed #ccc" } }}
							options={{ minWidth: 2, maxWidth: 3, penColor: "#000" }}
						// redrawOnResize
						/>
						<Button type="link" onClick={onClickBtnLimpiarFirma}>
							Limpiar Firma
						</Button>
					</>
				)}
			</div>
		</Modal>
	);
};

export default SignatureModal;
