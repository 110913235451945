import React from "react";
//import { PanelInformacion } from "./PanelInformacion"
//import { PanelBotones } from "./PanelBotones"
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FilePdfOutlined, CheckCircleOutlined } from "@ant-design/icons"
import { useContext } from "react";
import { MisDocumentosContext } from "../../../../../../../../context/MisDocumentos/MisDocumentosContext";
import { SecurityContext } from "context/SecurityContextProvider";
import { saveAs } from "file-saver";
import { useModalVisorPdf } from "store/global";

export function Header() {
	//const { documentoSeleccionado, cerrarVisorPdf } = useContext(MisDocumentosContext)
	const titulo = useModalVisorPdf(state => state.titulo);
	const loading = useModalVisorPdf((state) => state.loading);
	const imprimirPdf = useModalVisorPdf((state) => state.imprimirPdf);
	const descargarPdf = useModalVisorPdf((state) => state.descargarPdf);
	const cerrarVisorPdf = useModalVisorPdf((state) => state.cerrarModal);
	
    function BotonCerrar() {
	//const { cerrarVisorPdf } = useContext(MisDocumentosContext);
    
	return (
		<React.Fragment>
			<div className="visor-pdf-header-separador" />
			<Button type="text" className="cerrar" onClick={cerrarVisorPdf}>
				<CloseOutlined style={{ fontSize: "16px" }} />
			</Button>
		</React.Fragment>
	);
	}
	
    function BotonesEstadoFirmado() {
	//const { loadingPdf, blobPdf, documentoSeleccionado } = useContext(MisDocumentosContext);
	//const { getUsuarioAutenticado } = useContext(SecurityContext);

	// function onClickBtnImprimir() {
	// 	window.open(URL.createObjectURL(blobPdf)).print();
	// }

	// function onClickBtnDescargar() {
	// 	const nombreDocumento = documentoSeleccionado.nombreDocumento.replace(" ", "-");
	// 	const documentoIdentidad = getUsuarioAutenticado().numeroDocumentoIdentidad;
	// 	const nombreDescarga = nombreDocumento + "_" + documentoIdentidad + ".pdf";
	// 	saveAs(blobPdf, nombreDescarga);
	// }

	return (
		<React.Fragment>
			<Button className="visor-pdf-header-botones" disabled={loading} onClick={imprimirPdf}>
				Imprimir
			</Button>
			<Button className="visor-pdf-header-botones" disabled={loading} onClick={descargarPdf}>
				Descargar
			</Button>
		</React.Fragment>
	);
}
    return (
        <div className="visor-pdf-header">
            <div className="informacion">
            <FilePdfOutlined className="icono" />
            <div className="texto">
                {/* <span>                   
                    <span className="documento">{documentoSeleccionado?.nombreDocumento}</span>
					</span> */}
					{titulo}
					
                
            </div>
            </div>
            <div className="acciones">
						
				<BotonesEstadoFirmado />	

			    <BotonCerrar onClick={cerrarVisorPdf} />
		</div>
        </div>
    )

}