import React, { useEffect,useState} from "react";
import PerfilService from "services/reclutamiento-y-seleccion/PerfilService";
const useListarPerfil = () => {
    const [perfiles, setPerfiles] = useState([]);

    useEffect(() => {
        const cargarDatos = async () => {
            const respuesta = await PerfilService.listar();
            //console.log("perfiles:", respuesta);
            setPerfiles(respuesta);
        }
        cargarDatos();
    }, []);

    return{perfiles}
}
export default useListarPerfil;