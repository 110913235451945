import { SecurityContext } from "context/SecurityContextProvider";
import { useContext } from "react";
import {
	osVersion,
	osName,
	fullBrowserVersion,
	browserVersion,
	browserName,
	mobileVendor,
	mobileModel,
	engineName,
	engineVersion,
} from "react-device-detect";
import { useParams } from "react-router-dom";
import { LogVisorPdfService } from "services/LogVisorPdfService";

const useLogging = () => {
	const { documentoId } = useParams();
	const { getUsuarioAutenticado } = useContext(SecurityContext);

	const insertarLogErrorCargaPdf = (error) => {
		LogVisorPdfService.insertar({
			organizacionId: getUsuarioAutenticado().organizacionId,
			empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
			colaboradorId: getUsuarioAutenticado().id,
			colaboradorNumeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
			colaboradorNombreCompleto: getUsuarioAutenticado().nombreCompleto,
			detalleEnvioId: documentoId,
			sistemaOperativoVersion: osVersion,
			sistemaOperativoNombre: osName,
			navegadorWebVersion: browserVersion,
			navegadorWebVersionCompleta: fullBrowserVersion,
			navegadorWebNombre: browserName,
			movilFabricante: mobileVendor,
			movilModelo: mobileModel,
			engineVersion: engineVersion,
			engineName: engineName,
			error: error?.message,
		});
	};

	return { insertarLogErrorCargaPdf };
};

export default useLogging;
