import { HttpService } from "../HttpService";
import { URL } from "enum";

const URL_API = URL.RECLUTAMIENTO_Y_SELECCION;

const PerfilService = {
    async insertar(perfil){
        const respuesta= await HttpService(URL_API).post("/perfilSolicitudPersonal",perfil);
        return respuesta.data;
    },
    async listar(){
        const respuesta = await HttpService(URL_API).get("/perfilSolicitudPersonal");
        return respuesta.data;
    },
    async eliminar(perfilId){
        const respuesta = await HttpService(URL_API).delete(`/perfilSolicitudPersonal/${perfilId}`);
        return respuesta.data;
    },
    async buscarPorId(perfilId) {
        const respuesta = await HttpService(URL_API).get(`/perfilSolicitudPersonal/${perfilId}`);
        return respuesta.data;
    }


}
export default PerfilService;