export const TipoControl = {
	ETIQUETA: "etiqueta",
	CAJA_TEXTO: "cajatexto",
	LISTA: "lista",
	CHECKBOX: "checkbox",
	RADIO_BUTTON: "radiobutton",
	SIGNATURE: "signature",
	IMAGEN: "imagen",
	FECHA: "fecha",
};
