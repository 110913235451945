import React from "react";
import { FilePdfOutlined, CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styled from "@emotion/styled";

const Header = ({ titulo, botonesExtra, onCerrar }) => {
  return (
    <div className="milegajo-visor-pdf-header">
      <Titulo titulo={titulo} />
      <Botones botonesExtra={botonesExtra} onCerrar={onCerrar} />
    </div>
  );
};

const Titulo = ({ titulo }) => {
  return (
    <div className="informacion">
      <FilePdfOutlined className="icono" />
      <div className="texto" style={{ fontSize: "14px" }}>
        {titulo}
      </div>
    </div>
  );
};

const Botones = ({ botonesExtra, onCerrar }) => {
  return (
    <div className="acciones">
      {botonesExtra}
      <Separador />
      <Button type="text" className="cerrar" onClick={onCerrar}>
        <CloseOutlined style={{ fontSize: "16px" }} />
      </Button>
    </div>
  );
};

const Separador = styled.div`
  width: 1px;
  display: inline-block;
  height: 32px;
  background: rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
  margin: 0 8px;
`;

export default Header;
