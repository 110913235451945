import React, { useState, useEffect, useContext } from "react";
import {
	Form,
	Input,
	Row,
	Col,
	Button,
	Space,
	Modal,
	notification,
	message,
	Alert, Popover, Typography
} from "antd";
import { ContainerCrear } from "../../../components/ContainerCrear";
import FormItem from "antd/lib/form/FormItem";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import LoginService from 'services/LoginService';
import GestionarClaveService from "../../../services/GestionarClaveService";
import ActualizarClaveService from "../../../services/ActualizarClaveService";
import ModalGenerarCodigo from "./modalGenerarCodigo";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { VersionApiColaborador } from "enum/VersionApiColaborador";
import { useMediaQuery } from "react-responsive";

const host = window.location.host;
const subdominio = host.split(".")[0];
const { Link } = Typography;
export function CambiarClavePage() {
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const [listaCorreos, setListaCorreos] = useState([]);
	const [correoSeleccionado, setCorreoSeleccionado] = useState("");
	const [defaultValorCorreo, setDefaultValorCorreo] = useState("");

	const [visibleModal, setVisibelModal] = useState(false);
	const [formularioCambiarClave] = Form.useForm();
	const { getVersionApiColaborador, getUsuarioAutenticado } = useContext(
		SecurityContext
	);
	const [loading, setLoading] = useState(false);
	let history = useHistory();
	const [mensajeError, setMensajeError] = useState("");
	const [politicasActivas, setPoliticasActivas] = useState([]);
	useEffect(() => {
		async function cargarDatos() {
			const datos = await GestionarClaveService.obtenerPoliticas(LoginService.getUsuarioAutenticado().subdominio);
			setPoliticasActivas(datos);
		}
		cargarDatos();
	}, []);

	function OnModal(correo) {
		Modal.info({
			title: "Generar código de verificación Clave",
			content: (
				<div>
					<p>
						Se le enviará el código de seguridad al correo:{" "}
						<span>{correo}</span>
					</p>
				</div>
			),
			onOk() {
				handleOnOKGenerarCodigo(correo);
			},
		});
	}

	async function handleOnOKGenerarCodigo(correo) {
		try {
			await GestionarClaveService.crearCodigoSeguridad(
				correo,
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				subdominio
			);
			notification.success({
				message: "Operación exitosa",
				description: "Se envío el codigo de seguridad correctamente.",
			});
		} catch (error) {
			console.error(error.response);
			console.error(error.response.data.mensaje);
			notification.error({
				message: "No se puede enviar el codigo de seguridad",
				description: "Error-> " + error.response.data.mensaje,
			});
		}
	}

	async function handleOnFinish(formulario) {
		try {
			setLoading(true);
			const mensajeValidacion = await ActualizarClaveService.cambiarClave(
				// subdominio,
				getUsuarioAutenticado().subdominio,
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				formulario.clave,
				formulario.claveActual
			);
			let mensaje = (mensajeValidacion != null && mensajeValidacion != "") ? mensajeValidacion.message : null;
			setMensajeError(mensaje);
			setLoading(false);
			if (mensaje == null || mensaje == "") {

				LoginService.actualizarCambiarClaveAlIniciarSesion(false);

				notification.success({
					message: "Operación exitosa",
					description: "Se actualizo su clave.",
				});

				if (getVersionApiColaborador() === VersionApiColaborador.v1) {
					history.push("/bandejaEntrada");
				} else if (getVersionApiColaborador() === VersionApiColaborador.v2) {
					history.push("/mis-documentos");
				} else if (getVersionApiColaborador() === VersionApiColaborador.v3) {
					history.push("/v3/mis-pendientes");
				} else {
					history.push("/bandejaEntrada");
				}
			}

		} catch (error) {
			setLoading(false);
			console.error(error.response);
			console.error(error.response.data.mensaje);
			notification.error({
				message: "No se puede guardar la clave",
				description: "Error-> " + error.response.data.mensaje,
			});
		}
	}
	function handleOnClickCancelar() {
		history.push("/legajo/listar");
	}
	function onCerrarModal() {
		setVisibelModal(false);
	}

	function handleOk() {
		handleOnOKGenerarCodigo(correoSeleccionado);
	}

	function handleOnChangeCorreo(value) {
		setCorreoSeleccionado(value);
	}

	async function handleOnClickGenerarCodigo() {
		if (
			getUsuarioAutenticado().numeroDocumentoIdentidad === undefined ||
			getUsuarioAutenticado().numeroDocumentoIdentidad === ""
		) {
			message.error("Número Documento Identidad no existe");
		} else {
			const numeroDocumentoIdentidad = getUsuarioAutenticado()
				.numeroDocumentoIdentidad;
			const correos = await GestionarClaveService.buscarCorreo(
				subdominio,
				numeroDocumentoIdentidad
			);

			if (correos.length === 1) {
				OnModal(correos[0]);
			} else if (correos.length > 1) {
				setDefaultValorCorreo(correos[0]);
				setCorreoSeleccionado(correos[0]);
				setListaCorreos(correos);
				setVisibelModal(true);
			}
		}
	}

	const content = (
		<div>
			{politicasActivas
				&& politicasActivas.length > 0
				&& politicasActivas.map((politica, index) => (
					<p key={index}>{politica}</p>
				))}
		</div>
	);

	return (
		// <ContainerCrear titulo="Cambiar Clave" colSpan={12}>
		<div style={{
			padding: esMovil ? "20px 40px" : "40px 120px",
			background: "white"
		}}>
			{mensajeError && (
				<Alert
					style={{
						marginBottom: 24,
					}}
					message={mensajeError}
					type="error"
					showIcon
				/>
			)}
			<div style={{ textAlign: "center" }}>
				<Typography.Title level={4}>Cambiar Clave</Typography.Title>
			</div>
			<Form
				form={formularioCambiarClave}
				labelCol={{
					span: 12,
				}}
				wrapperCol={{
					span: 12,
				}}
				layout="vertical"
				size="default"
				onFinish={handleOnFinish}
			>
				<Row align="center">
					<Col span={20}>
						<FormItem
							label="Clave Actual"
							name="claveActual"
							rules={[
								{
									required: true,
									message: "Ingrese su clave actual",
								},
							]}
						>
							<Input.Password />
						</FormItem>
					</Col>
				</Row>
				<Row align="center">
					<Col span={20}>
						<FormItem
							label="Clave"
							name="clave"
							rules={[
								{
									required: true,
									message: "Ingrese su clave",
								},
							]}
						>
							<Input.Password />
						</FormItem>
					</Col>
				</Row>
				<Row align="center">
					<Col span={20}>
						<FormItem
							label="Repetir Clave"
							name="claveR"
							dependencies={["clave"]}
							hasFeedback
							rules={[
								{
									required: true,
									message: "Ingrese nuevamente su clave",
								},
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (
											!value ||
											getFieldValue("clave") === value
										) {
											return Promise.resolve();
										}

										return Promise.reject(
											"Las claves deben ser iguales!"
										);
									},
								}),
							]}
						>
							<Input.Password />
						</FormItem>
					</Col>
				</Row>

				{/* <Row>
          <FormItem label="Código">
            <Row gutter={8}>
              <Col span={12}>
                <FormItem
                  name="codigo"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese código",
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingrese código"
                    autoComplete="off"
                  ></Input>
                </FormItem>
              </Col>
              <Col span={8}>
                <Button onClick={handleOnClickGenerarCodigo}>
                  Solicitar código
                </Button>
              </Col>
            </Row>
          </FormItem>
        </Row> */}
				<Row align="center">
					<Col span={20}>
						{politicasActivas != null &&
							<div style={{ paddingBottom: "10px" }}>
								<Popover content={content} title="Políticas de la clave">
									<Link>Políticas de la clave</Link>
								</Popover>
							</div>

						}
					</Col>

				</Row>
				<Row justify="center">
					<Space>
						<Col span={20}>
							<Button
								type="primary"
								icon={<CheckOutlined />}
								htmlType="submit"
								loading={loading}
							>
								Guardar
							</Button>
						</Col>
						<Col>
							<Button
								type="default"
								onClick={handleOnClickCancelar}
							>
								Cancelar
							</Button>
						</Col>
					</Space>
				</Row>
			</Form>
			<ModalGenerarCodigo
				visibleModal={visibleModal}
				oncerrarModal={onCerrarModal}
				handleOk={handleOk}
				defaultValorCorreo={defaultValorCorreo}
				handleOnChangeCorreo={handleOnChangeCorreo}
				listaCorreos={listaCorreos}
			/>
		</div>
		// </ContainerCrear>
	);
}
