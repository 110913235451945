import React, { useState, useEffect, useContext, useRef } from "react";
import { Container } from "../../../components/Container";
import { UnorderedListOutlined, SearchOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { Table, Space, notification, Button, Input, Tag } from "antd";
import LegajoService from "../../../services/LegajoService";
import { ModalPDF } from "../../../components/PDFViewer";
import Highlighter from "react-highlight-words";

export function LegajoListarNoVisualizadosPage() {
	const [legajo, setLegajo] = useState([]);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [url, setUrl] = useState("");
	const [visible, setVisible] = useState("");
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const searchInput = useRef(null);
	// const [selectedRowKeys, setSelectedRowsKeys] = useState([]);
	// const [selectionType, setSelectionType] = useState("checkbox");
	const [loading, setLoading] = useState("");
	const [filaSeleccionada, setFilaSeleccionada] = useState("");

	useEffect(() => {
		async function cargarDatos() {
			/*  const legajos = await LegajoService.listar(
        getUsuarioAutenticado().numeroDocumentoIdentidad
      ); */
			const legajos = await LegajoService.listarPorVisualizados(
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				getUsuarioAutenticado().empresaSeleccionada.id,
				"No"
			);
			setLegajo(legajos);
		}

		cargarDatos();
	}, []);

	var adicionarColumnasFiltro = function getColumns(dataIndex, placeholder, propiedad) {
		return {
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
				<div style={{ padding: 8 }}>
					<Input
						ref={searchInput}
						placeholder={placeholder !== undefined ? placeholder : `Buscar por ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
						onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Space>
						<Button
							type="primary"
							onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
							icon={<SearchOutlined />}
							size="small"
							style={{ width: 90 }}
						>
							Buscar
						</Button>
						<Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
							Limpiar
						</Button>
					</Space>
				</div>
			),
			filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
			onFilter: (value, record) =>
				propiedad === undefined
					? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
					: record[dataIndex][propiedad].toString().toLowerCase().includes(value.toLowerCase()),
			onFilterDropdownVisibleChange: (visible) => {
				if (visible) {
					setTimeout(() => searchInput.current.select());
				}
			},
			render: (text) =>
				searchedColumn === dataIndex ? (
					<Highlighter
						highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={propiedad === undefined ? text.toString() : text[propiedad].toString()}
					/>
				) : propiedad === undefined ? (
					text
				) : (
					text[propiedad]
				),
		};
	};

	function handleSearch(selectedKeys, confirm, dataIndex) {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	}

	function handleReset(clearFilters) {
		clearFilters();
		setSearchText("");
	}

	async function handleOnClickVerDocumento(detalleEnvioId, visualizado) {
		try {
			setLoading(true);
			setFilaSeleccionada(detalleEnvioId);
			const formData = new FormData();
			formData.append("detalleEnvioId", detalleEnvioId);
			//1 es web
			formData.append("codigoPlataforma", 1);
			formData.append("numeroDocumentoIdentidad", getUsuarioAutenticado().numeroDocumentoIdentidad);

			formData.append("visualizado", visualizado);

			formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);

			let data = await LegajoService.verPDF(formData);
			//0 indica que existen documentos pendientes
			if (data.existe) {
				notification.warning({
					message: "Advertencia",
					description: "Usted aun cuenta con documentos pendientes de visualizar, porfavor revisarlos.",
				});
			} else {
				// //TODO validar esta funcion que crea url
				// const url = window.URL.createObjectURL(
				//   new Blob([data], { type: "application/pdf" })
				// );
				//setUrl(url);
				setUrl(data.urlEncriptada);
				setVisible(true);

				//TODO ver si se llama alcaragr datos o  asi como esta
				//const legajos = await LegajoService.listar(
				//  getUsuarioAutenticado().numeroDocumentoIdentidad
				//); //getUsuarioAutenticado().login
				/*const legajos = await DetalleEnvioService.listar(
        getUsuarioAutenticado().login
      );*/
				//setLegajo(legajos);
				// listarLegajos();
				setLoading(false);
				listarLegajos(detalleEnvioId);
			}
		} catch (error) {
			setLoading(false);
		}
	}

	async function listarLegajos(detalleEnvioId) {
		const detalleEnvio = await LegajoService.buscarPorId(detalleEnvioId);
		let fechaVisualizacion = detalleEnvio.visualizacionFecha;

		setLegajo(
			legajo.map((legajo) => {
				if (legajo.id === detalleEnvioId) {
					return {
						...legajo,
						visualizado: "Si",
						visualizacionFecha: fechaVisualizacion,
					};
				}
				return legajo;
			})
		);
	}

	function handleOnOkModal() {
		setVisible(false);
	}
	const columnas = [
		{
			title: "Documento",
			dataIndex: "envio",
			align: "center",
			...adicionarColumnasFiltro("envio", "Buscar por Tipo Documento", "tipoDocumentoNombre"),
		},
		{
			title: "Descripción",
			dataIndex: "envio",
			align: "center",
			...adicionarColumnasFiltro("envio", "Buscar por Descripción Documento", "descripcion"),
		},
		/*{
      title: "Estado",
      dataIndex: "estadoProcesoNombre",
      align: "center",
      ...adicionarColumnasFiltro("estadoProcesoNombre", "Buscar por Estado"),
    },*/
		{
			title: "Estado",
			dataIndex: "visualizado",
			align: "center",
			// ...adicionarColumnasFiltro("visualizado", "Buscar por visualización"),
			render: function (text) {
				if (text.toLowerCase() === "si") {
					return (
						<Tag icon={<CheckCircleOutlined />} color="green">
							VISUALIZADO
						</Tag>
					);
				} else {
					return <Tag color="red">PENDIENTE</Tag>;
				}
			},
		},
		{
			title: "Fecha Envío",
			dataIndex: "fechaEnvio",
			align: "center",
			...adicionarColumnasFiltro("fechaEnvio", "Buscar por Fecha Envio"),
		},
		{
			title: "Fecha Visualización",
			dataIndex: "visualizacionFecha",
			align: "center",
			...adicionarColumnasFiltro("visualizacionFechau", "Buscar por Fecha Visualización"),
		},
		{
			title: "Acciones",
			key: "acciones",
			align: "center",
			render: (text, fila) => (
				<Space size={8}>
					<Button
						type="link"
						loading={filaSeleccionada === fila.id ? loading : false}
						onClick={() => handleOnClickVerDocumento(fila.id, fila.visualizado)}
					>
						Ver
					</Button>
				</Space>
			),
		},
	];
	/* function start() {
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowsKeys([]);
    }, 1000);
  }*/

	// const rowSelection = {
	//   onChange: (selectedRowKeys, selectedRows) => {
	//     setSelectedRowsKeys(selectedRowKeys);
	//   },
	// };

	return (
		<Container icono={<UnorderedListOutlined />} titulo="Documentos Pendientes">
			<ModalPDF ruta={url} visible={visible} handleOnOkModal={handleOnOkModal} />
			<div style={{ marginBottom: 16 }}></div>
			<Table
				rowKey={(legajo) => legajo.id}
				columns={columnas}
				/*  rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}*/
				dataSource={legajo}
				pagination={{
					pageSize: [10],
				}}
			/>
		</Container>
	);
}
