import { useEffect } from "react";
import { useAtom } from "jotai";
import { detalleEntrevistaAtom, preguntasAtom, visibleModalPreguntasAtom } from "../DetalleEntrevistaStore";
import { EntrevistasService, PreguntasService } from "services/reclutamiento-y-seleccion";
import { useParams } from "react-router-dom";

const useListarPreguntas = () => {
	const { entrevistaId } = useParams();
	const [preguntas, setPreguntas] = useAtom(preguntasAtom);
	const [visibleModalPreguntas] = useAtom(visibleModalPreguntasAtom);
	const [detalleEntrevista] = useAtom(detalleEntrevistaAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			if (preguntas.length === 0 && visibleModalPreguntas) {
				if (detalleEntrevista?.preguntasCompleto) {
					const preguntas = await EntrevistasService.obtenerPreguntas(entrevistaId);
					setPreguntas(preguntas);
				} else {
					const preguntas = await PreguntasService.listar();
					setPreguntas(
						preguntas.map((pregunta) => {
							return {
								id: pregunta.id,
								descripcion: pregunta.descripcion,
								respuesta: undefined,
							};
						})
					);
				}
			}
		};
		cargarDatos();
	}, []);

	return { preguntas, setPreguntas };
};
export default useListarPreguntas;
