import React from "react";
import { Tag } from "antd";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";

export function ColumnaEstadoDocumento({ permitirVisualizarDocumento, estado, estadoAprobacion }) {
	if (estadoAprobacion != null && estadoAprobacion === "PENDIENTE" && estado === "FIRMADO") {
		return <Tag color="processing">PENDIENTE APROBACIÓN</Tag>;
	} else if (estadoAprobacion != null && estadoAprobacion === "RECHAZADO") {
		return <Tag color="red">RECHAZADO</Tag>;
	} else {
		switch (estado) {
			case EstadoVistaColaborador.PENDIENTE:
				if (permitirVisualizarDocumento) {
					return <Tag color="warning">PENDIENTE</Tag>;
				}
				return <Tag>PENDIENTE</Tag>;

			case EstadoVistaColaborador.VISUALIZADO:
				return <Tag color="green">VISUALIZADO</Tag>;

			case EstadoVistaColaborador.FIRMADO:
				return <Tag color="green">FIRMADO</Tag>;

			default:
				return <Tag color="default">-</Tag>;
		}
	}
}
