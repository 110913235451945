import React from "react";
import { Button } from "antd";
import { LikeTwoTone, CheckOutlined } from "@ant-design/icons";

const ResumenStep = () => {
	return (
		<div id="step-resumen" style={{ display: "flex", flexDirection: "column" }}>
			<span style={{ paddingTop: "20px", textAlign: "center" }}>
				<LikeTwoTone style={{ fontSize: "48px" }} twoToneColor="#52c41a" />
			</span>
			<span style={{ paddingTop: "5px", fontSize: "18px", textAlign: "center" }}>
				Gracias por verificar tu información
			</span>
			<span style={{ paddingTop: "20px", textAlign: "center" }}>
				<Button onClick={() => window.close()} type="primary" icon={<CheckOutlined />} size="large">
					Cerrar Ventana
				</Button>
			</span>
		</div>
	);
};

export default ResumenStep;
