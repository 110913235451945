import React from "react";
import logotipo from "../../../assets/imagenes/logotipo.png";
import { ArrowLeftOutlined } from "@ant-design/icons"

const HeaderMovil = ({ titulo, retornar = false, onClickBtnRetornar }) => {
    return (
        <div className="container-movil-pdf-header">
            {retornar &&
                <div style={{ width: "30%" }} onClick={onClickBtnRetornar}>
                    <ArrowLeftOutlined style={{ fontSize: "20px", marginRight: "8px" }} />
                    Retornar
                </div>
            }

            {!retornar &&
                <>
                    <img
                        src={logotipo}
                        style={{ height: "36px", marginRight: "10px" }}
                    />
                    {titulo}
                </>
            }
        </div>
    );
}

export default HeaderMovil;