import React, { useEffect, useState } from "react";
import { MisDocumentosContextProvider } from "context/MisDocumentos/MisDocumentosContextProvider";
import { Body } from "./Body";
import { ModalFirmar } from "pages/mis-documentos/componentes/ModalFirmar";
import { ModalExito } from "pages/mis-documentos/componentes/ModalExito";
import { Plataforma } from "enum/Plataforma";
import { ModalConsultar } from "./ModalConsultar";

const VisorPdfPage = () => {
	const [plataforma, setPlataforma] = useState(
		localStorage.getItem("plataforma")
	);

	useEffect(() => {
		if (
			plataforma === undefined ||
			plataforma === "" ||
			plataforma === null
		) {
			setPlataforma(Plataforma.MOVIL_WEB);
		}
	}, []);

	return (
		<MisDocumentosContextProvider>
			<Body />
			<ModalFirmar plataforma={plataforma} />
			<ModalConsultar />
			<ModalExito />
		</MisDocumentosContextProvider>
	);
};

export default VisorPdfPage;
