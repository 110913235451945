import { useEffect } from "react";
import { entrevistasAtom, loadingAtom } from "../store/EntrevistasStore";
import { useAtom } from "jotai";
import EntrevistasService from "services/reclutamiento-y-seleccion/EntrevistasService";

const useListarEntrevistas = () => {
	const [entrevistas, setEntrevistas] = useAtom(entrevistasAtom);
	const [,setLoading] = useAtom(loadingAtom)
	useEffect(() => {
		const cargarDatos = async () => {
			setLoading(true);
			const respuesta = await EntrevistasService.listar();
			setEntrevistas(respuesta);
			setLoading(false)
		};
		cargarDatos();
	}, []);
	return { entrevistas };
};
export default useListarEntrevistas;
