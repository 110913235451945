import React from "react";
import { Dropdown, Menu } from "antd";
import { SelectOutlined, CaretDownOutlined, UnorderedListOutlined, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { EstadoSolicituPersonal } from "enum/EstadoSolicitudPersonal";
import { useAtom } from "jotai";
import { solicitudPersonalIdSeleccionadoAtom, visibleModalCancelarSolicitudAtom } from "../../../common/store/MisSolicitudesPersonalStore";

const MenuAcciones = ({ solicitud }) => {
    
    const [, setSolicitudPersonalIdSeleccionado] = useAtom(solicitudPersonalIdSeleccionadoAtom);
    const [, setVisibleModalCancelarSolicitud] = useAtom(visibleModalCancelarSolicitudAtom);
   
    const menu = () => {
    const onClickMenu = (opcion) => {
    switch (opcion.key) {    

      case "cancelar-solicitud":
        setSolicitudPersonalIdSeleccionado(solicitud.id);
        setVisibleModalCancelarSolicitud(true);
       
        console.log("cancelado");
        break;      

      default:
        console.error("No se encuentra esa opción");
    }
  };
    return (
      <Menu onClick={onClickMenu}>        
        {(solicitud.estado == EstadoSolicituPersonal.INICIADO && (
            <Menu.Item key="cancelar-solicitud">
              <CloseOutlined /> Cancelar Solicitud
            </Menu.Item>
          ))}
      </Menu>
    );
  };    
    
return (
    <React.Fragment>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomCenter">
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
          <CaretDownOutlined style={{ paddingLeft: "2px", fontSize: "12px", fontWeight: "bold", color: "#1678c1" }} />
        </a>
      </Dropdown>
    </React.Fragment>
  );
}
export default MenuAcciones;