import React from "react";
import { Descriptions, Form, Input, Select } from "antd";

const Preguntas = ({ titulo, opciones, form, calcularPuntajeTotal }) => {
	const obtenerPuntajeParcial = () => {
		var suma = 0;
		const preguntas = form.getFieldsValue();
		for (var descripcion in preguntas) {
			if (preguntas[descripcion] && descripcion !== "puntajeParcial") {
				suma += parseInt(preguntas[descripcion]);
			}
		}
		form.setFieldsValue({ puntajeParcial: `${suma}` });
		if (calcularPuntajeTotal) {
			calcularPuntajeTotal(suma);
		}
	};

	return (
		<Form form={form} style={{ marginBottom: "20px" }}>
			<div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px" }}>{titulo}</div>
			<Descriptions bordered size="small" column={1} labelStyle={{ width: "70%" }}>
				{opciones &&
					opciones.map((opcion) => {
						return (
							<Descriptions.Item key={opcion.key} label={opcion.label}>
								<Form.Item
									name={opcion.key}
									style={{ marginBottom: "0px" }}
									rules={[
										{
											required: true,
											message: "Campo obligatorio",
										},
									]}
								>
									<Select placeholder="Seleccione la calificación" onChange={obtenerPuntajeParcial}>
										<Select.Option value={1}>1 - DEFICIENTE</Select.Option>
										<Select.Option value={2}>2 - REGULAR</Select.Option>
										<Select.Option value={3}>3 - BUENO</Select.Option>
										<Select.Option value={4}>4 - MUY BUENO</Select.Option>
										<Select.Option value={5}>5 - EXCELENTE</Select.Option>
									</Select>
								</Form.Item>
							</Descriptions.Item>
						);
					})}
				<Descriptions.Item label="Puntaje Parcial">
					<Form.Item name="puntajeParcial" style={{ marginBottom: "0px" }}>
						<Input readOnly={true} />
					</Form.Item>
				</Descriptions.Item>
			</Descriptions>
		</Form>
	);
};

export default Preguntas;
