import React, { useContext } from "react";
import { Modal, Table } from "antd";
import { useAtom } from "jotai";
import { visibleModalRecordVacacionalAtom } from "pages/vacaciones/componentes/common/mis-solicitudes/store/MisSolicitudesStore";
import { useObtenerDetalleVacacionesColaborador } from "pages/vacaciones/componentes/common/mis-solicitudes/hooks";
import { SecurityContext } from "context/SecurityContextProvider";

const ModalRecordVacacional = () => {
	const [visibleModal, setVisibleModal] = useAtom(visibleModalRecordVacacionalAtom);
	const { detalleVacacionesColaborador } = useObtenerDetalleVacacionesColaborador();
	const { getUsuarioAutenticado } = useContext(SecurityContext);

	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;

	return (
		<Modal
			title={"Vacaciones Disponibles"}
			cancelButtonProps={{ style: { display: "none" } }}
			width={600}
			centered
			visible={visibleModal}
			onCancel={() => {
				setVisibleModal(false);
			}}
			onOk={() => setVisibleModal(false)}
			okText={"Cerrar"}
		>
			<div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
				<span style={{ fontSize: "20px", fontWeight: "bold", color: "#108ee9", textAlign: "center" }}>
					{isAceros && detalleVacacionesColaborador?.vacacionesDisponiblesSinDiasTruncos}
					{!isAceros && detalleVacacionesColaborador?.vacacionesDisponibles} DÍAS
				</span>
				<span style={{ fontSize: "13px", textAlign: "center" }}>disponibles</span>
			</div>
			<ListaPeriodosVacaciones detalleVacacionesColaborador={detalleVacacionesColaborador} />
		</Modal>
	);
};

const ListaPeriodosVacaciones = ({ detalleVacacionesColaborador }) => {
	// const { detalleVacacionesColaborador } = useObtenerDetalleVacacionesColaborador();

	const columns = [
		{
			title: "Periodo Vacacional",
			key: "periodoVacacional",
			align: "center",
			render: (_, fila) => (
				<React.Fragment>
					{fila.periodoInicio} - {fila.periodoFin}
				</React.Fragment>
			),
		},
		{
			title: "Días Ganadas",
			dataIndex: "vacacionesGanadas",
			key: "vacacionesGanadas",
			align: "center",
		},
		{
			title: "Días Gozadas",
			dataIndex: "vacacionesGozadas",
			key: "vacacionesGozadas",
			align: "center",
		},
		{
			title: "Días Pendientes",
			dataIndex: "vacacionesPendientes",
			key: "vacacionesPendientes",
			align: "center",
		},
	];

	return (
		<Table
			dataSource={detalleVacacionesColaborador?.periodosVacacionales}
			columns={columns}
			size="small"
			pagination={false}
		/>
	);
};

export default ModalRecordVacacional;
