import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Badge, Breadcrumb, Input } from "antd";
import { ItemTareaPendiente } from "pages/tareas-pendientes/componentes/common";
import { useAtom, useSetAtom } from "jotai";
import {
	tareaPendienteSeleccionadaAtom,
	tareaSeleccionadaIdAtom,
} from "pages/tareas-pendientes/TareasPendientesStorage";
import { useListarTareasPendientes } from "pages/tareas-pendientes/componentes/common/hooks";
import { TareasPendientesService } from "services";

import {
	HomeOutlined
} from "@ant-design/icons"

const ListaTareasPendientes = () => {
	const [tareaSeleccionadaId, setTareaSeleccionadaId] = useAtom(tareaSeleccionadaIdAtom);
	const setTareaPendienteSeleccionada = useSetAtom(tareaPendienteSeleccionadaAtom);
	const { tareasPendientes, onSearchTareasPendientes } = useListarTareasPendientes();

	const onSeleccionar = (id) => {
		if (id !== tareaSeleccionadaId) {
			setTareaSeleccionadaId(id);
		}
	};

	useEffect(() => {
		const getInformacionTareaPendiente = async () => {
			if (tareaSeleccionadaId) {
				const respuesta = await TareasPendientesService.buscarPorId(tareaSeleccionadaId);
				setTareaPendienteSeleccionada(respuesta);
			}
		};

		getInformacionTareaPendiente();
	}, [tareaSeleccionadaId]);

	return (
		<Container>
			<Navegacion />
			<Input.Search
				placeholder="buscar por colaborador..."
				onSearch={onSearchTareasPendientes}
				style={{
					marginBottom: "5px",
				}}
			/>
			<Items>
				{tareasPendientes &&
					tareasPendientes.map((item) => {
						return (
							<ItemTareaPendiente
								key={item.id}
								id={item.id}
								tipo={item.tipo}
								colaborador={item.colaborador}
								descripcion={item.descripcion}
								fecha={item.fechaRegistro}
								checked={tareaSeleccionadaId == item.id}
								onSeleccionar={onSeleccionar}
							/>
						);
					})}
			</Items>
		</Container>
	);
};

const Navegacion = () => {
	return (
		<Breadcrumb style={{ marginBottom: "5px" }}>
			<Breadcrumb.Item>
				<HomeOutlined />
			</Breadcrumb.Item>
			<Breadcrumb.Item>
				Tareas Pendientes
			</Breadcrumb.Item>
		</Breadcrumb>
	)
}

const Container = styled.div`
	//border: 1px solid #ccc;
	flex: 1 1 25%;
	margin: 10px;
`;

const Titulo = styled.div`
	flex: 1 1 20%;
	padding: 10px;
	border-bottom: 1px solid #ccc;
`;

const Items = styled.div`
	padding: 10px;
	overflow: auto;
	max-height: calc(100% - 65px);
	background-color: white;
	border: 1px solid #ccc;
`;

export default ListaTareasPendientes;
