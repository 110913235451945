import React from "react";
import { Table, Tag, Popover, Space} from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import { useListarSolicitudesAusencia } from "pages/vacaciones/componentes/common/mis-solicitudes/hooks";
import MenuAcciones from "./MenuAcciones";
const ListaSolicitudesAusencias = () => {
	const { solicitudesAusencia } = useListarSolicitudesAusencia();

	return <Table rowKey={(value) => value.id} columns={columnas} dataSource={solicitudesAusencia} />;
};

const columnas = [
	{
		title: "Tipo Ausencia",
		dataIndex: ["tipo", "descripcion"],
	},
	{
		title: "Fecha Inicio",
		dataIndex: "fechaInicioAusencia",
		render: (text, fila) => moment(fila.fechaInicioAusencia).format("DD/MM/YYYY hh:mm a"),
	},
	{
		title: "Fecha Fin",
		dataIndex: "fechaFinAusencia",
		render: (text, fila) => moment(fila.fechaFinAusencia).format("DD/MM/YYYY hh:mm a"),
	},
	{
		title: "Dias",
		dataIndex: "diasSolicitados",
		align: "center",
	},
	{
		title: "Fecha de Solicitud",
		dataIndex: "fechaHoraSolicitud",
		render: (text, fila) => moment(fila.fechaHoraSolicitud).format("DD/MM/YYYY hh:mm a"),
	},
	{
		title: "Aprobador",
		dataIndex: "aprobador",
		render: (text, fila) =>
			//fila.requiereAprobacionJefeInmediato === true ? fila.jefeInmediato.nombreCompleto : "-",
			fila.requiereAprobacionJefeInmediato
				? fila.jefeInmediato.nombreCompleto
				: !fila.requiereAprobacionJefeInmediato &&
				  fila.requiereAprobacionOtrosCargos &&
				  fila.estado !== "PENDIENTE"
				? fila.responsableOtrosCargos?.nombreCompleto
				: "-",
	},
	{
		title: "Estado",
		render: (text, fila) => <EtiquetaEstado fila={fila} />,
	},
	{
		title: "Acciones",
		align: "center",
		render: (text, fila) => <MenuAcciones solicitud={fila} />,
	},
];

const EtiquetaEstado = ({ fila }) => {
	const tagStyle = {
		borderRadius: "15px",
	};

	const color = getColor(fila.estado);

	if (fila.estado === "RECHAZADO") { 

		if (fila.jefeInmediato != null && fila.jefeInmediato.motivoRechazo != null) {
			const content = (
				<div style={{ width: 140 }}>
					<p>
						<strong>Motivo rechazo: </strong>
						{fila.jefeInmediato.motivoRechazo}
					</p>
				</div>
			);

			return (
				<div style={{ width: 140 }}>
					<Popover
						title={null}
						content={content}
						trigger="click"
						placement="bottomRight"
					// onOpenChange={onOpenChange}
					>
						<Space>
							<Tag color="red" className="milegajo-ant-tag" style={{ cursor: "pointer", ...tagStyle }}>
								<SearchOutlined /> RECHAZADO
							</Tag>
						</Space>
					</Popover>
				</div>
			);
		} else { 
			return (
				<Tag color={color} className="milegajo-ant-tag">
					{fila.estado}
				</Tag>
			);
		}
		
	}

	return (
		<Tag color={color} className="milegajo-ant-tag">
			{fila.estado}
		</Tag>
	);
};

const getColor = (estado) => {
	if (estado === "APROBADO") {
		return "green";
	} else if (estado === "RECHAZADO") {
		return "red";
	} else if (estado === "PENDIENTE") {
		return "gold";
	} else {
		return "magenta";
	}
};

export default ListaSolicitudesAusencias;
