import React, { useEffect } from "react";
import { useParams } from "react-router";
import { ReloadOutlined } from "@ant-design/icons";
import LoginService from "services/LoginService";

const RutaCortaPage = () => {
	const { hashUrl } = useParams();
	const host = window.location.host;
	const subdominio = host.split(".")[0];

	useEffect(() => {
		const fetchUrl = async () => {
			const respuesta = await LoginService.redireccionar(subdominio, hashUrl);
			window.location.href = respuesta.url;
		};

		fetchUrl();
	}, []);

	const style = {
		width: "100%",
		height: "100%",
		background: "white",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	};

	return (
		<div style={style}>
			<span className="milegajo-primary-color">
				<ReloadOutlined spin={true} /> Redireccionando...
			</span>
		</div>
	);
};

export default RutaCortaPage;
