import { atom } from "jotai";

//export const contratosAtom = atom([]);
export const contratosAtom = atom({});
export const paginaActualAtom = atom(0);
export const totalPaginasAtom = atom(undefined);
export const totalContratosAtom = atom(undefined);
export const paginacionAtom = atom(10);
export const filtroAtom = atom(undefined);
export const filtroColaboradorAtom = atom (undefined);
export const filtroFechaFinAtom = atom(null);
export const filtroFechaInicioAtom = atom(null);
export const filtroProyectoAtom = atom(null);
export const filtroRespRenovacionAtom = atom(null);
export const filtroEstadoAtom = atom(null);
export const proyectosAtom = atom([]);
export const filtroEstadoFirmaAtom = atom(null);
export const filtroEstadoColaboradorAtom = atom(null);
export const filtroTipoContratoAtom = atom(null);

// export const visibleModalRenovarContratoAtom = atom(false);
// export const contratoSeleccionadoAtom = atom(undefined);

// export const visibleModalRechazarRenovacionAtom = atom(false);
// export const urlPdfContratoAtom = atom(undefined);
// export const visibleModalVisorPdfAtom = atom(false);
