import React, { useContext, useEffect, useState } from "react";
import { Tag, Typography, Divider } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import {
  categoriasAtom,
  filtrosSeleccionadosAtom,
  listaTiposDocumentoFiltroAtom,
  listraCarpetasFiltroAtom,
  tagVisibleAtom,
  filtrosAtom,
  opcionesSeleccionadasAtom
} from "pages/consulta-documento/ConsultaDocumentoStore";
import { SecurityContext } from "context/SecurityContextProvider";
//import {onQuitar} from "../ModalFiltrar/ModalFiltrar"

const TagFiltros = () => {
  const { Text } = Typography;
  const [filtrosSeleccionados, setFiltrosSeleccionados] = useAtom(filtrosSeleccionadosAtom);
  const [tagVisible] = useAtom(tagVisibleAtom);
  const [listaCarpetasFiltro] = useAtom(listraCarpetasFiltroAtom);
  const [listaTiposDocumentoFiltro] = useAtom(listaTiposDocumentoFiltroAtom);
  const [categorias] = useAtom(categoriasAtom);
  const [filtros, setFiltros] = useAtom(filtrosAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const camposColaboradores = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
  const [opcionesSeleccionadas,] = useAtom(opcionesSeleccionadasAtom);

  const TagFiltro = ({ titulo, categoria, valor }) => {

    if (isNull(valor)) {
      return null;
    }
    if (Array.isArray(valor)) {

      const etiquetas = valor.map((v) => {
        const nombre = listaTiposDocumentoFiltro.find((item) => item.id == v).nombre;

        return <Etiqueta key={v} titulo={titulo} nombre={nombre} categoria={categoria} valor={v} />;
      });
      return etiquetas;
    }
    return <Etiqueta titulo={titulo} valor={valor} categoria={categoria} />;
  };

  const Etiqueta = ({ titulo, valor, categoria, nombre }) => {

    return (
      <Tag
        key={valor}
        color="purple"
        closable
        //   onClose={handleOnCloseTagColaborador}
        style={{ borderRadius: "10px" }}
        onClose={(e) => handleOnCloseTag(e, valor, categoria)}
      >
        <Text style={{ color: "purple" }} strong>
          {titulo}
        </Text>
        {nombre == null ? valor : nombre}
      </Tag>
    );
  };

  const isNull = (valor) => {
    return valor === undefined || valor === null;
  };

  const TagFiltroEtiqueta = ({ valor, titulo, categoria }) => {
    if (isNull(valor)) {
      return "";
    }
    const etiquetas = valor.map((item) => {
      let nombre = null;
      switch (categoria) {
        // case "tipoDocumento":
        //   nombre = listaTiposDocumentoFiltro.find((item2) => item2.id == item);
        //   break;
        case "cargo":
          nombre = categorias.find((item2) => item2.id == item);
          break;
        case "sede":
          nombre = categorias.find((item2) => item2.id == item);
          break;

        case "subSede":
          nombre = categorias.find((item2) => item2.id == item);
          break;

        case "seccion":
          nombre = categorias.find((item2) => item2.id == item);
          break;

        case "perfil":
          nombre = categorias.find((item2) => item2.id == item);
          break;

        case "tipoPlanilla":
          nombre = categorias.find((item2) => item2.id == item);
          break;
      }

      // const objeto = JSON.parse(item);
      return (
        <Tag key={item} color="purple" closable style={{ borderRadius: "10px" }} onClose={(e) => handleOnCloseTag(e, item, categoria)}>
          <Text style={{ color: "purple" }} strong>
            {titulo}
          </Text>
          {nombre?.nombre}
        </Tag>
      );
    });
    return etiquetas;
  };

  const handleOnCloseTag = (e, valor, categoria) => {
    let temporalCampos = [];
    if (categoria == "seccion" || categoria == "sede" || categoria == "subSede" || categoria == "tipoPlanilla" || categoria == "perfil" || categoria == "cargo") {
      const campoSam = categorias.find(item => item.id == valor);
      const camposHijos = camposColaboradores.filter((c) => c.campoDependencia === campoSam.campo);
      camposHijos.forEach((campoHijo) => {
        const opcionesSeleccionadasPorCampo = opcionesSeleccionadas.find((opcion) => opcion.campo === campoHijo.campo)?.datos;
        opcionesSeleccionadasPorCampo.forEach((opcion) => {
          const tempInside = categorias.find(item => item.id == opcion)
          console.log(tempInside)
          if (tempInside.idDependencia === valor) {
            temporalCampos.push(opcion);
          }
        });

      });
    }
    if (temporalCampos.length > 0) {
      e.preventDefault();
    } else {
      let tempFiltro = null;
      let temp = null;
      let tempFiltroModal = null;
      switch (categoria) {
        case "tipoDocumento":
          temp = filtrosSeleccionados?.tipoDocumento.filter((item) => item != valor);
          tempFiltro = { ...filtrosSeleccionados, tipoDocumento: temp };
          break;
        case "cargo":
          temp = filtrosSeleccionados?.cargo.filter((item) => item != valor);
          tempFiltro = { ...filtrosSeleccionados, cargo: temp.length > 0 ? temp : undefined };
          tempFiltroModal = { ...filtros, filtroCargo: temp.length > 0 ? temp : null };
          break;
        case "sede":
          temp = filtrosSeleccionados?.sede.filter((item) => item != valor);
          tempFiltro = { ...filtrosSeleccionados, sede: temp.length > 0 ? temp : undefined };
          tempFiltroModal = { ...filtros, filtroSede: temp.length > 0 ? temp : null };
          break;
        case "periodoDocumento":
          tempFiltro = { ...filtrosSeleccionados, periodoDocumento: null };
          break;
        case "colaborador":
          tempFiltro = { ...filtrosSeleccionados, colaborador: null, colaboradorDni: null };
          break;

        case "documentoIdentidad":
          tempFiltro = { ...filtrosSeleccionados, documentoIdentidad: null };
          break;
        case "codigoColaborador":
          tempFiltro = { ...filtrosSeleccionados, codigoColaborador: null };
          break;
        case "estadoDocumento":
          tempFiltro = { ...filtrosSeleccionados, estadoDocumento: null };
          break;

        case "fechaEnvio":
          tempFiltro = { ...filtrosSeleccionados, fechaEnvio: null };
          break;

        case "carpeta":
          tempFiltro = { ...filtrosSeleccionados, carpeta: null };
          break;

        case "subSede":
          temp = filtrosSeleccionados?.subSede.filter((item) => item != valor);
          tempFiltro = { ...filtrosSeleccionados, subSede: temp.length > 0 ? temp : undefined };
          tempFiltroModal = { ...filtros, filtroSubSede: temp.length > 0 ? temp : null };
          break;

        case "seccion":
          temp = filtrosSeleccionados?.seccion.filter((item) => item != valor);
          tempFiltro = { ...filtrosSeleccionados, seccion: temp.length > 0 ? temp : undefined };
          tempFiltroModal = { ...filtros, filtroSeccion: temp.length > 0 ? temp : null };
          break;

        case "perfil":
          temp = filtrosSeleccionados?.perfil.filter((item) => item != valor);
          tempFiltro = { ...filtrosSeleccionados, perfil: temp.length > 0 ? temp : undefined };
          tempFiltroModal = { ...filtros, filtroPerfil: temp.length > 0 ? temp : null };
          break;

        case "tipoPlanilla":
          temp = filtrosSeleccionados?.tipoPlanilla.filter((item) => item != valor);
          tempFiltro = { ...filtrosSeleccionados, tipoPlanilla: temp.length > 0 ? temp : undefined };
          tempFiltroModal = { ...filtros, filtroTipoPlanilla: temp.length > 0 ? temp : null };
          break;

        case "fechaFirma":
          tempFiltro = { ...filtrosSeleccionados, fechaFirma: null };
          break;

      }


      setFiltrosSeleccionados(tempFiltro);
      if (categoria == "seccion" || categoria == "sede" || categoria == "subSede" || categoria == "tipoPlanilla" || categoria == "perfil" || categoria == "cargo") {
        setFiltros(tempFiltroModal);
      }
    }


  }

  return (
    <div style={{ display: tagVisible }}>
      <FilterOutlined />
      <TagFiltro titulo="Colaborador: " categoria="colaborador" valor={filtrosSeleccionados?.colaborador} />
      <TagFiltro titulo="Doc. Identidad: " categoria="documentoIdentidad" valor={filtrosSeleccionados?.documentoIdentidad} />
      <TagFiltro titulo="Tipo Documento: " categoria="tipoDocumento" valor={filtrosSeleccionados?.tipoDocumento} />
      <TagFiltro titulo="Código Colaborador: " categoria="codigoColaborador" valor={filtrosSeleccionados?.codigoColaborador} />
      {/* <TagFiltroEtiqueta valor={filtrosSeleccionados?.tipoDocumento} titulo={"Tipo Documento: "} categoria={"tipoDocumento"} /> */}

      <TagFiltroEtiqueta valor={filtrosSeleccionados?.cargo} titulo={"Cargo : "} categoria={"cargo"} />

      <TagFiltroEtiqueta valor={filtrosSeleccionados?.sede} titulo={"Centro de costo : "} categoria={"sede"} />

      <TagFiltroEtiqueta valor={filtrosSeleccionados?.subSede} titulo={"Subcentro de costo : "} categoria={"subSede"} />

      <TagFiltroEtiqueta valor={filtrosSeleccionados?.perfil} titulo={"Perfil : "} categoria={"perfil"} />

      <TagFiltroEtiqueta valor={filtrosSeleccionados?.tipoPlanilla} titulo={"Tipo de Planilla : "} categoria={"tipoPlanilla"} />

      <TagFiltroEtiqueta valor={filtrosSeleccionados?.seccion} titulo={"Seccion : "} categoria={"seccion"} />

      {/* <TagFiltro titulo="Cargo:  " valor={filtrosSeleccionados?.cargo} /> */}
      <TagFiltro titulo="Periodo:  " categoria="periodoDocumento" valor={filtrosSeleccionados?.periodoDocumento?.format("MM/YYYY")} />

      <TagFiltro titulo="Estado:  " categoria="estadoDocumento" valor={filtrosSeleccionados?.estadoDocumento} />

      <TagFiltro
        titulo="Fecha Envío: "
        categoria="fechaEnvio"
        valor={
          isNull(filtrosSeleccionados?.fechaEnvio)
            ? null
            : filtrosSeleccionados?.fechaEnvio[0]?.format("DD/MM/YYYY") + " al " + filtrosSeleccionados?.fechaEnvio[1]?.format("DD/MM/YYYY")
        }
      />

      <TagFiltro
        titulo="Carpeta :  "
        categoria="carpeta"
        valor={listaCarpetasFiltro.find((item) => item.id == filtrosSeleccionados?.carpeta)?.ruta}
      />

      <TagFiltro
        titulo="Fecha Firma: "
        categoria="fechaFirma"
        valor={
          isNull(filtrosSeleccionados?.fechaFirma)
            ? null
            : filtrosSeleccionados?.fechaFirma[0]?.format("DD/MM/YYYY") + " al " + filtrosSeleccionados?.fechaFirma[1]?.format("DD/MM/YYYY")
        }
      />

    </div>
  );
};

export default TagFiltros;
