import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { detalleEntrevistaAtom, loadingEntrevistaAtom } from "../DetalleEntrevistaStore";
import { useParams } from "react-router-dom";
import { EntrevistasService } from "services/reclutamiento-y-seleccion";

const useDetalleEntrevista = () => {
	const [detalleEntrevista, setDetalleEntrevista] = useAtom(detalleEntrevistaAtom);
	const [entrevistasRealizadas, setEntrevistasRealizadas] = useState(undefined);
	const [, setLoading ] = useAtom(loadingEntrevistaAtom);
	const { entrevistaId } = useParams();

	useEffect(() => {
		const cargarDatos = async () => {
			setLoading(true)
			const respuesta = await EntrevistasService.obtenerEstadoEntrevista(entrevistaId);
			setDetalleEntrevista(respuesta);
			if (respuesta.entrevistasFinalizadas)
				setEntrevistasRealizadas(respuesta.entrevistasFinalizadas)
			setLoading(false)
		};
		cargarDatos();
	}, [entrevistaId]);

	return {
		detalleEntrevista, setDetalleEntrevista, entrevistasRealizadas
	};
};
export default useDetalleEntrevista;
