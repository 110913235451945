import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { SolicitudAusenciaService } from "services";
import { ApiResponse } from "enum";
import { solicitudesAusenciaAtom } from "../store/MisSolicitudesStore";

const useCargarSolicitudesAusencia = () => {
	const [, setSolicitudesAusencia] = useAtom(solicitudesAusenciaAtom);

	const cargarDatos = async () => {
		const solicitudesAusencia = await SolicitudAusenciaService.listar();
		if (solicitudesAusencia.estado === ApiResponse.EXITO) {
			setSolicitudesAusencia(solicitudesAusencia.data);
		}
	};
	return { cargarDatos };
};
export default useCargarSolicitudesAusencia;
