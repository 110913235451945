import React, { useContext, useEffect, useState } from "react";
import { Tag, Button } from "antd";
import { FormOutlined, CloseOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import { useAtom } from "jotai";
import {
	erroresFormularioAtom,
	formularioSeleccionadoAtom,
	visibleVisorFormularioAtom,
} from "pages/mis-documentos/componentes/MisDocumentosWeb/MisDocumentosWebStorage";
import { useFormContext } from "react-hook-form";
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";

const Header = ({ loadingPdf }) => {
	const { mostrarModalFirmarFormulario } = useContext(MisDocumentosContext);
	const [formularioSeleccionado] = useAtom(formularioSeleccionadoAtom);
	const [, setVisibleVisorFormulario] = useAtom(visibleVisorFormularioAtom);
	const [, setErroresFormulario] = useAtom(erroresFormularioAtom);
	const { handleSubmit } = useFormContext();

	const onClickBtnCerrar = () => {
		setVisibleVisorFormulario(false);
	};

	const onSubmit = (datosFormulario) => {
		mostrarModalFirmarFormulario(datosFormulario);
	};

	const onError = (errores) => {
		setErroresFormulario({ ...errores });
	};

	const onClickBtnFirmar = async () => {
		handleSubmit(onSubmit, onError)();
	};

	return (
		<div className="visor-pdf-header" style={{ borderBottom: "0px" }}>
			<div className="informacion">
				<FormOutlined className="icono" />
				<div className="texto">
					<span>
						<span className="periodo">{formularioSeleccionado?.periodo}</span>
						{" - "}
						<span className="documento">{formularioSeleccionado?.nombreDocumento}</span>
					</span>
					<span className="estado">
						<EstadoDocumento estado={formularioSeleccionado?.estadoVistaColaborador} />
					</span>
				</div>
			</div>

			<div className="acciones">
				<Button type="primary" onClick={onClickBtnFirmar} loading={loadingPdf} disabled={loadingPdf}>
					{formularioSeleccionado.requiereFirmaColaborador ? "Firmar Documento" : "Confirmar Visualización"}
				</Button>
				<div className="visor-pdf-header-separador" />
				<Button type="text" className="cerrar" onClick={onClickBtnCerrar}>
					<CloseOutlined style={{ fontSize: "16px" }} />
				</Button>
			</div>
		</div>
	);
};

const EstadoDocumento = ({ estado }) => {
	if (estado === EstadoVistaColaborador.PENDIENTE) {
		return <Tag color="volcano">{estado}</Tag>;
	}
	return (
		<Tag color="green" icon={<CheckCircleOutlined />}>
			{estado}
		</Tag>
	);
};

export default Header;
