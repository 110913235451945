import React from "react";
import { Provider as JotaiProvaider } from "jotai";
import { ListaHistoricoSolicitudes } from "./componentes/ListaHistoricoSolicitudes";
const HistoricoSolicitudPage = () => {
    return (
        <JotaiProvaider>
          <ListaHistoricoSolicitudes/>
        </JotaiProvaider>
    )
}
export default HistoricoSolicitudPage;