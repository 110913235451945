import React from "react";
import { Container } from "components/Container";
import { TablaEntrevistas } from "./componentes/TablaEntrevistas";
const EntrevistasPageWeb = () => {
	return (
		<Container titulo="Entrevistas">
			<TablaEntrevistas />
		</Container>
	);
};
export default EntrevistasPageWeb;
