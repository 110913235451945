import { useEffect } from "react";
import { useAtom } from "jotai";
import { visibleModalActualizarPlanAccionAtom, planAccionSeleccionadaAtom, planesAccionAtom } from "../../BandejaEvaluacionStorage";
import moment from "moment";
import EvaluacionService from "services/EvaluacionService";

const useModalPlanAccion = () => {
    const [visibleModalPlanAccion, setVisibleModalPlanAccion] = useAtom(visibleModalActualizarPlanAccionAtom);
    const [planAccionSeleccionado, setPlanAccionSeleccionado] = useAtom(planAccionSeleccionadaAtom);
    const [planesAccion, setPlanesAccion] = useAtom(planesAccionAtom);      

    const mostrarModal = (planAccion) => {
        setPlanAccionSeleccionado(planAccion);
        setVisibleModalPlanAccion(true);         
        
    }
    const cerrarModal = () => {       
        setVisibleModalPlanAccion(false);
              
    }
    const onFinishActualizarPlanAccion = async (formularioPlanAccion) => {
        try {
            
             const planAccion = {
                 id: planAccionSeleccionado.id,
                 descripcion: planAccionSeleccionado.descripcion,
                 fechaEjecucion: formularioPlanAccion.fechaEjecucion,
                 observacion:formularioPlanAccion.observacion,
             }
            const planAccionActualizado= await EvaluacionService.actualizarPlanAccion(planAccion);            
             
            setPlanesAccion(planesAccion.map((plan) => {
                 if (plan.id === planAccionSeleccionado.id && plan.descripcion === planAccionSeleccionado.descripcion) {
                     return{...plan,fechaEjecucion:new Date(planAccionActualizado.fechaEjecucion),estado:planAccionActualizado.estado}
                 }
                 return plan;
             }));
            cerrarModal();
             
           
         } catch (error) {
             console.error(error);
         }        
        
    }

    return {
        mostrarModal,
        cerrarModal,
        visibleModalPlanAccion,
        onFinishActualizarPlanAccion,        
    };
}
export default useModalPlanAccion;