export const Paises = [
	{ value: "AUSTRIA", label: "AUSTRIA" },
	{ value: "BELGICA", label: "BELGICA" },
	{ value: "BULGARIA", label: "BULGARIA" },
	{ value: "CHIPRE", label: "CHIPRE" },
	{ value: "DINAMARCA", label: "DINAMARCA" },
	{ value: "ESPAÑA", label: "ESPAÑA" },
	{ value: "FINLANDIA", label: "FINLANDIA" },
	{ value: "FRANCIA", label: "FRANCIA" },
	{ value: "GRECIA", label: "GRECIA" },
	{ value: "HUNGRIA", label: "HUNGRIA" },
	{ value: "IRLANDA", label: "IRLANDA" },
	{ value: "ITALIA", label: "ITALIA" },
	{ value: "LUXEMBURGO", label: "LUXEMBURGO" },
	{ value: "MALTA", label: "MALTA" },
	{ value: "PAISES BAJOS", label: "PAISES BAJOS" },
	{ value: "POLONIA", label: "POLONIA" },
	{ value: "PORTUGAL", label: "PORTUGAL" },
	{ value: "REINO UNIDO", label: "REINO UNIDO" },
	{ value: "ALEMANIA", label: "ALEMANIA" },
	{ value: "RUMANIA", label: "RUMANIA" },
	{ value: "SUECIA", label: "SUECIA" },
	{ value: "LETONIA", label: "LETONIA" },
	{ value: "ESTONIA", label: "ESTONIA" },
	{ value: "LITUANIA", label: "LITUANIA" },
	{ value: "REPUBLICA CHECA", label: "REPUBLICA CHECA" },
	{ value: "REPUBLICA ESLOVACA", label: "REPUBLICA ESLOVACA" },
	{ value: "ESLOVENIA", label: "ESLOVENIA" },
	{
		value: "OTROS PAISES O TERRITORIOS DE LA UNION EUROPEA",
		label: "OTROS PAISES O TERRITORIOS DE LA UNION EUROPEA",
	},
	{ value: "ALBANIA", label: "ALBANIA" },
	{ value: "ISLANDIA", label: "ISLANDIA" },
	{ value: "LIECHTENSTEIN", label: "LIECHTENSTEIN" },
	{ value: "MONACO", label: "MONACO" },
	{ value: "NORUEGA", label: "NORUEGA" },
	{ value: "ANDORRA", label: "ANDORRA" },
	{ value: "SAN MARINO", label: "SAN MARINO" },
	{ value: "SANTA SEDE", label: "SANTA SEDE" },
	{ value: "SUIZA", label: "SUIZA" },
	{ value: "UCRANIA", label: "UCRANIA" },
	{ value: "MOLDAVIA", label: "MOLDAVIA" },
	{ value: "BELARUS", label: "BELARUS" },
	{ value: "GEORGIA", label: "GEORGIA" },
	{ value: "BOSNIA Y HERZEGOVINA", label: "BOSNIA Y HERZEGOVINA" },
	{ value: "CROACIA", label: "CROACIA" },
	{ value: "ARMENIA", label: "ARMENIA" },
	{ value: "RUSIA", label: "RUSIA" },
	{ value: "MACEDONIA ", label: "MACEDONIA " },
	{ value: "SERBIA", label: "SERBIA" },
	{ value: "MONTENEGRO", label: "MONTENEGRO" },
	{ value: "GUERNESEY", label: "GUERNESEY" },
	{ value: "SVALBARD Y JAN MAYEN", label: "SVALBARD Y JAN MAYEN" },
	{ value: "ISLAS FEROE", label: "ISLAS FEROE" },
	{ value: "ISLA DE MAN", label: "ISLA DE MAN" },
	{ value: "GIBRALTAR", label: "GIBRALTAR" },
	{ value: "ISLAS DEL CANAL", label: "ISLAS DEL CANAL" },
	{ value: "JERSEY", label: "JERSEY" },
	{ value: "ISLAS ALAND", label: "ISLAS ALAND" },
	{ value: "TURQUIA", label: "TURQUIA" },
	{
		value: "OTROS PAISES O TERRITORIOS DEL RESTO DE EUROPA",
		label: "OTROS PAISES O TERRITORIOS DEL RESTO DE EUROPA",
	},
	{ value: "BURKINA FASO", label: "BURKINA FASO" },
	{ value: "ANGOLA", label: "ANGOLA" },
	{ value: "ARGELIA", label: "ARGELIA" },
	{ value: "BENIN", label: "BENIN" },
	{ value: "BOTSWANA", label: "BOTSWANA" },
	{ value: "BURUNDI", label: "BURUNDI" },
	{ value: "CABO VERDE", label: "CABO VERDE" },
	{ value: "CAMERUN", label: "CAMERUN" },
	{ value: "COMORES", label: "COMORES" },
	{ value: "CONGO", label: "CONGO" },
	{ value: "COSTA DE MARFIL", label: "COSTA DE MARFIL" },
	{ value: "DJIBOUTI", label: "DJIBOUTI" },
	{ value: "EGIPTO", label: "EGIPTO" },
	{ value: "ETIOPIA", label: "ETIOPIA" },
	{ value: "GABON", label: "GABON" },
	{ value: "GAMBIA", label: "GAMBIA" },
	{ value: "GHANA", label: "GHANA" },
	{ value: "GUINEA", label: "GUINEA" },
	{ value: "GUINEA-BISSAU", label: "GUINEA-BISSAU" },
	{ value: "GUINEA ECUATORIAL", label: "GUINEA ECUATORIAL" },
	{ value: "KENIA", label: "KENIA" },
	{ value: "LESOTHO", label: "LESOTHO" },
	{ value: "LIBERIA", label: "LIBERIA" },
	{ value: "LIBIA", label: "LIBIA" },
	{ value: "MADAGASCAR", label: "MADAGASCAR" },
	{ value: "MALAWI", label: "MALAWI" },
	{ value: "MALI", label: "MALI" },
	{ value: "MARRUECOS", label: "MARRUECOS" },
	{ value: "MAURICIO", label: "MAURICIO" },
	{ value: "MAURITANIA", label: "MAURITANIA" },
	{ value: "MOZAMBIQUE", label: "MOZAMBIQUE" },
	{ value: "NAMIBIA", label: "NAMIBIA" },
	{ value: "NIGER", label: "NIGER" },
	{ value: "NIGERIA", label: "NIGERIA" },
	{ value: "REPUBLICA CENTROAFRICANA", label: "REPUBLICA CENTROAFRICANA" },
	{ value: "SUDAFRICA", label: "SUDAFRICA" },
	{ value: "RUANDA", label: "RUANDA" },
	{ value: "SANTO TOME Y PRINCIPE", label: "SANTO TOME Y PRINCIPE" },
	{ value: "SENEGAL", label: "SENEGAL" },
	{ value: "SEYCHELLES", label: "SEYCHELLES" },
	{ value: "SIERRA LEONA", label: "SIERRA LEONA" },
	{ value: "SOMALIA", label: "SOMALIA" },
	{ value: "SUDAN", label: "SUDAN" },
	{ value: "SWAZILANDIA", label: "SWAZILANDIA" },
	{ value: "TANZANIA", label: "TANZANIA" },
	{ value: "CHAD", label: "CHAD" },
	{ value: "TOGO", label: "TOGO" },
	{ value: "TUNEZ", label: "TUNEZ" },
	{ value: "UGANDA", label: "UGANDA" },
	{ value: "REP.DEMOCRATICA DEL CONGO", label: "REP.DEMOCRATICA DEL CONGO" },
	{ value: "ZAMBIA", label: "ZAMBIA" },
	{ value: "ZIMBABWE", label: "ZIMBABWE" },
	{ value: "ERITREA", label: "ERITREA" },
	{ value: "SANTA HELENA", label: "SANTA HELENA" },
	{ value: "REUNION", label: "REUNION" },
	{ value: "MAYOTTE", label: "MAYOTTE" },
	{ value: "SAHARA OCCIDENTAL", label: "SAHARA OCCIDENTAL" },
	{ value: "OTROS PAISES O TERRITORIOS DE AFRICA", label: "OTROS PAISES O TERRITORIOS DE AFRICA" },
	{ value: "CANADA", label: "CANADA" },
	{ value: "ESTADOS UNIDOS DE AMERICA", label: "ESTADOS UNIDOS DE AMERICA" },
	{ value: "MEXICO", label: "MEXICO" },
	{ value: "SAN PEDRO Y MIQUELON ", label: "SAN PEDRO Y MIQUELON " },
	{ value: "GROENLANDIA", label: "GROENLANDIA" },
	{
		value: "OTROS PAISES  O TERRITORIOS DE AMERICA DEL NORTE",
		label: "OTROS PAISES  O TERRITORIOS DE AMERICA DEL NORTE",
	},
	{ value: "ANTIGUA Y BARBUDA", label: "ANTIGUA Y BARBUDA" },
	{ value: "BAHAMAS", label: "BAHAMAS" },
	{ value: "BARBADOS", label: "BARBADOS" },
	{ value: "BELICE", label: "BELICE" },
	{ value: "COSTA RICA", label: "COSTA RICA" },
	{ value: "CUBA", label: "CUBA" },
	{ value: "DOMINICA", label: "DOMINICA" },
	{ value: "EL SALVADOR", label: "EL SALVADOR" },
	{ value: "GRANADA", label: "GRANADA" },
	{ value: "GUATEMALA", label: "GUATEMALA" },
	{ value: "HAITI", label: "HAITI" },
	{ value: "HONDURAS", label: "HONDURAS" },
	{ value: "JAMAICA", label: "JAMAICA" },
	{ value: "NICARAGUA", label: "NICARAGUA" },
	{ value: "PANAMA", label: "PANAMA" },
	{ value: "SAN VICENTE Y LAS GRANADINAS", label: "SAN VICENTE Y LAS GRANADINAS" },
	{ value: "REPUBLICA DOMINICANA", label: "REPUBLICA DOMINICANA" },
	{ value: "TRINIDAD Y TOBAGO", label: "TRINIDAD Y TOBAGO" },
	{ value: "SANTA LUCIA", label: "SANTA LUCIA" },
	{ value: "SAN CRISTOBAL Y NIEVES", label: "SAN CRISTOBAL Y NIEVES" },
	{ value: "ISLAS CAIMÁN", label: "ISLAS CAIMÁN" },
	{ value: "ISLAS TURCAS Y CAICOS", label: "ISLAS TURCAS Y CAICOS" },
	{ value: "ISLAS VÍRGENES DE LOS ESTADOS UNIDOS", label: "ISLAS VÍRGENES DE LOS ESTADOS UNIDOS" },
	{ value: "GUADALUPE", label: "GUADALUPE" },
	{ value: "ANTILLAS HOLANDESAS", label: "ANTILLAS HOLANDESAS" },
	{ value: "SAN MARTIN (PARTE FRANCESA)", label: "SAN MARTIN (PARTE FRANCESA)" },
	{ value: "ARUBA", label: "ARUBA" },
	{ value: "MONTSERRAT", label: "MONTSERRAT" },
	{ value: "ANGUILLA", label: "ANGUILLA" },
	{ value: "SAN BARTOLOME", label: "SAN BARTOLOME" },
	{ value: "MARTINICA", label: "MARTINICA" },
	{ value: "PUERTO RICO", label: "PUERTO RICO" },
	{ value: "BERMUDAS", label: "BERMUDAS" },
	{ value: "ISLAS VIRGENES BRITANICAS", label: "ISLAS VIRGENES BRITANICAS" },
	{
		value: "OTROS PAISES O TERRITORIOS DEL CARIBE Y AMERICA CENTRAL",
		label: "OTROS PAISES O TERRITORIOS DEL CARIBE Y AMERICA CENTRAL",
	},
	{ value: "ARGENTINA", label: "ARGENTINA" },
	{ value: "BOLIVIA", label: "BOLIVIA" },
	{ value: "BRASIL", label: "BRASIL" },
	{ value: "COLOMBIA", label: "COLOMBIA" },
	{ value: "CHILE", label: "CHILE" },
	{ value: "ECUADOR", label: "ECUADOR" },
	{ value: "GUYANA", label: "GUYANA" },
	{ value: "PARAGUAY", label: "PARAGUAY" },
	{ value: "PERU", label: "PERU" },
	{ value: "SURINAM", label: "SURINAM" },
	{ value: "URUGUAY", label: "URUGUAY" },
	{ value: "VENEZUELA", label: "VENEZUELA" },
	{ value: "GUAYANA FRANCESA", label: "GUAYANA FRANCESA" },
	{ value: "ISLAS MALVINAS", label: "ISLAS MALVINAS" },
	{ value: "OTROS PAISES O TERRITORIOS  DE SUDAMERICA", label: "OTROS PAISES O TERRITORIOS  DE SUDAMERICA" },
	{ value: "AFGANISTAN", label: "AFGANISTAN" },
	{ value: "ARABIA SAUDI", label: "ARABIA SAUDI" },
	{ value: "BAHREIN", label: "BAHREIN" },
	{ value: "BANGLADESH", label: "BANGLADESH" },
	{ value: "MYANMAR", label: "MYANMAR" },
	{ value: "CHINA", label: "CHINA" },
	{ value: "EMIRATOS ARABES UNIDOS", label: "EMIRATOS ARABES UNIDOS" },
	{ value: "FILIPINAS", label: "FILIPINAS" },
	{ value: "INDIA", label: "INDIA" },
	{ value: "INDONESIA", label: "INDONESIA" },
	{ value: "IRAQ", label: "IRAQ" },
	{ value: "IRAN", label: "IRAN" },
	{ value: "ISRAEL", label: "ISRAEL" },
	{ value: "JAPON", label: "JAPON" },
	{ value: "JORDANIA", label: "JORDANIA" },
	{ value: "CAMBOYA", label: "CAMBOYA" },
	{ value: "KUWAIT", label: "KUWAIT" },
	{ value: "LAOS", label: "LAOS" },
	{ value: "LIBANO", label: "LIBANO" },
	{ value: "MALASIA", label: "MALASIA" },
	{ value: "MALDIVAS", label: "MALDIVAS" },
	{ value: "MONGOLIA", label: "MONGOLIA" },
	{ value: "NEPAL", label: "NEPAL" },
	{ value: "OMAN", label: "OMAN" },
	{ value: "PAKISTAN", label: "PAKISTAN" },
	{ value: "QATAR", label: "QATAR" },
	{ value: "COREA", label: "COREA" },
	{ value: "COREA DEL NORTE ", label: "COREA DEL NORTE " },
	{ value: "SINGAPUR", label: "SINGAPUR" },
	{ value: "SIRIA", label: "SIRIA" },
	{ value: "SRI LANKA", label: "SRI LANKA" },
	{ value: "TAILANDIA", label: "TAILANDIA" },
	{ value: "VIETNAM", label: "VIETNAM" },
	{ value: "BRUNEI", label: "BRUNEI" },
	{ value: "ISLAS MARSHALL", label: "ISLAS MARSHALL" },
	{ value: "YEMEN", label: "YEMEN" },
	{ value: "AZERBAIYAN", label: "AZERBAIYAN" },
	{ value: "KAZAJSTAN", label: "KAZAJSTAN" },
	{ value: "KIRGUISTAN", label: "KIRGUISTAN" },
	{ value: "TADYIKISTAN", label: "TADYIKISTAN" },
	{ value: "TURKMENISTAN", label: "TURKMENISTAN" },
	{ value: "UZBEKISTAN", label: "UZBEKISTAN" },
	{ value: "ISLAS MARIANAS DEL NORTE", label: "ISLAS MARIANAS DEL NORTE" },
	{ value: "PALESTINA", label: "PALESTINA" },
	{ value: "HONG KONG", label: "HONG KONG" },
	{ value: "BHUTÁN", label: "BHUTÁN" },
	{ value: "GUAM", label: "GUAM" },
	{ value: "MACAO", label: "MACAO" },
	{ value: "OTROS PAISES O TERRITORIOS DE ASIA", label: "OTROS PAISES O TERRITORIOS DE ASIA" },
	{ value: "AUSTRALIA", label: "AUSTRALIA" },
	{ value: "FIJI", label: "FIJI" },
	{ value: "NUEVA ZELANDA", label: "NUEVA ZELANDA" },
	{ value: "PAPUA NUEVA GUINEA", label: "PAPUA NUEVA GUINEA" },
	{ value: "ISLAS SALOMON", label: "ISLAS SALOMON" },
	{ value: "SAMOA", label: "SAMOA" },
	{ value: "TONGA", label: "TONGA" },
	{ value: "VANUATU", label: "VANUATU" },
	{ value: "MICRONESIA", label: "MICRONESIA" },
	{ value: "TUVALU", label: "TUVALU" },
	{ value: "ISLAS COOK", label: "ISLAS COOK" },
	{ value: "NAURU", label: "NAURU" },
	{ value: "PALAOS", label: "PALAOS" },
	{ value: "TIMOR ORIENTAL", label: "TIMOR ORIENTAL" },
	{ value: "POLINESIA FRANCESA", label: "POLINESIA FRANCESA" },
	{ value: "ISLA NORFOLK", label: "ISLA NORFOLK" },
	{ value: "KIRIBATI", label: "KIRIBATI" },
	{ value: "NIUE", label: "NIUE" },
	{ value: "ISLAS PITCAIRN", label: "ISLAS PITCAIRN" },
	{ value: "TOKELAU", label: "TOKELAU" },
	{ value: "NUEVA CALEDONIA", label: "NUEVA CALEDONIA" },
	{ value: "WALLIS Y FORTUNA", label: "WALLIS Y FORTUNA" },
	{ value: "SAMOA AMERICANA", label: "SAMOA AMERICANA" },
	{ value: "OTROS PAISES O TERRITORIOS DE OCEANIA", label: "OTROS PAISES O TERRITORIOS DE OCEANIA" },
];
