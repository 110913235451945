import React, { useState, useEffect } from "react";
import { Form, Input, Button, Modal, Alert, notification, Popover, Typography } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import GestionarClaveService from "../../../services/GestionarClaveService";
import LoginService from "../../../services/LoginService";

const host = window.location.host;
const subdominio = host.split(".")[0];
const { Link } = Typography;
const search = window.location.search;
const params = new URLSearchParams(search);
const nc = params.get("nc");
const td = params.get("td");
const nd = params.get("nd");

export function GenerarClavePage() {
	let history = useHistory();
	const [colaborador, setColaborador] = useState({});
	const [mensaje, setMensaje] = useState("");
	const [mensajeError, setMensajeError] = useState("");
	const [urlFondo, setUrlFondo] = useState("");
	const [urlLogotipo, setUrlLogotipo] = useState("");
	const [loading, setLoading] = useState(false);
	const [formulario] = Form.useForm();
	const [politicasActivas, setPoliticasActivas] = useState([]);

	useEffect(() => {
		async function cargarDatos() {
			const datos = await LoginService.cargarDatosIniciales(subdominio);
			console.log("datos===>", datos);
			setUrlFondo(datos.urlImagenFondo);
			setUrlLogotipo(datos.urlImagenLogotipo);
			setPoliticasActivas(datos.activePolicies);

			const colaborador = await GestionarClaveService.validarInformacion(nc, td, nd, subdominio);
			setColaborador(colaborador);
			formulario.setFieldsValue({
				//	documentoIdentidad: colaborador.numeroDocumentoIdentidad,
			});

			setMensaje(
				<div>
					<span style={{ color: "#1890ff", fontSize: "17px" }}>Bienvenido: </span>
					<span>{colaborador.nombreCompleto}</span>
					<br />
				</div>
			);
		}
		cargarDatos();
	}, []);

	if (
		colaborador.claveGenerada &&
		(colaborador.solicitoCambiarClave === false || colaborador.solicitoCambiarClave === null)
	) {
		redireccionarPaginaLogin();
	}

	function redireccionarPaginaLogin() {
		history.push("/login");
	}

	async function handleOnFinish(formulario) {
		const documentoTrim = formulario.documentoIdentidad.replace(/\s+/g, "");
		try {
			if (colaborador.numeroDocumentoIdentidad !== documentoTrim) {
				notification["error"]({
					message: "Error",
					description: "El número de documento de identidad es incorrecto.",
				});
			} else {
				setLoading(true);
				const mensajeValidacion = await GestionarClaveService.generarClave(subdominio, documentoTrim, formulario.clave);
				setMensajeError(mensajeValidacion);
				if (mensajeValidacion == null || mensajeValidacion=="") { 
					Modal.success({
						title: "Clave Generada Exitosamente",
						content: "Ingresa a la plataforma con tu número de documento de identidad y clave.",
						okText: "Aceptar",
						onOk() {
							history.push("/login");
						},
					});
				}
				setLoading(false);
			}
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	}

	const content = (
    <div>
      {politicasActivas && politicasActivas.map((politica, index) => (
        <p key={index}>{politica}</p>
      ))}
    </div>
	);
	
	return (
		<div className="container-login">
			<div className="container-login--fondo" style={{ backgroundImage: "url(" + urlFondo + ")" }}></div>
			<div className="container-login--wrapper">
				<img className="container-login--wrapper__logotipo" src={urlLogotipo} width="180px" alt="" />

				<div className="container-login--wrapper__titulo">
					<span>Generar Clave</span>
				</div>

				<Alert style={{ marginBottom: 24 }} message={mensaje} type="info" />

				{mensajeError && (
					<Alert
					style={{
						marginBottom: 24,
					}}
					message={mensajeError}
					type="error"
					showIcon
					/>
				)}
				<Form form={formulario} layout="vertical" name="frmLogin" onFinish={handleOnFinish}>
					<FormItem
						label="Documento de identidad"
						name="documentoIdentidad"
						rules={[
							{
								required: true,
								message: "Ingrese su documento de identidad",
							},
							({ getFieldValue }) => ({
								validator(rule, value) {
									const reg = RegExp("^[a-zA-Z0-9 ]*$");
									if (reg.test(getFieldValue("documentoIdentidad"))) {
										return Promise.resolve();
									}
									return Promise.reject("No se permite caracteres especiales.");
								},
							}),
						]}
					>
						<Input />
					</FormItem>

					<FormItem
						label="Clave"
						name="clave"
						rules={[
							{
								required: true,
								message: "Ingrese su clave",
							},
						]}
					>
						<Input.Password />
					</FormItem>
					<FormItem
						label="Repetir Clave"
						name="claveR"
						dependencies={["clave"]}
						hasFeedback
						rules={[
							{
								required: true,
								message: "Ingrese nuevamente su clave",
							},
							({ getFieldValue }) => ({
								validator(rule, value) {
									if (!value || getFieldValue("clave") === value) {
										return Promise.resolve();
									}

									return Promise.reject("Las claves deben ser iguales!");
								},
							}),
						]}
					>
						<Input.Password />
					</FormItem>
					{politicasActivas != null &&
						<div style={{ paddingBottom:"10px"}}>
							<Popover content={content} title="Políticas de la clave">
								<Link>Políticas de la clave</Link>
							</Popover>	
						</div>
					}
					<FormItem>
						<Button
							type="primary"
							icon={<CheckOutlined />}
							htmlType="submit"
							size="large"
							style={{ width: "100%" }}
							loading={loading}
						>
							Generar Clave
						</Button>
					</FormItem>
				</Form>
				
			</div>
		</div>
	);
}
