import React from "react";
import { Card, Row, Col, Avatar, Divider, Image, Space, Upload, message, Form, Button } from "antd";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import { useBuscarColaborador, useCargarColaborador } from "pages/perfil/componentes/common/hooks";
import LoginService from "services/LoginService";
import { Headers } from "enum";
import ImgCrop from "antd-img-crop";

const CardInformacionPersonal = () => {
	const { colaborador } = useBuscarColaborador();
	const { cargarColaborador } = useCargarColaborador();

	const propsFoto = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/actualizar-url-foto-personal",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
		},
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				cargarColaborador();
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};
	return (
		<div>
			<Card>
				<Row>
					<Col span={8}>
						<Space align="center">
							<Col>
								<div style={{ textAlign: "center" }}>
									{colaborador?.urlFotoPersonalFirmado != null ? (
										<Image
											src={colaborador?.urlFotoPersonalFirmado}
											width={100}
											style={{ borderRadius: "50%" }}
										/>
									) : (
										<Avatar size={100} icon={<UserOutlined />} />
									)}
								</div>
								<div>
									<Form.Item name="fotoColaborador" label="">
										<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
											<Upload {...propsFoto} maxCount={1} accept={["image/*"]}>
												<Button block icon={<UploadOutlined />} type="link">
													Cambiar Foto
												</Button>
											</Upload>
										</ImgCrop>
									</Form.Item>
								</div>
							</Col>

							<Col>
								<div style={{ textAlign: "center", color: "#0a63a4" }}>
									{colaborador?.apellidoPaterno} {colaborador?.apellidoMaterno} {colaborador?.nombres}
								</div>
								<div style={{ textAlign: "center", color: "#999999" }}>
									{colaborador?.cargo?.nombre}
								</div>
							</Col>
						</Space>
					</Col>
					{/* <Divider type="vertical" /> */}
					<Col span={8}>
						<div>
							<p style={{ color: "#999999" }}> Estado </p>
							<p style={{ color: "#0a63a4" }}> {colaborador?.estadoRegistro}</p>
						</div>
						<div>
							<p style={{ color: "#999999" }}> Documento Identidad </p>
							<p style={{ color: "#0a63a4" }}> {colaborador?.numeroDocumentoIdentidad}</p>
						</div>
					</Col>
					<Col span={8}>
						<div>
							<p style={{ color: "#999999" }}> Correo Electrónico </p>
							<p style={{ color: "#0a63a4" }}> {colaborador?.correoElectronico}</p>
						</div>

						<div>
							<p style={{ color: "#999999" }}> Celular </p>
							<p style={{ color: "#0a63a4" }}> {colaborador?.celular}</p>
						</div>
					</Col>
				</Row>
			</Card>
		</div>
	);
};
export default CardInformacionPersonal;
