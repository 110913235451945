import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  notification,
  Alert
} from "antd";
//import { ContainerCrear } from "../../../components/ContainerCrear";
import FormItem from "antd/lib/form/FormItem";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import GestionarClaveService from "../../../services/GestionarClaveService";

const host = window.location.host;
const subdominio = host.split(".")[0];
export function ActualizarClavePage() {
  const [formularioRegistrarClave] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visibleAlertError, setVisibleAlertError] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  // const [envio, setEnvio] = useState(false);
  // const [correoElectronico, setCorreoElectronico] = useState("");

  let history = useHistory();

  useEffect(() => {
    async function cargarDatos() { }
    cargarDatos();
  }, []);

  async function handleOnFinish(formulario) {
    try {
      setLoading(true);
      const rpta = await GestionarClaveService.recuperarClave(
        subdominio,
        formulario.numeroDocumentoIdentidad.trim(),
        formulario.correoElectronico.trim()
      );

      if (rpta === "OK") {
        // setEnvio(true);
        setVisibleAlertError(false);
        setMensajeError("");
        // setCorreoElectronico(formulario.correoElectronico);

        Modal.success({
          title: "Recuperación exitosa",
          content: (
            <span>
              Enviamos un correo electrónico para cambiar la clave
							a <b>{formulario.correoElectronico}</b>
            </span>
          ),
          onOk() {
            history.push("/login");
          },
        });
      } else {
        // setEnvio(false);
        setVisibleAlertError(true);
        setMensajeError(rpta);
        // setCorreoElectronico("");
      }

      // notification.success({
      //   message: "Operación exitosa",
      //   description: "Se registro y/o actualizo su clave.",
      // });
      setLoading(false);
      // history.push("/login");
    } catch (error) {
      // setEnvio(false);
      setLoading(false);
      console.error(error.response);
      console.error(error.response.data.mensaje);
      notification.error({
        message: "Error",
        description: "Error-> " + error.response.data.mensaje,
      });
    }
  }

  function onCloseMensajeAlerta() {
    setVisibleAlertError(false);
  }

  function handleOnClickCancelar() {
    history.push("/login");
  }

  const onChangeNumeroDocumentoIdentidad=(e)=>{
    const dni=formularioRegistrarClave.getFieldValue("numeroDocumentoIdentidad").replace(/\s+/g, '');
    formularioRegistrarClave.setFieldsValue({
      "numeroDocumentoIdentidad":dni,
    })    
  }

  return (
    <div className="container">
      <div className="container-recuperar-clave-wrapper">
        <Button
          type="text"
          style={{
            textAlign: "left",
            backgroundColor: "white",
            marginBottom: "20px",
          }}
          onClick={handleOnClickCancelar}
        >
          <LeftOutlined /> Retornar
          </Button>

        <img
          style={{
            margin: "0 auto",
            display: "inline",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          src="https://storage.googleapis.com/milegajodigital.com/admin/publico/logotipo.png"
          width="140px"
          height="40px"
          alt=""
        />

        <span className="container-recuperar-clave-titulo">
          Recuperar clave
          </span>

        {visibleAlertError ? (
          <Alert
            message={mensajeError}
            type="warning"
            closable
            onClose={onCloseMensajeAlerta}
          />
        ) : (
            ""
          )}

        <Form
          form={formularioRegistrarClave}
          layout="vertical"
          onFinish={handleOnFinish}
          style={{ marginTop: "10px" }}
        >
          <FormItem
            label="Número documento identidad"
            name="numeroDocumentoIdentidad"
            rules={[
              {
                required: true,
                message:
                  "Ingresa número documento identidad",
                whitespace:true
              },
            ]}
          >
            <Input autoComplete="off" onChange={onChangeNumeroDocumentoIdentidad}  />
          </FormItem>

          <FormItem
            label="Correo electrónico"
            name="correoElectronico"
            rules={[
              {
                type: "email",
                message:
                  "Correo electrónico ingresado no es válido",
              },
              {
                required: true,
                message: "Ingrese correo electrónico",
              },
            ]}
          >
            <Input autoComplete="off" />
          </FormItem>

          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "100%" }}
            >
              Recuperar Clave
              </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
}
