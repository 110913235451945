import React, { useEffect, useState } from 'react';
import { Provider as JotaiProvider, useAtom } from "jotai";
import { Button, Card, Col, Form, Layout, Row, Steps, Upload } from 'antd';
import LoginService from 'services/LoginService';
import styled from '@emotion/styled';
import { formInstanceAtom, pasoActualAtom, stepsAtom } from './common/store/Store';
import { UploadOutlined } from "@ant-design/icons"
import { ModalSubirFirma } from 'components/common';
import { useInformacionPersonal } from './common/hooks';

const { Header, Content } = Layout;

const OnboardingPageV3 = () => {

    return (
        <JotaiProvider>
            <div>
                <Header style={style.header}>
                    <Row>
                        <Col span={4}>
                            <img
                                src={LoginService.getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo}
                                width="64px"
                                alt="Logotipo" />
                        </Col>
                        <Col span={16}>
                            <span style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "18px", fontWeight: "bold" }}>
                                Proceso de ingreso
                            </span>
                        </Col>
                        <Col span={4}>
                            Cerrar Sesión
                        </Col>
                    </Row>
                </Header>
                <div>
                    <Pasos />
                    <Contenido />
                </div>
            </div>
        </JotaiProvider>
    );
};

const Pasos = () => {

    const [pasoActual] = useAtom(pasoActualAtom);

    return (
        <ContainerStep>
            <Steps
                current={pasoActual}
                items={[
                    {
                        title: 'Información Personal',
                        subTitle: '0%',
                    },
                    {
                        title: 'Documentos Digitales',
                        subTitle: '0%'
                    }
                ]}
            />
        </ContainerStep>
    )
}

const Contenido = () => {
    const [pasoActual] = useAtom(pasoActualAtom);

    if (pasoActual === 0) {
        return <InformacionPersonal />
    }

    return "";
}

const InformacionPersonal = () => {

    const [form] = Form.useForm();
    const [, setFormInstance] = useAtom(formInstanceAtom);
    const [openModalSubirFirma, setOpenModalSubirFirma] = useState(false);
    const [firmaHolografa, setFirmaHolografa] = useState(undefined);
    const { guardarInformacionFormulario } = useInformacionPersonal();

    const Container = styled.div`
        padding: 0px 20%;
        margin: 10px 0px;;
    `

    useEffect(() => {
        setFormInstance(form);
    }, []);

    return (
        <Container>
            <Form layout="vertical" form={form}>
                <Card
                    size="small"
                    style={style.card}
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="firmaholografa"
                                label="Firma Hológrafa"
                                valuePropName="firmaholografa"
                                rules={[
                                    {
                                        required: true,
                                        message: "Suba una imágen de su firma hológrafa",
                                    },
                                ]}
                            >
                                <Button
                                    onClick={() => setOpenModalSubirFirma(true)}
                                    block
                                    icon={<UploadOutlined />}
                                    style={{ flex: 1 }}
                                >
                                    Subir Firma Hológrafa
                                </Button>
                                <Upload
                                    fileList={firmaHolografa}
                                    style={{ width: "100%" }}
                                    onRemove={() => {
                                        setFirmaHolografa([]);

                                        return {
                                            firmaHolografa: [],
                                        };
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px" }}>
                    <Button
                        type='primary'
                        shape='round'
                        size='large
                        ' style={{ padding: "0px 30px" }}
                        onClick={guardarInformacionFormulario}>
                        Continuar {">"}
                    </Button>
                </div>
            </Form>
            <ModalSubirFirma
                visible={openModalSubirFirma}
                onAceptar={(file) => {
                    if (file && file.length > 0) {
                        setFirmaHolografa([...file]);
                        form.setFieldsValue({ firmaholografa: { fileList: file } });
                    }
                    setOpenModalSubirFirma(false);
                }}
                onCerrar={() => setOpenModalSubirFirma(false)} />
        </Container>
    )
}

const ContainerStep = styled.div`
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    background: #fff;
    padding: 15px 25%;
`

const style = {
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
    },
    content: {
        background: "#fff"
    },
    card: {
        borderRadius: "10px",
        marginTop: "10px"
    }
}

export default OnboardingPageV3;