import React, { useContext,useEffect } from "react";
import ReactDOM from 'react-dom';
import { OrganizationGraph } from '@ant-design/graphs';
import { useAtom } from "jotai";
import { listaOrganigramaAtom , dataAtom} from "../../../../../PerfilStore";

const Organigrama = () => {
	const [listaOrganigrama, setListaOrganigrama] = useAtom(listaOrganigramaAtom);
	const [dataOrganigrama, setDataOrganigrama] = useAtom(dataAtom);

	const data = dataOrganigrama;

	return (
		<React.Fragment>
			<OrganizationGraph
				data={data}
				nodeCfg={{
					size: [40, 40],
					autoFit: false,
					autoWidth: true,
					items: {
						layout: 'follow',
					},
					padding: 5,
    				fitCenter: true,
					style: (node) => {
						return node.id ===  listaOrganigrama[listaOrganigrama.length - 1]?.id
							? {
								fill: '#91d5ff',
								stroke: '#91d5ff',
								fontSize: 5
							}
							: {};
					},
					label: {
						style: (node, group, type) => {
							const styles = {
								icon: {
									width: 30,
									height: 30,
								},
								title: {
									fill: '#000',
									fontSize: 5,
								},
								name: {
									fill: '#000',
									fontSize: 6,
									fontWeight: 'bold',
								},
							};
							return  styles[type];
						},
					},
					// anchorPoints: [
					// 	[0, 0.5],
					// 	[1, 0.5],
					//   ],
				}
					
			} /> 
			{/* <OrganizationGraph
				data={data}
				nodeCfg={{
					autoWidth: true,
					style: (node) => {
					return node.id === listaOrganigrama[listaOrganigrama.length - 1]?.id
						? {
							fill: '#91d5ff',
							stroke: '#91d5ff',
						}
						: {};
					},
					label: {
					style: (node, group, type) => {
						const styles = {
						icon: {
							width: 32,
							height: 32,
						},
						title: {
							fill: '#fff',
						},
						name: {
							fill: '#fff',
						},
						};
						return node.id === listaOrganigrama[listaOrganigrama.length - 1]?.id ? styles[type] : {};
					},
					},
				}}
    		/> */}
		</React.Fragment>
	);
};
export default Organigrama;
