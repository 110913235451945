import React from "react";
import { Skeleton } from "antd";

export function LoadingDocumentos({ items }) {
	function DocumentoSkeleton() {
		return <Skeleton active avatar paragraph={{ rows: 2 }} />;
	}

	function ListaDocumentosSkeleton() {
		const elementos = [];
		for (var posicion = 0; posicion < items; posicion++) {
			elementos.push(<DocumentoSkeleton key={posicion} />);
		}
		return elementos;
	}

	return (
		<div style={{ padding: "15px" }}>
			<ListaDocumentosSkeleton />
		</div>
	);
}
