import { useEffect } from "react";

import { RenovacionContratosService } from "services/contratos";
import { useAtom } from "jotai";
import LoginService from "services/LoginService";
//import { contratosAtom } from "../store/SeguimientoContratoStore";
import { contratosAtom } from "pages/contratos/renovacion/RenovacionContratosStore";

const useListarContratos = () => {
	const [contratos, setContratos] = useAtom(contratosAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			if (LoginService.isJefeProyecto()) {
				const contratos = await RenovacionContratosService.listarContratosPendientesDeAprobarPorJefeProyecto();
				setContratos(contratos);
			} else if (LoginService.isAdministradorObra()) {
				const contratos =
					await RenovacionContratosService.listarContratosPendientesDeAprobarPorAdministradorObra();
				setContratos(contratos);
			}
		};
		cargarDatos();
	}, []);

	return { contratos, setContratos };
};

export default useListarContratos;
