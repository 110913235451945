import React, { useState } from "react";
import { Modal, Button, Space, Row, Drawer, Divider, Col } from "antd";
import { PDFObject } from "react-pdfobject";
import { FileTextOutlined, UserOutlined, CalendarOutlined } from "@ant-design/icons";
//import PDFViewer from "pdf-viewer-reactjs";

export function ModalPDF({
	ruta,
	visible,
	handleOnOkModal,
	handleOnClickFirmar,
	firmaConformidad,
	documentoNombre,
	representanteLegal,
	fechaFirma,
	nombreColaborador,
	fechaFirmaColaborador,
	estadoDetalleEnvio,
	handleOnClickConsultar,
}) {
	const [visibleDrawer, setVisibleDrawer] = useState(false);

	function MostarDrawer() {
		setVisibleDrawer(true);
	}
	function cerraDrawer() {
		setVisibleDrawer(false);
	}

	return (
		<Modal
			title={
				firmaConformidad ? (
					<Row>
						<Col span={10}>
							<Button type="primary" shape="round" onClick={MostarDrawer}>
								Más Información
							</Button>
						</Col>
						<Col span={14}>
							<Space>
								<Button onClick={handleOnOkModal}>Cancelar</Button>
								<Button type="primary" onClick={handleOnClickFirmar}>
									Firmar
								</Button>
								<Button type="primary" onClick={handleOnClickConsultar}>
									Consultar
								</Button>
							</Space>{" "}
						</Col>{" "}
					</Row>
				) : (
					<Button type="primary" shape="round" onClick={MostarDrawer}>
						Más Información
					</Button>
				)
			}
			visible={visible}
			onCancel={handleOnOkModal}
			style={{
				top: 20,
				bottom: 20,
			}}
			bodyStyle={{ padding: "0px", height: "calc(100vh - 50px)" }}
			width="96vw"
			height="96vh"
			footer={null}
		>
			<PDFObject url={ruta} width={"96vw"} height={"calc(100vh - 50px)"} />
			<Drawer placement="right" onClose={cerraDrawer} visible={visibleDrawer} width={450}>
				<p style={{ fontSize: "16px" }}>Datos del Documento</p>
				<Divider />
				<p style={{ fontSize: "15px" }}>
					<FileTextOutlined style={{ fontSize: "20px", color: "#1678c1" }} /> {documentoNombre}{" "}
				</p>
				<p style={{ fontSize: "15px" }}>
					<UserOutlined style={{ fontSize: "20px", color: "#1678c1" }} /> Firmado por representante legal{" "}
					{representanteLegal}
				</p>
				<p style={{ fontSize: "15px" }}>
					<CalendarOutlined style={{ fontSize: "20px", color: "#1678c1" }} /> Firma realizada el {fechaFirma}{" "}
				</p>
				<br />
				{estadoDetalleEnvio === 8 && (
					<React.Fragment>
						<p style={{ fontSize: "16px" }}>Datos del Colaborador</p>
						<Divider />
						<p style={{ fontSize: "15px" }}>
							<UserOutlined style={{ fontSize: "20px", color: "#1678c1" }} /> {nombreColaborador}
						</p>
						<p style={{ fontSize: "15px" }}>
							<CalendarOutlined style={{ fontSize: "20px", color: "#1678c1" }} /> Firma realizada el{" "}
							{fechaFirmaColaborador}{" "}
						</p>
					</React.Fragment>
				)}
			</Drawer>
		</Modal>
	);
}
