import React from "react";
import { Modal, Button, Select } from "antd";
import { Option } from "antd/lib/mentions";
export default function ModalGenerarcodigo({
  visibleModal,
  oncerrarModal,
  handleOk,
  handleOnChangeCorreo,
  defaultValorCorreo,
  listaCorreos,
}) {
  return (
    <Modal
      visible={visibleModal}
      title="Generar código de verificación Clave"
      onCancel={oncerrarModal}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Ok
        </Button>,
      ]}
    >
      <p>
        Por favor seleccionar el correo al cual se le enviará el código de
        seguridad :
      </p>
      <Select
        placeholder="Seleccione Correo"
        defaultValue={defaultValorCorreo}
        onChange={handleOnChangeCorreo}
      >
        {listaCorreos.map((correo) => {
          return (
            <Option key={correo} value={correo}>
              {correo}
            </Option>
          );
        })}
      </Select>
    </Modal>
  );
}
