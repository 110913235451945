import React from "react";
import { usePreguntasProactividad } from "../../hooks";
import { Preguntas } from "../Preguntas";

const PreguntasProactividad = () => {
	const { frmPreguntas, setPuntajeTotal } = usePreguntasProactividad();

	return (
		<Preguntas
			form={frmPreguntas}
			calcularPuntajeTotal={setPuntajeTotal}
			titulo="PROACTIVIDAD (2/6)"
			opciones={[
				{
					key: "muestraNuevasIdeasParaMejorarLosProcesos",
					label: "Muestra nuevas ideas para mejorar los procesos",
				},
				{ key: "seMuestraAsequibleAlCambio", label: "Se muestra asequible al cambio" },
				{ key: "seAnticipaALasDificultades", label: "Se anticipa a las dificultades" },
				{
					key: "tieneGranCapacidadParaResolverProblemas",
					label: "Tiene gran capacidad para resolver problemas",
				},
			]}
		/>
	);
};

export default PreguntasProactividad;
