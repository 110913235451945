import React from "react";
import { Table,Tag } from "antd";
import { useListarSolicitudPersonal } from "pages/solicitud-personal/componentes/common/hooks";
import { EstadoSolicituPersonal } from "enum/EstadoSolicitudPersonal";
import MenuAcciones from "./MenuAcciones";
const TablaSolicitudPersonal = () => {
    const { solicitudesPersonal } = useListarSolicitudPersonal();
    return (
        <Table rowKey={(value) => value.id} dataSource={solicitudesPersonal} columns={columnas} pagination={false} />
    )
}
    const columnas = [
        {
            title: "Perfil",
            dataIndex: ["perfilPuesto", "descripcion"],
            align:"center",
        },
         {
            title: "Cantidad",
             dataIndex: "cantidad",
             align:"center",
        },
          {
            title: "FechaSolicitud",
              dataIndex: "fechaSolicitud",
             align:"center",
        },
           {
            title: "Fecha Inicio Labores",
               dataIndex: "fechaInicioLabores",
             align:"center",
        },
        {
            title: "Estado",
            //dataIndex: "estado",
            align: "center",
            render:(fila)=>(<EtiquetaEstado estado={fila.estado}/>)
        },
        {
            title: "Acciones",
            key: "acciones",
            align: "center",
            render: (fila) => <MenuAcciones solicitud={fila} />,
        }
        
     ]
    const EtiquetaEstado = ({ estado }) => {
    switch (estado) {
        case EstadoSolicituPersonal.PENDIENTE:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;

        case EstadoSolicituPersonal.INICIADO:
          
           return <Tag color="green" className="milegajo-ant-tag">{EstadoSolicituPersonal.INICIADO}</Tag>

        case EstadoSolicituPersonal.RECHAZADO:
          
           return <Tag color="red" className="milegajo-ant-tag">{EstadoSolicituPersonal.RECHAZADO}</Tag>                
        
       case EstadoSolicituPersonal.CERRADO:
          
           return <Tag color="magenta" className="milegajo-ant-tag">{EstadoSolicituPersonal.CERRADO}</Tag>

        default:
            return <Tag className="milegajo-ant-tag">{estado}</Tag>;
    }
}
    

export default TablaSolicitudPersonal;