import React, { useContext } from "react";
import { Button, Dropdown, Menu, Space } from "antd";
import { UnorderedListOutlined, SelectOutlined, CheckSquareOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import {
	visibleModalCrearSolicitudAusenciaAtom,
	visibleModalRecordVacacionalAtom,
} from "pages/vacaciones/componentes/common/mis-solicitudes/store/MisSolicitudesStore";

import { useHistory } from "react-router-dom";

import logotipo from "../../../../../../../assets/imagenes/logotipo.png";
import "./Header.css";
import { SecurityContext } from "../../../../../../../context/SecurityContextProvider";

const Header = () => {
	const [, setVisibleModalCrearSolicitudAusencia] = useAtom(visibleModalCrearSolicitudAusenciaAtom);
	const [, setVisibleModalRecordVacacional] = useAtom(visibleModalRecordVacacionalAtom);
	const history = useHistory();
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	
	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;
	
	const onClickMenu = (opcion) => {
		switch (opcion.key) {
			case "vacaciones-disponibles":
				setVisibleModalRecordVacacional(true);
				break;

			case "pendientes-aprobacion":
				history.push("/movil/vacaciones/solicitudes-pendientes-aprobar");
				break;

			default:
				console.error("No se encuentra esa opción");
		}
	};

	const menu = () => {
		return (
			<Menu onClick={onClickMenu} style={{ border: "1px solid #d9d9d9" }}>
				<Menu.Item key="vacaciones-disponibles">
					<SelectOutlined /> Vacaciones Disponibles
				</Menu.Item>
				<Menu.Item key="pendientes-aprobacion">
					<CheckSquareOutlined /> Pendientes de Aprobación
				</Menu.Item>
			</Menu>
		);
	};

	return (
		<div className="container-movil-pdf-header">
			<div style={{ width: "60%" }}>
				<img src={logotipo} alt="" style={{ height: "36px", marginRight: "10px" }} />
				{!isAceros && ("Vacaciones solicitadas")}
				{isAceros && ("Ausentismos solicitados")}
			</div>
			<div style={{ width: "40%", textAlign: "right", paddingRight: "10px" }}>
				<Space>
					<Button type="primary" onClick={() => setVisibleModalCrearSolicitudAusencia(true)}>
						Solicitar
					</Button>
					<Dropdown overlay={menu} trigger={["click"]} placement="bottomRight" arrow>
						<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
							<UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
							<CaretDownOutlined
								style={{ paddingLeft: "2px", fontSize: "12px", fontWeight: "bold", color: "#1678c1" }}
							/>
						</a>
					</Dropdown>
				</Space>
			</div>
		</div>
	);
};

export default Header;
