import React, { useEffect } from "react";
import { Alert, Button, Col, DatePicker, Form, Modal, Row, Input, Table, message } from "antd";
import { useListarContratos } from "../../../common/hooks";
import { LikeOutlined, DislikeOutlined, QuestionCircleOutlined, MailOutlined, SearchOutlined } from "@ant-design/icons";
import { useAtom, useSetAtom } from "jotai";
import {
	contratoSeleccionadoAtom,
	contratosAtom,
	visibleModalRechazarRenovacionAtom,
	visibleModalRenovarContratoAtom,
	urlPdfContratoAtom,
	visibleModalVisorPdfAtom,
	visibleBtnRenovarContratoAtom
} from "pages/contratos/renovacion/RenovacionContratosStore";
import { useState } from "react";
import { RenovacionContratosService } from "services/contratos";
import produce from "immer";
import moment from "moment";
import ModalRechazarRenovacion from "./ModalRechazarRenovacion";
import LoginService from "services/LoginService";
import { ModalVisorPdf as VisorPdf } from "components/common";
import { Provider as JotaiProvider } from "jotai";

const ContratosPendientesRenovacionTab = () => {
	return (
		<JotaiProvider>
			<ListaContratos />
			<ModalRenovarContrato />
			<ModalRechazarRenovacion />
			<ModalVisorPdf />
		</JotaiProvider>
	);
};

const ListaContratos = () => {
	const { Search } = Input;
	const { contratos, setContratos } = useListarContratos();
	const [loading, setLoading] = useState(false);

	const columnas = [
		{
			title: "Contrato",
			dataIndex: "nombreContrato",
		},
		{
			title: "Colaborador",
			dataIndex: ["colaborador", "nombreCompleto"],
		},
		{
			title: "Cargo",
			dataIndex: ["colaborador", "cargo"],
		},
		{
			title: "Fecha Inicio",
			dataIndex: "fechaInicio",
			align: "center",
		},
		{
			title: "Fecha Fin",
			dataIndex: "fechaFin",
			align: "center",
		},
		{
			title: "Estado",
			dataIndex: "estado",
			align: "center",
		},
		{
			title: "Acciones",
			align: "center",
			width: "15%",
			render: (fila) => <MenuAcciones contrato={fila} />,
		},
	];

	const onSearch = async (valor) => {
		setLoading(true);

		let cargo = "";
		if (LoginService.isJefeProyecto()) {
			cargo = "JEFE_PROYECTO";
		} else if (LoginService.isAdministradorObra()) {
			cargo = "ADMIN_OBRA";
		}

		const contratosFiltro =
			await RenovacionContratosService.listarContratosPendientesDeAprobarPorJefeProyectoOrAdministrador({
				filtro: valor.trim(),
				cargo: cargo,
			});
		setContratos(contratosFiltro);
		setLoading(false);
	};

	return (
		<div>
			<Row>
				<Col span={12}>
					<Search
						placeholder="Buscar por Nombre ó Documento de identidad"
						allowClear
						loading={loading}
						onSearch={onSearch}
						enterButton
					/>
				</Col>
			</Row>

			<br />
			<Table rowKey={(item) => item.id} columns={columnas} dataSource={contratos} pagination={false} />
		</div>
	);
};

const MenuAcciones = ({ contrato }) => {
	const [, setVisibleModalRenovarContrato] = useAtom(visibleModalRenovarContratoAtom);
	const [, setVisibleModalRechazarRenovacion] = useAtom(visibleModalRechazarRenovacionAtom);
	const [, setContratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
	const [, setUrlPdfContrato] = useAtom(urlPdfContratoAtom);
	const [, setVisibleVisorPdf] = useAtom(visibleModalVisorPdfAtom);
	const [visibleRenovar, setVisibleRenovar] = useAtom(visibleBtnRenovarContratoAtom);
	//console.log("contratos::", contrato);
	// const setInformacionVisor = useSetAtom(informacionVisorAtom);
	// const setVisibleVisor = useSetAtom(visibleVisorAtom);
	// const setVisibleSubirDocumentos = useSetAtom(visibleSubirDocumentosAtom);

	const onClickBtnRenovar = () => {
		setContratoSeleccionado(contrato);
		if (LoginService.isAdministradorObra()) {
			setVisibleRenovar(true);
		} else { 
			setVisibleRenovar(false);
		}	
		setVisibleModalRenovarContrato(true);
	};

	const onClickBtnRechazar = () => {
		setContratoSeleccionado(contrato);
		setVisibleModalRechazarRenovacion(true);
	};

	const onClickBtnVisualizarContrato = async () => {
		console.log("entreeeee");

		const url = await RenovacionContratosService.getUrlVistaPrevia(contrato.id);
		console.log("Urllll:::::", url);

		setUrlPdfContrato(url);
		setVisibleVisorPdf(true);
		// setInformacionVisor({
		// 				urlPlantillaPdfFirmada: url,
		// 				componentes: [],
		// 				variablesGlobales: {},
		// 				datosFormulario: {},
		// 			});
		// setVisibleVisor(true);
		// setVisibleSubirDocumentos(false);
	};

	return (
		<React.Fragment>
			{
				contrato?.tipoRegistro != "MIGRACION" && (
					<Button type="link" onClick={onClickBtnVisualizarContrato}>
						<SearchOutlined /> Visualizar contrato actual
					</Button>
				)
			}
			
			<Button type="link" onClick={onClickBtnRenovar}>
				{LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.administradorObra && (
					<>
						<MailOutlined /> Notificar
					</>
				)}
				{LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.jefeProyecto && (
					<>
						<LikeOutlined /> Renovar
					</>
				)}
			</Button>
			<Button type="link" danger onClick={onClickBtnRechazar}>
				<DislikeOutlined /> Rechazar
			</Button>
		</React.Fragment>
	);
};

const ModalRenovarContrato = () => {
	const [form] = Form.useForm();

	const [contratos, setContratos] = useAtom(contratosAtom);
	const [contratoSeleccionado, setContratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
	const [visible, setVisible] = useAtom(visibleModalRenovarContratoAtom);
	const [loading, setLoading] = useState(false);
	const [visibleRenovar, setVisibleRenovar] = useAtom(visibleBtnRenovarContratoAtom);
	const [, setUrlPdfContrato] = useAtom(urlPdfContratoAtom);
	const [, setVisibleVisorPdf] = useAtom(visibleModalVisorPdfAtom);

	console.log("contratoSeleccionado:::", contratoSeleccionado);

	useEffect(() => {
		form.resetFields();
		if (contratoSeleccionado) {
			form.setFieldsValue({
				fechaInicio: moment(contratoSeleccionado?.fechaInicioRenovacion),
			});
		}
	}, [contratoSeleccionado, form]);
	
	const onClickBtnRenovar = async () => {
		if (LoginService.isJefeProyecto()) {
			form.validateFields()
				.then(async (values) => {
					try {
						await RenovacionContratosService.aprobarRenovacionContratoPorJefeProyecto({
							seguimientoContratoId: contratoSeleccionado.id,
							numeroDocumentoIdentidadAprobador:
								LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
							//fechaInicio: contratoSeleccionado.fechaInicioRenovacion,
							fechaInicio: moment(contratoSeleccionado.fechaInicioRenovacion).format("DD/MM/YYYY"),
							fechaFin: moment(values.fechaFin).format("DD/MM/YYYY"),
							//..values
						});

						message.success(
							"Se ha solicitado la validación de la renovación de contrato a Administración de Personal"
						);

						setContratos(
							produce((draft) => {
								const index = draft.findIndex((contrato) => contrato.id === contratoSeleccionado.id);
								draft.splice(index, 1);
							})
						);

						//const _documentos = [...contratos]; //siempre se debe generar un nuevo array
						//const indice = contratos.findIndex((c) => c.id === contratoSeleccionado.id);
						//const contratoEliminar = contratos[indice];

						//_documentos.splice(indice, 1, contratoEliminar);
						//colaboradoresAgregados.filter(item => item.id !== colaboradorId)
						//_documentos.splice(indice, 1);
						//setContratos(_documentos.filter(item => item.id !== contratoSeleccionado.id));

						setContratoSeleccionado(undefined);
						setLoading(false);
						setVisible(false);
					} catch (error) {
						message.error("Ha ocurrido un error, favor de intentar nuevamente.");
					}
				})
				.catch((info) => {
					console.log("Validate Failed:", info);
				});
		} else if (LoginService.isAdministradorObra()) {
			try {
				await RenovacionContratosService.aprobarRenovacionContratoPorAdministradorObra({
					seguimientoContratoId: contratoSeleccionado.id,
				});
				message.success("Se ha notificado el contrato al colaborador");
				setContratos(
					produce((draft) => {
						const index = draft.findIndex((contrato) => contrato.id === contratoSeleccionado.id);
						draft.splice(index, 1);
					})
				);
				setContratoSeleccionado(undefined);
				setLoading(false);
				setVisible(false);
			} catch (error) {
				message.error("Ha ocurrido un error, favor de intentar nuevamente.");
			}
		}
	};

	const onClickBtnCancelar = () => {
		setVisible(false);
	};

	const disabledDate = (current) => {
		return current && current < form.getFieldValue("fechaInicio").endOf("day");
		//return current && current < moment().endOf("day");
	};

	const handleVisualizarContrato = async () => {
		form.validateFields()
			.then(async (values) => {	
				console.log("visualizar contrato");
				console.log("entreeeee");
				
			
				let fechaInicio = moment(contratoSeleccionado.fechaInicioRenovacion).format("DD-MM-YYYY");
				let fechaFin = moment(values.fechaFin).format("DD-MM-YYYY");
				console.log("fechaInicio::",fechaInicio);
				console.log("fechaFin::", fechaFin);
				const url = await RenovacionContratosService.getVistaPreviaPdf(contratoSeleccionado.id, fechaInicio, fechaFin);
				console.log("Urllll:::::", url);

				setVisibleRenovar(true);
				setUrlPdfContrato(url);
				setVisibleVisorPdf(true);
		})
		.catch((info) => {
			console.log("Validate Failed:", info);
		});
				// const url = await RenovacionContratosService.getUrlVistaPrevia(contrato.id);
		// console.log("Urllll:::::", url);

		// setUrlPdfContrato(url);
		// setVisibleVisorPdf(true);
		// setInformacionVisor({
		// 				urlPlantillaPdfFirmada: url,
		// 				componentes: [],
		// 				variablesGlobales: {},
		// 				datosFormulario: {},
		// 			});
		// setVisibleVisor(true);
		// setVisibleSubirDocumentos(false);
	};

	return (
		<Modal
			title="Renovar Contrato"
			open={visible}
			// onOk={onClickBtnRenovar}
			onCancel={onClickBtnCancelar}
			// okText="Si, Aprobar Renovación!"
			// okCancel="Cancelar"
			footer={[
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Button key="cancelar" onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
						Cancelar
					</Button>
					<div style={{ display: 'flex', gap: '10px' }}>
						{!LoginService.isAdministradorObra() && (
							<Button key="visualizar" icon={<SearchOutlined />} onClick={handleVisualizarContrato}>
								Visualizar
							</Button>
						) }
						<Button key="aceptar" type="primary" disabled={!visibleRenovar} onClick={onClickBtnRenovar} style={{ padding: "0px 30px" }}>
						Si, Aprobar!
						</Button>
      				</div>
    			</div>
			]}
			confirmLoading={loading}
		>
			<div>
				<div style={{ textAlign: "center", paddingBottom: "15px" }}>
					<QuestionCircleOutlined style={{ fontSize: "40px", color: "#ebc334" }} />
				</div>
				<div style={{ fontSize: "18px", textAlign: "center", marginBottom: "20px", color: "#457b9d" }}>
					¿Está seguro de renovar el contrato?
				</div>
				{LoginService.isJefeProyecto() && (
					<div>
						<Form form={form} layout="vertical" autoComplete="off">
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										label="Fecha Inicio"
										name="fechaInicio"
										rules={[
											{
												required: true,
											},
										]}
										// initialValue={moment(contratoSeleccionado?.fechaInicioRenovacion)}
									>
										<DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} disabled />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Fecha Fin"
										name="fechaFin"
										rules={[
											{
												required: true,
											},
										]}
									>
										<DatePicker
											style={{ width: "100%" }}
											format={"DD/MM/YYYY"}
											disabledDate={disabledDate}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={24}>
									<Alert
										message="Es recomendable que la fecha fin del nuevo contrato sea al cierre de cada mes"
										type="info"
										showIcon
									/>
								</Col>
							</Row>
						</Form>
					</div>
				)}
			</div>
		</Modal>
	);
};

const ModalVisorPdf = () => {
	const [visible, setVisible] = useAtom(visibleModalVisorPdfAtom);
	const [urlPdfContrato] = useAtom(urlPdfContratoAtom);
	console.log("visible::", visible);
	console.log("urlPdf::", urlPdfContrato);

	return (
		<VisorPdf type={"visualizar"} urlPdf={urlPdfContrato} visible={visible} onCerrar={() => setVisible(false)} />
	);
};

export default ContratosPendientesRenovacionTab;
