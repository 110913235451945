import { HttpService } from "../HttpService";
import { URL } from "enum";
const URL_API = URL.RECLUTAMIENTO_Y_SELECCION;

const EntrevistasService = {
	async listar() {
		const respuesta = await HttpService(URL_API).get("/portal-colaborador/entrevistas");
		return respuesta.data;
	},
	async buscarPorId(entrevistaId) {
		const respuesta = await HttpService(URL_API).get(`/entrevista/${entrevistaId}`);
		return respuesta.data;
	},
	async completar(entrevista) {
		const respuesta = await HttpService(URL_API).post("/entrevista/completar", entrevista);
		return respuesta.data;
	},
	async obtenerEstadoEntrevista(entrevistaId) {
		const respuesta = await HttpService(URL_API).get(`/entrevistas/${entrevistaId}/entrevistador/estado`);
		return respuesta.data;
	},
	async obtenerInformacionPersonal(entrevistaId) {
		const respuesta = await HttpService(URL_API).get(
			`/entrevistas/${entrevistaId}/entrevistador/informacion-personal`
		);
		return respuesta.data;
	},
	async completarInformacionPersonal(informacionPersonal) {
		const respuesta = await HttpService(URL_API).post(
			"/entrevistas/entrevistador/completar-informacion-personal",
			informacionPersonal
		);
		return respuesta.data;
	},
	async obtenerExperienciasLaborales(entrevistaId) {
		const respuesta = await HttpService(URL_API).get(
			`/entrevistas/${entrevistaId}/entrevistador/experiencias-laborales`
		);
		return respuesta.data;
	},
	async obtenerCompetenciasLaborales(entrevistaId) {
		const respuesta = await HttpService(URL_API).get(
			`/entrevistas/${entrevistaId}/entrevistador/competencias-laborales`
		);
		return respuesta.data;
	},
	async obtenerPreguntas(entrevistaId) {
		const respuesta = await HttpService(URL_API).get(`/entrevistas/${entrevistaId}/entrevistador/preguntas`);
		return respuesta.data;
	},
	async agregarExperienciasLaborales(data) {
		const respuesta = await HttpService(URL_API).post(
			"/entrevistas/entrevistador/agregar-experiencias-laborales",
			data
		);
		return respuesta.data;
	},
	async completarCompetenciasLaborales(data) {
		const respuesta = await HttpService(URL_API).post(
			"/entrevistas/entrevistador/completar-competencias-laborales",
			data
		);
		return respuesta.data;
	},
	async completarPreguntas(data) {
		const respuesta = await HttpService(URL_API).post("/entrevistas/entrevistador/completar-preguntas", data);
		return respuesta.data;
	},
	async completarResultado(data) {
		const respuesta = await HttpService(URL_API).post("/entrevistas/entrevistador/completar-resultado", data);
		return respuesta.data;
	},
	async listarPorPostulante(postulanteId,convocatoriaId) {
    const respuesta = await HttpService(URL_API).get("/entrevistas/postulantes", {
      params: {
         postulanteId: postulanteId,
         convocatoriaId:convocatoriaId },
    });
    return respuesta.data;
  },
};
export default EntrevistasService;
