import React from "react";
import { FileImageFilled, FilePdfFilled } from '@ant-design/icons';
import { Card, Modal, Row, Typography } from "antd";

export function SeleccionarTipoCarga({ setSeleccionado, setIsImage, setMostrar }) {
    const { Meta } = Card
    const { Title } = Typography;

    const gridStyle = {
        width: '50%',
        textAlign: 'center',
        color: "#1c74b3"
    };

    const textStyle = {
        //color: "#1c74b3",
        fontSize: '12px'
    };

    return (
        <div style={{padding: "70px 50px"}}>
            <Row>
                <Card
                    hoverable
                    style={gridStyle}
                    cover={<FileImageFilled style={{ fontSize: '50px', marginTop: "20px" }} />}
                    onClick={() => { setSeleccionado(true); setIsImage(true); setMostrar(false) }}
                >
                    <Meta title={<Title style={textStyle} > Subir Imagen</Title>} />
                </Card>
                <Card
                    hoverable
                    style={gridStyle}
                    cover={<FilePdfFilled style={{ fontSize: '50px', marginTop: "20px" }} />}
                    onClick={() => { setSeleccionado(true); setIsImage(true); setIsImage(false); setMostrar(false) }}
                >
                    <Meta title={<Title style={textStyle} >Subir PDF</Title>} />
                </Card>
            </Row>
        </div>
    )

}