import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Input, Select, DatePicker } from "antd";
import { useAtom } from "jotai";
import { visibleModalDatosNacimientoAtom } from "pages/movil/PerfilMovilStore";
import { UbigeoService } from "services";
import { SecurityContext } from "context/SecurityContextProvider";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { Paises } from "enum/Paises";
import moment from "moment";
const ModalEditarDatosNacimiento = ({ colaborador, setColaborador }) => {
	const [visibleModal, setVisibleModal] = useAtom(visibleModalDatosNacimientoAtom);
	const [departamentos, setDepartamentos] = useState([]);
	const [provincias, setProvincias] = useState([]);
	const [distritos, setDistritos] = useState([]);
	const [formulario] = Form.useForm();
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [mostrarSelect, setMostrarSelect] = useState(true);

	useEffect(() => {
		const cargarDatos = async () => {
			const departamentos = await UbigeoService.listarDepartamentos();
			setDepartamentos(departamentos);

			const formData = new FormData();
			formData.append("departamento", colaborador?.lugarNacimiento?.departamento);
			const provincias_ = await UbigeoService.listarProvincias(formData);
			setProvincias(provincias_);

			const formDataDistrito = new FormData();
			formDataDistrito.append("provincia", colaborador?.lugarNacimiento?.provincia);
			const distritos_ = await UbigeoService.listarDistritos(formDataDistrito);
			setDistritos(distritos_);
			if (colaborador?.lugarNacimiento && colaborador?.lugarNacimiento.pais === "PERU") setMostrarSelect(true);
			else setMostrarSelect(false);

			formulario.setFieldsValue({
				datosNacimiento: {
					pais: colaborador?.lugarNacimiento?.pais,
					nacionalidad: colaborador?.lugarNacimiento?.nacionalidad,
					departamento: colaborador?.lugarNacimiento?.departamento,
					provincia: colaborador?.lugarNacimiento?.provincia,
					distrito: colaborador?.lugarNacimiento?.distrito,
					direccion: colaborador?.lugarNacimiento?.direccion,
					fechaNacimiento: colaborador?.fechaNacimiento && moment(colaborador?.fechaNacimiento, "DD/MM/YYYY"),
				},
			});
		};

		cargarDatos();
	}, [colaborador]);

	const onChangeDepartamento = async (value) => {
		const formData = new FormData();
		formData.append("departamento", value);
		const provincias = await UbigeoService.listarProvincias(formData);
		setProvincias(provincias);

		formulario.setFieldsValue({
			datosNacimiento: {
				provincia: provincias[0].provincia,
			},
		});

		const formDataDistrito = new FormData();
		formDataDistrito.append("provincia", provincias[0].provincia);

		const distritos = await UbigeoService.listarDistritos(formDataDistrito);
		setDistritos(distritos);

		formulario.setFieldsValue({
			datosNacimiento: {
				distrito: distritos[0].distrito,
			},
		});
	};
	const onChangeProvincia = async (value) => {
		const formData = new FormData();
		formData.append("provincia", value);
		const distritos = await UbigeoService.listarDistritos(formData);
		setDistritos(distritos);

		formulario.setFieldsValue({
			datosNacimiento: {
				distrito: distritos[0].distrito,
			},
		});
	};

	const handleCancel = async () => {
		setVisibleModal(false);
		formulario.resetFields();
		setProvincias([]);
		setDistritos([]);

		const formData = new FormData();
		formData.append("departamento", colaborador?.lugarNacimiento?.departamento);
		const provincias_ = await UbigeoService.listarProvincias(formData);
		setProvincias(provincias_);

		const formDataDistrito = new FormData();
		formDataDistrito.append("provincia", colaborador?.lugarNacimiento?.provincia);
		const distritos_ = await UbigeoService.listarDistritos(formDataDistrito);
		setDistritos(distritos_);
		if (colaborador?.lugarNacimiento && colaborador?.lugarNacimiento.pais === "PERU") setMostrarSelect(true);
		else setMostrarSelect(false);
	};

	const handleActualizarDatosNacimiento = async (colaborador) => {
		colaborador.datosPersonales = {
			numeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
		};
		await PortalColaboradorService.actualizarDatosNacimiento(colaborador);
		const response = await PortalColaboradorService.buscarColaborador();
		setColaborador(response);
		handleCancel();
	};
	const onChangePais = (value) => {
		if (value === "PERU") {
			setMostrarSelect(true);
		} else {
			setMostrarSelect(false);
		}
	};
	return (
		<Modal
			title="Editar Datos Nacimiento"
			open={visibleModal}
			onOk={() => formulario.submit()}
			onCancel={handleCancel}
		>
			<Form
				layout="horizontal"
				labelCol={{
					span: 8,
				}}
				wrapperCol={{
					span: 16,
				}}
				form={formulario}
				onFinish={handleActualizarDatosNacimiento}
				initialValues={{
					datosNacimiento: {
						pais: colaborador?.lugarNacimiento?.pais,
						nacionalidad: colaborador?.lugarNacimiento?.nacionalidad,
						departamento: colaborador?.lugarNacimiento?.departamento,
						provincia: colaborador?.lugarNacimiento?.provincia,
						distrito: colaborador?.lugarNacimiento?.distrito,
						direccion: colaborador?.lugarNacimiento?.direccion,
						fechaNacimiento:
							colaborador?.fechaNacimiento && moment(colaborador?.fechaNacimiento, "DD/MM/YYYY"),
					},
				}}
			>
				<Form.Item label="País" name={["datosNacimiento", "pais"]} rules={[{ required: true }]}>
					<Select
						style={{ width: "100%" }}
						allowClear
						showSearch
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						onChange={onChangePais}
					>
						{Paises !== undefined &&
							Paises?.map((objeto) => (
								<Select.Option key={objeto.value} value={objeto.value}>
									{objeto.label}
								</Select.Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item label="Nacionalidad" name={["datosNacimiento", "nacionalidad"]} rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item
					label="Fecha Nacimiento"
					name={["datosNacimiento", "fechaNacimiento"]}
					rules={[{ required: true }]}
				>
					<DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
				</Form.Item>
				<Form.Item
					label="Departamento"
					name={["datosNacimiento", "departamento"]}
					rules={[
						{
							required: true,
							message: "Ingrese el departamento",
						},
					]}
				>
					{mostrarSelect ? (
						<Select
							style={{ width: "100%" }}
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={onChangeDepartamento}
						>
							{departamentos !== undefined &&
								departamentos?.map((departamento) => (
									<Select.Option key={departamento.nombre} value={departamento.nombre}>
										{departamento.nombre}
									</Select.Option>
								))}
						</Select>
					) : (
						<Input />
					)}
				</Form.Item>
				<Form.Item
					label="Provincia"
					name={["datosNacimiento", "provincia"]}
					rules={[
						{
							required: true,
							message: "Ingrese la provincia",
						},
					]}
				>
					{mostrarSelect ? (
						<Select
							style={{ width: "100%" }}
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={onChangeProvincia}
						>
							{provincias !== undefined &&
								provincias?.map((objeto) => (
									<Select.Option key={objeto.provincia} value={objeto.provincia}>
										{objeto.provincia}
									</Select.Option>
								))}
						</Select>
					) : (
						<Input />
					)}
				</Form.Item>
				<Form.Item
					label="Distrito"
					name={["datosNacimiento", "distrito"]}
					rules={[
						{
							required: true,
							message: "Ingresa el distrito",
						},
					]}
				>
					{mostrarSelect ? (
						<Select
							style={{ width: "100%" }}
							showSearch
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{distritos !== undefined &&
								distritos?.map((objeto) => (
									<Select.Option key={objeto.distrito} value={objeto.distrito}>
										{objeto.distrito}
									</Select.Option>
								))}
						</Select>
					) : (
						<Input />
					)}
				</Form.Item>
				<Form.Item
					label="Dirección"
					name={["datosNacimiento", "direccion"]}
					rules={[
						{
							required: true,
							message: "Ingresa una dirección",
						},
					]}
				>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};
export default ModalEditarDatosNacimiento;
