import React, { useEffect, useContext, useState } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";
import { SecurityContext } from "context/SecurityContextProvider";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";

export function Body() {
	const [totalPaginas, setTotalPaginas] = useState(undefined);
	const {
		documentoSeleccionadoId,
		loadingPdf,
		urlPdf,
		getUrlPdf,
		setBlobPdf,
		iniciarCargaPdf,
		finalizarCargaPdf,
		reloadPdf,
		setReloadPdf,
		documentoSeleccionado,
		mostrarModalFirmar,
	} = useContext(MisDocumentosContext);

	const { getUsuarioAutenticado } = useContext(SecurityContext);

	useEffect(() => {
		if (documentoSeleccionadoId) {
			cargarPdf();
		}
	}, [documentoSeleccionadoId]);

	useEffect(() => {
		if (reloadPdf === true) {
			cargarPdf();
		}

		return () => {
			setReloadPdf(undefined);
		};
	}, [reloadPdf]);

	const cargarPdf = async () => {
		iniciarCargaPdf();
		getUrlPdf(getUsuarioAutenticado().empresaSeleccionada.id, documentoSeleccionadoId);
	};

	async function onLoadSuccess(successEvent) {
		const _data = await successEvent.getData();
		const _blob = new Blob([_data], { type: "application/pdf" });
		setBlobPdf(_blob);
		setTotalPaginas(successEvent.numPages);
		finalizarCargaPdf();

		if (
			documentoSeleccionado.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE &&
			documentoSeleccionado.mostrarVentanaFirmaAntesDeVisualizarDocumento
		) {
			mostrarModalFirmar();
		}
	}

	return (
		<div className="visor-pdf-body">
			{loadingPdf && (
				<div className="container-loading">
					<Loading3QuartersOutlined spin className="loading" />
				</div>
			)}

			<Document file={urlPdf} onLoadSuccess={onLoadSuccess} loading={null}>
				{Array.from(new Array(totalPaginas), (el, index) => (
					<Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.5} loading={""} />
				))}
			</Document>
		</div>
	);
}
