import React from "react";

export const SecurityContext = React.createContext();

export default function SecurityContextProvider({ children }) {
	function getVersionApiColaborador() {
		return getUsuarioAutenticado().versionApiColaborador;
	}

	function estaAutenticado() {
		if (localStorage.getItem("jwt-token-colaborador")) {
			return true;
		}
		return false;
	}

	function setSesionUsuario(datos) {
		localStorage.setItem("jwt-token-colaborador", datos.token);

		localStorage.setItem(
			"jwt-sesion-colaborador",
			JSON.stringify({
				id: datos.usuario.id,
				nombres: datos.usuario.nombres,
				nombreCompleto: datos.usuario.nombreCompleto,
				subdominio: datos.usuario.subdominio,
				empresaSeleccionada: datos.usuario.empresaFavorita,
				empresas: datos.usuario.empresas,
				organizacionId: datos.usuario.organizacionId,
				login: datos.usuario.login,
				correoElectronico: datos.usuario.correoElectronico,
				numeroDocumentoIdentidad: datos.usuario.numeroDocumentoIdentidad,
				cambiarClaveAlIniciarSesion: datos.usuario.cambiarClaveAlIniciarSesion,
				versionApiColaborador: datos.usuario.versionApiColaborador,
			})
		);
	}

	function setActualizarSesionUsuario(datos) {
		localStorage.setItem(
			"jwt-sesion-colaborador",
			JSON.stringify({
				id: datos.usuario.id,
				nombres: datos.usuario.nombres,
				nombreCompleto: datos.usuario.nombreCompleto,
				subdominio: datos.usuario.subdominio,
				empresaSeleccionada: datos.usuario.empresaFavorita,
				empresas: datos.usuario.empresas,
				organizacionId: datos.usuario.organizacionId,
				login: datos.usuario.login,
				correoElectronico: datos.usuario.correoElectronico,
				numeroDocumentoIdentidad: datos.usuario.numeroDocumentoIdentidad,
				cambiarClaveAlIniciarSesion: datos.usuario.cambiarClaveAlIniciarSesion,
				versionApiColaborador: datos.usuario.versionApiColaborador,
			})
		);
	}

	function cerrarSesion() {
		//TODO: consultar con endpoint para invalidar token
		localStorage.removeItem("jwt-token-colaborador");
		localStorage.removeItem("jwt-sesion-colaborador");
		window.location.href = "/login";
	}

	function getUsuarioAutenticado() {
		return JSON.parse(localStorage.getItem("jwt-sesion-colaborador"));
	}

	function setEmpresaSeleccionada(idEmpresa) {
		const empresa = getUsuarioAutenticado().empresas.find((empresa) => empresa.id === idEmpresa);
		localStorage.setItem(
			"jwt-sesion-colaborador",
			JSON.stringify({
				...getUsuarioAutenticado(),
				empresaSeleccionada: empresa,
			})
		);
	}

	function actualizarEstadoCambiarClaveIniciarSesion(estado) {
		localStorage.setItem(
			"jwt-sesion-colaborador",
			JSON.stringify({
				...getUsuarioAutenticado(),
				cambiarClaveAlIniciarSesion: estado,
			})
		);
	}

	function actualizarEstadoFormulario() {
		const _empresaSeleccionada = getUsuarioAutenticado().empresaSeleccionada;
		_empresaSeleccionada.colaborador.informacionPersonalCompletado = true;
		localStorage.setItem(
			"jwt-sesion-colaborador",
			JSON.stringify({
				...getUsuarioAutenticado(),
				empresaSeleccionada: _empresaSeleccionada,
			})
		);
	}

	return (
		<SecurityContext.Provider
			value={{
				getVersionApiColaborador,
				estaAutenticado,
				setSesionUsuario,
				cerrarSesion,
				getUsuarioAutenticado,
				setEmpresaSeleccionada,
				actualizarEstadoCambiarClaveIniciarSesion,
				setActualizarSesionUsuario,
				actualizarEstadoFormulario
			}}
		>
			{children}
		</SecurityContext.Provider>
	);
}
