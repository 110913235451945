import React, { useContext, useEffect } from "react";
import { Button, Descriptions, Image, Row, Col, message, Upload, Form } from "antd";
import { useAtom } from "jotai";
import {
	colaboradorAtom,
	listaOrganigramaAtom,
	dataAtom,
	visibleModalDomicilioAtom,
	visibleModalDatosNacimientoAtom,
	visibleModalContactoEmergenciaAtom,
} from "../../../../../PerfilStore";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import LoginService from "services/LoginService";
import { Headers } from "enum";
import { useCargarColaborador } from "pages/perfil/componentes/common/hooks";
import ImgCrop from "antd-img-crop";
import { SecurityContext } from "context/SecurityContextProvider";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { ModalEditarDomicilio } from "pages/perfil/componentes/common/EditarDomicilio";
import { ModalEditarDatosNacimiento } from "pages/perfil/componentes/common/EditarDatosNacimiento";
import { ModalEditarcontactoEmergencia } from "pages/perfil/componentes/common/EditarContactoEmergencia";
const DatosPersonales = () => {
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const maccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const [colaborador] = useAtom(colaboradorAtom);
	const { cargarColaborador } = useCargarColaborador();
	const [listaOrganigrama, setListaOrganigrama] = useAtom(listaOrganigramaAtom);
	const [dataOrganigrama, setDataOrganigrama] = useAtom(dataAtom);
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	const [, setModalDomicilio] = useAtom(visibleModalDomicilioAtom);
	const [, setModalDatosNacimiento] = useAtom(visibleModalDatosNacimientoAtom);
	const [, setModalContactoEmergencia] = useAtom(visibleModalContactoEmergenciaAtom);
	const isPSP = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20601257280" ? true : false;
	useEffect(() => {
		const cargarDatos = async () => {
			const _consultas = await PortalColaboradorService.buscarOrganigrama(
				getUsuarioAutenticado().empresaSeleccionada.id,
				getUsuarioAutenticado().numeroDocumentoIdentidad
			);
			setListaOrganigrama(_consultas);
			//console.log("Lista organigrama:::", _consultas);
			//children: convertirAReactData(listaOrganigrama, listaOrganigrama[listaOrganigrama.length - 1]?.id)
			const data = {
				id: _consultas[_consultas.length - 1]?.id,
				value: {
					name: _consultas[_consultas.length - 1]?.nombresYApellidosPaterno,
					title: _consultas[_consultas.length - 1]?.cargo,
					icon:
						_consultas[_consultas.length - 1]?.urlFotoPersonal != null
							? _consultas[_consultas.length - 1]?.urlFotoPersonal
							: "https://storage.googleapis.com/dev.milegajodigital.com/_default/configuracion/imagen/icons8-persona-de-sexo-masculino-64.png",
				},
				children: convertirAReactData(_consultas, _consultas[_consultas.length - 1]?.id),
			};
			//console.log("data:::", data);
			setDataOrganigrama(data);
			//setDataOrganigrama()
		};
		cargarDatos();
	}, []);

	const propsFirma = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/actualizar-url-firma-holografa",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
		},

		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				cargarColaborador();
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};
	const propsHuella = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/actualizar-url-huella",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
		},

		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				cargarColaborador();
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	const convertirAReactData = (colaboradores, jefeInmediato) => {
		const data = colaboradores
			.filter((colaborador) => colaborador?.jefeInmediato?.id === jefeInmediato)
			.map((colaborador) => {
				const subordinados = convertirAReactData(colaboradores, colaborador?.id);
				return {
					id: colaborador?.id,
					value: {
						name: colaborador?.nombresYApellidosPaterno,
						title: colaborador?.cargo,
						icon:
							colaborador?.urlFotoPersonal != null
								? colaborador?.urlFotoPersonal
								: "https://storage.googleapis.com/dev.milegajodigital.com/_default/configuracion/imagen/icons8-persona-de-sexo-masculino-64.png",
					},
					...(subordinados.length > 0 && { children: subordinados }),
				};
			});

		return data;
	};

	const onClickEditarDomicilio = () => {
		setModalDomicilio(true);
	};
	const onClickEditarDatosNacimiento = () => {
		setModalDatosNacimiento(true);
	};
	const onClickEditarContactoEmergencia = () => {
		setModalContactoEmergencia(true);
	};

	return (
		<React.Fragment>
			<Descriptions title="Información Personal" bordered size="small">
				<Descriptions.Item label="Documento Identidad" span={3}>
					{`${colaborador?.tipoDocumentoIdentidad} - ${colaborador?.numeroDocumentoIdentidad}`}
				</Descriptions.Item>
				<Descriptions.Item label="Apellidos y Nombres" span={3}>
					{colaborador?.apellidoPaterno} {colaborador?.apellidoMaterno} {colaborador?.nombres}
				</Descriptions.Item>
				<Descriptions.Item label="Género" span={3}>
					{colaborador?.sexoColaborador}
				</Descriptions.Item>
				<Descriptions.Item label="Fecha Nacimiento" span={3}>
					{colaborador?.fechaNacimiento}
				</Descriptions.Item>
				<Descriptions.Item label="Estado Civil" span={3}>
					{colaborador?.estadoCivil}
				</Descriptions.Item>
				<Descriptions.Item label="Firma Hológrafa" span={3} contentStyle={{ textAlign: "center" }}>
					<Row gutter={16}>
						<Col span={8}>
							{colaborador?.urlFirmaFirmado != null ? (
								<Image src={colaborador?.urlFirmaFirmado} width={100} />
							) : (
								"Pendiente"
							)}
						</Col>
						{isExplomin && colaborador.estadoImagenFirma !== "APROBADO" && (
							<Col span={8}>
								<Form.Item name="firmaHolografa" label="">
									<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
										<Upload {...propsFirma} maxCount={1} accept={["image/*"]}>
											<Button block icon={<UploadOutlined />} type="link">
												Cambiar Firma Hológrafa
											</Button>
										</Upload>
									</ImgCrop>
								</Form.Item>
							</Col>
						)}
						{!isExplomin && (
							<Col span={8}>
								<Form.Item name="firmaHolografa" label="">
									<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
										<Upload {...propsFirma} maxCount={1} accept={["image/*"]}>
											<Button block icon={<UploadOutlined />} type="link">
												Cambiar Firma Hológrafa
											</Button>
										</Upload>
									</ImgCrop>
								</Form.Item>
							</Col>
						)}
					</Row>
				</Descriptions.Item>
				{!maccaferriDePeru && (
					<Descriptions.Item label="Huella Dactilar" span={3} contentStyle={{ textAlign: "center" }}>
						<Row gutter={16}>
							<Col span={8}>
								{colaborador?.urlHuellaFirmado != null ? (
									<Image src={colaborador?.urlHuellaFirmado} width={100} />
								) : (
									"Pendiente"
								)}
							</Col>
							{isExplomin && colaborador.estadoImagenHuella !== "APROBADO" && (
								<Col span={8}>
									<Form.Item name="huellaDactilar" label="">
										<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
											<Upload {...propsHuella} maxCount={1} accept={["image/*"]}>
												<Button block icon={<UploadOutlined />} type="link">
													Cambiar Huella Dactilar
												</Button>
											</Upload>
										</ImgCrop>
									</Form.Item>
								</Col>
							)}
							{!isExplomin && (
								<Col span={8}>
									<Form.Item name="huellaDactilar" label="">
										<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
											<Upload {...propsHuella} maxCount={1} accept={["image/*"]}>
												<Button block icon={<UploadOutlined />} type="link">
													Cambiar Huella Dactilar
												</Button>
											</Upload>
										</ImgCrop>
									</Form.Item>
								</Col>
							)}
						</Row>
					</Descriptions.Item>
				)}
			</Descriptions>
			<br />
			{/* <Descriptions title="Contacto" bordered size="small">
				<Descriptions.Item label="Correo Electrónico" span={3}>
					{colaborador?.correoElectronico}
				</Descriptions.Item>
				<Descriptions.Item label="Celular" span={3}>
					{colaborador?.celular}
				</Descriptions.Item>
				<Descriptions.Item label="Dirección" span={3}>
					{colaborador?.domicilioActual?.direccion}
				</Descriptions.Item>
			</Descriptions> */}
			<Descriptions
				title="Información Domicilio"
				bordered
				size="small"
				extra={
					isPSP && (
						<Button type={"link"} onClick={onClickEditarDomicilio}>
							Actualizar
						</Button>
					)
				}
			>
				<Descriptions.Item label="Dirección" span={3}>
					{colaborador?.domicilioActual?.direccion}
				</Descriptions.Item>
				<Descriptions.Item label="Departamento" span={3}>
					{colaborador?.domicilioActual?.departamento}
				</Descriptions.Item>
				<Descriptions.Item label="Provincia" span={3}>
					{colaborador?.domicilioActual?.provincia}
				</Descriptions.Item>
				<Descriptions.Item label="Distrito" span={3}>
					{colaborador?.domicilioActual?.distrito}
				</Descriptions.Item>
			</Descriptions>
			{/* <br /> */}
			{/* <Descriptions title="Empresa" bordered size="small">
				<Descriptions.Item label="Código" span={3}>
					{colaborador?.codigoTrabajador}
				</Descriptions.Item>
				<Descriptions.Item label="Fecha Ingreso" span={3}>
					{colaborador?.fechaIngreso}
				</Descriptions.Item>
				<Descriptions.Item label="Jefe Inmediato" span={3}>
					{colaborador?.jefeInmediato?.apellidoPaterno} {colaborador?.jefeInmediato?.apellidoMaterno}{" "}
					{colaborador?.jefeInmediato?.nombres}
				</Descriptions.Item>
			</Descriptions> */}
			<br />
			<Descriptions title="Régimen Pensionario" bordered size="small">
				<Descriptions.Item label="Tipo" span={3}>
					{colaborador?.regimenPensionario?.tipo}
				</Descriptions.Item>
				<Descriptions.Item label="Cuspp AFP" span={3}>
					{colaborador?.regimenPensionario?.cusppAfp}
				</Descriptions.Item>
				<Descriptions.Item label="Nombre AFP" span={3}>
					{colaborador?.regimenPensionario?.nombreAfp}
				</Descriptions.Item>
			</Descriptions>
			<br />
			<Descriptions title="Cuenta Bancaria" bordered size="small">
				<Descriptions.Item label="Banco" span={3}>
					{colaborador?.cuentaHaberes?.banco}
				</Descriptions.Item>
				<Descriptions.Item label="Moneda" span={3}>
					{colaborador?.cuentaHaberes?.tipoMoneda}
				</Descriptions.Item>
				<Descriptions.Item label="N° Cuenta" span={3}>
					{colaborador?.cuentaHaberes?.nroCuenta}
				</Descriptions.Item>
			</Descriptions>
			<br />
			<Descriptions title="Cuenta CTS" bordered size="small">
				<Descriptions.Item label="Banco" span={3}>
					{colaborador?.cuentaCTS?.banco}
				</Descriptions.Item>
				<Descriptions.Item label="Moneda" span={3}>
					{colaborador?.cuentaCTS?.tipoMoneda}
				</Descriptions.Item>
				<Descriptions.Item label="N° Cuenta" span={3}>
					{colaborador?.cuentaCTS?.nroCuenta}
				</Descriptions.Item>
			</Descriptions>
			<br />
			<Descriptions
				title="Datos de Nacimiento"
				bordered
				size="small"
				extra={
					isPSP && (
						<Button type={"link"} onClick={onClickEditarDatosNacimiento}>
							Actualizar
						</Button>
					)
				}
			>
				<Descriptions.Item label="País" span={3}>
					{colaborador?.lugarNacimiento?.pais}
				</Descriptions.Item>
				<Descriptions.Item label="Nacionalidad" span={3}>
					{colaborador?.lugarNacimiento?.nacionalidad}
				</Descriptions.Item>
				<Descriptions.Item label="Fecha" span={3}>
					{colaborador?.fechaNacimiento}
				</Descriptions.Item>
				<Descriptions.Item label="Departamento" span={3}>
					{colaborador?.lugarNacimiento?.departamento}
				</Descriptions.Item>
				<Descriptions.Item label="Provincia" span={3}>
					{colaborador?.lugarNacimiento?.provincia}
				</Descriptions.Item>
				<Descriptions.Item label="Distrito" span={3}>
					{colaborador?.lugarNacimiento?.distrito}
				</Descriptions.Item>
				<Descriptions.Item label="Dirección" span={3}>
					{colaborador?.lugarNacimiento?.direccion}
				</Descriptions.Item>
			</Descriptions>
			<br />
			<Descriptions
				title="Contacto Emergencia"
				bordered
				size="small"
				extra={
					isPSP && (
						<Button type={"link"} onClick={onClickEditarContactoEmergencia}>
							Actualizar
						</Button>
					)
				}
			>
				<Descriptions.Item label="Nombre" span={3}>
					{colaborador?.contactoEmergencia?.nombres}
				</Descriptions.Item>
				<Descriptions.Item label="Teléfono" span={3}>
					{colaborador?.contactoEmergencia?.telefono}
				</Descriptions.Item>
			</Descriptions>
			<ModalEditarDomicilio />
			<ModalEditarDatosNacimiento />
			<ModalEditarcontactoEmergencia />
		</React.Fragment>
	);
};
export default DatosPersonales;
