import React from "react";
import { TipoControl } from "enum";
import { DatePicker, Form, Select, Input } from "antd";

const Control = ({ info }) => {
	if (info.type === TipoControl.LISTA) {
		return <Lista info={info} />;
	} else if (info.type === TipoControl.FECHA) {
		return <Fecha info={info} />;
	} else if (info.type === TipoControl.CAJA_TEXTO) {
		return <CajaTexto info={info} />;
	}
	return null;
};

const CajaTexto = ({ info }) => {
	return (
		<Form.Item
			label={info.label}
			name={["informacionAdicional", info.key]}
			labelAlign="left"
			rules={[{ required: info.required }]}
		>
			<Input />
		</Form.Item>
	);
};

const Fecha = ({ info }) => {
	return (
		<Form.Item
			label={info.label}
			name={["informacionAdicional", info.key]}
			labelAlign="left"
			rules={[{ required: info.required }]}
		>
			<DatePicker allowClear={true} format={"DD/MM/YYYY"} style={{ width: "100%" }} />
		</Form.Item>
	);
};

const Lista = ({ info }) => {
	return (
		<Form.Item
			label={info.label}
			name={["informacionAdicional", info.key]}
			labelAlign="left"
			rules={[{ required: info.required }]}
		>
			<Select style={{ width: "100%" }}>
				{info.items !== undefined &&
					info.items.map((item) => {
						return (
							<Select.Option key={item.key} value={item.value}>
								{item.value}
							</Select.Option>
						);
					})}
			</Select>
		</Form.Item>
	);
};

export default Control;
