import React from "react";
import { Header, SeleccionarEmpresa } from "./componentes";

const PerfilColaboradorMovilPage = () => {
	return (
		<React.Fragment>
			<Header />
			<SeleccionarEmpresa />
		</React.Fragment>
	);
};

export default PerfilColaboradorMovilPage;
