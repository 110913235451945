import React from "react";
import { Button, Table, Tag } from "antd";
import { useListarEntrevistas } from "pages/entrevistas/componentes/common/hooks";
import { useHistory } from "react-router-dom";
import { SpinLoading } from "components/SpinLoading";
import { useAtom } from "jotai";
import { loadingAtom } from "pages/entrevistas/componentes/common/store/EntrevistasStore";

const TablaEntrevistas = () => {
	const { entrevistas } = useListarEntrevistas();
	const [loading,] = useAtom(loadingAtom)
	let history = useHistory();
	const onClickDetalle = (entrevista) => {
		history.push(`/entrevistas/detalle/${entrevista.id}`);
	};
	const columnas = [
		{
			title: "Hora Entrevista",
			dataIndex: "horaEntrevista",
			align: "center",
		},
		{
			title: "Puesto",
			// dataIndex: ["puesto","nombre"],
			align: "center",
			render: (fila) =>
				fila.estado === "PENDIENTE" ? (
					<Button type="text" onClick={() => onClickDetalle(fila)}>
						{fila?.puesto?.nombre}
					</Button>
				) : (
					fila.puesto.nombre
				),
		},
		{
			title: "Postulante",
			dataIndex: ["postulante", "nombreCompleto"],
			align: "center",
		},
		{
			title: "Estado",
			dataIndex: "estado",
			align: "center",
			render: (fila) => <EtiquetaEstado estado={fila} />,
			sorter: (a, b) => a.estado.localeCompare(b.estado),
		},
		{
			title: "Acciones",
			align: "center",
			render: (fila) =>
				fila.estado === "PENDIENTE" && (
					<Button type="link" onClick={() => onClickDetalle(fila)}>
						Seleccionar
					</Button>
				),
		},
	];
	return <SpinLoading loadingPage={loading}> <Table rowKey={(entrevista) => entrevista.id} columns={columnas} dataSource={entrevistas} /> </SpinLoading>;
};
const EtiquetaEstado = ({ estado }) => {
	switch (estado) {
		case "PENDIENTE":
			return <Tag color="gold">{estado}</Tag>;

		case "REALIZADO":
			return <Tag color="green">{estado}</Tag>;

		case "DESCARTADO":
			return <Tag color="red">{estado}</Tag>;
		case "NO_ASISTIO":
			return <Tag color="red">{estado}</Tag>;
		default:
			return <Tag>-</Tag>;
	}
};
export default TablaEntrevistas;
