import React from "react";
import { Header } from "./componentes/Header";
import { ListaSolicitudesDocumentosPersonales } from "./componentes/ListaSolicitudesDocumentos";
import { ModalCrearSolicitudDocumento } from "../commons/componentes/ModalCrearSolicitudDocumento";
const SolicitudDocumentosPageMovil = () => {
	return (
		<React.Fragment>
			<Header />
			<ListaSolicitudesDocumentosPersonales />
			<ModalCrearSolicitudDocumento />
		</React.Fragment>
	);
};
export default SolicitudDocumentosPageMovil;
