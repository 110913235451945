import React from "react";
import { FileDoneOutlined } from "@ant-design/icons";
import "./index.css";

export function ItemDocumentoVisualizado({ documento, onClick }) {
	function EtiquetaEstado() {
		if (documento.estadoProcesoId === 8) {
			return "VISUALIZADO Y FIRMADO";
		} else if (documento.estadoProcesoId === 4) {
			return "CARGADO";
		} else if (
			documento.estadoProcesoNombre &&
			documento.estadoProcesoNombre.toLowerCase() === "notificado" &&
			documento.visualizado === "Si"
		) {
			// return <Tag color="green">VISUALIZADO</Tag>;
			return "VISUALIZADO";
		}
	}

	return (
		<div
			className="bandeja-entrada__item-documento-visualizado"
			onClick={onClick}
		>
			<div className="imagen">
				<FileDoneOutlined />
				{/* <img
					src={imagenDocumentoVisualizado}
					alt=""
					width="24px"
					height="30px"
				/> */}
			</div>
			<div className="informacion">
				<span className="item-documento__descripcion">
					<b>{documento.envio.descripcion}</b>
				</span>
				<span className="item-documento__tipo">
					{documento.envio.tipoDocumentoNombre}
				</span>
				<span className="item-documento__estado-visualizado">
					<EtiquetaEstado />
				</span>
			</div>
			{/* <div className="acciones">
				<EtiquetaEstado />
			</div> */}
		</div>
	);
}
