import React from "react";

import "./index.css";

export function MenuItemMovil({
	identificador,
	icono,
	titulo,
	menuActivo,
	onClick,
}) {
	let className = "container-movil-menu__opcion";
	if (identificador === menuActivo) {
		className += " activo";
	}

	function onSeleccionar() {
		onClick(identificador);
	}

	return (
		<div className={className} onClick={onSeleccionar}>
			{icono}
			<div style={{ fontSize: ".65rem", textAlign: "center" }}>
				{titulo}
			</div>
		</div>
	);
}
