import React,{useEffect,useContext} from "react";
import { DatePicker, Form, Input, Modal, Select, Tag, Upload, Button } from "antd";
import { useAtom } from "jotai";
import { EditOutlined, CalendarOutlined } from "@ant-design/icons";
import { visibleModalCrearSolicitudAtom,solicitudesPersonalAtom } from "../../store/MisSolicitudesPersonalStore";
import { useListarPerfil,useSeleccionarPerfil,useListarCargos,useSeleccionarCargo } from "../../hooks";
import Control from "./Control";
import { SecurityContext } from "context/SecurityContextProvider";
import SolicitudPersonalService from "services/reclutamiento-y-seleccion/SolicitudPersonalService";
import moment from "moment";
import produce from "immer";

const ModalCrearSolicitudPersonal = () => {
    const [visibleModal, setVisibleModal] = useAtom(visibleModalCrearSolicitudAtom);
    const [, setSolicitudesPersonal] = useAtom(solicitudesPersonalAtom);
    const { perfiles } = useListarPerfil();
    const { perfil, limpiarPerfil, onChangePerfil } = useSeleccionarPerfil();
    const { cargos } = useListarCargos();
    const { cargo, limpiarCargo, onChangeCargo } = useSeleccionarCargo();
    const [form] = Form.useForm();
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    useEffect(() => {
        const cargarDatos = () => {
            if (visibleModal) {
				form.resetFields();				
			}
        }
        cargarDatos();
    
    }, [visibleModal]);

    const onFinishCrearSolicitudPersonal = async (formulario) => {
        try {
            const perfilSeleccionado = {
            id: perfil.id,
            descripcion: perfil.nombre,
            perfilInformacionAdicional:formulario.perfilInformacionAdicional,
            }
            const puestoSeleccionado = {
                id: cargo.id,
                nombre:cargo.nombre,
            }
            formulario.puesto = puestoSeleccionado;
            formulario.perfilPuesto = perfilSeleccionado;
            const usuarioSolicitante = {
                numeroDocumentoIdentidad:getUsuarioAutenticado().numeroDocumentoIdentidad,
                nombreCompleto:getUsuarioAutenticado().nombreCompleto,
            }
            formulario.solicitante = usuarioSolicitante;
            formulario.fechaInicioLabores = formulario.fechaInicioLabores.format("DD/MM/YYYY");

            //console.log("perfil: ", formulario);
            const solicitudNueva=await SolicitudPersonalService.insertar(formulario);
            setVisibleModal(false);
            setSolicitudesPersonal(
                produce((draft) => {
					draft.push(solicitudNueva);
				})
            )
            
        } catch (error) {
            console.log(error);
        }
        
    }
    const deshabilitarFechaSolicitud= (current) => {
         if(perfil?.fechaSolicitudDisponible!==undefined){
                return current && current < moment(perfil.fechaSolicitudDisponible).endOf('day');
           }	
		
   };   
    
    return (
        <Modal
            title={"Registrar Solicitud Personal"}
			bodyStyle={{ overflowY: "auto", minHeight: "calc(100vh - 220px)", maxHeight: "calc(100vh - 220px)" }}
			width={600}
			centered
			visible={visibleModal}
			onCancel={() => {
				setVisibleModal(false);
				form.resetFields();
				limpiarPerfil();
			}}
			onOk={() => form.submit()}
			okText={"Solicitar"}
			
        >

            <Form form={form} layout={"vertical"} style={{ padding: "0px 5%" }} onFinish={onFinishCrearSolicitudPersonal}>
                <Form.Item
					label="Perfil"
					name={"perfilId"}
					labelAlign="left"
					rules={[{ required: true, message: "Seleccione el perfil" }]}
				>
					<Select style={{ width: "100%" }} onChange={onChangePerfil}>
						{perfiles !== undefined &&
							perfiles.map((p) => {
								return (
									<Select.Option key={p.id} value={p.id}>
										{p.nombre}
									</Select.Option>
								);
							})}
					</Select>
                </Form.Item>
                <Form.Item
					label="Tipo Convocatoria"
					name={"tipoConvocatoria"}
					labelAlign="left"
					rules={[{ required: true, message: "Seleccione el tipo de convocatoria." }]}>
					<Select style={{ width: "100%" }} >
                        <Select.Option key={"externa"} value={"EXTERNA"}>{"Externa"}</Select.Option>
                        <Select.Option key={"interna"} value={"INTERNA"}>{"Interna"}</Select.Option>
					</Select>
                </Form.Item>
                <Form.Item
                    label="Puesto"
                    name="puesto"
                    labelAlign="left"
                    rules={[{ required: true, message: "Seleccione el puesto" }]}
                >
                    <Select showSearch style={{ width: "100%" }} optionFilterProp="children" onChange={onChangeCargo}>
                        {cargos !== undefined &&
							cargos.map((cargo) => {
								return (
									<Select.Option key={cargo.id} value={cargo.id}>
										{cargo.nombre}
									</Select.Option>
								);
							})}
                    </Select>
                </Form.Item>
                
                <Form.Item
					label="Objetivo"
					name={"objetivo"}
					labelAlign="left"
					rules={[{ required: true, message: "Ingrese objetivo." }]}>
					<Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }}  style={{ width: "100%"  }} />
                </Form.Item>

                <Form.Item
					label="Requisitos"
					name={"requisitos"}
					labelAlign="left"
					rules={[{ required: true, message: "Ingrese los requisitos." }]}>
					<Input.TextArea autoSize={{ minRows: 4, maxRows: 7 }}  style={{ width: "100%" }} />
                </Form.Item>

                {perfil?.campos &&
					perfil.campos.map((info) => {
						return <Control key={info.key} info={info} />;
                    })}

                <Form.Item
                    label="Cantidad"
                    name="cantidad"
                    labelAlign="left"
                    rules={[{ required: true, message: "Ingrese la cantidad" }]}
                >
                   <Input/>
                </Form.Item>
                <Form.Item
                    label="Fecha Inicio Labores"
                    name="fechaInicioLabores"
                    labelAlign="left"
                    rules={[{ required: true, message: "Seleccione la fecha de inicio labores" }]}
                >
                    <DatePicker
                        allowClear={true}
                        format={"DD/MM/YYYY"}
                        style={{ width: "100%" }}
                        disabledDate={deshabilitarFechaSolicitud}                        
                    />
                </Form.Item>
                
           </Form>
        </Modal>
    )
}
export default ModalCrearSolicitudPersonal;