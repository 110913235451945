import React from "react";
import { CardInformacionPersonal } from "./componentes/CardInformacionPersonal";
import { CardDatosColaborador } from "./componentes/CardDocumentos";
import { Provider as JotaiProvider } from "jotai";
import { useState } from "react";

const PerfilPageWeb = () => {
	return (
		<JotaiProvider>
			<div>
				<CardInformacionPersonal />
				<CardDatosColaborador />
			</div>
		</JotaiProvider>
	);
};
export default PerfilPageWeb;
