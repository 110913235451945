import React, { useState, useContext, useEffect } from "react";
import { Form, Input, Button, Alert, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import LoginService from "services/LoginService";
import { useHistory } from "react-router";
import { SecurityContext } from "context/SecurityContextProvider";
import { VersionApiColaborador } from "../../enum/VersionApiColaborador";

const LoginGenericoPage = () => {
	const [autenticacionFallida, setAutenticacionFallida] = useState(false);
	const [mensajeError, setMensajeError] = useState("");
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const { getVersionApiColaborador, setSesionUsuario } = useContext(SecurityContext);

	useEffect(() => {
		const sesionExpirada = localStorage.getItem("sesionExpirada");
		if (sesionExpirada === "si") {
			message.info("Su sesión ha expirado.", 8);
			localStorage.removeItem("sesionExpirada");
		}
	}, []);

	const onClickBtnIniciarSesion = async (formulario) => {
		setAutenticacionFallida(false);
		setLoading(true);
		const documentoTrim = formulario.usuario.replace(/\s+/g, "");
		try {
			formulario.usuario = "MOVIL__" + documentoTrim;
			formulario.tokenFirebase = localStorage.getItem("FCMToken");

			const respuesta = await LoginService.autenticarConTokenFirebase(
				formulario.usuario,
				formulario.clave,
				formulario.tokenFirebase
			);

			if (respuesta.estado === "ok") {
				setSesionUsuario(respuesta);
				setAutenticacionFallida(false);
				if (getVersionApiColaborador() === VersionApiColaborador.v1) {
					history.push("/bandejaEntrada");
				} else if (getVersionApiColaborador() === VersionApiColaborador.v2) {
					history.push("/mis-documentos");
				} else if (getVersionApiColaborador() === VersionApiColaborador.v3) {
					history.push("/v3/mis-pendientes");
				} else {
					history.push("/bandejaEntrada");
				}
			} else if (respuesta.error === "auth-1") {
				setLoading(false);
				setAutenticacionFallida(true);
				setMensajeError("Usuario y/o clave son incorrectos");
			} else if (respuesta.error === "auth-2") {
				setLoading(false);
				setAutenticacionFallida(true);
				setMensajeError("No tiene acceso a la plataforma. Revise sus credenciales.");
			}
		} catch (error) {
			if (error.response && error.response.data.mensaje) {
				setAutenticacionFallida(true);
				setMensajeError(error.response.data.mensaje);
			}
			setLoading(false);
		}
	};

	const onClickLinkOlvideClave = () => {
		history.push("/actualizarClave");
	};

	return (
		<div className="container-login">
			<div
				className="container-login--fondo"
				style={{
					backgroundImage: "url(https://storage.googleapis.com/milegajodigital.com/admin/publico/fondo.png)",
				}}
			/>

			<div className="container-login--wrapper">
				<img
					className="container-login--wrapper__logotipo"
					src="https://storage.googleapis.com/milegajodigital.com/admin/publico/logotipo.png"
					width="180px"
					alt="Logotipo"
				/>

				<div className="container-login--wrapper__titulo">
					<span>Iniciar Sesión</span>
				</div>

				{autenticacionFallida && (
					<Alert
						style={{
							marginBottom: 24,
						}}
						message={mensajeError}
						type="error"
						showIcon
					/>
				)}

				<Form name="frmLogin" className="" onFinish={onClickBtnIniciarSesion}>
					<Form.Item
						name="usuario"
						rules={[
							{
								required: true,
								message: "Por favor, ingresa tu número de documento de identidad.",
							},
						]}
					>
						<Input
							size="large"
							maxLength={32}
							prefix={<UserOutlined className="site-form-item-icon" />}
							placeholder="Número de documento de identidad"
						/>
					</Form.Item>
					<Form.Item
						name="clave"
						rules={[
							{
								required: true,
								message: "Por favor, ingresa tu clave.",
							},
						]}
					>
						<Input.Password
							size="large"
							prefix={<LockOutlined className="site-form-item-icon" />}
							type="password"
							placeholder="Clave"
						/>
					</Form.Item>
					<Form.Item>
						<a href="#/" style={{ float: "right" }} onClick={onClickLinkOlvideClave}>
							¿Olvidaste tu clave?
						</a>
					</Form.Item>

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							loading={loading}
							style={{ width: "100%" }}
						>
							Iniciar Sesión
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default LoginGenericoPage;
