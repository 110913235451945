import { useEffect } from "react";
import { useAtom } from "jotai";
import { OnboardingService } from "services";
import { documentoSeleccionadoAtom, documentosAtom } from "pages/onboarding/OnboardingStore";

const useListarDocumentos = () => {
	const [documentos, setDocumentos] = useAtom(documentosAtom);
	const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			const documentos = await OnboardingService.obtenerDocumentos();
			setDocumentos(documentos);
			setDocumentoSeleccionado(documentos[0])
		};
		cargarDatos();
	}, []);

	return { documentos };
};

export default useListarDocumentos;
