import React from "react";
import { Titulo } from "./Titulo";
import { Botones } from "./Botones";

const Header = ({ botonesExtra }) => {
	return (
		<div>
			<div //className="milegajo-visor-pdf-header"
				className="visor-pdf-header"
			>
				<Titulo />
				<Botones botonesExtra={botonesExtra} />
			</div>
		</div>
	);
};

export default Header;
