import React from "react";
import { FileImageFilled, FilePdfFilled, FileWordOutlined } from '@ant-design/icons';
import { Card, Modal, Row } from "antd";

export function SeleccionarTipoCarga({setSeleccionado, setIsImage, documentoSeleccionado, setIsWord, setMostrar}) {
    const { Meta } = Card
    const gridStyle = {
    width: '50%',
    textAlign: 'center',
    };
    return (
        <Row>

            {(!documentoSeleccionado.requiereFormatoCarga
								|| (documentoSeleccionado.requiereFormatoCarga
									&& documentoSeleccionado.formatosCarga.includes("imagen")))
				&& <Card
                        hoverable
                        style={gridStyle}
                        cover={ <FileImageFilled style={{ fontSize: '50px', marginTop:"20px" }} />}
                    onClick={() => { setSeleccionado(true); setIsImage(true); setIsWord(false); setMostrar(false)}}
                    >
                        <Meta title="Seleccionar Imagen" />
                    </Card>
            }

            {(!documentoSeleccionado.requiereFormatoCarga
								|| (documentoSeleccionado.requiereFormatoCarga
									&& documentoSeleccionado.formatosCarga.includes("pdf")))
				&& <Card
                    hoverable
                    style={gridStyle}
                    cover={ <FilePdfFilled style={{ fontSize: '50px', marginTop:"20px" }} />}
                    onClick={() => { setSeleccionado(true); setIsImage(true); setIsImage(false); setIsWord(false); setMostrar(false)}} 
                >
                    <Meta title="Seleccionar PDF"/>
                </Card>
            }

            {documentoSeleccionado.requiereFormatoCarga
                && documentoSeleccionado.formatosCarga.includes("word")
                && <Card
                    hoverable
                    style={gridStyle}
                    cover={ <FileWordOutlined style={{ fontSize: '50px', marginTop:"20px" }} />}
                    onClick={() => { setSeleccionado(true); setIsImage(false); setIsWord(true); setMostrar(false)}} 
                >
                    <Meta title="Seleccionar Word"/>
                </Card> 
            }

            </Row>
    )

}