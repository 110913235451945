import React from "react";
import { Header,ListaSolicitudesPersonal } from "./componentes";
import { ModalCrearSolicitudPersonal } from "../common/componentes";
import { ModalCancelarSolicitud } from "../common/componentes";
const SolicitudPersonalPageMovil = () => {
    return (
        <React.Fragment>
            <Header />
            <ListaSolicitudesPersonal/>
            <ModalCrearSolicitudPersonal />
            <ModalCancelarSolicitud/>
        </React.Fragment>
    )
}
export default SolicitudPersonalPageMovil;