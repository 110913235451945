const { Plataforma } = require("enum/Plataforma");

const usePlataforma = () => {
	let plataforma = localStorage.getItem("plataforma");
	if (plataforma === undefined || plataforma === "" || plataforma === null) {
		plataforma = Plataforma.MOVIL_WEB;
	}
	return { plataforma };
};

export default usePlataforma;
