import { Alert, Button, Input, Modal } from "antd";
import React from "react";
import useModalFirmarEvaluacion from "../../hooks/useModalFirmarEvaluacion";

const ModalFirmarEvaluacion = () => {
	const {
		visibleModal,
		ocultarModal,
		onClickBtnFirmar,
		mensajeError,
		setMensajeError,
		claveIngresada,
		setClaveIngresada,
		loading,
	} = useModalFirmarEvaluacion();

	return (
		<Modal
			title="Firmar Evaluación"
			visible={visibleModal}
			closable={false}
			width={400}
			destroyOnClose={true}
			footer={[
				<Button key="cancelar" disabled={loading} onClick={ocultarModal} style={{ padding: "0px 30px" }}>
					Cancelar
				</Button>,
				<Button
					key="firmar"
					type="primary"
					loading={loading}
					onClick={onClickBtnFirmar}
					style={{ padding: "0px 30px" }}
				>
					Firmar
				</Button>,
			]}
			centered
		>
			{mensajeError && (
				<Alert
					message={mensajeError}
					onClose={() => setMensajeError(undefined)}
					style={{ marginBottom: "4px" }}
					type="error"
					showIcon
					closable
				/>
			)}
			<span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
			<Input.Password
				value={claveIngresada}
				onChange={(e) => {
					setClaveIngresada(e.target.value);
					setMensajeError(undefined);
				}}
				placeholder={"Clave"}
			/>
		</Modal>
	);
};

export default ModalFirmarEvaluacion;
