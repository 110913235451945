import React from "react";
import { Button, Select } from "antd";
import { useAtom } from "jotai";
import { zoomVisorFormularioAtom } from "../../MisDocumentosMovilStorage";
import { ZoomOutOutlined, ZoomInOutlined } from "@ant-design/icons";

const BarraHerramientas = () => {
	const [zoom, setZoom] = useAtom(zoomVisorFormularioAtom);

	const onClickBtnAcercar = () => {
		if (zoom < 1.5) {
			setZoom(zoom + 0.25);
		}
	};

	const onClickBtnAlejar = () => {
		if (zoom > 0.5) {
			setZoom(zoom - 0.25);
		}
	};

	const onSeleccionarZoom = (valor) => {
		setZoom(valor);
	};

	return (
		<div className="visor-formulario-tool-movil">
			<Button onClick={onClickBtnAlejar}>
				<ZoomOutOutlined />
			</Button>
			<Select defaultValue={zoom} style={{ width: 120 }} value={zoom} onChange={onSeleccionarZoom}>
				<Select.Option value={1}>100 %</Select.Option>
				<Select.Option value={1.25}>125 %</Select.Option>
				<Select.Option value={1.5}>150 %</Select.Option>
				<Select.Option value={1.75}>175 %</Select.Option>
				<Select.Option value={2}>200 %</Select.Option>
			</Select>
			<Button onClick={onClickBtnAcercar}>
				<ZoomInOutlined />
			</Button>
		</div>
	);
};

export default BarraHerramientas;
