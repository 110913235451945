import React, { useEffect } from "react";
import { FilePdfOutlined } from "@ant-design/icons";
import { useAtomValue, useSetAtom } from "jotai";
import {
	componentesAtom,
	formAtom,
	loadingAtom,
	urlFirmaHolografaAtom,
	urlFotoAtom,
	urlHuellaDactilarAtom,
	urlPdfAtom,
	variablesGlobalesAtom,
	visibilidadComponentesAtom,
} from "../../VisorFormularioStorage";
import styled from "@emotion/styled";
import { datosFormularioAtom } from "pages/tareas-pendientes/TareasPendientesStorage";

const PdfNoEspecificado = () => {
	const urlPdf = useAtomValue(urlPdfAtom);
	const setUrlPdf = useSetAtom(urlPdfAtom);
	// const setLoading = useSetAtom(loadingAtom);
	const setComponentes = useSetAtom(componentesAtom);
	// const setVisibilidadComponentes = useSetAtom(visibilidadComponentesAtom);
	const setVariablesGlobales = useSetAtom(variablesGlobalesAtom);
	const setDatosFormulario = useSetAtom(datosFormularioAtom);
	const setForm = useSetAtom(formAtom);
	const setUrlFirmaHolografa = useSetAtom(urlFirmaHolografaAtom);
	const setUrlHuellaDactilar = useSetAtom(urlHuellaDactilarAtom);
	const setUrlFoto = useSetAtom(urlFotoAtom);

	useEffect(() => {
		const inicializar = () => {
			if (!urlPdf) {
				setUrlPdf(undefined);
				setComponentes(undefined);
				// setVisibilidadComponentes({});
				setVariablesGlobales(undefined);
				setDatosFormulario(undefined);
				setForm(undefined);
				setUrlFirmaHolografa(undefined);
				setUrlHuellaDactilar(undefined);
				setUrlFoto(undefined);
			}
		};
		inicializar();
	}, [urlPdf]);

	if (urlPdf) {
		return "";
	}

	return (
		<Container>
			<FilePdfOutlined style={{ fontSize: "40px", color: "#8e9aaf" }} />
			<span style={{ color: "#8e9aaf", fontSize: "16px", marginTop: "5px" }}>
				No ha seleccionado ningún documento
			</span>
		</Container>
	);
};

const Container = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background: #f6f6f6;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export default PdfNoEspecificado;
