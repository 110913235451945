import React from "react";
import { useModalVisorPdf } from "store/global";
import { FilePdfOutlined } from "@ant-design/icons";

export const Titulo = () => {
	const titulo = useModalVisorPdf((state) => state.titulo);

	return (
		<div className="informacion">
			<FilePdfOutlined className="icono" />
			<div className="texto">{titulo}</div>
		</div>
	);
};
