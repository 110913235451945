import React from "react";
import { useEffect } from "react";
import { SolicitudAusenciaService } from "services";
import { ApiResponse } from "enum";
import { useAtom } from "jotai";
import { solicitudesAusenciaHistoricoAtom } from "../HistoricoSolicitudesStore";

const useListarSolicitudesAusenciaPorJefeInmediato = () => {
    const [solicitudesAusenciaHistorico, setSolicitudesAusenciaHistorico] = useAtom(solicitudesAusenciaHistoricoAtom);
    
	useEffect(() => {
		const cargarDatos = async () => {
			const solicitudesAusenciaHistorico = await SolicitudAusenciaService.listarHistoricoPorJefeInmediato();
			if (solicitudesAusenciaHistorico.estado === ApiResponse.EXITO) {
				setSolicitudesAusenciaHistorico(solicitudesAusenciaHistorico.data);				
			}
		};
		cargarDatos();
	}, []);
	return { solicitudesAusenciaHistorico};
    
}
export default useListarSolicitudesAusenciaPorJefeInmediato;