import { useAtom } from "jotai";
import PerfilService from "services/reclutamiento-y-seleccion/PerfilService";
import { perfilSeleccionadoAtom } from "../store/MisSolicitudesPersonalStore";
const useSeleccionarPerfil = () => {
    const [perfil, setPerfil] = useAtom(perfilSeleccionadoAtom);
    
    const onChangePerfil = async (perfilId) => {
		const respuesta = await PerfilService.buscarPorId(perfilId);
		setPerfil(respuesta);	
		
	};

	const limpiarPerfil = () => {
		setPerfil(undefined);
	};

	return { perfil, limpiarPerfil, onChangePerfil };
}
export default useSeleccionarPerfil;