import React, { useState, useContext, useEffect } from "react";
import { Table } from "antd";
import { MenuAcciones } from "./MenuAcciones";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { SecurityContext } from "context/SecurityContextProvider";

export const TablaFormularios = () => {

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [loading, setLoading] = useState(false);
    const [formularios, setFormularios] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);
                const formularios = await PortalColaboradorService.listarFormularios(
                    getUsuarioAutenticado().empresaSeleccionada.id,
                );
                setFormularios(formularios);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        fetch();
    }, []);

    const columnas = [
        {
            title: "Nombre",
            dataIndex: "nombre",
            align: "center",
            width: '70%'
        },
        {
            title: "Acciones",
            key: "acciones",
            align: 'center',
            render: (formulario) =>
                <MenuAcciones formulario={formulario} />
        },
    ];

    return (
        <Table
            loading={loading}
            rowKey={(formulario) => formulario.id}
            columns={columnas}
            dataSource={formularios}
        />
    )
}