import React from "react";
import { MisDocumentosContextProvider } from "context/MisDocumentos/MisDocumentosContextProvider";
import { useMediaQuery } from "react-responsive";
import { PerfilColaboradorMovilPage } from "./movil";

const PerfilColaboradorPage = () => {
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

	return (
		<MisDocumentosContextProvider>
			{esMovil && <PerfilColaboradorMovilPage />}
			{/* {esDesktop && <PerfilColaboradorMovilPage />} */}
		</MisDocumentosContextProvider>
	);
};

export default PerfilColaboradorPage;
