import React, { useEffect, useContext, useState } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";
import { SecurityContext } from "context/SecurityContextProvider";
import { useModalVisorPdf } from "store/global";
export function Body() {
	const [totalPaginas, setTotalPaginas] = useState(undefined);
	const loading = useModalVisorPdf((state) => state.loading);
    const setLoading = useModalVisorPdf((state) => state.setLoading);
    const urlPdf = useModalVisorPdf((state) => state.urlPdf);
    const setBlobPdf = useModalVisorPdf((state) => state.setBlobPdf);
	
	const { getUsuarioAutenticado } = useContext(SecurityContext);	

	async function onLoadSuccess(successEvent) {
		const _data = await successEvent.getData();
		const _blob = new Blob([_data], { type: "application/pdf" });
		setBlobPdf(_blob);
		setTotalPaginas(successEvent.numPages);		
		setLoading(false);
	}

	return (
		<div className="visor-pdf-body">
			{loading && (
				<div className="container-loading">
					<Loading3QuartersOutlined spin className="loading" />
				</div>
			)}

			<Document file={urlPdf} onLoadSuccess={onLoadSuccess} loading={null}>
				{Array.from(new Array(totalPaginas), (el, index) => (
					<Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.5} loading={""} />
				))}
			</Document>
		</div>
	);
}
