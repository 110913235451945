import React from "react";
import { useMediaQuery } from "react-responsive";
import { MisDocumentosContextProvider } from "../../context/MisDocumentos/MisDocumentosContextProvider";
import { BandejaEvaluacionWeb } from "./componentes/BandejaEvaluacionWeb";


const ListarBandejaEvaluacionPage = () => {
    const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
    const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });
    
    return (
         <MisDocumentosContextProvider>
            {esDesktop && <BandejaEvaluacionWeb/>}
         </MisDocumentosContextProvider>
    )
    
    
}
export default ListarBandejaEvaluacionPage;