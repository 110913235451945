import React from "react";
import { Modal, Radio, Space, Alert } from "antd";
import { useState } from "react";
import { useAtom } from "jotai";
import { visibleModalConfirmarEntrevistaAtom } from "pages/detalle-entrevista/DetalleEntrevistaStore";
import { EntrevistasService } from "services/reclutamiento-y-seleccion";
import { useHistory, useParams } from "react-router-dom";

const style = {
	radioGroup: {
		width: "100%",
	},
	radio: {
		border: "1px solid #ddd",
		borderRadius: "10px",
		width: "100%",
		padding: "10px 8px",
	},
};

const ModalConfirmarEntrevista = () => {
	const { entrevistaId } = useParams();
	const [visible, setVisible] = useAtom(visibleModalConfirmarEntrevistaAtom);
	const [opcionSeleccionada, setOpcionSeleccionada] = useState(undefined);
	const [error, setError] = useState(undefined);
	const history = useHistory();

	const opciones = [
		{ descripcion: "Apto para el Puesto", valor: 3 },
		{ descripcion: "Apto con observaciones para el Puesto", valor: 2 },
		{ descripcion: "No Apto para el Puesto", valor: 1 },
	];

	const onSeleccionarRespuesta = (e) => {
		const opcionSeleccionada = opciones.find((opcion) => opcion.valor == e.target.value);
		setOpcionSeleccionada(opcionSeleccionada);
		setError(undefined);
	};

	const onClickBtnGuardar = async () => {
		if (opcionSeleccionada === undefined) {
			setError(true);
			return;
		}
		await EntrevistasService.completarResultado({
			entrevistaId: entrevistaId,
			...opcionSeleccionada,
		});

		history.push("/entrevistas");
	};

	return (
		<Modal
			title="Confirmar"
			centered={true}
			maskClosable={false}
			visible={visible}
			okText="Enviar"
			cancelText="Cancelar"
			onOk={onClickBtnGuardar}
			// confirmLoading={loading}
			onCancel={() => setVisible(false)}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<span style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "15px" }}>
					¿Considera que el postulante está?
				</span>
				<Radio.Group style={{ width: "100%" }} onChange={onSeleccionarRespuesta}>
					<Space direction="vertical" style={{ width: "100%" }}>
						{opciones.map((opcion) => {
							return (
								<Radio key={opcion.valor} style={style.radio} value={opcion.valor}>
									{opcion.descripcion}
								</Radio>
							);
						})}
					</Space>
				</Radio.Group>
				{error && (
					<Alert
						message="Seleccione una respuesta!"
						type="error"
						showIcon
						onClose={() => setError(undefined)}
						closable
						style={{ marginTop: "15px", width: "100%", borderRadius: "20px" }}
					/>
				)}
			</div>
		</Modal>
	);
};

export default ModalConfirmarEntrevista;
