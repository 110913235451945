import { useEffect } from "react";
import { useAtom } from "jotai";
import { OnboardingService } from "services";
import { documentosAtom } from "pages/onboarding/OnboardingStore";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import { EstadoAprobacion } from "enum/EstadoAprobacion";

const useListarDocumentos = () => {
	const [documentos, setDocumentos] = useAtom(documentosAtom);

	// useEffect(() => {
	// 	const cargarDatos = async () => {
	// 		const documentos = await OnboardingService.obtenerDocumentos();
	// 		setDocumentos(documentos);
	// 	};
	// 	cargarDatos();
	// }, []);

	const fetchDocumentosPendientes = async () => {
		const documentos = await OnboardingService.obtenerDocumentos();
		setDocumentos(
			documentos.filter(
				documento =>
					documento.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE
					|| documento.estadoAprobacion === EstadoAprobacion.RECHAZADO
			));
	}

	const fetchDocumentosCompletados = async () => {
		const documentos = await OnboardingService.obtenerDocumentos();


		console.log("documentos completados", documentos.filter(documento => documento.estadoVistaColaborador !== EstadoVistaColaborador.PENDIENTE));
		setDocumentos(
			documentos.filter(
				documento =>
					documento.estadoVistaColaborador !== EstadoVistaColaborador.PENDIENTE
					&& documento.estadoAprobacion !== EstadoAprobacion.RECHAZADO
			));
	}

	return {
		documentos,
		fetchDocumentosPendientes,
		fetchDocumentosCompletados
	};
};

export default useListarDocumentos;
