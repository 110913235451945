import Axios from "axios";
import { BaseService } from "./BaseService";

const verificarDatosPersonalesService = {
  async verificar(colaboradorVerificado) {
    const respuesta = await Axios.post(
      process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE + "/verificar", colaboradorVerificado
    );
    return respuesta.data;
  },

};
export default verificarDatosPersonalesService;
