import React,{useState,useEffect,useContext}  from "react";
import { Modal,Typography,Comment,List,Form,Input,Button,Checkbox,Tag} from "antd";
import * as atoms from "../BandejaConsultaStorage";
import { useAtom } from "jotai";
import { InfoCircleTwoTone , UserOutlined,ExclamationCircleOutlined} from "@ant-design/icons";
import moment from 'moment';
import { DetalleConsultaService } from "services/DetalleConsultaService";
import { SecurityContext } from "context/SecurityContextProvider";
import { EstadoConsulta } from "enum/EstadoConsulta";
import { PortalColaboradorService } from "services/PortalColaboradorService";

const ModalVerConsulta = () =>{

    const[visibleModalVerConsulta,setVisibleModalVerConsulta] = useAtom(atoms.visibleModalVerConsultaAtom);
    const [consultaSeleccionada, setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);
    const [detalleConsulta, setDetalleConsulta] = useAtom(atoms.detalleConsultaAtom);    
    const { Text } = Typography;
    //const [comentarios, setComentarios] = useState([]);
    const [comentario, setComentario] = useState("");
    const [loading, setLoading] = useState(false);
    const { TextArea } = Input;
    const [ formComentario ] = Form.useForm();    
    
    useEffect(() => {
        
     },[])
    const onCancelModal = async () => {
        formComentario.setFieldsValue({ valor: "" });
        setVisibleModalVerConsulta(false);         
    } 
    
    const handleOnChangeComentario = (e) => {
       
        setComentario(e.target.value);
    }
    const handleOnClickAgregar = async () => {        
    
        if (comentario.trim()) {
            try {

                setLoading(true);  
                const detalleConsultaAgregada =
                {
                    consultaColaboradorId:consultaSeleccionada.id,
                    nombreCompletoColaborador:consultaSeleccionada.nombreCompletoColaborador,
                    mensaje:comentario.trim(),		
                }
                await  DetalleConsultaService.insertar(detalleConsultaAgregada);
                detalleConsultaAgregada.fechaRegistroMensaje=new Date();
                setDetalleConsulta([...detalleConsulta, detalleConsultaAgregada]);
                formComentario.setFieldsValue({ valor: "" });
                setComentario("");
                setLoading(false);

            } catch (error) {
                setLoading(false);
                console.log(error);
            }                    
            
        }
        
    }
    return(
        <Modal
        visible={visibleModalVerConsulta}
        onCancel={onCancelModal}
        footer={null}
        title={<span><InfoCircleTwoTone style={{ fontSize: '20px' }} /> {""}{consultaSeleccionada!==undefined && consultaSeleccionada.asunto}</span>}
        width={800}
        destroyOnClose={true}
        >            
            {consultaSeleccionada!==undefined && consultaSeleccionada.estadoConsulta===EstadoConsulta.RESUELTA &&(
                            <Tag color="#F7914F " icon={<ExclamationCircleOutlined />} >
                            Esta solicitud ya se encuentra cerrada y no podrá agregar más comentarios.                                               
                            </Tag>
                        )}
            {detalleConsulta.length > 0 && (
                <List
                    className="comment-list"
                    itemLayout="horizontal"
                    dataSource={detalleConsulta}
                    renderItem={item => (
                        <li>
                            <Comment
                                //actions={item.actions}
                                author={item.nombreCompletoColaborador}
                                avatar={<UserOutlined style={{ fontSize: '16px', color: '#F5860F' }} />}
                                content={item.mensaje}
                                datetime={new Date(item.fechaRegistroMensaje).toLocaleString()}
                            >
                               
                                {(item.detalleConsultaRespuesta != null && item.detalleConsultaRespuesta.length > 0) && (
                                    item.detalleConsultaRespuesta.map((detalleRespuesta) => {
                                        return (
                                            <Comment
                                                //actions={item.actions}
                                                author={detalleRespuesta.usuarioNombreCompleto}
                                                avatar={<UserOutlined style={{ fontSize: '16px', color: '#099359' }} />}
                                                content={detalleRespuesta.respuesta}
                                                datetime={new Date(detalleRespuesta.fechaRegistroRespuesta).toLocaleString()}
                                            />
                                        )
                                    })
                              
                                )}
                            </Comment>
                        </li>
                        
                    )}
                />
            )}
            
            
            {consultaSeleccionada!==undefined && consultaSeleccionada.estadoConsulta===EstadoConsulta.ABIERTO && (
                <Comment                
                    content={                    
                        <Form form={formComentario}>
                        <Form.Item name="valor">
                            <TextArea rows={4} onChange={handleOnChangeComentario} value={comentario} />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" onClick={handleOnClickAgregar} loading={loading} type="primary"> Agregar comentario</Button>
                            </Form.Item>
                        {/* <Checkbox >¿Se llegó a solucionar su consulta?</Checkbox> */}
                    </Form>
                    }
            />
            )}
            {/* Se agrega este código por el momento hasta rediseñar el modelo de consultas antiguas */}
            {detalleConsulta.length === 0 && (                   
                        <Comment
                           author={consultaSeleccionada?.nombreCompleto}
                            avatar={<UserOutlined style={{ fontSize: '16px', color: '#F5860F' }}/>}
                            content={consultaSeleccionada?.mensaje}
                            datetime={consultaSeleccionada?.fechaRegistro}
                        />                      
                   
                )              
            }
           {/* hasta aqui va el codigo agregado */}
        </Modal>
    )
}
export default ModalVerConsulta;