import { HttpService } from "../HttpService";
import { URL, Headers } from "enum";
import LoginService from "../LoginService";
import qs from "qs";
const URL_API = URL.BASE;

const RenovacionContratosService = {
	async listarContratosPendientesDeAprobarPorJefeProyecto() {
		const respuesta = await HttpService(URL_API).get(
			`/portal-colaborador/contratos/jefe-proyecto/pendientes-aprobar`
		);
		return respuesta.data;
	},

	async listarContratosPorJefeProyecto(params) {
		const respuesta = await HttpService(URL_API).get(`/portal-colaborador/contratos/jefe-proyecto`, {
			params: {
				...params,
			},
		});
		return respuesta.data;
	},

	async aprobarRenovacionContratoPorJefeProyecto(request) {
		const respuesta = await HttpService(URL_API).post(
			"/portal-colaborador/contratos/jefe-proyecto/aprobar-renovacion",
			request
		);
		return respuesta.data;
	},

	async rechazarRenovacionContratoPorJefeProyecto(request) {
		const respuesta = await HttpService(URL_API).post(
			"/portal-colaborador/contratos/jefe-proyecto/rechazar-renovacion",
			request
		);
		return respuesta.data;
	},

	async rechazarRenovacionContratoPorAdministradorObra(request) {
		const respuesta = await HttpService(URL_API).post(
			"/portal-colaborador/contratos/administrador-obra/rechazar-renovacion",
			request
		);
		return respuesta.data;
	},
	// async listarContratosPendientesDeAprobarPorAdministradorObraSeguimiento(request) {
	// 	const respuesta = await HttpService(URL_API).post(
	// 		"/portal-colaborador/contratos/administrador-obra/seguimiento",
	// 		request
	// 	);
	// 	return respuesta.data;
	// },

	async listarContratosPendientesDeAprobarPorAdministradorObraSeguimiento(params) {
		const respuesta = await HttpService(URL_API).get(`/portal-colaborador/contratos/administrador-obra/seguimiento`, {
			params: {
				...params,
			},
		});
		return respuesta.data;
	},

	async listarContratosPendientesDeAprobarPorAdministradorObra() {
		const respuesta = await HttpService(URL_API).get(
			`/portal-colaborador/contratos/administrador-obra/pendientes-aprobar`
		);
		return respuesta.data;
	},

	async aprobarRenovacionContratoPorAdministradorObra(request) {
		const respuesta = await HttpService(URL_API).post(
			"/portal-colaborador/contratos/administrador-obra/aprobar-renovacion",
			request
		);
		return respuesta.data;
	},
	getUrlVistaPrevia(seguimientoContratoId) {
		console.log("ingrese");
		const urlPdf = process.env.REACT_APP_ENDPOINT + `portal-colaborador/contratos/ver-pdf/${seguimientoContratoId}`;
		console.log("XX::" + urlPdf);
		const httpHeaders = {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
		};
		console.log("YY::" + httpHeaders);

		return {
			url: urlPdf,
			httpHeaders: httpHeaders,
		};
	},
	async listarContratosPendientesDeAprobarPorJefeProyectoOrAdministrador(request) {
		const respuesta = await HttpService(URL_API).post(
			"/portal-colaborador/contratos/administrador-jefe/pendientes-aprobar-filtro",
			request
		);
		return respuesta.data;
	},

	async descargarReporteSeguimientoExcel(params) {
		const respuesta = await HttpService(URL_API).get("/portal-colaborador/contratos/seguimiento/exportar", {
			params: params,
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "repeat" });
			},

			responseType: "blob",
		});
		return respuesta;
	},

	getVistaPreviaPdf(seguimientoContratoId, fechaInicio, fechaFin) {
		const urlPdf = process.env.REACT_APP_ENDPOINT + `portal-colaborador/contratos/pre-visualizar/${seguimientoContratoId}/${fechaInicio}/${fechaFin}`;
		const httpHeaders = {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
		};

		return {
			url: urlPdf,
			httpHeaders: httpHeaders,
		};
	},

	async actualizarRenovacionContratoPorJefeProyecto(request) {
		const respuesta = await HttpService(URL_API).post(
			"/portal-colaborador/contratos/jefe-proyecto/actualizar-renovacion",
			request
		);
		return respuesta.data;
	},

	async getDescargarDocumento(seguimientoContratoId) {
		const respuesta = await HttpService(URL_API).get(`/portal-colaborador/contratos/ver-pdf/${seguimientoContratoId}`, {
			responseType: "blob",
		});
    	return respuesta;
	},
};

export { RenovacionContratosService };
