import { useEffect } from "react";
import { Form } from "antd";
import { useAtom } from "jotai";
import { frmPreguntasTrabajoEnEquipoAtom, puntajeTotalTrabajoEnEquipoAtom } from "../../BandejaEvaluacionStorage";

const usePreguntasTrabajoEnEquipo = () => {
	const [puntajeTotal, setPuntajeTotal] = useAtom(puntajeTotalTrabajoEnEquipoAtom);
	const [frmPreguntas, setFrmPreguntas] = useAtom(frmPreguntasTrabajoEnEquipoAtom);
	const [form] = Form.useForm();

	useEffect(() => {
		if (!frmPreguntas) {
			setFrmPreguntas(form);
		}
	}, []);

	return { frmPreguntas, puntajeTotal, setPuntajeTotal };
};

export default usePreguntasTrabajoEnEquipo;
