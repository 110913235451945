import React, { useContext, useState } from "react";
import {
	SolicitudesPendientesAprobarPage,
	MisSolicitudesPage,
	HistoricoSolicitudesPage,
	CalendarioPage,
} from "./componentes";
import { Card, Tabs } from "antd";
import { SecurityContext } from "context/SecurityContextProvider";

const parametros = window.location.search;
const buscadorParametros = new URLSearchParams(parametros);

const VacacionesPageWeb = () => {
	const sesionTab = buscadorParametros.get("sesionTab");
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [activeTabKey, setActiveTabKey] = useState(sesionTab ? sesionTab : "misSolicitudes");

	const tabs = [
		{
			key: "misSolicitudes",
			tab: "Mis Solicitudes",
		},
		{
			key: "solicitudesPendientesAprobacion",
			tab: "Pendientes de Aprobación",
		},
		{
			key: "historicoSolicitudes",
			tab: "Histórico Solicitudes",
		},
	];

	if (getUsuarioAutenticado().empresaSeleccionada?.colaborador?.jefeInmediato || getUsuarioAutenticado().empresaSeleccionada?.colaborador?.otrosAprobadoresVacaciones) {
		tabs.push({
			key: "calendario",
			tab: "Calendario",
		});
	}

	const listaTabs = {
		misSolicitudes: <MisSolicitudesPage />,
		solicitudesPendientesAprobacion: <SolicitudesPendientesAprobarPage />,
		historicoSolicitudes: <HistoricoSolicitudesPage />,
		calendario: <CalendarioPage />,
	};

	const onTabChange = (key) => {
		setActiveTabKey(key);
	};

	return (
		<Card
			tabList={tabs}
			activeTabKey={activeTabKey}
			onTabChange={(key) => {
				onTabChange(key);
			}}
			// tabBarExtraContent={<BotonesExtra tab={activeTabKey} />}
			bodyStyle={{ height: "calc(100vh - 185px)", padding: "0px" }}
		>
			{listaTabs[activeTabKey]}
		</Card>
		// <React.Fragment>
		// 	<div
		// 		style={{
		// 			padding: "10px 20px",
		// 			background: "white",
		// 			minHeight: "calc(100vh - 130px)",
		// 		}}
		// 	>
		// 		<Tabs destroyInactiveTabPane={true} defaultActiveKey={sesionTab ? sesionTab : "calendario"}>
		// 			<Tabs.TabPane tab="Mis Solicitudes Vacacionales" key="mis-solicitudes">
		// 				<div style={{ padding: "10px 10px" }}>
		// 					<MisSolicitudesPage />
		// 				</div>
		// 			</Tabs.TabPane>
		// 			<Tabs.TabPane tab="Pendientes de Aprobación" key="solicitudes-pendientes-aprobacion">
		// 				<SolicitudesPendientesAprobarPage />
		// 			</Tabs.TabPane>
		// 			<Tabs.TabPane tab="Histórico Solicitudes" key="historico-solicitudes">
		// 				<HistoricoSolicitudesPage />
		// 			</Tabs.TabPane>
		// 			{getUsuarioAutenticado().empresaSeleccionada?.colaborador?.jefeInmediato && (
		// 				<Tabs.TabPane tab="Calendario" key="calendario">
		// 					<CalendarioPage />
		// 				</Tabs.TabPane>
		// 			)}
		// 		</Tabs>
		// 	</div>
		// </React.Fragment>
	);
};

export default VacacionesPageWeb;
