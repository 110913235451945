import { useEffect } from "react";
import { SolicitudAusenciaService } from "services";
import { ApiResponse } from "enum";
import { useAtom } from "jotai";
import { solicitudesAusenciaPendientesAprobarAtom } from "../SolicitudesPendientesAprobarStore";

const useListarSolicitudesAusenciaPorJefeInmediato = (estado) => {
	const [solicitudesAusencia, setSolicitudesAusencia] = useAtom(solicitudesAusenciaPendientesAprobarAtom);
	useEffect(() => {
		const cargarDatos = async () => {
			const solicitudesAusencia = await SolicitudAusenciaService.listarPorJefeInmediato({ estado: estado });
			if (solicitudesAusencia.estado === ApiResponse.EXITO) {
				setSolicitudesAusencia(solicitudesAusencia.data);
			}
		};
		cargarDatos();
	}, []);
	return { solicitudesAusencia };
};

export default useListarSolicitudesAusenciaPorJefeInmediato;
