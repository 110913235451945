import React from "react";
import { useListarEntrevistas } from "pages/entrevistas/componentes/common/hooks";
import { ItemEntrevista } from "./ItemEntrevista";
import { LoadingDocumentos } from "components/LoadingDocumentos";
import { loadingAtom } from "pages/entrevistas/componentes/common/store/EntrevistasStore";
import { useAtom } from "jotai";

const ListaEntrevistas = () => {
	const { entrevistas } = useListarEntrevistas();
	const [loading] = useAtom(loadingAtom)
	return (
		<div className="bandeja-entrada__container-documentos">
			{loading && <div style={{padding:" 20px 25px"}}> <LoadingDocumentos items={5} /> </div> }
			{!loading &&<MisEntrevistas entrevistas={entrevistas} />}
			
		</div>
	);
};

const MisEntrevistas = ({ entrevistas }) => {
	const items = entrevistas.map((entrevista, index) => <ItemEntrevista key={index} entrevista={entrevista} />);
	return items;
};

export default ListaEntrevistas;
