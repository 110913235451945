import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Avatar, Card, Image, Upload, Button, message } from "antd";
import { UserOutlined, RightOutlined, EditOutlined } from "@ant-design/icons";
import LoginService from "services/LoginService";
import { useHistory } from "react-router-dom";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { Headers } from "enum";
import ImgCrop from "antd-img-crop";

const PerfilMovilPage = () => {
	const history = useHistory();
	const [informacion, setInformacion] = useState(undefined);
	useEffect(() => {
		const cargarInformacion = async () => {
			const response = await PortalColaboradorService.buscarColaborador();
			setInformacion(response);
		};

		cargarInformacion();
	}, []);
	return (
		<Container>
			<ContainerPerfil>
				<Foto informacion={informacion} setInformacion={setInformacion} />
				<Descripcion />
			</ContainerPerfil>
			<ContainerMenu>
				<MenuItem
					icono={{
						icono: <EditOutlined style={{ fontSize: "16px", color: "#1e6091" }} />,
						colorFondo: "#caf0f8",
					}}
					nombre="Mi Perfil"
					onClick={() => history.push("/movil/perfil/detalle")}
				/>
			</ContainerMenu>
		</Container>
	);
};

const ContainerPerfil = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	background: white;
	padding: 20px 12px;
`;

const ContainerMenu = styled.div`
	margin-top: 10px;
	background: white;
`;

const MenuItem = ({ icono, nombre, onClick }) => {
	return (
		<div style={{ display: "flex", height: "60px" }} onClick={onClick}>
			<div id="icono" style={{ flex: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
				<span
					style={{
						background: icono.colorFondo,
						padding: "5px 10px",
						borderRadius: "5px",
						marginLeft: "10px",
					}}
				>
					{icono.icono}
				</span>
			</div>
			<div
				id="nombre"
				style={{ flex: "80%", fontSize: "14px", display: "flex", alignItems: "center", marginLeft: "10px" }}
			>
				{nombre}
			</div>
			<div id="enlace" style={{ display: "flex", alignItems: "center", flex: "10%", justifyContent: "center" }}>
				<RightOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#8e9aaf" }} />
			</div>
		</div>
	);
};

const Container = styled.div`
	background: #f8f9fa;
	height: 100%;
	padding: 10px 10px;
`;

const Foto = ({ informacion, setInformacion }) => {
	const cargarInformacionColaborador = async () => {
		const response = await PortalColaboradorService.buscarColaborador();
		setInformacion(response);
	};
	const propsFoto = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/actualizar-url-foto-personal",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
		},
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				cargarInformacionColaborador();
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};
	return (
		<>
			{informacion?.urlFotoPersonalFirmado !== null ? (
				<Image src={informacion?.urlFotoPersonalFirmado} width={60} style={{ borderRadius: "50%" }} />
			) : (
				<Avatar size={60} icon={<UserOutlined />} />
			)}
			<div style={{ textAlign: "center" }}>
				<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
					<Upload {...propsFoto} maxCount={1} accept={["image/*"]}>
						<Button block type="link">
							Cambiar Foto
						</Button>
					</Upload>
				</ImgCrop>
			</div>
		</>
	);
};

const Descripcion = () => {
	return (
		<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
			<div style={{ fontWeight: "bold", fontSize: "16px", color: "#0077b6" }}>
				{LoginService.getUsuarioAutenticado().nombreCompleto}
			</div>
			<div style={{ color: "#6c757d", fontSize: "14px" }}>
				{LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.cargoNombre}
			</div>
			<div style={{ color: "#6c757d", fontSize: "14px" }}>
				{LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.correoElectronico}
			</div>
		</div>
	);
};

export default PerfilMovilPage;
