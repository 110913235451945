import React from "react";

import { Row, Col, Card } from "antd";

const ContainerFormulario = ({ children }) => {
    return (
        <Row>
            <Col span={24}>
                <Card>
                    {children}
                </Card>
            </Col>
        </Row>
    );
}

export default ContainerFormulario;