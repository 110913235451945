import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { Alert } from "antd";
import { useFormContext } from "react-hook-form";
import { motivoRechazoAtom } from "../../VisorFormularioStorage";

const MotivoRechazo = () => {
	const [motivoRechazo, setMotivoRechazo] = useAtom(motivoRechazoAtom);
	
	
	if (motivoRechazo === undefined) {
		return "";
	}
	
	return (
		<div style={{ maxHeight: "40px" }}>
			{motivoRechazo && <Alert message={`Motivo de rechazo: ${motivoRechazo}`} type="warning" showIcon />}
		</div>
	);
};

export default MotivoRechazo;
