import React, { useState } from "react";
import { useListarSolicitudesDocumentos } from "pages/solicitud-documentos/componentes/commons/hooks";
import { LoadingDocumentos } from "components/LoadingDocumentos";
import { ItemDocumento } from "./ItemDocumento";

const ListaSolicitudesDocumentosPersonales = () => {
	const { solicitudes, loading } = useListarSolicitudesDocumentos();
	return (
		<div className="bandeja-entrada__container-documentos">
			{loading && <LoadingDocumentos items={5} />}
			{!loading && <MisSolicitudes solicitudes={solicitudes} />}
		</div>
	);
};
const MisSolicitudes = ({ solicitudes }) => {
	const items = solicitudes.map((solicitud, index) => <ItemDocumento key={index} solicitud={solicitud} />);
	return items;
};
export default ListaSolicitudesDocumentosPersonales;
