import React, { useEffect, useState } from "react";
import { Alert, Button, Input, message, Modal, Badge } from "antd";
import { useAtom } from "jotai";
import { SolicitudAusenciaService } from "services";
import { ApiResponse } from "enum";
import produce from "immer";
import styled from "@emotion/styled";
import { FormProvider, useForm } from "react-hook-form";
import { VisorFormulario } from "components/common";
import {
	solicitudAusenciaSeleccionadaAtom,
	visibleModalVerDocumentoAdjuntoAtom,
	urlDocumentoAdjuntoAtom
} from "../../SolicitudesPendientesAprobarStore";

const ModalVerDocumentoAdjunto = () => {
	const [solicitudAusenciaSeleccionada] = useAtom(solicitudAusenciaSeleccionadaAtom);
	const [visibleVerDocumentoAdjunto, setVisibleModalVerDocumentoAdjunto] = useAtom(visibleModalVerDocumentoAdjuntoAtom);
	const [urlDocumento, setUrlDocumento] = useAtom(urlDocumentoAdjuntoAtom);
	const frmVisor = useForm();

	const onClickBtnCancelar = () => {
		setVisibleModalVerDocumentoAdjunto(false);
	};

	const onClickVerPDF = async (url) => {
		console.log("Url", url);
		const response = await SolicitudAusenciaService.verDocumentoAdjunto({
			urlDocumento: url
		});
		setUrlDocumento(response.urlPlantilla)
		console.log("response", response.urlPlantilla);
	};

	return (
		<Modal
			title={
				<div>
					Documentos Adjuntos
				</div>
			}
			open={visibleVerDocumentoAdjunto}
			closable={true}
			onCancel={onClickBtnCancelar}
			width="calc(100vw - 150px)"
			destroyOnClose={true}
			// footer={[
			// 	<Button key="cancelar" onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
			// 		Cancelar
			// 	</Button>,
			// ]}
			footer={null}
			centered
		>
			<ContainerPrincipal >
				<Container>
					<Titulo>
						<Badge status="success" text="Documentos" />
					</Titulo>
					{solicitudAusenciaSeleccionada?.adjuntos && Object.keys(solicitudAusenciaSeleccionada.adjuntos).map((key, index) => {
						return (
							<Button key={key} style={{ marginTop: "10px", marginLeft: "5px" }} onClick={() => onClickVerPDF(solicitudAusenciaSeleccionada.adjuntos[key])} >{key}</Button>
						);
					})}

				</Container>
				<ContainerVisor>
					<Body withHeader={false}>
						<FormProvider {...frmVisor}>
							<VisorFormulario
								urlPdf={urlDocumento}
								form={frmVisor}
							/>
						</FormProvider>
					</Body>
				</ContainerVisor>
			</ContainerPrincipal>
		</Modal>
	);
};

const ContainerPrincipal = styled.div`
	display: flex;
	flex-wrap: "wrap";
	justify-content: space-between;
	height: calc(100vh - 200px);
`;

const Container = styled.div`
	border: 1px solid #ccc;
	flex: 1 1 25%;
	margin: 10px;
	background-color: white;
`;

const ContainerVisor = styled.div`
	flex: 1 1 70%;
	margin: 10px;
`;

const Header = styled.div`
	min-height: 50px;
	max-height: 50px;
	display: flex;
	background: white;
	padding: 0px 10px;
	border: 1px solid #ccc;
	border-bottom: 0px;
`;

// const Body = styled.div`
// 	height: calc(100% - 50px);
// `;

const Body = styled.div`
	height: ${(props) => (props.withHeader === true ? "calc(100% - 50px)" : "100%")};
`;

const Acciones = styled.div`
	flex: 1 1 50%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const Titulo = styled.div`
	flex: 1 1 20%;
	padding: 10px;
	border-bottom: 1px solid #ccc;
`;
export default ModalVerDocumentoAdjunto;
