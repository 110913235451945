import React, { useContext } from "react";
import { Modal } from "antd";
import { cssVisorPdf, cssBodyVisorPdf } from "./estilos";
import { Header } from "./Header"
import { Body } from "./Body"
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";
import { useModalVisorPdf } from "store/global";
export function VisorPdf() {
    
    const visible = useModalVisorPdf((state) => state.visible);
    const cerrarModal = useModalVisorPdf((state) => state.cerrarModal);
   
    return (
        <React.Fragment>
            <Modal
                title={null}
                visible={visible}
                onCancel={cerrarModal}
                bodyStyle={cssBodyVisorPdf}
                style={cssVisorPdf}
                closable={false}
                footer={null}
                destroyOnClose={true}
                className="milegajo-ant-modal"
                width="calc(100vw - 60px)"
                height="calc(100vh - 60px)"
            >

                <Header />
                <Body />

            </Modal>

        </React.Fragment >
    )
}