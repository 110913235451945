import React, { useState } from "react";
import { Modal, Button } from "antd";
import { ZoomOutOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";

export function ModalVisorFilePDF({
	previewOpen,setPreviewOpen, file, isMovil=false
}) {

    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(1);
    
	const handleCancel = () => setPreviewOpen(false);

	const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
	};
	
	const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  };

  const handleZoomOut = () => {
    setScale((prevScale) => prevScale - 0.1);
  };

	return (
		<Modal open={previewOpen} title={file?.name+ " (preview)"} onCancel={handleCancel} width={"80vw"} style={{height:"70vh", maxHeight:"60vh"}} footer={null}>
			{file && (
					<div style={{ maxWidth: '100%', height: '60vh', overflow: 'auto', borderRadius:"15px",
              display: isMovil?'block':'flex',
              justifyContent: 'center', alignItems: 'flex-start', }}>
					<Document
						file={file}
						onLoadSuccess={onDocumentLoadSuccess}
						className="pdf-document"
						renderMode="canvas"
              			loading={<div>Cargando PDF...</div>}
					>
						{Array.from(new Array(numPages), (el, index) => (
						<Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
						))}
						</Document>
						 <div
              className="zoom-buttons"
            >
              <Button onClick={handleZoomOut} disabled={scale<=0.7} style={{borderRadius:"15px 0 0 15px"}}><ZoomOutOutlined /></Button>
              <span style={{ padding: '4px 9px', border:"1px solid transparent", borderColor: "#d9d9d9",backgroundColor:"white" }}>{`${Math.floor(scale * 100)}%`}</span>
              <Button onClick={handleZoomIn} disabled={scale >= 1.5} style={{borderRadius:"0 15px 15px 0"}}><ZoomInOutlined /></Button>
            </div>
					</div>
				)}
      </Modal>
	);
}