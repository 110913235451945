import React from "react";
import { Button, Dropdown, Modal, message } from "antd";
import {
	UnorderedListOutlined,
	CaretDownOutlined,
	CloseCircleOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";
import { SolicitudAusenciaService } from "services";
import { ApiResponse } from "enum";

import { useCargarSolicitudesAusencia } from "pages/vacaciones/componentes/common/mis-solicitudes/hooks";

const MenuAcciones = ({ solicitud }) => {
	const { cargarDatos } = useCargarSolicitudesAusencia();
	const items = [
		((solicitud.requiereAprobacionJefeInmediato &&
			solicitud.jefeInmediato?.estadoAprobacion === "PENDIENTE" &&
			!solicitud.requiereAprobacionOtrosCargos &&
			!solicitud.requiereAprobacionGestionHumana) ||
			(solicitud.requiereAprobacionOtrosCargos &&
				solicitud.responsableOtrosCargos?.estadoAprobacion === "PENDIENTE" &&
				!solicitud.requiereAprobacionJefeInmediato &&
				!solicitud.requiereAprobacionGestionHumana) ||
			(solicitud.requiereAprobacionGestionHumana &&
				solicitud.responsableGestionHumana?.estadoAprobacion === "PENDIENTE" &&
				!solicitud.requiereAprobacionJefeInmediato &&
				!solicitud.requiereAprobacionOtrosCargos) ||
			(solicitud.requiereAprobacionJefeInmediato &&
				solicitud.jefeInmediato?.estadoAprobacion === "PENDIENTE" &&
				solicitud.requiereAprobacionOtrosCargos &&
				solicitud.responsableOtrosCargos?.estadoAprobacion === "PENDIENTE" &&
				!solicitud.requiereAprobacionGestionHumana) ||
			(solicitud.requiereAprobacionJefeInmediato &&
				solicitud.jefeInmediato?.estadoAprobacion === "PENDIENTE" &&
				solicitud.requiereAprobacionOtrosCargos &&
				solicitud.responsableOtrosCargos?.estadoAprobacion === "PENDIENTE" &&
				solicitud.requiereAprobacionGestionHumana &&
				solicitud.responsableGestionHumana?.estadoAprobacion === "PENDIENTE") ||
			(solicitud.requiereAprobacionOtrosCargos &&
				solicitud.responsableOtrosCargos?.estadoAprobacion === "PENDIENTE" &&
				solicitud.requiereAprobacionGestionHumana &&
				solicitud.responsableGestionHumana?.estadoAprobacion === "PENDIENTE" &&
				!solicitud.requiereAprobacionJefeInmediato) ||
			(solicitud.requiereAprobacionJefeInmediato &&
				solicitud.jefeInmediato?.estadoAprobacion === "PENDIENTE" &&
				solicitud.requiereAprobacionGestionHumana &&
				solicitud.responsableGestionHumana?.estadoAprobacion === "PENDIENTE" &&
				!solicitud.requiereAprobacionOtrosCargos)) &&
			solicitud.estado === "PENDIENTE" && {
				label: "Cancelar Solicitud",
				key: "cancelar-solicitud",
				icon: <CloseCircleOutlined style={{ fontSize: "16px" }} />,
			},
	];
	const onClick = ({ key }) => {
		switch (key) {
			case "cancelar-solicitud":
				onClickCancelarSolicitud();
				break;
			default:
				break;
		}
	};

	const onClickCancelarSolicitud = () => {
		Modal.confirm({
			icon: <ExclamationCircleOutlined />,
			content: "¿Está seguro de cancelar la solicitud ?",
			onOk() {
				cancelarSolicitud();
			},
			onCancel() {},
			okText: "Si",
			cancelText: "No",
		});
	};
	const cancelarSolicitud = async () => {
		const response = await SolicitudAusenciaService.cancelarSolicitud({ solicitudAusenciaId: solicitud.id });
		if (response.estado === ApiResponse.EXITO) {
			message.success("Se ha cancelado la solicitud correctamente.");

			cargarDatos();
		} else {
			message.error(response.mensaje);
		}
	};

	return (
		<Dropdown menu={{ items, onClick }}>
			<Button type="link" className="ant-dropdown-link">
				<UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
				<CaretDownOutlined
					style={{
						paddingLeft: "2px",
						fontSize: "12px",
						fontWeight: "bold",
						color: "#1678c1",
					}}
				/>
			</Button>
		</Dropdown>
	);
};
export default MenuAcciones;
