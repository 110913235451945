import React, { useContext } from "react";

import { Layout, Select } from "antd";
import { MenuPrincipal } from "./components/MenuPrincipal";
import { MenuPerfil } from "./components/MenuPerfil";
import { SecurityContext } from "../../context/SecurityContextProvider";
import { useState } from "react";
import { useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { MenuMovil } from "./components/MenuMovil";
import { VersionApiColaborador } from "enum/VersionApiColaborador";

const { Header, Content, Footer } = Layout;

const { Option } = Select;

export function PlantillaWeb({ children }) {
	const { getVersionApiColaborador, getUsuarioAutenticado, setEmpresaSeleccionada } = useContext(SecurityContext);

	const [opciones, setOpciones] = useState(null);
	const [urlImagenLogotipo, setUrlImagenLogotipo] = useState("");

	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

	let history = useHistory();

	useEffect(() => {
		const opcionesEmpresaSeleccionada = getUsuarioAutenticado().empresaSeleccionada.opciones;
		setOpciones(opcionesEmpresaSeleccionada);
		setUrlImagenLogotipo(getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo);
	}, []);

	function handleOnChangeEmpresa(idEmpresa) {
		setEmpresaSeleccionada(idEmpresa);
		setOpciones(getUsuarioAutenticado().empresaSeleccionada.opciones);
		setUrlImagenLogotipo(getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo);
		if (getVersionApiColaborador() === VersionApiColaborador.v1) {
			history.push("/bandejaEntrada");
		} else {
			history.push("/mis-documentos");
			window.location.reload();
		}
	}

	return (
		<React.Fragment>
			{esDesktop && (
				<Layout className="layout">
					<Header />
					<Header className="layout-header">
						<div>
							<img
								className="layout-header__logotipo-imagen"
								src={urlImagenLogotipo ? urlImagenLogotipo : ""}
								alt="logo"
							/>
						</div>
						<div className="layout-header__logotipo">
							<Select
								className="texto-menu-perfil"
								suffixIcon={<DownOutlined style={{ color: "#1678c1" }} />}
								defaultValue={getUsuarioAutenticado().empresaSeleccionada.id}
								bordered={false}
								dropdownMatchSelectWidth={false}
								onChange={handleOnChangeEmpresa}
							>
								{getUsuarioAutenticado().empresas.map((empresa) => (
									<Option key={empresa.id} value={empresa.id}>
										{empresa.razonSocial}
									</Option>
								))}
							</Select>
						</div>
						<div className="layout-header__menu">
							<MenuPrincipal opciones={opciones} />
						</div>
						<div className="layout-header__menu-perfil">
							<MenuPerfil />
						</div>
					</Header>
					<Content className="layout-content">{children}</Content>
					{/* <Footer className="layout-footer">InGenio Technologies ©2020</Footer> */}
				</Layout>
			)}

			{esMovil && (
				<React.Fragment>
					<div className="container-movil">
						<div className="container-movil-body">{children}</div>
					</div>
					{/* <MenuMovil /> */}
					<MenuMovil opciones={opciones} />
				</React.Fragment>
			)}
		</React.Fragment>
	);
}
