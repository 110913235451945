import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";

const Header = ({ titulo, onClickBtnRetornar }) => {
	return (
		<div className="container-movil-pdf-header">
			<div style={{ width: "100%", display: "flex", alignItems: "center" }} onClick={onClickBtnRetornar}>
				<ArrowLeftOutlined style={{ fontSize: "20px", marginRight: "8px" }} />
				{titulo}
			</div>
		</div>
	);
};

export default Header;
