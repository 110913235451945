import React from "react";

import { ItemDocumentoPendiente } from "../ItemDocumentoPendiente";

export function ListaDocumentosPendientes({ documentosPendientes, onClick }) {
	function ListaDocumentos() {
		const itemsDocumentosPendientes = documentosPendientes.map(
			(documento) => (
				<ItemDocumentoPendiente
					key={documento.envio.id}
					documento={documento}
					onClick={() => onClick(documento)}
				/>
			)
		);
		return itemsDocumentosPendientes;
	}

	// function ListaVacia() {
	// 	return "";
	// }

	function Lista() {
		if (documentosPendientes != null && documentosPendientes.length > 0) {
			return <ListaDocumentos />;
		}
		return "";
		// else {
		// 	return <ListaVacia />;
		// }
	}

	return <Lista />;
}
