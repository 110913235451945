import React, { useEffect, useState } from "react";
import { FileSyncOutlined, UserOutlined, FieldTimeOutlined, FileDoneOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { useHistory } from "react-router";

export const ItemEntrevista = ({ entrevista }) => {
	let history = useHistory();
	const [classNameEstado, setClassNameEstado] = useState(" loading");

	useEffect(() => {
		if (entrevista?.estado === "DESCARTADO") {
			setClassNameEstado(" rechazado");
		} else if (entrevista?.estado === "PENDIENTE") {
			setClassNameEstado(" pendiente");
		} else {
			setClassNameEstado(" visualizado");
		}
	}, [entrevista]);

	const onClickDetalleEntrevista = () => {
		if (entrevista.estado === "PENDIENTE") {
			history.push(`/movil/entrevistas/detalle/${entrevista.id}`);
		}
	};

	return (
		<div className={"bandeja-entrada__item-documento" + classNameEstado} onClick={onClickDetalleEntrevista}>
			<div className={"imagen" + classNameEstado}>
				{entrevista?.estado === "PENDIENTE" && <FileSyncOutlined />}
				{entrevista?.estado !== "PENDIENTE" && <FileDoneOutlined />}
			</div>
			<div className="informacion">
				<span className="item-documento__descripcion">
					<Space>
						<b>{entrevista?.puesto.nombre}</b>
					</Space>
				</span>
				<span className="item-documento__tipo">
					<Space>
						<UserOutlined />
						{entrevista?.postulante.nombreCompleto}
					</Space>
				</span>
				<span className="item-documento__tipo">
					<Space>
						<FieldTimeOutlined />
						{entrevista?.horaEntrevista}
					</Space>
				</span>

				<span className={"item-documento__estado" + classNameEstado}>{entrevista?.estado}</span>
			</div>
		</div>
	);
};
