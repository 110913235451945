import { BaseService } from "services/BaseService";

const MisDocumentosService = {

    async listar(params) {
        const respuesta = await BaseService.get("/portal-colaborador/v3/mis-documentos", {
            params: { ...params },
        });
        return respuesta.data;
    },

    async obtenerUrlDocumento(documentoId) {
        const respuesta = await BaseService.get(`/portal-colaborador/v3/mis-documentos/${documentoId}/url`);
        return respuesta.data;
    },

};
export default MisDocumentosService;
