import Axios from "axios";
import { URL } from "enum";

const URL_API = URL.BASE;

const AuthLoginService = {
	async verificarIdentidad(datos) {
		const respuesta = await Axios.post(URL_API + "portal-colaborador/auth/verificar-identidad", datos);
		return respuesta.data;
	},

	async crearClave(datos) {
		const respuesta = await Axios.post(URL_API + "portal-colaborador/auth/crear-clave", datos);
		return respuesta.data;
	},
};

export { AuthLoginService };
