import React, { useState, useEffect } from "react";
import { Button, Input, Alert, Spin } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { colaboradorAtom, pasoActualAtom, linkValidoAtom,loadingAtom } from "../../VerificarDatosPersonalesStore";
import GestionarClaveService from "services/GestionarClaveService";

const params = new URLSearchParams(window.location.search);
const td = params.get("td");
const nd = params.get("nd");
const ap = params.get("ap");
const am = params.get("am");
const n = params.get("n");
const ce = params.get("ce");
const c = params.get("c");
const ei = params.get("ei");
const ri = params.get("ri");

const AutenticarStep = () => {
	const [, setPasoActual] = useAtom(pasoActualAtom);
	const [numeroDocumentoIdentidad, setNumeroDocumentoIdentidad] = useState("");
	const [mensajeErrorVisible, setMensajeErrorVisible] = useState(false);
	const [colaborador, setColaborador] = useAtom(colaboradorAtom);
	const [, setLinkValido] = useAtom(linkValidoAtom);
	const [,setLoaging] = useAtom(loadingAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			setLoaging(true);
			const _colaborador = await GestionarClaveService.desencriptarDatosPersonalesColaborador(
				td,	nd,	ap,	am,	n, ce, c, ei, ri
			);
			setColaborador(_colaborador);	
			if (_colaborador.verificado && _colaborador.estadoRegistro === 'ACTIVO' && _colaborador!=null) {
				setPasoActual(2);
			} else if (_colaborador.estadoRegistro === 'INACTIVO' || _colaborador.estadoRegistro === 'ELIMINADO') {
				setLinkValido(false)
			} else if (_colaborador==null){
				setLinkValido(false)
			}
			setLoaging(false);
		};
		cargarDatos();
	}, []);

	const onClickBtnSiguiente = () => {
		if (colaborador.nd !== numeroDocumentoIdentidad) {
			setMensajeErrorVisible(true);
		} else {
			setMensajeErrorVisible(false);
			setPasoActual(1);
		}
	};

	return (
		<div id="step-autenticar" style={{ display: "flex", flexDirection: "column" }}>
			<span style={{ paddingTop: "20px", fontSize: "20px", fontWeight: "bold", textAlign: "center" }}>
				Estimado colaborador
			</span>
			<span style={{ paddingTop: "5px", fontSize: "18px", textAlign: "center" }}>
				Por favor ingrese su número de documento de identidad
			</span>
			<div style={{ paddingTop: "15px" }}>
				<Input
					style={{ padding: "12px 8px", fontSize: "20px", textAlign: "center" }}
					size="large"
					value={numeroDocumentoIdentidad}
					onChange={(e) => {
						setNumeroDocumentoIdentidad(e.target.value);
						setMensajeErrorVisible(false);
					}}
				/>
			</div>
			{mensajeErrorVisible && (
				<div style={{ paddingTop: "15px" }}>
					<Alert message="El documento de identidad ingresado es incorrecto." type="error" />
				</div>
			)}
			<div style={{ paddingTop: "20px", textAlign: "center" }}>
				<Button size="large" type="primary" block onClick={onClickBtnSiguiente}>
					Siguiente <RightOutlined />
				</Button>
			</div>
		</div>
	);
};

export default AutenticarStep;
