import { BaseService } from "./BaseService";

const EvaluacionService = {
	async listarPorJefeInmediato(empresaId, numeroDocumentoIdentidad, estadoAprobacion) {
		const respuesta = await BaseService.get("/evaluacion/listar-por-jefe-inmediato", {
			params: {
				empresaId: empresaId,
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
				estadoAprobacion: estadoAprobacion,
			},
		});
		return respuesta.data;
	},
	async actualizarResultado(resultado) {
		const respuesta = await BaseService.post("/evaluacion/actualizar-resultado", resultado);
		return respuesta.data;
	},

	async listarPlanesAccion(numeroDocumentoIdentidad) {
		const respuesta = await BaseService.get("/evaluacion/listar-planes-accion", {
			params: {
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
			},
		});
		return respuesta.data;
	},
	async actualizarPlanAccion(planAccion) {
		const respuesta = await BaseService.post("/evaluacion/actualizar-plan-accion", planAccion);
		return respuesta.data;
	},
	async obtenerUrlDocumento(evaluacionId) {
		const respuesta = await BaseService.get(`/evaluacion/obtener-url/${evaluacionId}`);
		return respuesta.data;
	},
};
export default EvaluacionService;
