import { BaseService } from "./BaseService";

const OpcionService = {
  async listar(usuarioId) {
    const respuesta = await BaseService.get("/opcion", {
      params: {
        usuarioId: usuarioId,
      },
    });
    return respuesta.data;
  },
};
export default OpcionService;
