import React from "react";
import { usePreguntasRelacionesInterpersonales } from "../../hooks";
import { Preguntas } from "../Preguntas";

const PreguntasRelacionesInterpersonales = () => {
	const { frmPreguntas, setPuntajeTotal } = usePreguntasRelacionesInterpersonales();

	return (
		<Preguntas
			form={frmPreguntas}
			calcularPuntajeTotal={setPuntajeTotal}
			titulo="RELACIONES INTERPERSONALES (5/6)"
			opciones={[
				{
					key: "seMuestraCortesConElPersonalYConSusCompaneros",
					label: "Se muestra cortés con el personal y con sus compañeros",
				},
				{
					key: "brindaUnaAdecuadaOrientaciónASusCompaneros",
					label: "Brinda una adecuada orientación a sus compañeros.",
				},
				{
					key: "evitaLosConflictosDentroDelTrabajo",
					label: "Evita los conflictos dentro del trabajo",
				},
			]}
		/>
	);
};

export default PreguntasRelacionesInterpersonales;
