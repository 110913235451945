import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Input, Select } from "antd";
import { useAtom } from "jotai";
import { visibleModalDomicilioAtom } from "pages/movil/PerfilMovilStore";
import { UbigeoService } from "services";
import { SecurityContext } from "context/SecurityContextProvider";
import { PortalColaboradorService } from "services/PortalColaboradorService";

const ModalEditarDomicilio = ({ colaborador, setColaborador }) => {
	const [visibleModal, setVisibleModal] = useAtom(visibleModalDomicilioAtom);
	const [departamentos, setDepartamentos] = useState([]);
	const [provincias, setProvincias] = useState([]);
	const [distritos, setDistritos] = useState([]);
	const [formulario] = Form.useForm();
	const { getUsuarioAutenticado } = useContext(SecurityContext);

	useEffect(() => {
		const cargarDatos = async () => {
			const departamentos = await UbigeoService.listarDepartamentos();
			setDepartamentos(departamentos);

			const formData = new FormData();
			formData.append("departamento", colaborador?.domicilioActual?.departamento);
			const provincias_ = await UbigeoService.listarProvincias(formData);
			setProvincias(provincias_);

			const formDataDistrito = new FormData();
			formDataDistrito.append("provincia", colaborador?.domicilioActual?.provincia);
			const distritos_ = await UbigeoService.listarDistritos(formDataDistrito);
			setDistritos(distritos_);

			formulario.setFieldsValue({
				domicilioActual: {
					departamento: colaborador?.domicilioActual?.departamento,
					provincia: colaborador?.domicilioActual?.provincia,
					distrito: colaborador?.domicilioActual?.distrito,
					direccion: colaborador?.domicilioActual?.direccion,
				},
			});
		};

		cargarDatos();
	}, [colaborador]);
	const onChangeDepartamento = async (value) => {
		const formData = new FormData();
		formData.append("departamento", value);
		const provincias = await UbigeoService.listarProvincias(formData);
		setProvincias(provincias);

		formulario.setFieldsValue({
			domicilioActual: {
				provincia: provincias[0].provincia,
			},
		});

		const formDataDistrito = new FormData();
		formDataDistrito.append("provincia", provincias[0].provincia);

		const distritos = await UbigeoService.listarDistritos(formDataDistrito);
		setDistritos(distritos);

		formulario.setFieldsValue({
			domicilioActual: {
				distrito: distritos[0].distrito,
			},
		});
	};
	const onChangeProvincia = async (value) => {
		const formData = new FormData();
		formData.append("provincia", value);
		const distritos = await UbigeoService.listarDistritos(formData);
		setDistritos(distritos);

		formulario.setFieldsValue({
			domicilioActual: {
				distrito: distritos[0].distrito,
			},
		});
	};
	const handleCancel = async () => {
		setVisibleModal(false);
		formulario.resetFields();
		setProvincias([]);
		setDistritos([]);

		const formData = new FormData();
		formData.append("departamento", colaborador?.domicilioActual?.departamento);
		const provincias_ = await UbigeoService.listarProvincias(formData);
		setProvincias(provincias_);

		const formDataDistrito = new FormData();
		formDataDistrito.append("provincia", colaborador?.domicilioActual?.provincia);
		const distritos_ = await UbigeoService.listarDistritos(formDataDistrito);
		setDistritos(distritos_);
	};
	const handleActualizarDomicilio = async (formulario) => {
		formulario.datosPersonales = {
			numeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
		};
		await PortalColaboradorService.actualizarDomicilio(formulario);
		const response = await PortalColaboradorService.buscarColaborador();
		setColaborador(response);
		handleCancel();
	};

	return (
		<Modal title="Editar Domicilio" open={visibleModal} onOk={() => formulario.submit()} onCancel={handleCancel}>
			<Form
				layout="horizontal"
				labelCol={{
					span: 6,
				}}
				wrapperCol={{
					span: 18,
				}}
				form={formulario}
				onFinish={handleActualizarDomicilio}
				initialValues={{
					domicilioActual: {
						departamento: colaborador?.domicilioActual?.departamento,
						provincia: colaborador?.domicilioActual?.provincia,
						distrito: colaborador?.domicilioActual?.distrito,
						direccion: colaborador?.domicilioActual?.direccion,
					},
				}}
			>
				<Form.Item
					label="Departamento"
					name={["domicilioActual", "departamento"]}
					rules={[
						{
							required: true,
							message: "Ingrese el departamento",
						},
					]}
				>
					<Select
						style={{ width: "100%" }}
						//allowClear
						showSearch
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						onChange={onChangeDepartamento}
					>
						{departamentos !== undefined &&
							departamentos?.map((departamento) => (
								<Select.Option key={departamento.nombre} value={departamento.nombre}>
									{departamento.nombre}
								</Select.Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item
					label="Provincia"
					name={["domicilioActual", "provincia"]}
					rules={[
						{
							required: true,
							message: "Ingrese la provincia",
						},
					]}
				>
					<Select
						style={{ width: "100%" }}
						//allowClear
						showSearch
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						onChange={onChangeProvincia}
					>
						{provincias !== undefined &&
							provincias?.map((objeto) => (
								<Select.Option key={objeto.provincia} value={objeto.provincia}>
									{objeto.provincia}
								</Select.Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item
					label="Distrito"
					name={["domicilioActual", "distrito"]}
					rules={[
						{
							required: true,
							message: "Ingresa el distrito",
						},
					]}
				>
					<Select
						style={{ width: "100%" }}
						//allowClear
						showSearch
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{distritos !== undefined &&
							distritos?.map((objeto) => (
								<Select.Option key={objeto.distrito} value={objeto.distrito}>
									{objeto.distrito}
								</Select.Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item
					label="Dirección"
					name={["domicilioActual", "direccion"]}
					rules={[
						{
							required: true,
							message: "Ingresa una dirección",
						},
					]}
				>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};
export default ModalEditarDomicilio;
