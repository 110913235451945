import React, { useContext, useEffect } from "react";
import { Table, Button } from "antd";
import { useAtom } from "jotai";
import * as atoms from "../BandejaEvaluacionStorage";
import { SecurityContext } from "context/SecurityContextProvider";
import EvaluacionService from "services/EvaluacionService";
import { useModalPlanAccion } from "./hooks";
import { ModalActualizarPlanAccion } from "./componentes/ModalActualizarPlanAccion";
import { EstadoPlanAccionEvaluacion } from "enum/EstadoPlanAccionEvaluacion";
const TablaPlanAccion = () => {
    const [planesAccion, setPlanesAccion] = useAtom(atoms.planesAccionAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const { mostrarModal } = useModalPlanAccion();
    
    useEffect(() => {

        const cargarDatos = async () => {            
            const _planesAccion = await EvaluacionService.listarPlanesAccion(getUsuarioAutenticado().numeroDocumentoIdentidad);
            setPlanesAccion(_planesAccion);          
            
        }
        cargarDatos();
    }, []);

    
    const columnasPlanAccion = [
        {
            title: "Colaborador",
			dataIndex: "colaboradorNombreCompleto",
			align: "center",
        },
        {
            title: "Jefe Inmediato",
			dataIndex: "jefeInmediatoNombreCompleto" ,
			align: "center",
        },
        {
            title: "Descripción del Plan",
			dataIndex: "descripcion",
			align: "center",
        },
        {
            title: "Fecha Programación",			
            align: "center",
            //render: (registro) => registro.fecha!=null ? new Date(registro.fecha).toLocaleString():""
            render: (registro) => {
                if (registro.fecha!= null) {
                    var fecha = new Date(registro.fecha)
                    fecha.setDate(fecha.getDate() + 1)
                    return fecha.toLocaleDateString()
                }
                else {
                    return ""
                }
            }
        },
        {
            title: "Fecha Ejecución",			
            align: "center",      
            //render: (registro) => { registro.fechaEjecucion != null ? new Date(registro.fechaEjecucion).setDate(new Date(registro.fechaEjecucion).getDate() + 1).toLocaleDat : "-" }
            render: (registro) => {
                if (registro.fechaEjecucion != null) {
                    var fecha = new Date(registro.fechaEjecucion)
                    fecha.setDate(fecha.getDate() + 1)
                    return fecha.toLocaleDateString()
                }
                else {
                    return "-"
                }
            }
        },
        {
            title: "Estado",			
            align: "center",
            render:(registro)=>registro.estado===EstadoPlanAccionEvaluacion.CERRADO_FUERA_PLAZO ? "CERRADO FUERA PLAZO": registro.estado
        },
        {
            title: "Acciones",
			key: "acciones",
            align: "center",
            render:(registro)=>(<Button type="link" onClick={()=>mostrarModal(registro)}>Actualizar Plan de Acción</Button>)
        }
    ]
    return (
        <React.Fragment>
            <Table
                rowKey={(planAccion) => (planAccion.id, planAccion.descripcion)}                
                columns={columnasPlanAccion}
                dataSource={planesAccion}
            />
           <ModalActualizarPlanAccion/>
        </React.Fragment>
        
    )
}
export default TablaPlanAccion;