import React from "react";
import { Modal } from "antd";
import { VisorFormulario } from "../VisorFormulario";
import { Header } from "./componentes";
import styled from "@emotion/styled";

const ModalVisorPdf = ({ titulo, urlPdf, visible, botonesExtra, onCerrar, type }) => {
  return (
    <Modal
      title={null}
      open={visible}
      onCancel={onCerrar}
      bodyStyle={style.body}
      style={style.main}
      closable={false}
      footer={null}
      className="milegajo-ant-modal"
      width="calc(100vw - 60px)"
      height="calc(100vh - 60px)"
    >
      <Header titulo={titulo} botonesExtra={botonesExtra} onCerrar={onCerrar} />
      <Body>
        <VisorFormulario type={type} urlPdf={urlPdf} />
      </Body>
    </Modal>
  );
};

const Body = styled.div`
  height: calc(100% - 64px);
`;

const style = {
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  main: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
    margin: "30px",
    display: "flex",
    flexDirection: "column",
  },
};

export default ModalVisorPdf;
