import React, { useContext, useEffect } from "react";
import { Button, Card, Modal, Row, Checkbox, Col } from "antd";
//import { cssVisorPdf, cssBodyVisorPdf } from "./estilos"
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState } from "react";
import { SeleccionarTipoCarga } from "./SeleccionarTipoCarga";
import { CargarImagen } from "./CargarImagen";
import { CargarPDF } from "./CargarPDF";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { useAtom } from "jotai";
import { visibleModalDeclaracionAtom } from "../MisDocumentosWeb/MisDocumentosWebStorage";

import { saveAs } from "file-saver";

export function ModalSubirArchivo() {

    const { visibleModalUpload, cerrarModalUpload, documentoSeleccionado, documentoSeleccionadoId } = useContext(MisDocumentosContext);
    
    const [seleccionado, setSeleccionado] = useState(false)
    const [isImage, setIsImage] = useState(undefined)
    const [urlPlantilla, setUrlPlantilla] = useState("")
    const [mostrarBtnDeclaracion, setMostrarBtnDeclaracion] = useState(false);
    const [mostrar, setMostrar] = useState(false);
    const [visibleModalDeclaracion, setVisibleModalDeclaracion] = useAtom(visibleModalDeclaracionAtom);
    
    function onCerrarModal() {
        setSeleccionado(false)
        setIsImage(undefined)
        setUrlPlantilla("")
        cerrarModalUpload();
        setMostrarBtnDeclaracion(false);
    }

    useEffect(() => {
        const cargarUrl = async() => {
            const respuesta = await PortalColaboradorService.obtenerFormatoCarga(documentoSeleccionado?.tipoDocumentoId)
            setUrlPlantilla(respuesta.urlDocumento)
        }
        setMostrarBtnDeclaracion(false);
        if (documentoSeleccionado?.mostrarDeclaracion) {
			setMostrar(true);
		} else {
			setMostrar(false);
        }
        if(documentoSeleccionadoId!= null)
            cargarUrl();
    }, [documentoSeleccionadoId, documentoSeleccionado])

    const onClickDescargarPlantilla = () => {
        saveAs(urlPlantilla, "Formato Carga - "+ documentoSeleccionado?.nombreDocumento +".pdf") ;
    }
    
    const onAceptarModal = async () => {
        setVisibleModalDeclaracion(true);
    };
    
    const onChangeDeclaracion = (e) => {
        setMostrarBtnDeclaracion(e.target.checked); 
    }
    
    const validarDeclaracion = () => {
        setMostrarBtnDeclaracion(false);
        if (documentoSeleccionado?.mostrarDeclaracion) {
			setMostrar(true);
		} else {
			setMostrar(false);
        }
    }

    return (
        <React.Fragment>
            <Modal
                
                title={<div>{seleccionado && <ArrowLeftOutlined onClick={()=>setSeleccionado(false)} />} Cargar Documento</div>}
                visible={visibleModalUpload}
                onCancel={onCerrarModal}
                centered
                footer={!seleccionado ? [
                <Row key={"row-modal"} justify={"space-between"}>
                    {mostrarBtnDeclaracion && (
                        <div>
                            <Button key="aceptar" type="primary" onClick={onAceptarModal}>
                            Aceptar
                            </Button>
                        </div> 
                        )  
                    }
                    {urlPlantilla!=null && urlPlantilla!= "" ? <Button key="descargar-plantilla" type="primary" onClick={()=>onClickDescargarPlantilla()}>
                        Descargar Plantilla
                    </Button>:<span></span> }
                     <Button key="back" onClick={onCerrarModal}>
                        Cancelar
                    </Button></Row>]:null}
                destroyOnClose={true}
            >
                {!seleccionado && <SeleccionarTipoCarga setSeleccionado={setSeleccionado} setIsImage={setIsImage} setMostrar={setMostrar}/>}
                {seleccionado && isImage!== undefined && isImage && <CargarImagen onCerrarModal={onCerrarModal}  />}
                {seleccionado && isImage!== undefined && !isImage && <CargarPDF onCerrarModal={onCerrarModal} />}
                { mostrar &&(
                    <Row gutter={16}>
                    <Col span={24} style={{ marginTop: "10px"}}>
                        <Checkbox onChange={onChangeDeclaracion} >
                            <strong>
                                <em>
                                    {documentoSeleccionado?.declaracion}
                                </em>
                            </strong>
                        </Checkbox>
                    </Col>
                </Row>
                )}
            </Modal>

        </React.Fragment >
    )
}