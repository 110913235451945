import React, { useState, useEffect, useContext } from "react";
import { ContainerFormulario } from "components/ContainerFormulario";
// import { Form } from "@formio/react";
import { notification } from "antd";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { useHistory, useParams } from "react-router";
import { SecurityContext } from "context/SecurityContextProvider";

const CompletarFormularioPage = () => {
	let { formularioId } = useParams();
	let history = useHistory();
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [loading, setLoading] = useState(false);
	const [esquemaJson, setEsquemaJson] = useState(undefined);
	const [formulario, setFormulario] = useState(undefined);
	// const [esquemaJsonInicial, setEsquemaJsonInicial] = useState({
	//     display: 'form'
	// });

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const _formulario = await PortalColaboradorService.buscarFormulario(
					formularioId,
					getUsuarioAutenticado().empresaSeleccionada.id
				);
				if (_formulario.esquemaJson) {
					setFormulario(_formulario);
					setEsquemaJson(JSON.parse(_formulario.esquemaJson));
					// setEsquemaJsonInicial(JSON.parse(formulario.esquemaJson));
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, []);

	const onSubmitFormulario = async (datos) => {
		// try {
		// 	setLoading(true);
		// 	const detalleEnvio = {
		// 		organizacionId: getUsuarioAutenticado().organizacionId,
		// 		empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
		// 		formularioId: formularioId,
		// 		colaboradorId: getUsuarioAutenticado().id,
		// 		nombreFormulario: formulario.nombre,
		// 		datos: JSON.stringify(datos.data),
		// 	};
		// 	console.log("datos", detalleEnvio);
		// 	await PortalColaboradorService.registrarDocumentoFormulario(
		// 		detalleEnvio
		// 	);
		// 	notification.success({
		// 		message: "Operación exitosa",
		// 		description: "Se ha enviado el formulario de forma exitosa.",
		// 	});
		// 	history.push("/mis-documentos");
		// } catch (error) {
		// 	console.error(error);
		// } finally {
		// 	setLoading(false);
		// }
	};

	return (
		<ContainerFormulario titulo="Completar Formulario">
			{/* <Form form={esquemaJson} onSubmit={(data) => onSubmitFormulario(data)} /> */}
		</ContainerFormulario>
	);
};

export default CompletarFormularioPage;
