import React from "react";
import { Select, Divider, Modal, Form, Input } from "antd";
import { useAtom } from "jotai";
import {
	detalleEntrevistaAtom,
	visibleModalCompletarInformacionPersonalAtom,
} from "pages/detalle-entrevista/DetalleEntrevistaStore";
import { useParams } from "react-router-dom";
import { EntrevistasService } from "services/reclutamiento-y-seleccion";
import { useState } from "react";
import { useEffect } from "react";

const ModalCompletarInformacionPersonal = () => {
	const [visible, setVisible] = useAtom(visibleModalCompletarInformacionPersonalAtom);
	const [detalleEntrevista, setDetalleEntrevista] = useAtom(detalleEntrevistaAtom);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const { entrevistaId } = useParams();

	useEffect(() => {
		const cargarDatos = async () => {
			if (visible) {
				const informacionPersonal = await EntrevistasService.obtenerInformacionPersonal(entrevistaId);
				form.setFieldsValue({
					...informacionPersonal,
				});
			}
		};
		cargarDatos();
	}, [visible]);

	const onClickBtnGuardar = () => {
		form.validateFields()
			.then(async (formulario) => {
				setLoading(true);
				const respuesta = await EntrevistasService.completarInformacionPersonal({
					entrevistaId: entrevistaId,
					...formulario,
				});
				setDetalleEntrevista({ ...detalleEntrevista, informacionPersonalCompleto: respuesta });
				setLoading(false);
				setVisible(false);
			})
			.catch((info) => {
				setLoading(false);
				console.log("Validate Failed:", info);
			});
	};

	return (
		<Modal
			title="Información Personal"
			bodyStyle={{ overflowY: "scroll", minHeight: "calc(100vh - 220px)", maxHeight: "calc(100vh - 220px)" }}
			centered={true}
			maskClosable={false}
			visible={visible}
			okText="Guardar"
			cancelText="Cancelar"
			onOk={onClickBtnGuardar}
			confirmLoading={loading}
			onCancel={() => setVisible(false)}
		>
			<Form layout="vertical" form={form}>
				<Form.Item
					name="apellidoPaterno"
					label="Apellido Paterno"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="apellidoMaterno"
					label="Apellido Materno"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="nombres"
					label="Nombres"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="fechaNacimiento"
					label="Fecha de Nacimiento (día/mes/año)"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="estadoCivil"
					label="Estado Civil"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Select
						style={{
							width: "100%",
						}}
						options={[
							{
								value: "SOLTERO",
								label: "SOLTERO",
							},
							{
								value: "CASADO",
								label: "CASADO",
							},
							{
								value: "DIVORCIADO",
								label: "DIVORCIADO",
							},
						]}
					/>
				</Form.Item>
				<Form.Item
					name="numeroHijos"
					label="Nro. de Hijos"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Divider style={{ color: "#0077b6" }}>Situación Académica</Divider>
				<Form.Item
					name="formacionAcademica"
					label="Formación Académica"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Select
						style={{
							width: "100%",
						}}
						options={[
							{
								value: "PROFESIONAL",
								label: "PROFESIONAL",
							},
							{
								value: "TÉCNICA",
								label: "TÉCNICA",
							},
							{
								value: "SECUNDARIA",
								label: "SECUNDARIA",
							},
							{
								value: "NINGUNA",
								label: "NINGUNA",
							},
						]}
					/>
				</Form.Item>
				<Form.Item
					name="gradoAcademico"
					label="Grado Académico"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Select
						style={{
							width: "100%",
						}}
						options={[
							{
								value: "TITULADO",
								label: "TITULADO",
							},
							{
								value: "BACHILLER",
								label: "BACHILLER",
							},
							{
								value: "EGRESADO",
								label: "EGRESADO",
							},
							{
								value: "ESTUDIANTE",
								label: "ESTUDIANTE",
							},
						]}
					/>
				</Form.Item>
				<Form.Item
					name="postgrado"
					label="Postgrado"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Select
						style={{
							width: "100%",
						}}
						options={[
							{
								value: "DOCTORADO",
								label: "DOCTORADO",
							},
							{
								value: "MBA",
								label: "MBA",
							},
							{
								value: "MAESTRÍA",
								label: "MAESTRÍA",
							},
							{
								value: "NINGUNA",
								label: "NINGUNA",
							},
						]}
					/>
				</Form.Item>
				<Form.Item name="especialidad" label="Especialidad">
					<Input />
				</Form.Item>
				<Divider style={{ color: "#0077b6" }}>Domicilio</Divider>
				<Form.Item
					name="direccionActual"
					label="Dirección Actual"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="distrito"
					label="Distrito"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="provincia"
					label="Provincia"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="departamento"
					label="Departamento"
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ModalCompletarInformacionPersonal;
