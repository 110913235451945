import React, { useEffect } from "react";
import { Alert, Button, Col, DatePicker, Form, Modal, Select, Input, Table, Row, Tag, notification, Space, Popover, message } from "antd";
import { useListarSeguimientoContratos, useListarProyectos } from "../../../common/hooks";
import { LikeOutlined, EditOutlined, DownloadOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useAtom, useSetAtom } from "jotai";
import {
	contratoSeleccionadoAtom,
	//contratosAtom,
	//visibleModalRechazarRenovacionAtom,
	//visibleModalRenovarContratoAtom,
	urlPdfContratoAtom,
	visibleModalVisorPdfAtom,
	visibleModalActualizarContratoAtom,
	visibleBtnActualizarContratoAtom,
	urlPdfActualizarContratoAtom,
	visibleModalActualizarVisorPdfAtom,
	visibleModalOrigenPdfAtom
} from "pages/contratos/renovacion/RenovacionContratosStore";
import { useState } from "react";
import { RenovacionContratosService } from "services/contratos";
import produce from "immer";
import moment from "moment";
import { saveAs } from "file-saver";
// import ModalRechazarRenovacion from "./ModalRechazarRenovacion";
import LoginService from "services/LoginService";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { ModalVisorPdf as VisorPdf } from "components/common";
import { Provider as JotaiProvider } from "jotai";
import {
	paginaActualAtom,
	paginacionAtom,
	totalContratosAtom,
	totalPaginasAtom,
	filtroAtom,
	filtroColaboradorAtom,
	filtroFechaFinAtom,
	filtroProyectoAtom,
	filtroRespRenovacionAtom,
	filtroEstadoAtom,
	contratosAtom,
	filtroFechaInicioAtom,
	filtroEstadoFirmaAtom,
	filtroEstadoColaboradorAtom,
	filtroTipoContratoAtom
} from "../../../common/store/SeguimientoContratoStore";

const SeguimientoContratosTab = () => {
	return (
		<JotaiProvider>
			<ListaContratos />
			<ModalActualizarContrato/>
			<ModalVisorPdf />
		</JotaiProvider>
	);
};

const ListaContratos = () => {
	const { Search } = Input;
	const { contratosSeguimiento, setContratosSeguimiento } = useListarSeguimientoContratos();
	const { proyectos, setProyectos } = useListarProyectos();
	const [loadingExportar, setLoadingExportar] = useState(false);
	const [totalContratos] = useAtom(totalContratosAtom);
	const [paginaActual, setPaginaActual] = useAtom(paginaActualAtom);
	const [paginacion] = useAtom(paginacionAtom);
	//const [filtroSearch, setFiltroSearch] = useAtom(filtroAtom);
	const [filtroColaborador, setFiltroColaborador] = useAtom(filtroColaboradorAtom);
	const [filtroProyecto, setFiltroProyecto] = useAtom(filtroProyectoAtom);
	const [filtroRespRenovacion, setFiltroRespRenovacion] = useAtom(filtroRespRenovacionAtom);
	const [filtroEstado, setFiltroEstado] = useAtom(filtroEstadoAtom);
	const [filtroFechaFin, setFiltroFechaFin] = useAtom(filtroFechaFinAtom);
	const [filtroFechaInicio, setFiltroFechaInicio] = useAtom(filtroFechaInicioAtom);
	const [filtroEstadoFirma, setFiltroEstadoFirma] = useAtom(filtroEstadoFirmaAtom);
	const [filtroEstadoColaborador, setFiltroEstadoColaborador] = useAtom(filtroEstadoColaboradorAtom);
	const [filtroTipoContrato, setFiltroTipoContrato] = useAtom(filtroTipoContratoAtom);
																
	const [colaboradores, setColaboradores] = useState(undefined);

	const [formFiltro] = Form.useForm();
	const [, setTotalContratos] = useAtom(totalContratosAtom);
	const [, setContratos] = useAtom(contratosAtom);

	const columnas = [
		{
			title: "Contrato",
			width: "25%",
			dataIndex: "nombreContrato",
		},
		// {
		// 	title: "Colaborador",
		// 	width: "25%",
		// 	dataIndex: ["colaborador", "nombreCompleto"],
		// },
		{
			title: "Colaborador",
			//dataIndex: ["colaborador", "nombreCompleto"],
			width: "25%",
			render: (fila) => (
				<div>
					<div style={{ display: "flex", alignItems: "center" }}>
						{fila.colaborador.nombreCompleto}
					</div>
					{fila.colaborador?.estadoRegistro == "CESADO" && (
						<div style={{ alignItems: "center" }}>
							<Tag color="red">CESADO</Tag>
						</div>
					)}
					{fila.colaborador?.estadoRegistro !== "CESADO" && (
						<div style={{ alignItems: "center" }}>
							<Tag color="green">ACTIVO</Tag>
						</div>
					)}
				</div>
			),
		},
		{
			title: "Proyecto",
			dataIndex: ["colaborador", "proyecto"],
		},
		{
			title: "Cargo",
			dataIndex: ["colaborador", "cargo"],
		},
		{
			title: "Fecha Inicio",
			dataIndex: "fechaInicio",
			align: "center",
		},
		{
			title: "Fecha Fin",
			dataIndex: "fechaFin",
			align: "center",
		},
		{
			title: "Estado",
			align: "center",
			width: "10%",
			render: (fila) => <TagEstado contrato={fila} />,
		},
		{
            title: "Firmado por Colaborador",
            dataIndex: "estadoFirma",
            align: "center",
            width: "10%",
            render: (estado) => <TagEstadoFirma estado={estado}/>
		},
		
		{
			title: "Acciones",
			align: "center",
			width: "15%",
			render: (fila) => <MenuAcciones contrato={fila} />,
		},
	];

	// const onChangePagination = async (page) => {
	// 	setPaginaActual(page);
	// 	cargarContratos(page - 1);
	// };
	function onChangePagination(page) {
		cargarContratos(page - 1);
	}

	useEffect(() => {
		cargarContratos();
	}, [filtroColaborador,
		filtroProyecto,
		filtroRespRenovacion,
		filtroEstado,
		filtroFechaFin,
		filtroFechaInicio,
		filtroEstadoFirma,
		filtroEstadoColaborador,
		filtroTipoContrato]);

	const cargarContratos = async (paginaInput) => {
		//setLoading(true);

		if (LoginService.isJefeProyecto()) {
			const response = await RenovacionContratosService.listarContratosPorJefeProyecto({
				filtroColaborador: filtroColaborador,
				filtroProyecto: filtroProyecto,
				filtroRespRenovacion: filtroRespRenovacion,
				filtroEstado: filtroEstado,
				filtroEstadoFirma: filtroEstadoFirma,
				filtroFechaInicio: filtroFechaInicio,
				filtroFechaFin: filtroFechaFin,
				filtroEstadoColaborador:filtroEstadoColaborador,
				filtroTipoContrato:filtroTipoContrato,
				pagina: paginaInput != undefined ? paginaInput : 0,
				cantidad: 10,
			});

			setContratosSeguimiento({
				...contratosSeguimiento,
				contratos: response.contratos,
				paginaActual: response.paginaActual,
				totalContratos: response.totalContratos,
			});

		} else if (LoginService.isAdministradorObra()) {
			const response =
				await RenovacionContratosService.listarContratosPendientesDeAprobarPorAdministradorObraSeguimiento({
					filtroColaborador: filtroColaborador,
					filtroProyecto: filtroProyecto,
					filtroRespRenovacion: filtroRespRenovacion,
					filtroEstado: filtroEstado,
					filtroEstadoFirma: filtroEstadoFirma,
					filtroFechaInicio: filtroFechaInicio,
					filtroFechaFin: filtroFechaFin,
					filtroEstadoColaborador:filtroEstadoColaborador,
					filtroTipoContrato:filtroTipoContrato,
					pagina: paginaInput != undefined ? paginaInput : 0,
					cantidad: 10,
				});
			setContratosSeguimiento({
				...contratosSeguimiento,
				contratos: response.contratos,
				paginaActual: response.paginaActual,
				totalContratos: response.totalContratos,
			});
		}
		//setLoading(false);
	};

	const onSearchColaboradores = async (value) => {
		if (value === "") {
			setColaboradores([]);
			return;
		}
		const respuesta = await PortalColaboradorService.listarPorNombreCompletoODni({
			filtro: value.trim(),
		});
		setColaboradores(respuesta.colaboradores);
	};

	const onClickFiltrar = async () => {
		setPaginaActual(0);
		setFiltroColaborador(formFiltro.getFieldValue("colaborador"));
		setFiltroProyecto(formFiltro.getFieldValue("proyecto"));
		setFiltroRespRenovacion(formFiltro.getFieldValue("responsableRenovacion"));
		setFiltroEstado(formFiltro.getFieldValue("estado"));
		setFiltroFechaFin(
			formFiltro.getFieldValue("meses") !== undefined && formFiltro.getFieldValue("meses") !== null
				? formFiltro.getFieldValue("meses").format("MM/YYYY")
				: ""
		);
		setFiltroFechaInicio(
			formFiltro.getFieldValue("fechaInicio") !== undefined && formFiltro.getFieldValue("fechaInicio") !== null
				? formFiltro.getFieldValue("fechaInicio").format("MM/YYYY")
				: ""
		);
		setFiltroEstadoFirma(formFiltro.getFieldValue("estadoFirma"));
		setFiltroEstadoColaborador(formFiltro.getFieldValue("estadoColaborador"));
		setFiltroTipoContrato(formFiltro.getFieldValue("tipoContrato"));
	};

	const handleOnclickExportarSeguimiento = async () => {
		try {
			setLoadingExportar(true);
			let tipoBusqueda = "";
			if (LoginService.isJefeProyecto()) {
				tipoBusqueda = "RESPONSABLE_RENOVACION";
			} else if (LoginService.isAdministradorObra()) {
				tipoBusqueda = "ADMINISTRADOR_OBRA";
			}

			const respuesta = await RenovacionContratosService.descargarReporteSeguimientoExcel({
				filtroColaborador: formFiltro.getFieldValue("colaborador"),
				filtroProyecto: formFiltro.getFieldValue("proyecto"),
				filtroRespRenovacion: formFiltro.getFieldValue("responsableRenovacion"),
				filtroEstado: formFiltro.getFieldValue("estado"),
				filtroEstadoFirma: formFiltro.getFieldValue("estadoFirma"),
				filtroFechaInicio: formFiltro.getFieldValue("fechaInicio") !== undefined && formFiltro.getFieldValue("fechaInicio") !== null
				? formFiltro.getFieldValue("fechaInicio").format("MM/YYYY")
				: "",
				filtroFechaFin: formFiltro.getFieldValue("meses") !== undefined && formFiltro.getFieldValue("meses") !== null
				? formFiltro.getFieldValue("meses").format("MM/YYYY")
				: "",
				filtroEstadoColaborador:formFiltro.getFieldValue("estadoColaborador"),
				filtroTipoContrato:formFiltro.getFieldValue("tipoContrato"),
				tipoBusqueda: tipoBusqueda,
			});
			setLoadingExportar(false);
			const filename = respuesta.headers.filename;
			saveAs(respuesta.data, filename);
		} catch (error) {
			setLoadingExportar(false);
			notification.error({
				message: "No hay Seguimiento para exportar",
				description: error.response.data.mensaje,
			});
		}
	};

	return (
		<div>
			<Form layout="vertical" form={formFiltro}>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item label="Colaborador" name="colaborador">
							<Select
								style={{ width: "100%" }}
								placeholder="Apellidos y Nombres"
								onSearch={onSearchColaboradores}
								allowClear
								showSearch
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								onClear={() => setFiltroColaborador(undefined)}
							>
								{colaboradores !== undefined &&
									colaboradores?.map((colaborador) => (
										<Select.Option
											key={colaborador.numeroDocumentoIdentidad}
											value={colaborador.numeroDocumentoIdentidad}
										>
											{colaborador.nombreCompleto}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label="Proyecto" name="proyecto">
							<Select
								style={{ width: "100%" }}
								placeholder="Ingrese el proyecto"
								allowClear
								showSearch
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								onClear={() => setFiltroProyecto(undefined)}
							>
								{proyectos !== undefined &&
									proyectos?.map((proyecto) => (
										<Select.Option key={proyecto.id} value={proyecto.nombre}>
											{proyecto.nombre}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={5}>
							<Form.Item label="Fecha inicio de contrato" name="fechaInicio">
								<DatePicker
									style={{ width: "100%" }}
									picker="month"
									format={"MM/YYYY"}
									onChange={(date) => {
										if (date === null) {
											setFiltroFechaInicio(undefined);
										}
									}}
								/>
							</Form.Item>
						</Col>
					<Col span={5}>
						<Form.Item label="Fecha fin de contrato" name="meses">
							<DatePicker
								style={{ width: "100%" }}
								picker="month"
								format={"MM/YYYY"}
								onChange={(date) => {
									if (date === null) {
										setFiltroFechaFin(undefined);
									}
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={4}>
						<Form.Item label="Estado" name="estado">
							<Select
								allowClear
								placeholder="Ingrese el estado"
								style={{ width: "100%" }}
								onClear={() => setFiltroEstado(undefined)}
							>
								<Select.Option value={"PENDIENTE"}>PENDIENTE</Select.Option>
								<Select.Option value={"EMITIDO"}>EMITIDO</Select.Option>
								<Select.Option value={"NOTIFICADO"}>NOTIFICADO</Select.Option>
								<Select.Option value={"RENOVADO"}>RENOVADO</Select.Option>
								<Select.Option value={"RECHAZADO"}>RECHAZADO</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
							<Form.Item label="Estado Firma" name="estadoFirma">
								<Select
									allowClear
									placeholder="Seleccion el estado"
									onClear={() => setFiltroEstadoFirma(undefined)}
								>
									<Select.Option value={"PENDIENTE"}>PENDIENTE</Select.Option>
									<Select.Option value={"FIRMADO"}>FIRMADO</Select.Option>
								</Select>
							</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label="Estado Colaborador" name="estadoColaborador">
							<Select
								allowClear
								placeholder="Seleccion el estado"
								onClear={() => setFiltroEstadoColaborador(undefined)}
							>
								<Select.Option value={"ACTIVO"}>ACTIVO</Select.Option>
								<Select.Option value={"CESADO"}>CESADO</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label="Tipo Contrato" name="tipoContrato">
							<Select
								allowClear
								placeholder="Seleccion el tipo"
								onClear={() => setFiltroTipoContrato(undefined)}
							>
								<Select.Option value={"INGRESO"}>INGRESO</Select.Option>
								<Select.Option value={"RENOVACION"}>RENOVACION</Select.Option>
								<Select.Option value={"TRANSFERENCIA"}>TRANSFERENCIA</Select.Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<div
					style={{
						textAlign: "right",
					}}
				>
					<Space size="small">
						<Button type="primary" onClick={onClickFiltrar} style={{ padding: "0px 30px" }}>
							Filtrar
						</Button>
						<Button
							icon={<DownloadOutlined />}
							loading={loadingExportar}
							onClick={handleOnclickExportarSeguimiento}
						>
							Exportar
						</Button>
					</Space>
				</div>
			</Form>
			<br />
			<Table
				rowKey={(item) => item.id}
				columns={columnas}
				dataSource={contratosSeguimiento.contratos}
				pagination={{
					size: "small",
					defaultCurrent: 1,
					current: contratosSeguimiento?.paginaActual + 1,
					total: contratosSeguimiento.totalContratos,
					showTotal: (total) => `Total: ${total} contratos`,
					pageSize: paginacion,
					pageSize: 10,
					onChange: (page) => onChangePagination(page),
				}}
			/>
		</div>
	);
};

const MenuAcciones = ({ contrato }) => {
	console.log("MenuAcciones::", contrato);
	const [, setVisibleModalActualizarContrato] = useAtom(visibleModalActualizarContratoAtom);
	const [, setContratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
	const [, setUrlPdfContrato] = useAtom(urlPdfContratoAtom);
	const [, setVisibleVisorPdf] = useAtom(visibleModalVisorPdfAtom);
	
	const [, setVisibleModalOrigenPdf] = useAtom(visibleModalOrigenPdfAtom);
	
	// const [, setUrlPdfContrato] = useAtom(urlPdfActualizarContratoAtom);
	// const [, setVisibleVisorPdf] = useAtom(visibleModalActualizarVisorPdfAtom);

	const onClickBtnRenovar = () => {
		setContratoSeleccionado(contrato);
		setVisibleModalActualizarContrato(true);
	};

	const onClickBtnVisualizarContrato = async () => {
		console.log("onClickBtnVisualizarContrato");
		setVisibleModalOrigenPdf("visualizar-contrato-from-admin");
		const url = await RenovacionContratosService.getUrlVistaPrevia(contrato.id);
		console.log("Urllll:::::", url);
		// setUrlPdfContrato(url);
		// setVisibleVisorPdf(true);
		setContratoSeleccionado(contrato);
		setUrlPdfContrato(url);
		setVisibleVisorPdf(true);
	}

	if (LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.jefeProyecto && contrato.estado === "RECHAZADO") {
		return (
			<React.Fragment>
				<Button type="link" onClick={onClickBtnVisualizarContrato}>
					<SearchOutlined /> Visualizar
				</Button>

				<Button type="link" onClick={onClickBtnRenovar}>
					<EditOutlined /> Actualizar
				</Button>
			</React.Fragment>
		);

	} else { 
		return (
			<React.Fragment>
				<Button type="link" onClick={onClickBtnVisualizarContrato}>
					<SearchOutlined /> Visualizar
				</Button>
			</React.Fragment>
		);
	}

	return (<div></div>);
};

const TagEstado=({contrato})=>{
    const tagStyle = {
        borderRadius: "15px",
    };

    if(contrato.estado==="PENDIENTE"){
        return(
          <Tag color="warning" style={{ borderRadius: "5px" }}>
            PENDIENTE
          </Tag>
        );
    }else if(contrato.estado==="EMITIDO"){
        return(
            <Tag color="gold" style={{ borderRadius: "5px" }}>
                EMITIDO
            </Tag>
        );
    } else if(contrato.estado==="NOTIFICADO"){
        return(
            <Tag color="blue" style={{ borderRadius: "5px" }}>
                NOTIFICADO
            </Tag>
        );
    } else if(contrato.estado==="RENOVADO"){
        return(
            <Tag color="success" style={{ borderRadius: "5px" }}>
                RENOVADO
            </Tag>
        );
    }else if(contrato.estado==="RECHAZADO"){
		// return (
		// 	<Tag color="red" className="milegajo-ant-tag" style={{ cursor: "pointer", ...tagStyle }}>
		// 		RECHAZADO
		// 	</Tag>
		// );
		const content = (
            <div style={{ width: 140 }}>
              <p><strong>Motivo rechazo: </strong>{contrato.motivoRechazo}</p>
              {contrato?.descripcionRechazo &&(
                <p><strong>Descripción: </strong>{contrato.descripcionRechazo}</p>
              )}
            </div>
          );

        return(
            <div style={{ width: 140 }}>
				<Popover
                    title={null}
                    content={content}
                    trigger="click"
                    placement="bottomRight"
                // onOpenChange={onOpenChange}
                >
                    <Space>
                        <Tag color="red" className="milegajo-ant-tag" style={{ cursor: "pointer", ...tagStyle }}>
                            RECHAZADO
                        </Tag>
                    </Space>
                </Popover>
            </div>
        );
    }
    return contrato.estado;
};

const TagEstadoFirma = ({ estado }) => {
	if (estado === "PENDIENTE") {
		return (
			<Tag color="gold" style={{ borderRadius: "5px" }}>
				PENDIENTE
			</Tag>
		);
	} else if (estado === "FIRMADO") {
		return (
			<Tag color="cyan" style={{ borderRadius: "5px" }}>
				FIRMADO
			</Tag>
		);
	}
	return estado;
};

const ModalActualizarContrato = () => {
	const [form] = Form.useForm();
	const [contratos, setContratos] = useAtom(contratosAtom);
	const [contratoSeleccionado, setContratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
	const [visible, setVisible] = useAtom(visibleModalActualizarContratoAtom);
	const [loading, setLoading] = useState(false);
	const [visibleRenovar, setVisibleRenovar] = useAtom(visibleBtnActualizarContratoAtom);
	const [, setUrlPdfContrato] = useAtom(urlPdfActualizarContratoAtom);
	const [, setVisibleVisorPdf] = useAtom(visibleModalActualizarVisorPdfAtom);
	const [, setVisibleModalOrigenPdf] = useAtom(visibleModalOrigenPdfAtom);

	const onClickBtnActualizar = async () => {
		form.validateFields()
				.then(async (values) => {
					try {
						await RenovacionContratosService.actualizarRenovacionContratoPorJefeProyecto({
							seguimientoContratoId: contratoSeleccionado.id,
							numeroDocumentoIdentidadAprobador:
								LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
							//fechaInicio: contratoSeleccionado.fechaInicioRenovacion,
							fechaInicio: moment(contratoSeleccionado.fechaInicioRenovacion).format("DD/MM/YYYY"),
							fechaFin: moment(values.fechaFin).format("DD/MM/YYYY"),
							//..values
						});

						// message.success(
						// 	"Se ha solicitado la validación de la renovación de contrato a Administración de Personal"
						// );
						console.log("0",contratoSeleccionado.id);
						console.log(contratos);
						// setContratos(
						// 	produce((draft) => {
						// 		const documento = draft.find((contrato) => contrato.id === contratoSeleccionado.id);
						// 		console.log("documento:::", documento);
						// 		console.log("values.fechaFin:::",moment(values.fechaFin).format("DD/MM/YYYY"));
						// 		documento.fechaFin = moment(values.fechaFin).format("DD/MM/YYYY");
						// 	})
						// );

						const _contratos = [...contratos]; //siempre se debe generar un nuevo array
						const indice = contratos.findIndex((contrato) => contrato.id === contratoSeleccionado.id);
						const contratoModificar = contratos[indice];
						const contratoModificado = {
							...contratoModificar,
							fechaFin: moment(values.fechaFin).format("DD/MM/YYYY")
						}
						_contratos.splice(indice, 1, contratoModificado);
						setContratos(_contratos);
						
						setContratoSeleccionado(undefined);
						
						setLoading(false);
			
						setVisible(false);
					} catch (error) {
						console.log("error", error);
						message.error("Ha ocurrido un error, favor de intentar nuevamente.");
					}
				})
				.catch((info) => {
					console.log("Validate Failed:", info);
				});
	};

	const onClickBtnCancelar = () => {
		setVisible(false);
	};

	const disabledDate = (current) => {
		return current && current < form.getFieldValue("fechaInicio").endOf("day");
	};

	const handleVisualizarContrato = async () => {
		form.validateFields()
			.then(async (values) => {	
				let fechaInicio = moment(contratoSeleccionado.fechaInicioRenovacion).format("DD-MM-YYYY");
				let fechaFin = moment(values.fechaFin).format("DD-MM-YYYY");
				const url = await RenovacionContratosService.getVistaPreviaPdf(contratoSeleccionado.id, fechaInicio, fechaFin);

				setVisibleModalOrigenPdf("visualizar-contrato-from-responsable-renovacion");
				setVisibleRenovar(true);
				setUrlPdfContrato(url);
				setVisibleVisorPdf(true);
		})
		.catch((info) => {
			console.log("Validate Failed:", info);
		});
	};

	return (
		<Modal
			title="Actualizar datos del contrato"
			open={visible}
			onCancel={onClickBtnCancelar}
			footer={[
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Button key="cancelar" onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
						Cancelar
					</Button>
					<div style={{ display: 'flex', gap: '10px' }}>
						{!LoginService.isAdministradorObra() && (
							<Button key="visualizar" icon={<SearchOutlined />} onClick={handleVisualizarContrato}>
								Visualizar
							</Button>
						) }
						<Button key="aceptar" type="primary" disabled={!visibleRenovar} onClick={onClickBtnActualizar} style={{ padding: "0px 30px" }}>
						Si, Actualizar!
						</Button>
      				</div>
    			</div>
			]}
			confirmLoading={loading}
		>
			<div>
				<div style={{ textAlign: "center", paddingBottom: "15px" }}>
					<QuestionCircleOutlined style={{ fontSize: "40px", color: "#ebc334" }} />
				</div>
				<div style={{ fontSize: "18px", textAlign: "center", marginBottom: "20px", color: "#457b9d" }}>
					¿Está seguro de actualizar el contrato?
				</div>
				{LoginService.isJefeProyecto() && (
					<div>
						<Form form={form} layout="vertical" autoComplete="off">
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										label="Fecha Inicio"
										name="fechaInicio"
										rules={[
											{
												required: true,
											},
										]}
										initialValue={moment(contratoSeleccionado?.fechaInicioRenovacion)}
									>
										<DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} disabled />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Fecha Fin"
										name="fechaFin"
										rules={[
											{
												required: true,
											},
										]}
									>
										<DatePicker
											style={{ width: "100%" }}
											format={"DD/MM/YYYY"}
											disabledDate={disabledDate}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={24}>
									<Alert
										message="Es recomendable que la fecha fin del nuevo contrato sea al cierre de cada mes"
										type="info"
										showIcon
									/>
								</Col>
							</Row>
						</Form>
					</div>
				)}
			</div>
		</Modal>
	);
};

const Botones = () => {
    //const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
	const [loadingDescarga, setLoadingDescarga] = useState(false);
	const [contratoSeleccionado, ] = useAtom(contratoSeleccionadoAtom);

    const handleDescargarPdf = async () => {
        setLoadingDescarga(true);
		try {
			console.log("descargando");
            const respuesta = await RenovacionContratosService.getDescargarDocumento(contratoSeleccionado.id);
			setLoadingDescarga(false);
			console.log("respuesta.headers::", respuesta.headers);
            const filename = respuesta.headers.filename;
            console.log("filename", filename);
            saveAs(respuesta.data, filename);
		} catch(error) {
		  console.log("error", error);
          setLoadingDescarga(false);
          notification.error({
            message:"Error al intentar descargar el documento. No se encontraron resultados."
          })
        }
        
      };

        return (
                <Space>
                    <Button onClick={handleDescargarPdf} loading={loadingDescarga}>Descargar</Button>
                </Space>

        );

};

const ModalVisorPdf = () => {
	const [visible, setVisible] = useAtom(visibleModalActualizarVisorPdfAtom);
	const [urlPdfContrato] = useAtom(urlPdfActualizarContratoAtom);
	const [visibleModalOrigenPdf, setVisibleModalOrigenPdf] = useAtom(visibleModalOrigenPdfAtom);
	const [visible2, setVisibleVisorPdf] = useAtom(visibleModalVisorPdfAtom);
	const [urlPdfContrato2] = useAtom(urlPdfContratoAtom);
	
	if (visibleModalOrigenPdf && visibleModalOrigenPdf === "visualizar-contrato-from-admin") {
		return (
			<VisorPdf type={"visualizar"} urlPdf={urlPdfContrato2} visible={visible2}  botonesExtra={<Botones />} onCerrar={() => setVisibleVisorPdf(false)} />
		);
	} 

	return (
		<VisorPdf type={"visualizar"} urlPdf={urlPdfContrato} visible={visible} onCerrar={() => setVisible(false)} />
	);
	
};

// const ModalVisorPdf = () => {
// 	const [visible, setVisible] = useAtom(visibleModalVisorPdfAtom);
// 	const [urlPdfContrato] = useAtom(urlPdfContratoAtom);
// 	console.log("visible::", visible);
// 	console.log("urlPdf::", urlPdfContrato);

// 	return (
// 		<VisorPdf type={"visualizar"} urlPdf={urlPdfContrato} visible={visible} onCerrar={() => setVisible(false)} />
// 	);
// };
export default SeguimientoContratosTab;
