import React from "react";
import { useMediaQuery } from "react-responsive";
import { Provider as JotaiProvider } from "jotai";
import { SolicitudDocumentosPageMovil, SolicitudDocumentosPageWeb } from "./componentes";

const SolicitudDocumentosPage = () => {
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

	return (
		<JotaiProvider>
			{esMovil && <SolicitudDocumentosPageMovil />}
			{esDesktop && <SolicitudDocumentosPageWeb />}
		</JotaiProvider>
	);
};

export default SolicitudDocumentosPage;
