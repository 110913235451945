import React from "react";
import "./Header.css";
import { useHistory } from "react-router";
import { ArrowLeftOutlined } from '@ant-design/icons';

const Header = ({seleccionado, onRetornar}) => {
	let history = useHistory();

	const onClickRetornar = () => {
		if (seleccionado)
			onRetornar();
		else
			history.push(`v3/mis-pendientes`);
			// history.push(`/mis-documentos/`);
	}

	return (
		<React.Fragment>
			<div className="container-movil-pdf-header">
				<div style={{ width: "30%" }} onClick={onClickRetornar}>
							<ArrowLeftOutlined style={{ fontSize: "20px", marginRight: "8px" }} />
							Retornar
				</div>
			</div>
		</React.Fragment>
	);
}

export default Header;