import React, {useContext} from "react";
import { Alert, Input, Button, Modal, Form, notification, Row, Space, Col } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
	datosFormularioAtom,
	visibleModalFirmarAtom,
	documentoSeleccionadoAtom,
	documentosAtom,
} from "pages/onboarding/OnboardingStore";
import { useState } from "react";
import { Plataforma } from "enum/Plataforma";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import produce from "immer";
import { useEffect } from "react";
import { EstadoAprobacion } from "enum/EstadoAprobacion";
import { SecurityContext } from "../../../../../../context/SecurityContextProvider";
import ActualizarClaveService from "../../../../../../services/ActualizarClaveService";
import FormItem from "antd/lib/form/FormItem";
import LoginService from "../../../../../../services/LoginService";
import { CheckOutlined } from "@ant-design/icons";

const ModalFirmar = ({ plataforma = Plataforma.WEB }) => {
	const [claveIngresada, setClaveIngresada] = useState("");
	const [visible, setVisible] = useAtom(visibleModalFirmarAtom);
	const [loading, setLoading] = useState(false);
	const [datosFormulario, setDatosFormulario] = useAtom(datosFormularioAtom);
	const [mensajeError, setMensajeError] = useState(undefined);
	const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
	//const [documentos, setDocumentos] = useAtom(documentosAtom);
	const setDocumentos = useSetAtom(documentosAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const ismaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const ismaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const [existeClave, setExisteClave] = useState(false);
	const [visibleCrearClaveModal, setVisibleCrearClaveModal] = useState(false);
	const [formularioCambiarClave] = Form.useForm();
	const [loadingModalClave, setLoadingModalClave] = useState(false);
	
	useEffect(() => {
		const cargarValidacion = async () => {
			if (ismaccaferriDePeru || ismaccaferriConstruction) { 
				const datos = await PortalColaboradorService.validarExisteClave();
				console.log("datos:::", datos);
				setExisteClave(datos.existeClave);
			}
		};
		cargarValidacion();
	}, [existeClave]);
	
	const onClickBtnFirmar = async () => {
		try {
			setLoading(true);

			await PortalColaboradorService.completarFormulario({
				detalleEnvioId: documentoSeleccionado.id,
				datos: datosFormulario,
				clave: claveIngresada,
				plataforma,
			});

			setDocumentoSeleccionado({
				...documentoSeleccionado,
				estadoVistaColaborador: EstadoVistaColaborador.FIRMADO,
				estadoAprobacion: EstadoAprobacion.PENDIENTE
			});

			setDocumentos(
				produce((draft) => {
					const documento = draft.find((documento) => documento.id === documentoSeleccionado.id);
					documento.estadoVistaColaborador = EstadoVistaColaborador.FIRMADO;
					documento.estadoAprobacion = EstadoAprobacion.PENDIENTE;
				})
			);

			setClaveIngresada("");
			setMensajeError(undefined);
			setVisible(false);
		} catch (error) {
			console.error(error?.response?.data?.mensaje);
			setMensajeError(error?.response?.data?.mensaje);
		} finally {
			setLoading(false);
		}
	};

	const onClickBtnCancelar = () => {
		setVisible(false);
	};

	// Funciones para abrir y cerrar el modal para crear clave
  	const abrirCrearClaveModal = () => setVisibleCrearClaveModal(true);
	const cerrarCrearClaveModal = () => setVisibleCrearClaveModal(false);

	async function handleOnFinish(formulario) {
		console.log("formularioooo:::", formulario);
		try {
			setLoadingModalClave(true);
			const mensajeValidacion = await ActualizarClaveService.crearClave(
				LoginService.getUsuarioAutenticado().subdominio,
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				formulario.clave
			);
			console.log("mensajeValidacion::", mensajeValidacion);
			let mensaje = (mensajeValidacion != null && mensajeValidacion != "")? mensajeValidacion.message : null;
			//setMensajeError(mensaje);
			setLoadingModalClave(false);
			if (mensaje == null || mensaje == "") {
				setExisteClave(true);
				notification.success({
					message: "Operación exitosa",
					description: "Se actualizo su clave.",
				});
				setVisibleCrearClaveModal(false)
			}
			
		} catch (error) {
			console.log(error);
			setLoadingModalClave(false);
			notification.error({
				message: "No se puede guardar la clave",
				description: "Error al generar la clave",
			});
		}
	}

	return (
		<>
			<Modal
			title="Firmar Documento"
			open={visible}
			closable={false}
			width={400}
			destroyOnClose={true}
			footer={[
				<Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
					Cancelar
				</Button>,
				<Button
					key="firmar"
					type="primary"
					loading={loading}
					onClick={onClickBtnFirmar}
					style={{ padding: "0px 30px" }}
				>
					Firmar
				</Button>,
			]}
			centered
		>
			{mensajeError && (
				<Alert
					message={mensajeError}
					onClose={() => setMensajeError(undefined)}
					style={{ marginBottom: "4px" }}
					type="error"
					showIcon
					closable
				/>
			)}

			<span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
			<Input.Password
				value={claveIngresada}
				onChange={(e) => {
					setClaveIngresada(e.target.value);
					setMensajeError(undefined);
				}}
				placeholder={"Clave"}
			/>
			 {(ismaccaferriDePeru || ismaccaferriConstruction) && !existeClave && ( 
				<div style={{ paddingTop: "10px" }}>
					<Button type="link" onClick={abrirCrearClaveModal} style={{ padding: "0" }}>
						Crea tu clave para firmar tus documentos.
					</Button>
				</div>
			 )} 
			</Modal>

			<Modal
				title="Crear Clave"
				open={visibleCrearClaveModal}
				onCancel={cerrarCrearClaveModal}
				footer={null}
				>
					<Form
						form={formularioCambiarClave}
						labelCol={{
							span: 12,
						}}
						wrapperCol={{
							span: 12,
						}}
						layout="vertical"
						size="default"
						onFinish={handleOnFinish}
					>
						<FormItem
								label="Clave"
								name="clave"
								rules={[
									{
										required: true,
										message: "Ingrese su clave",
									},
								]}
							>
								<Input.Password />
						</FormItem>
						<FormItem
								label="Repetir Clave"
								name="claveR"
								dependencies={["clave"]}
								hasFeedback
								rules={[
									{
										required: true,
										message: "Ingrese nuevamente su clave",
									},
									({ getFieldValue }) => ({
										validator(rule, value) {
											if (
												!value ||
												getFieldValue("clave") === value
											) {
												return Promise.resolve();
											}

											return Promise.reject(
												"Las claves deben ser iguales!"
											);
										},
									}),
								]}
							>
							<Input.Password />
						</FormItem>
						<Row justify="center">
						<Space>
							<Col span={20}>
								<Button
									type="primary"
									icon={<CheckOutlined />}
									htmlType="submit"
									loading={loadingModalClave}
								>
									Guardar
								</Button>
							</Col>
							<Col>
								<Button
									type="default"
									onClick={cerrarCrearClaveModal}
								>
									Cancelar
								</Button>
							</Col>
						</Space>
					</Row>
					</Form>
			</Modal>
		</>
		
	);
};

export default ModalFirmar;
