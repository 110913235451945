import React from "react";
import { Button } from "antd";
import { EditOutlined, LayoutOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

export const MenuAcciones = ({ formulario }) => {

    let history = useHistory();

    const BotonCompletar = () => {

        const onClickBtnCompletar = () => {
            const rutaFormulario = `/mis-formularios/${formulario.id}/completar`;
            history.push(rutaFormulario);
        }

        return (
            <Button type="link" onClick={onClickBtnCompletar}>
                <EditOutlined /> Completar
            </Button >
        )
    }

    return (
        <React.Fragment>
            <BotonCompletar />
        </React.Fragment>
    );
}