import { HttpService } from "../HttpService";
import { URL } from "enum";
const URL_API = URL.RECLUTAMIENTO_Y_SELECCION;

const CompetenciasService = {
	async listar() {
		const respuesta = await HttpService(URL_API).get("/competencias");
		return respuesta.data;
	},
};
export default CompetenciasService;
