import { BaseService } from "./BaseService";

import { HttpService } from "./HttpService";

const CargoService = {
    async listar(empresaId) {
    const respuesta = await BaseService.get("/cargo", {
      params: {
        empresaId: empresaId,
      },
    });
    return respuesta.data;
  },
    async listarPromise(empresaId) {
    return HttpService(empresaId).get("/cargo");
    },
    async buscarPorId(id) {
    const respuesta = await BaseService.get(`/cargo/${id}`);
    return respuesta.data;
  },
    
    async listarPorColaborador(login) {
        const respuesta = await BaseService.get("/cargo/colaborador", {
            params: {
                login: login,
            },
        });
        return respuesta.data;
    },
    
}
export default CargoService;