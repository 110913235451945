import React, { useEffect, useContext } from "react";
import { DatePicker, Form, Input, Modal, Select, Tag, Upload, Button } from "antd";
import { useAtom } from "jotai";
import { EditOutlined, CalendarOutlined } from "@ant-design/icons";
import {
	formularioSolicitarAusenciaAtom,
	visibleModalCrearSolicitudAusenciaAtom,
	visibleModalFirmarSolicitudAusenciaAtom,
	archivosAdjuntosAtom,
	ocultarFechasSolicitadasAtom,
} from "../../store/MisSolicitudesStore";
import {
	useListarTiposAusencia,
	useObtenerDetalleVacacionesColaborador,
	useObtenerJefeInmediato,
	useSeleccionarTipoAusencia,
	useSimularDescuentoVacaciones,
} from "../../hooks";
import { useDispositivo } from "hooks";
import Control from "./Control";
import Archivo from "./Archivo";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { SecurityContext } from "context/SecurityContextProvider";

const ModalCrearSolicitudAusencia = () => {
	const { RangePicker } = DatePicker;
	const { TextArea } = Input;
	const [form] = Form.useForm();
	const fechasSolicitadas = [];
	const [visibleModal, setVisibleModal] = useAtom(visibleModalCrearSolicitudAusenciaAtom);
	const [, setVisibleModalFirmar] = useAtom(visibleModalFirmarSolicitudAusenciaAtom);
	const [, setFormularioSolicitarAusencia] = useAtom(formularioSolicitarAusenciaAtom);

	const { tipoAusencia, limpiarTipoAusencia, onChangeTipoAusencia } = useSeleccionarTipoAusencia();
	const { tiposAusencia } = useListarTiposAusencia();
	const { vacacionesSimuladas, vacacionesSimuladasError, descontarDias, limpiarSimulacion } =
		useSimularDescuentoVacaciones();
	const { jefeInmediato } = useObtenerJefeInmediato();
	const { detalleVacacionesColaborador } = useObtenerDetalleVacacionesColaborador();
	const { esMovil, esDesktop } = useDispositivo();
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isMaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const isMaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const [ocultarFechasSolicitadas, setOcultarFechasSolicitadas] = useAtom(ocultarFechasSolicitadasAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			if (visibleModal) {
				form.resetFields();
				limpiarSimulacion();
			}
		};
		cargarDatos();
	}, [visibleModal]);

	useEffect(() => {
		const cargarAprobador = async () => {
			form.setFieldsValue({
				aprobador: tipoAusencia?.requiereAprobacionJefeInmediato
					? jefeInmediato?.nombreCompleto
					: !tipoAusencia?.requiereAprobacionJefeInmediato && tipoAusencia?.requiereAprobacionOtrosCargos
					? tipoAusencia?.aprobadoresOtrosCargos[0].nombreCompleto
					: null,
			});
		};
		cargarAprobador();
	}, [tipoAusencia]);

	const deshabilitarFechasSolicitudFin = (current) => {
		if (tipoAusencia?.contabilizarHoras) {
			return moment(current).format("DD/MM/YYYY") < moment(fechasSolicitadas[0]).format("DD/MM/YYYY");
		}
		return current < fechasSolicitadas[0];
	};
	const deshabilitarFechaSolicitudInicio = (current) => {
		//return current && current < moment().endOf('day');
		return current && current < moment().startOf("day");
	};

	const onClickBtnSolicitar = async (formulario) => {
		if (ocultarFechasSolicitadas) {
			formulario.fechasSolicitud = [];
			formulario.fechasSolicitud[0] = formulario.informacionAdicional.diaCompensacion;
			formulario.fechasSolicitud[1] = formulario.informacionAdicional.diaCompensacion;
		}

		if (esMovil && !ocultarFechasSolicitadas) {
			formulario.fechasSolicitud = [];
			formulario.fechasSolicitud[0] = formulario.fechaSolicitudInicio;
			formulario.fechasSolicitud[1] = formulario.fechaSolicitudFin;
		}
		//console.log("formulario", formulario);

		// console.log("keys", Object.keys(formulario.adjuntos));
		// console.log("values", Object.values(formulario.adjuntos));

		// var adjuntos = [];
		// for (var posicion = 0; posicion < Object.keys(formulario.adjuntos).length; posicion++) {
		// 	adjuntos[posicion] = Object.values(formulario.adjuntos)[posicion].file;
		// }
		// console.log("adjuntos", adjuntos);

		// const adjuntos = formulario.adjuntos.map((adjunto) => {
		// 	return adjunto.file;
		// });
		// console.log("adjuntos", adjuntos);

		setFormularioSolicitarAusencia(formulario);
		setVisibleModalFirmar(true);
	};

	const onChangeDatePickerFechaInicio = (fecha) => {
		fechasSolicitadas[0] = fecha;
	};

	const onChangeDatePickerFechaFin = (fecha) => {
		fechasSolicitadas[1] = fecha;
		descontarDias(fechasSolicitadas);
	};

	const FechasSolicitadasExtra = () => {
		if (tipoAusencia?.contabilizarDiasVacaciones) {
			if (vacacionesSimuladas) {
				return (
					<React.Fragment>
						<div
							style={{
								marginTop: "5px",
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Tag color="warning">
								<EditOutlined /> Se descontarán de su saldo:
								<span style={{ padding: "0px", fontWeight: "bold" }}>
									{vacacionesSimuladas.diasSolicitados}
								</span>{" "}
								días
							</Tag>
						</div>
						<div style={{ marginTop: "5px" }}>
							<Tag color="blue">
								<CalendarOutlined /> Periodo Vacacional:{" "}
								<span style={{ fontWeight: "bold" }}>
									{vacacionesSimuladas.periodoVacacionalInicio}
								</span>{" "}
								{" - "}
								<span style={{ fontWeight: "bold" }}>{vacacionesSimuladas.periodoVacacionalFin}</span>
							</Tag>
						</div>
					</React.Fragment>
				);
			}
			if (vacacionesSimuladasError) {
				return (
					<div
						style={{
							marginTop: "5px",
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Tag color="error">
							<span style={{ padding: "0px", fontWeight: "bold" }}>{vacacionesSimuladasError}</span>
						</Tag>
					</div>
				);
			}
		}
		return "";
	};

	return (
		<Modal
			title={"Registrar Solicitud Ausencia"}
			bodyStyle={{ overflowY: "auto", minHeight: "calc(100vh - 220px)", maxHeight: "calc(100vh - 220px)" }}
			width={600}
			centered
			visible={visibleModal}
			onCancel={() => {
				setVisibleModal(false);
				form.resetFields();
				limpiarTipoAusencia();
			}}
			onOk={() => form.submit()}
			okText={"Solicitar"}
			okButtonProps={{
				disabled: vacacionesSimuladasError ? true : false,
			}}
		>
			{" "}
			{tipoAusencia?.contabilizarDiasVacaciones && (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<span style={{ fontSize: "20px", fontWeight: "bold", color: "#108ee9", textAlign: "center" }}>
						{tipoAusencia?.ocultarDiasTruncos
							? detalleVacacionesColaborador?.vacacionesDisponiblesConDiasTruncos + " DÍAS"
							: detalleVacacionesColaborador?.vacacionesDisponibles + " DÍAS"}
						{/* {detalleVacacionesColaborador?.vacacionesDisponibles} DÍAS */}
					</span>
					<span style={{ fontSize: "13px", textAlign: "center" }}>disponibles</span>
				</div>
			)}
			<Form form={form} layout={"vertical"} onFinish={onClickBtnSolicitar} style={{ padding: "0px 5%" }}>
				<Form.Item
					label="Tipo Ausencia"
					name={"tipoAusenciaId"}
					labelAlign="left"
					rules={[{ required: true, message: "Seleccione el tipo" }]}
				>
					<Select style={{ width: "100%" }} onChange={onChangeTipoAusencia}>
						{tiposAusencia !== undefined &&
							tiposAusencia.map((tipo) => {
								return (
									<Select.Option key={tipo.id} value={tipo.id}>
										{tipo.descripcion}
									</Select.Option>
								);
							})}
					</Select>
				</Form.Item>

				{tipoAusencia?.controles &&
					tipoAusencia.controles.map((info) => {
						return <Control key={info.key} info={info} />;
					})}

				{tipoAusencia?.archivos &&
					tipoAusencia.archivos.map((info) => {
						return <Archivo key={info.key} info={info} />;
					})}

				{esDesktop && !ocultarFechasSolicitadas && (
					<Form.Item
						label={
							<div>
								<CalendarOutlined /> Fechas Solicitadas
							</div>
						}
						name="fechasSolicitud"
						labelAlign="left"
						rules={[{ required: true, message: "Ingrese la fecha" }]}
						extra={<FechasSolicitadasExtra />}
					>
						<RangePicker
							allowClear={true}
							format={tipoAusencia?.contabilizarHoras ? "DD/MM/YYYY hh:mm a" : "DD/MM/YYYY"}
							//format="DD/MM/YYYY HH:mm:ss"
							showTime={tipoAusencia?.contabilizarHoras ? true : false}
							disabledDate={
								tipoAusencia?.permitirSolicitudFechasAnteriores
									? false
									: deshabilitarFechaSolicitudInicio
							}
							onChange={(fechasSolicitadas) => {
								descontarDias(fechasSolicitadas);
							}}
							style={{ width: "100%" }}
						/>
					</Form.Item>
				)}

				{esMovil && !ocultarFechasSolicitadas && (
					<React.Fragment>
						<Form.Item
							label={
								<div>
									<CalendarOutlined /> Fecha Inicio
								</div>
							}
							name="fechaSolicitudInicio"
							labelAlign="left"
							rules={[{ required: true, message: "Ingrese la la fecha inicio" }]}
						>
							<DatePicker
								allowClear={true}
								disabledDate={
									tipoAusencia?.permitirSolicitudFechasAnteriores
										? false
										: deshabilitarFechaSolicitudInicio
								}
								onChange={onChangeDatePickerFechaInicio}
								//format={"DD/MM/YYYY"}
								format={tipoAusencia?.contabilizarHoras ? "DD/MM/YYYY hh:mm a" : "DD/MM/YYYY"}
								showTime={tipoAusencia?.contabilizarHoras ? true : false}
								style={{ width: "100%" }}
							/>
						</Form.Item>

						<Form.Item
							label={
								<div>
									<CalendarOutlined /> Fecha Fin
								</div>
							}
							name="fechaSolicitudFin"
							labelAlign="left"
							rules={[{ required: true, message: "Ingrese la la fecha fin" }]}
							extra={<FechasSolicitadasExtra />}
						>
							<DatePicker
								allowClear={true}
								onChange={onChangeDatePickerFechaFin}
								//format={"DD/MM/YYYY"}
								format={tipoAusencia?.contabilizarHoras ? "DD/MM/YYYY hh:mm a" : "DD/MM/YYYY"}
								showTime={tipoAusencia?.contabilizarHoras ? true : false}
								disabledDate={deshabilitarFechasSolicitudFin}
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</React.Fragment>
				)}

				<Form.Item
					label={isMaccaferriConstruction || isMaccaferriDePeru ? "Empleado designado" : "Comentario"}
					name="comentario"
					labelAlign="left"
				>
					<TextArea autoSize={{ minRows: 2, maxRows: 5 }} showCount maxLength={250} />
				</Form.Item>

				<Form.Item
					label="Aprobador"
					name="aprobador"
					// initialValue={jefeInmediato?.nombreCompleto}
				>
					<Input readOnly />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ModalCrearSolicitudAusencia;
