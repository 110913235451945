import { useEffect } from "react";
import { useAtom, useSetAtom } from "jotai";
import { OnboardingService } from "services";
import UbigeoService from "../../../../../services/UbigeoService";
import {
	informacionPersonalAtom,
	loadingAtom,
	provinciaAtom,
	distritoAtom,
	mostrarCamposPorPaisAtom,
	provinciaNacimientoAtom,
	distritoNacimientoAtom,
} from "pages/onboarding/OnboardingStore";
import { Form } from "antd";
import moment from "moment";

const useCargarInformacionPersonal = () => {
	const [form] = Form.useForm();
	const [informacionPersonal, setInformacionPersonal] = useAtom(informacionPersonalAtom);
	const [provincias, setProvincias] = useAtom(provinciaAtom);
	const [distritos, setDistritos] = useAtom(distritoAtom);
	const [mostrarSelect, setMostrarSelect] = useAtom(mostrarCamposPorPaisAtom);
	const [provinciasNac, setProvinciasNac] = useAtom(provinciaNacimientoAtom);
	const [distritosNac, setDistritosNac] = useAtom(distritoNacimientoAtom);
	//const setLoading = useSetAtom(loadingAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			const respuesta = await OnboardingService.obtenerInformacionPersonal();
			setInformacionPersonal(respuesta);
		};
		cargarDatos();
	}, []);

	useEffect(() => {
		const cargarInformacionPersonal = () => {
			form.setFieldsValue({
				datosPersonales: {
					tipoDocumentoIdentidad: informacionPersonal?.datosPersonales?.tipoDocumentoIdentidad,
					numeroDocumentoIdentidad: informacionPersonal?.datosPersonales?.numeroDocumentoIdentidad,
					apellidoPaterno: informacionPersonal?.datosPersonales?.apellidoPaterno,
					apellidoMaterno: informacionPersonal?.datosPersonales?.apellidoMaterno,
					nombres: informacionPersonal?.datosPersonales?.nombres,
					genero: informacionPersonal?.datosPersonales?.genero,
					estadoCivil: informacionPersonal?.datosPersonales?.estadoCivil,
					correoElectronico: informacionPersonal?.datosPersonales?.correoElectronico,
					celular: informacionPersonal?.datosPersonales?.celular,
				},
				domicilioActual: {
					direccion: informacionPersonal?.domicilioActual?.direccion,
					departamento: informacionPersonal?.domicilioActual?.departamento,
					provincia: informacionPersonal?.domicilioActual?.provincia,
					distrito: informacionPersonal?.domicilioActual?.distrito,
				},
				datosNacimiento: {
					pais: informacionPersonal?.datosNacimiento?.pais,
					nacionalidad: informacionPersonal?.datosNacimiento?.nacionalidad,
					fechaNacimiento:
						informacionPersonal?.datosNacimiento?.fechaNacimiento &&
						moment(
							moment(informacionPersonal?.datosNacimiento?.fechaNacimiento).format("DD/MM/YYYY"),
							"DD/MM/YYYY"
						),
					departamento: informacionPersonal?.datosNacimiento?.departamento,
					provincia: informacionPersonal?.datosNacimiento?.provincia,
					distrito: informacionPersonal?.datosNacimiento?.distrito,
					direccion: informacionPersonal?.datosNacimiento?.direccion,
				},
				contactoEmergencia: {
					nombres: informacionPersonal?.contactoEmergencia?.nombres,
					telefono: informacionPersonal?.contactoEmergencia?.telefono,
				},
				regimenPensionario: {
					//tipo: informacionPersonal?.regimenPensionario?.tipo,
					tipo:
						informacionPersonal?.regimenPensionario != null
							? informacionPersonal?.regimenPensionario.tipo
							: "NINGUNO",
					cusppAfp: informacionPersonal?.regimenPensionario?.cusppAfp,
					nombreAfp: informacionPersonal?.regimenPensionario?.nombreAfp,
				},
				cuentaHaberes: {
					exist: informacionPersonal?.cuentaHaberes?.exist,
					banco: informacionPersonal?.cuentaHaberes?.banco,
					tipoMoneda: informacionPersonal?.cuentaHaberes?.tipoMoneda,
					nroCuenta: informacionPersonal?.cuentaHaberes?.nroCuenta,
					nroCuentaCCI: informacionPersonal?.cuentaHaberes?.nroCuentaCCI,
				},
				cuentaCTS: {
					banco: informacionPersonal?.cuentaCTS?.banco,
					tipoMoneda: informacionPersonal?.cuentaCTS?.tipoMoneda,
				},
			});
		};
		cargarProvinciasDomicilio();
		cargarDistritosDomicilio();
		cargarProvinciasNacimiento();
		cargarDistritoNacimiento();
		cargarInformacionPersonal();
	}, [informacionPersonal]);

	const cargarProvinciasDomicilio = async () => {
		if (informacionPersonal?.domicilioActual?.departamento) {
			const formData = new FormData();
			formData.append("departamento", informacionPersonal?.domicilioActual?.departamento);
			const provincias = await UbigeoService.listarProvincias(formData);
			setProvincias(provincias);
		}
	};
	const cargarDistritosDomicilio = async () => {
		if (informacionPersonal?.domicilioActual?.provincia) {
			const formDataDistrito = new FormData();
			formDataDistrito.append("provincia", informacionPersonal?.domicilioActual?.provincia);

			const distritos = await UbigeoService.listarDistritos(formDataDistrito);
			setDistritos(distritos);
		}
	};

	const cargarProvinciasNacimiento = async () => {
		if (informacionPersonal?.datosNacimiento?.pais && informacionPersonal?.datosNacimiento?.pais == "PERU") {
			setMostrarSelect(true);
		} else {
			setMostrarSelect(false);
		}

		if (informacionPersonal?.datosNacimiento?.departamento) {
			const formData = new FormData();
			formData.append("departamento", informacionPersonal?.datosNacimiento?.departamento);
			const provincias = await UbigeoService.listarProvincias(formData);
			setProvinciasNac(provincias);
		}
	};

	const cargarDistritoNacimiento = async () => {
		if (informacionPersonal?.datosNacimiento?.provincia) {
			const formDataDistrito = new FormData();
			formDataDistrito.append("provincia", informacionPersonal?.datosNacimiento?.provincia);

			const distritos = await UbigeoService.listarDistritos(formDataDistrito);
			setDistritosNac(distritos);
		}
	};

	return { form, informacionPersonal, provincias, distritos, mostrarSelect, provinciasNac, distritosNac };
};

export default useCargarInformacionPersonal;
