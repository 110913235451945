import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import {
	detalleEntrevistaAtom,
	posicionPreguntaActualAtom,
	visibleModalPreguntasAtom,
} from "../DetalleEntrevistaStore";
import { message } from "antd";
import useListarPreguntas from "./useListarPreguntas";
import produce from "immer";
import { EntrevistasService } from "services/reclutamiento-y-seleccion";
import { useParams } from "react-router-dom";

const usePreguntas = () => {
	const { entrevistaId } = useParams();
	const { preguntas, setPreguntas } = useListarPreguntas();
	const [detalleEntrevista, setDetalleEntrevista] = useAtom(detalleEntrevistaAtom);
	const [posicionActual, setPosicionActual] = useAtom(posicionPreguntaActualAtom);
	const [, setVisibleModalPreguntas] = useAtom(visibleModalPreguntasAtom);
	const [deshabilitarBotonAtras, setDeshabilitarBotonAtras] = useState(true);
	const [mostrarBotonSiguiente, setMostrarBotonSiguiente] = useState(true);
	const [mostrarBotonFinalizar, setMostrarBotonFinalizar] = useState(false);

	//const [comentario, setComentario] = useState([{comentario:""}]);

	const totalPreguntas = preguntas.length	;

	useEffect(() => {
		if (posicionActual === 0) {
			setDeshabilitarBotonAtras(true);
		} else {
			setDeshabilitarBotonAtras(false);
		}

		if (totalPreguntas > 0 && posicionActual === totalPreguntas) {
			setMostrarBotonFinalizar(true);
			setMostrarBotonSiguiente(false);
		} else {
			setMostrarBotonFinalizar(false);
			setMostrarBotonSiguiente(true);
		}
	}, [posicionActual]);

	const asignarRespuestaPregunta = (respuesta) => {
		setPreguntas(
			produce((draft) => {
				const pregunta = draft.find((pregunta) => pregunta.id === preguntas[posicionActual].id);
				pregunta.respuesta = respuesta;
			})
		);
	};

	// const asignarComentario = (respuesta) => {

	// 	setPreguntas(
	// 		produce((draft) => {
	// 			const pregunta = draft[0];
	// 			pregunta.comentario = respuesta;
	// 		})
	// 	);
	// 	setComentario(respuesta)
	// };

	const onClickBtnSiguiente = () => {
		const preguntaActual = preguntas.find((pregunta) => pregunta.id === preguntas[posicionActual].id);

		if (preguntaActual.respuesta === undefined) {
			message.error("Seleccione una respuesta!");
		} else {
			if (posicionActual < totalPreguntas) {
				setPosicionActual((posicion) => posicion + 1);
			}
		}
	};

	const onClickBtnAnterior = () => {
		if (posicionActual > 0) {
			setPosicionActual((posicion) => posicion - 1);
		}
	};

	const onClickBtnFinalizar = (comentario) => {
		// const preguntaActual = preguntas.find((pregunta) => pregunta.id === preguntas[posicionActual].id);

		// if (preguntaActual.respuesta === undefined) {
		// 	message.error("Seleccione una respuesta!");
		// 	return;
		// }
		console.log(comentario)
		if (comentario === null || comentario.trim() === '') {
			message.error("Ingrese un comentario!");
			return;
		}
		
			const respuesta = EntrevistasService.completarPreguntas({
				entrevistaId: entrevistaId,
				preguntas: preguntas,
				comentario: comentario
			});
			setDetalleEntrevista({ ...detalleEntrevista, preguntasCompleto: respuesta });
			setVisibleModalPreguntas(false);
		
	};

	const getPreguntaActual = () => {
		const preguntaActual = preguntas[posicionActual];
		return {
			descripcion: preguntaActual?.descripcion,
			respuesta: preguntaActual?.respuesta,
			posicion: posicionActual + 1,
		};
	};

	return {
		asignarRespuestaPregunta,
		onClickBtnSiguiente,
		onClickBtnAnterior,
		onClickBtnFinalizar,
		getPreguntaActual,
		totalPreguntas,
		deshabilitarBotonAtras,
		mostrarBotonSiguiente,
		mostrarBotonFinalizar
	};
};
export default usePreguntas;
