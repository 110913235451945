import React, { useEffect } from "react";
import {
	componentesAtom,
	datosFormularioAtom,
	formAtom,
	loadingAtom,
	urlFirmaHolografaAtom,
	urlHuellaDactilarAtom,
	urlPdfAtom,
	variablesGlobalesAtom,
	urlFotoAtom,
	visibilidadComponentesAtom,
	typeAtom,
	motivoRechazoAtom
} from "../../VisorFormularioStorage";
import { useAtom, useSetAtom } from "jotai";

const InicializarValores = ({
	type,
	form,
	urlPdf,
	componentes,
	variablesGlobales,
	datosFormulario,
	urlFirmaHolografa,
	urlHuellaDactilar,
	urlFoto,
	setLoadingPdf,
	motivoRechazo,
}) => {
	const setType = useSetAtom(typeAtom);
	const setUrlPdf = useSetAtom(urlPdfAtom);
	const [loading, setLoading] = useAtom(loadingAtom);
	const setComponentes = useSetAtom(componentesAtom);
	const setVisibilidadComponentes = useSetAtom(visibilidadComponentesAtom);
	const setVariablesGlobales = useSetAtom(variablesGlobalesAtom);
	const setDatosFormulario = useSetAtom(datosFormularioAtom);
	const setForm = useSetAtom(formAtom);
	const setUrlFirmaHolografa = useSetAtom(urlFirmaHolografaAtom);
	const setUrlHuellaDactilar = useSetAtom(urlHuellaDactilarAtom);
	const setUrlFoto = useSetAtom(urlFotoAtom);
	const setMotivoRechazo = useSetAtom(motivoRechazoAtom);
	
	useEffect(() => {
		const inicializar = () => {
			setType(type);
			setUrlPdf(urlPdf);
			setVariablesGlobales(variablesGlobales);
			setDatosFormulario(datosFormulario);
			setForm(form);
			setUrlFirmaHolografa(urlFirmaHolografa);
			setUrlHuellaDactilar(urlHuellaDactilar);
			setUrlFoto(urlFoto);
			setMotivoRechazo(motivoRechazo);
			if (urlPdf) {
				setLoading(true);
				if (setLoadingPdf !== undefined) { 
					setLoadingPdf(true);
				}
			}
		};
		inicializar();
	}, [urlPdf]);

	// useEffect(() => {
	// 	const limpiar = () => {
	// 		if (loading) {
	// 			console.log("limpiando visibilidad componentes.....");
	// 			setComponentes([]);
	// 			setVisibilidadComponentes({});
	// 			form.reset();
	// 		} else {
	// 			setComponentes(componentes);
	// 		}
	// 	};
	// 	limpiar();
	// }, [loading]);

	useEffect(() => {
		const limpiar = () => {
			// if (type === "form") { 
				if (loading) {
					setComponentes([]);
					setVisibilidadComponentes({});
					if (type === "form") {
						form.reset();
					}
				} else if(type === "form"){
					setComponentes(componentes);
				}
			// }
			
		};
		limpiar();
	}, [loading]);

	// useEffect(() => {
    // const limpiar = () => {
    //   if (type === "form") {
    //     if (loading) {
    //       setComponentes([]);
    //       form.reset();
    //     } else {
    //       setComponentes(componentes);
    //     }
    //   }
    // };
    // limpiar();
	// }, [loading]);
	
	return "";
};

export default InicializarValores;
