import { useAtom } from "jotai";
import { formInstanceAtom } from "../store/Store";

const useInformacionPersonal = () => {

	const [formInstance] = useAtom(formInstanceAtom);

	const guardarInformacionFormulario = () => {
		formInstance.validateFields()
			.then(async (formulario) => {
				console.log("formulario", formulario)
			})
			.catch((info) => {
				console.error("Validate Failed:", info);
				// message.error("Complete los datos obligatorios.");
			});
	}

	return { guardarInformacionFormulario };
};

export default useInformacionPersonal;
