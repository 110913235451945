import React, { useEffect, useRef } from "react";
import { Modal, Button } from "antd";
import { useAtom } from "jotai";
import SignaturePad from "react-signature-pad-wrapper";
import produce from "immer";
import {
	componentSelectedAtom,
	signatureModalVisibleAtom,
	signaturesMetadataAtom,
} from "../../MisDocumentosMovilStorage";

const SignatureModal = () => {
	const [visible, setVisible] = useAtom(signatureModalVisibleAtom);
	const [, setSignaturesMetadata] = useAtom(signaturesMetadataAtom);
	const [componentSelected] = useAtom(componentSelectedAtom);
	const refSignature = useRef();

	const onClickBtnOk = () => {
		setSignaturesMetadata(
			produce((draft) => {
				const signature = draft.find((s) => s.key === componentSelected.key);
				let base64 = refSignature.current.isEmpty() ? undefined : refSignature.current.toDataURL();
				if (signature) {
					signature.base64 = base64;
				} else {
					draft.push({
						key: componentSelected.key,
						base64: base64,
					});
				}
			})
		);
		setVisible(false);
	};

	const onClickBtnCancelar = () => {
		setVisible(false);
	};

	const onClickBtnLimpiarFirma = () => {
		refSignature.current.clear();
	};

	return (
		<Modal
			title="Firmar"
			visible={visible}
			okText="Aceptar"
			cancelText="Cancelar"
			onOk={onClickBtnOk}
			onCancel={onClickBtnCancelar}
			centered
			bodyStyle={{ textAlign: "center" }}
			maskClosable={false}
		>
			<SignaturePad
				ref={refSignature}
				canvasProps={{ style: { border: "1px dashed #ccc" } }}
				options={{ minWidth: 2, maxWidth: 3, penColor: "#000" }}
			/>
			<Button type="link" onClick={onClickBtnLimpiarFirma}>
				Limpiar Firma
			</Button>
		</Modal>
	);
};

export default SignatureModal;
