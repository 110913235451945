import React, { useContext,useEffect } from "react";

import { ListaDocumentosPendientes } from "../ListaDocumentosPendientes";
import { ListaHistorialDocumentos } from "../ListaHistorialDocumentos";
import { SecurityContext } from "../../../../context/SecurityContextProvider";
import { useHistory } from "react-router-dom";
import { HeaderMovil } from "../HeaderMovil";
import logotipo from "../../../../assets/imagenes/logotipo.png";
import { LoadingDocumentos } from "../../../../components/LoadingDocumentos";
import { Alert } from "antd";
import * as atomsConsulta from "../../../hooks/consultas/ConsultasStorage";
import { useAtom } from "jotai";
import { PortalColaboradorService } from "services/PortalColaboradorService";

export function MovilBandejaEntrada({
	loading,
	documentosPendientes,
	documentosVisualizados,
}) {
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [totalRespuestasUsuario, setTotalRespuestasUsuario] = useAtom(atomsConsulta.totalRespuestasUsuarioAtom);
	let history = useHistory();

	useEffect(() => {
		obtenerNumeroDetalleNoVisualizadas();
	}, []);
	
	const obtenerNumeroDetalleNoVisualizadas = async () => {
		const consultas = await PortalColaboradorService.listaConsultas(getUsuarioAutenticado().empresaSeleccionada.id, getUsuarioAutenticado().numeroDocumentoIdentidad);
			let listaConsultasId = [];
			if (consultas.length > 0) {
				consultas.map((consulta) => {
					listaConsultasId.push(consulta.id);
				})
				const respuestasXConsulta = await PortalColaboradorService.listaNumeroRespuestas(listaConsultasId, "respuestaUsuario");
				let totalRespuestas = 0;
				respuestasXConsulta.length > 0 && respuestasXConsulta.map((respuesta) => {
					respuesta.numeroRespuestas > 0 && totalRespuestas++;
				})
				setTotalRespuestasUsuario(totalRespuestas);
			}
	}
  
	function onClickVerDocumentoPendiente(documento) {
		history.push(
			`/bandejaEntrada/detalle/${documento.id}&${
				getUsuarioAutenticado().numeroDocumentoIdentidad
			}&${documento.visualizado}&${
				getUsuarioAutenticado().empresaSeleccionada.id
			}&${documento.estadoProcesoId}`
		);
	}

	async function onClickVerDocumentoVisualizado(documento) {
		history.push(
			`/bandejaEntrada/detalle/${documento.id}&${
				getUsuarioAutenticado().numeroDocumentoIdentidad
			}&${documento.visualizado}&${
				getUsuarioAutenticado().empresaSeleccionada.id
			}&${documento.estadoProcesoId}`
		);
	}

	function ListaDocumentos() {
		return (
			<React.Fragment>
				<ListaDocumentosPendientes
					documentosPendientes={documentosPendientes}
					onClick={onClickVerDocumentoPendiente}
				/>

				<ListaHistorialDocumentos
					documentos={documentosVisualizados}
					onClick={onClickVerDocumentoVisualizado}
				/>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<HeaderMovil>
				<img
					src={logotipo}
					alt=""
					style={{ height: "36px", marginRight: "10px" }}
				/>
				Mis Documentos
			</HeaderMovil>
			{totalRespuestasUsuario > 0 &&
				(<Alert message="Estimado Colaborador, tiene notificaciones pendientes de revisar." type="info" showIcon />)
			}
			<div className="bandeja-entrada__container-documentos">
				{!loading && <ListaDocumentos />}
				{loading && <LoadingDocumentos items={5} />}
			</div>
		</React.Fragment>
	);
}
