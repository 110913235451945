import React from "react";
import { useMediaQuery } from "react-responsive";
import { MisDocumentosContextProvider } from "../../../context/MisDocumentos/MisDocumentosContextProvider";
import { BandejaConsultaWeb } from "./componentes/BandejaConsultaWeb";
import { BandejaConsultaMovil } from "./componentes/BandejaConsultaMovil";

const ListarBandejaConsultaPage = () => {
    const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
    const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });
    return (
        <MisDocumentosContextProvider>
            {esMovil && <BandejaConsultaMovil/>}
            {esDesktop && <BandejaConsultaWeb />}
            
        </MisDocumentosContextProvider>
        
    )
}
export default ListarBandejaConsultaPage;