import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TareasPendientesService } from 'services';
import { Provider as JotaiProvider } from "jotai";
import { Button, Form, Input, Modal, Space, notification } from 'antd';
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { HeaderMovil, SubirDocumento, VisorFormulario } from 'components/common';
import styled from '@emotion/styled';
import { TipoTarea } from 'enum';

const DetalleTareaPendientePageMovil = () => {

    const { tareaPendienteId } = useParams();
    const [tareaPendienteSeleccionda, setTareaPendienteSeleccionada] = useState(undefined);
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            const respuesta = await TareasPendientesService.buscarPorId(tareaPendienteId);
            setTareaPendienteSeleccionada(respuesta);
        }
        fetchData();
    }, [])

    const onClickBtnRetornar = () => {
        history.push("/tareas-pendientes");
    }

    return (
        <JotaiProvider>
            <Container>
                <HeaderMovil retornar={true} onClickBtnRetornar={onClickBtnRetornar} />
                <VisorAprobarDocumento tareaPendienteSeleccionada={tareaPendienteSeleccionda} />
                <CargarDocumento tareaPendienteSeleccionada={tareaPendienteSeleccionda} />
            </Container>
        </JotaiProvider>
    );
};

const CargarDocumento = ({ tareaPendienteSeleccionada }) => {

    const history = useHistory();

    if (!tareaPendienteSeleccionada || tareaPendienteSeleccionada.tipo !== TipoTarea.CARGA_DOCUMENTO) {
        return "";
    }

    const onSuccess = () => {
        history.push("/tareas-pendientes");
    };

    return (
        <div style={{ height: "calc(100% - 60px)" }}>
            <SubirDocumento
                tareaSeleccionada={tareaPendienteSeleccionada}
                onSuccess={onSuccess}
            />
        </div>
    )
}

const VisorAprobarDocumento = ({ tareaPendienteSeleccionada }) => {
    const [loadingPdf, setLoadingPdf] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();


    const onClickBtnAprobar = () => {
        Modal.confirm({
            title: '¿Está seguro de aprobar el documento?',
            icon: <ExclamationCircleOutlined />,
            okText: "Si, Aprobar!",
            cancelButtonProps: { disabled: loading },
            onOk: async () => {

                setLoading(true);

                await TareasPendientesService.aprobarDocumento({
                    tareaId: tareaPendienteSeleccionada.id,
                    detalleEnvioId: tareaPendienteSeleccionada.procesoId,
                });

                history.push("/tareas-pendientes");

            },
        });
    }

    const onClickBtnRechazar = () => {
        Modal.confirm({
            title: '¿Está seguro de rechazar el documento?',
            icon: <ExclamationCircleOutlined />,
            content:
                <Form form={form} layout="vertical">
                    <Form.Item name="motivo" label="Ingrese un motivo" rules={[{ required: true, message: "Ingrese un motivo." }]}>
                        <Input.TextArea />
                    </Form.Item>
                </Form>,
            okText: "Si, Rechazar!",
            cancelButtonProps: { disabled: loading },
            onOk: async () => {

                return form.validateFields()
                    .then(async (datosFormulario) => {
                        setLoading(true);

                        await TareasPendientesService.rechazarDocumento({
                            tareaId: tareaPendienteSeleccionada.id,
                            detalleEnvioId: tareaPendienteSeleccionada.procesoId,
                            ...datosFormulario
                        });

                        history.push("/tareas-pendientes");

                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                        return new Promise((resolve, reject) => reject());
                    });



            },
        });
    }

    const onFinishLoadPdf = (loading) => {
        setLoadingPdf(loading);
    }

    if (!tareaPendienteSeleccionada || tareaPendienteSeleccionada.tipo !== TipoTarea.APROBACION) {
        return "";
    }

    return (
        <>
            <Body withHeader={tareaPendienteSeleccionada ? true : false}>
                <VisorFormulario
                    type="pdf"
                    urlPdf={tareaPendienteSeleccionada?.urlPlantillaPdfFirmada}
                    componentes={tareaPendienteSeleccionada?.componentes}
                    setLoadingPdf={onFinishLoadPdf}
                />
            </Body>
            <Footer>
                <Space>
                    <Button type="primary" onClick={onClickBtnAprobar} disabled={loadingPdf}>
                        Aprobar
                    </Button>
                    <Button type="danger" onClick={onClickBtnRechazar} disabled={loadingPdf}>
                        Rechazar
                    </Button>
                </Space>
            </Footer>
        </>
    )
}

const Container = styled.div`
    min-height: 100%;
    height: 100%;
    position: relative;
    background-color: white;
`

const Footer = styled.div`
    min-height: 60px;
	max-height: 60px;
    display: flex;
    justify-content: center;
    border-top: 1px solid #ccc;
`

const Body = styled.div`
	height: ${(props) => (props.withHeader === true ? "calc(100% - 120px)" : "100%")};
`;

export default DetalleTareaPendientePageMovil;