import React from "react";
import logotipo from "../../../../../../assets/imagenes/logotipo.png";

const Header = () => {
	return (
		<div className="container-movil-pdf-header">
			<div style={{ width: "60%" }}>
				<img src={logotipo} alt="" style={{ height: "36px", marginRight: "10px" }} />
				Entrevistas
			</div>
		</div>
	);
};
export default Header;
