import React from "react";
import { useListarSolicitudesAusencia } from "pages/vacaciones/componentes/common/mis-solicitudes/hooks";
import { ItemDocumento } from "./ItemDocumento";
import { LoadingDocumentos } from "components/LoadingDocumentos";

const ListaSolicitudesAusencias = () => {
	const { loading, solicitudesAusencia } = useListarSolicitudesAusencia();

	return (
		<div className="bandeja-entrada__container-documentos">
			{loading && <LoadingDocumentos items={5} />}
			{!loading && <MisSolicitudes solicitudes={solicitudesAusencia} />}
		</div>
	);
};

const MisSolicitudes = ({ solicitudes }) => {
	const items = solicitudes.map((solicitud, index) => <ItemDocumento key={index} solicitud={solicitud} />);
	return items;
};

export default ListaSolicitudesAusencias;
