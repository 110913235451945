import React, { useContext, useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import { MisDocumentosContext } from "context/v3/MisDocumentosContext";
//import { documentoSeleccionadoAtom, documentosAtom, rutaSubirDocumentoAtom } from "pages/onboarding/OnboardingStore";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import { Button, Modal, Upload, notification } from "antd";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { PlusOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { Headers } from "enum";
import produce from "immer";
import LoginService from "services/LoginService";
import { ModalVisorFilePDF } from "components/ModalVisorFilePDF";
import { EstadoAprobacion } from "enum/EstadoAprobacion";

const SubirWord = ({onCerrarModal}) => {
	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	//const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
	const { documentoSeleccionadoId, getDocumentos } = useContext(MisDocumentosContext);
	//const setDocumentos = useSetAtom(documentosAtom);
	//const setRutaSubirDocumentos = useSetAtom(rutaSubirDocumentoAtom);
	const [uid, setUid] = useState("");
	const [previewOpen, setPreviewOpen] = useState(false);
	const [file, setFile] = useState(undefined);

	const props = {
		name: "file",
		multiple: true,
		action: process.env.REACT_APP_ENDPOINT + "portal-colaborador/cargarDocumentos",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
			[Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
		},
		data: {
			detalleEnvioId: documentoSeleccionadoId,
			uid: uid,
		},
		beforeUpload(file) {
			setUid(file.uid);
			return file;
		},
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
	};

	const onChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

	const onClickCargarDocumento = async () => {
		setUploading(true);
		const datosSubida = {
			listaUids: fileList.map((c) => c.uid),
			//detalleEnvioId: documentoSeleccionado.id,
			detalleEnvioId: documentoSeleccionadoId,
			tipoCarga: "WORD",
		};
		const data = await PortalColaboradorService.generarPDFArchivosSubidos(datosSubida);
		if (data === true) {
			// setDocumentoSeleccionado({
			// 	...documentoSeleccionado,
			// 	estadoVistaColaborador: EstadoVistaColaborador.FIRMADO,
			// 	estadoAprobacion: EstadoAprobacion.PENDIENTE
			// });

			// setDocumentos(
			// 	produce((draft) => {
			// 		const documento = draft.find((documento) => documento.id === documentoSeleccionado.id);
			// 		documento.estadoVistaColaborador = EstadoVistaColaborador.FIRMADO;
			// 		documento.estadoAprobacion = EstadoAprobacion.PENDIENTE;
			// 	})
			// );

			notification.success({
				description: "Documento Cargado",
				message: "Documento Cargado Correctamente",
			});

			getDocumentos();
			onCerrarModal();
			//setRutaSubirDocumentos("home");
		} else {
			notification.error({
				description: "Error al cargar",
				message: "Ha ocurrido un error",
			});
		}
		setUploading(false);
	};

	return (
		// <div style={{ width: "calc(100% - 150px)" }}>
			<div>
				<Upload.Dragger
					{...props}
					listType="picture"
					onChange={onChange}
					fileList={fileList}
					maxCount={1}
					accept=".doc, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				>
					<p className="ant-upload-drag-icon">
						<PlusOutlined />
					</p>
					<p>Seleccionar Archivo WORD</p>
				</Upload.Dragger>
				
					<Button
						type="primary"
						onClick={onClickCargarDocumento}
						disabled={fileList.length === 0}
						loading={uploading}
						block
						style={{
							marginTop: 16,
						}}
					>
						{uploading ? "Cargando" : "Enviar Documentos"}
					</Button>
			</div>

		// </div>
	);
};

export default SubirWord;
