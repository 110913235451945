import create from 'zustand'

const useModalAsignarCarpeta = create((set, get) => ({
    loading: false,
    visible: false,
    mostrarModal: () => {
        set({ visible: true });
    },
    cerrarModal: () => {
        set({ visible: false });
    }
}));
export default useModalAsignarCarpeta;