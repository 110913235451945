import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { BotonesMisSolicitudes, ListaSolicitudesAusencias, ModalRecordVacacional } from "./componentes";
import {
	ModalCrearSolicitudAusencia,
	ModalFirmarSolicitudAusencia,
} from "pages/vacaciones/componentes/common/mis-solicitudes";

const MisSolicitudesPage = () => {
	return (
		<JotaiProvider>
			<BotonesMisSolicitudes />
			<ListaSolicitudesAusencias />
			<ModalCrearSolicitudAusencia />
			<ModalFirmarSolicitudAusencia />
			<ModalRecordVacacional />
		</JotaiProvider>
	);
};

export default MisSolicitudesPage;
