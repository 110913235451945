import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { ArrowLeftOutlined, CheckCircleOutlined, Loading3QuartersOutlined, DownloadOutlined } from "@ant-design/icons";
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";
import { SecurityContext } from "context/SecurityContextProvider";
import { Document, Page } from "react-pdf";
import { Button, Tag } from "antd";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import { LogVisorPdfService } from "services/LogVisorPdfService";
import * as atoms from "../VisorStorage";
import { useAtom } from "jotai";
import { ConsultaService } from "services/ConsultaService";
import { EstadoConsulta } from "enum/EstadoConsulta";
import {
	osVersion,
	osName,
	fullBrowserVersion,
	browserVersion,
	browserName,
	mobileVendor,
	mobileModel,
	engineName,
	engineVersion,
} from "react-device-detect";
import { Plataforma } from "enum/Plataforma";
import { saveAs } from "file-saver";
import { PortalColaboradorService } from "services/PortalColaboradorService";

const Body = () => {
	let history = useHistory();
	let { documentoId } = useParams();
	let words = documentoId.split("__");
	if (words) {
		documentoId = words[0];
	}

	const [totalPaginas, setTotalPaginas] = useState(undefined);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const {
		loadingPdf,
		reloadPdf,
		setReloadPdf,
		blobPdf,
		documentoSeleccionado,
		obtenerDocumento,
		mostrarModalFirmar,
		urlPdf,
		getUrlPdf,
		setBlobPdf,
		iniciarCargaPdf,
		finalizarCargaPdf,
		mostrarModalConsultar,
	} = useContext(MisDocumentosContext);
	const documentWrapperRef = useRef(null);
	const [documento, setDocumento] = useState(undefined);
	const [botonDescargarHabilitado, setBotonDescargarHabilitado] = useState(false);
	const [scale, setScale] = useState(1);
	const [zoomIn, setZoomIn] = useState(false);

	const [, setRespuestaConsultaAbierta] = useAtom(atoms.respuestaConsultaAbiertaAtom);
	const [, setDetallesConsulta] = useAtom(atoms.detallesConsultaAtom);
	const [, setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);
	useEffect(() => {
		const fetchData = async () => {
			await cargarPdf();
			await obtenerDocumento(getUsuarioAutenticado().empresaSeleccionada.id, documentoId);
		};
		fetchData();
	}, []);

	useEffect(() => {
		if (reloadPdf === true) {
			cargarPdf();
		}

		return () => {
			setReloadPdf(undefined);
		};
	}, [reloadPdf]);

	const cargarPdf = async () => {
		iniciarCargaPdf();
		await getUrlPdf(getUsuarioAutenticado().empresaSeleccionada.id, documentoId, getPlataforma());
	};

	useEffect(() => {
		if (documentoSeleccionado != undefined)
			setBotonDescargarHabilitado(
				documentoSeleccionado?.estadoVistaColaborador !== EstadoVistaColaborador.PENDIENTE
			);
	}, [documentoSeleccionado]);

	const getPlataforma = () => {
		let plataforma = localStorage.getItem("plataforma");
		if (plataforma === undefined || plataforma === "" || plataforma === null) {
			plataforma = Plataforma.MOVIL_WEB;
		}
		return plataforma;
	};

	const onLoadSuccess = async (successEvent) => {
		const _data = await successEvent.getData();
		const _blob = new Blob([_data], { type: "application/pdf" });
		setBlobPdf(_blob);
		setTotalPaginas(successEvent.numPages);
		finalizarCargaPdf();

		if (
			documentoSeleccionado.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE &&
			documentoSeleccionado.mostrarVentanaFirmaAntesDeVisualizarDocumento
		) {
			mostrarModalFirmar();
		}
	};

	const onLoadError = (error) => {
		try {
			const log = {
				organizacionId: getUsuarioAutenticado().organizacionId,
				empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
				colaboradorId: getUsuarioAutenticado().id,
				colaboradorNumeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
				colaboradorNombreCompleto: getUsuarioAutenticado().nombreCompleto,
				detalleEnvioId: documentoId,
				sistemaOperativoVersion: osVersion,
				sistemaOperativoNombre: osName,
				navegadorWebVersion: browserVersion,
				navegadorWebVersionCompleta: fullBrowserVersion,
				navegadorWebNombre: browserName,
				movilFabricante: mobileVendor,
				movilModelo: mobileModel,
				engineVersion: engineVersion,
				engineName: engineName,
				error: error?.message,
			};
			LogVisorPdfService.insertar(log);
		} catch (error) {
			console.error("Se produjero error al enviar el log", error);
		} finally {
			finalizarCargaPdf();
		}
	};

	const onClickRetornar = () => {
		console.log("onClickRetornar::", documentoId);
		//let words = documentoId.split("__");
		//console.log("words::", words);
		if (words && words.length == 2) {
			history.push("/" + words[1] + "/mis-documentos");
		} else {
			history.push("/mis-documentos");
		}

	};

	const onClickZoomPdf = () => {
		setZoomIn(!zoomIn);
		zoomIn ? setScale(1) : setScale(2);
	};

	const onClickBtnConsultar = async () => {
		mostrarModalConsultar();
		const consultas = await ConsultaService.buscarConsultaPorDocumento(
			documentoSeleccionado.id,
			EstadoConsulta.ABIERTO
		);
		setRespuestaConsultaAbierta(consultas);
		if (consultas.length > 0) {
			const _detallesconsulta = await PortalColaboradorService.listaDetalleConsultas(consultas[0].id);
			setDetallesConsulta(_detallesconsulta);
			setConsultaSeleccionada(consultas[0]);
			const consultaEnviada = {
				consultaColaboradorId: consultas[0].id,
				tipoDetalle: "respuestaUsuario",
			};
			await PortalColaboradorService.cambiarEstadoVisualizacionDetalleConsulta(consultaEnviada);
		}
	};

	const onClickBtnDescargar = async () => {
		const plataforma = localStorage.getItem("plataforma");

		if (plataforma === Plataforma.MOVIL_IOS || plataforma === Plataforma.MOVIL_ANDROID) {
			const respuesta = await PortalColaboradorService.obtenerUrlDescargaMovil(
				getUsuarioAutenticado().empresaSeleccionada.id,
				documentoId
			);

			window.location.href = `${respuesta.urlDocumento}`;
		} else {
			const nombreDocumento = documentoSeleccionado.nombreDocumento.replace(" ", "-");
			const documentoIdentidad = getUsuarioAutenticado().numeroDocumentoIdentidad;
			const nombreDescarga = nombreDocumento + "_" + documentoIdentidad + ".pdf";

			saveAs(blobPdf, nombreDescarga);
		}
	};

	return (
		<div ref={documentWrapperRef} className="new-container-movil">
			<div className="container-movil-pdf-header">
				<div style={{ width: "100%" }} onClick={onClickRetornar}>
					<ArrowLeftOutlined style={{ fontSize: "20px", marginRight: "8px" }} />
					Retornar
				</div>
				{botonDescargarHabilitado && !documentoSeleccionado?.ocultarBotonDescarga && (
					<div
						style={{
							width: "100%",
							textAlign: "right",
							paddingRight: "10px",
						}}
					>
						<Button style={{ color: "#2b81de" }} icon={<DownloadOutlined />} onClick={onClickBtnDescargar}>
							Descargar
						</Button>
					</div>
				)}
				{!botonDescargarHabilitado && !documentoSeleccionado?.ocultarBotonConsultarRecursosHumanos && (
					<div style={{ width: "100%", textAlign: "right", paddingRight: "10px" }}>
						<Button type="primary" onClick={onClickBtnConsultar}>
							Consultar
						</Button>
					</div>
				)}
				{/* {!botonDescargarHabilitado && (
					<div style={{ width: "100%", textAlign: "right", paddingRight: "10px" }}>
						<Button type="primary" onClick={onClickBtnConsultar}>
							Consultar
						</Button>
					</div>
				)} */}
			</div>

			<div className="new-container-movil-body">
				{loadingPdf && (
					<div className="contianer-loading-movil-body">
						<Loading3QuartersOutlined spin className="new-container-movil-pdf-loading" />
					</div>
				)}

				<div className="new-container-movil-pdf-body" onClick={onClickZoomPdf}>
					{urlPdf && (
						<Document file={urlPdf} onLoadSuccess={onLoadSuccess} onLoadError={onLoadError} loading={null}>
							{Array.from(new Array(totalPaginas), (el, index) => (
								<Page
									width={documentWrapperRef.current?.getBoundingClientRect().width || undefined}
									key={`page_${index + 1}`}
									pageNumber={index + 1}
									loading={""}
									scale={scale}
								/>
							))}
						</Document>
					)}
				</div>
			</div>
			<div className="container-movil-pdf-footer">
				{documentoSeleccionado?.estadoVistaColaborador === EstadoVistaColaborador.PENDIENTE && (
					<Button type="primary" block disabled={loadingPdf} onClick={mostrarModalFirmar}>
						Firmar Documento
					</Button>
				)}

				{documentoSeleccionado?.estadoVistaColaborador !== EstadoVistaColaborador.PENDIENTE && (
					<React.Fragment>
						<span style={{ display: "block", with: "100%" }}>{documentoSeleccionado?.nombreDocumento}</span>
						<span style={{ display: "block", with: "100%" }}>
							<Tag color="green" icon={<CheckCircleOutlined />}>
								{documentoSeleccionado?.estadoVistaColaborador}
							</Tag>
						</span>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

export default Body;
