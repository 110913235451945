import { useAtom } from "jotai";
import React, { useState } from "react";
import { Page } from "react-pdf";
import { zoomVisorFormularioAtom } from "../../MisDocumentosMovilStorage";
import { ControlPdf } from "./ControlPdf";
import { SeparadorPaginaPdf } from "./SeparadorPaginaPdf";

const PaginaPdf = ({ refContainer, numeroPagina, componentes, variablesFormulario, datosFormulario, urlFirmaHolografa, urlHuellaDactilar }) => {
	// const [zoom, setZoom] = useState(1);
	const [zoom] = useAtom(zoomVisorFormularioAtom);
	return (
		<React.Fragment>
			<div
				style={{
					position: "relative",
					// border: "2px solid red",
				}}
			>
				<Page
					// width={refContainer.current?.getBoundingClientRect().width || undefined}
					pageNumber={numeroPagina}
					loading={null}
					scale={zoom}
				/>
				<div id="componentes" style={{ zoom: zoom }}>
					{componentes?.length > 0 &&
						componentes
							.filter((componente) => componente.overlay.page === numeroPagina)
							.map((componente) => {
								return (
									<ControlPdf
										key={componente.key}
										componente={componente}
										variables={variablesFormulario}
										datosFormulario={datosFormulario}
										urlFirmaHolografa={urlFirmaHolografa}
										urlHuellaDactilar={urlHuellaDactilar}
									/>
								);
							})}
				</div>
			</div>
			<SeparadorPaginaPdf style={{ zoom: zoom }} />
		</React.Fragment>
	);
};

export default PaginaPdf;
