import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { usePlanAccion, useResultadoEvaluacion } from "../../hooks";
import { EstadoPlanAccionEvaluacion } from "enum/EstadoPlanAccionEvaluacion";
const PlanAccion = () => {
	const { frmPlanAccion } = usePlanAccion();
	const { aptoConObservacionesStyle } = useResultadoEvaluacion();

	useEffect(() => {
		frmPlanAccion.validateFields();
	}, [aptoConObservacionesStyle]);

	return (
		<Form form={frmPlanAccion} style={{ marginBottom: "20px" }}>
			<div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px" }}>PLAN DE ACCIÓN</div>
			<Form.List name="planAccion">
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, ...restField }) => (
							<div key={key} style={{ width: "100%", display: "flex" }}>
								<Form.Item
									{...restField}
									name={[name, "estado"]}
									noStyle
									>
									<Input  type="hidden" defaultValue={ EstadoPlanAccionEvaluacion.PENDIENTE} />
								</Form.Item>
								<div style={{ width: "70%", paddingRight: "10px" }}>
									<Form.Item
										{...restField}
										name={[name, "descripcion"]}
										rules={[
											{
												required: true,
												message: "Descripción es requerido",
											},
										]}
										style={{ marginBottom: "5px" }}
									>
										<Input placeholder="Descripción" />
									</Form.Item>
								</div>
								<div style={{ width: "25%", paddingRight: "10px" }}>
									<Form.Item
										{...restField}
										name={[name, "fecha"]}
										rules={[
											{
												required: true,
												message: "Fecha es requerido",
											},
										]}
										style={{ marginBottom: "5px" }}
									>
										<DatePicker
											style={{
												width: "100%",
											}}
											format="DD/MM/YYYY"
										/>
									</Form.Item>
								</div>
								<div style={{ width: "5%" }}>
									<MinusCircleOutlined onClick={() => remove(name)} />
								</div>
							</div>
						))}
						<Form.Item name={"opcion"}>
							<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
								Adicionar Plan
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		</Form>
	);
};

export default PlanAccion;
