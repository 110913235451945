import React, { useEffect, useState } from "react";
import { Button, Card, Row, Space, Tag, Alert, Tooltip } from "antd";
import {
	FileImageOutlined,
	FilePdfOutlined,
	LeftOutlined,
	FileWordOutlined
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { SubirImagen, SubirPdf, SubirWord } from "./componentes";
import {
	documentoSeleccionadoAtom,
	rutaSubirDocumentoAtom,
	visibleModalOmitirAtom,
} from "pages/onboarding/OnboardingStore";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { saveAs } from "file-saver";
import { CategoriaDocumento } from "enum/CategoriaDocumento";
import LoginService from "services/LoginService";

const SubirDocumentos = () => {

	const isLaredo = LoginService.getUsuarioAutenticado().empresaSeleccionada?.ruc === "20132377783" ? true : false;

	const [rutaSubirDocumentos, setRutaSubirDocumentos] = useAtom(
		rutaSubirDocumentoAtom
	);
	const documentoSeleccionado = useAtomValue(documentoSeleccionadoAtom);
	const [urlPlantilla, setUrlPlantilla] = useState("");
	const setVisibleModalOmitir = useSetAtom(visibleModalOmitirAtom);
	//const [habilitarCarga, setHabilitarCarga] = useState(habilitarCargaAtom);
	const [habilitarCarga, setHabilitarCarga] = useState(true);
	const [motivoRechazo, setMotivoRechazo] = useState(undefined);

	const onClickSeleccionarImagen = () => {
		setRutaSubirDocumentos("subir-imagen");
	};

	const onClickSeleccionarPdf = () => {
		setRutaSubirDocumentos("subir-pdf");
	};

	const onClickSeleccionarWord = () => {
		setRutaSubirDocumentos("subir-word");
	};

	const onClicBtnRetornar = () => {
		setRutaSubirDocumentos("home");
	};

	useEffect(() => {

		const fetchData = async () => {
			if (documentoSeleccionado != null) {
				const response = await PortalColaboradorService.obtenerDatosFormulario(documentoSeleccionado.formularioId, documentoSeleccionado.id);
				setMotivoRechazo(response.motivoRechazo);

				if (
					documentoSeleccionado.categoriaDocumentoId ===
					CategoriaDocumento.CARGA_DOCUMENTO
				) {
					const respuesta =
						await PortalColaboradorService.obtenerFormatoCarga(
							documentoSeleccionado?.tipoDocumentoId
						);
					setUrlPlantilla(respuesta.urlDocumento);
				}
			}

			if (documentoSeleccionado?.mostrarlink) {
				setHabilitarCarga(false);
			} else {
				setHabilitarCarga(true);
			}
		}

		fetchData();

	}, [documentoSeleccionado]);

	const onClickDescargarPlantilla = () => {
		saveAs(
			urlPlantilla,
			"Formato Carga - " + documentoSeleccionado?.nombreDocumento + ".pdf"
		);
	};

	const onClickBtnOmitir = () => {
		setVisibleModalOmitir(true);
	};

	const onClickBtnLink = () => {
		setHabilitarCarga(true);
	};


	return (
		<Container>
			<Header>
				{/* <Row style={{ width: "100%" }} justify={"space-between"}> */}
				<Row style={{ width: "100%" }}>
					<div>
						{rutaSubirDocumentos !== "home" && (
							<Button type="link" onClick={onClicBtnRetornar}>
								<LeftOutlined />
								Retornar
							</Button>
						)}
					</div>

					<div>
						<Space>
							{documentoSeleccionado?.opcional && (
								<Button
									onClick={onClickBtnOmitir}
									type="danger"
								>
									Omitir
								</Button>
							)}
							{urlPlantilla !== undefined &&
								urlPlantilla !== null &&
								urlPlantilla !== "" && (
									<Button
										type="primary"
										onClick={() =>
											onClickDescargarPlantilla()
										}
									>
										Descargar Plantilla
									</Button>
								)}
						</Space>
					</div>
					<div>
						<Space>
							{!habilitarCarga && documentoSeleccionado?.mostrarlink && (
								<div>
									<Tooltip defaultOpen="true" title="Para poder cargar sus documentos debe de ver el siguiente tutorial" color="gold" key="gold">
										<Button
											onClick={onClickBtnLink}
											type="link"
											href={documentoSeleccionado?.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											Ver Tutorial
										</Button>
									</Tooltip>
								</div>

							)}
						</Space>
					</div>
				</Row>

			</Header>
			{motivoRechazo &&
				<Alert message={"Observación: " + motivoRechazo} type="warning" showIcon />
			}
			<Body>
				<div style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "100%",
					overflow: "auto"
				}}>
					{habilitarCarga && rutaSubirDocumentos === "home" && (
						<React.Fragment>
							{(!documentoSeleccionado.requiereFormatoCarga
								|| (documentoSeleccionado.requiereFormatoCarga
									&& documentoSeleccionado.formatosCarga.includes("imagen")))
								&& <Card
									hoverable
									onClick={onClickSeleccionarImagen}
									style={{
										width: "calc(100% - 100px)",
										height: "150px",
										borderRadius: "5px",
										marginBottom: "20px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<ContainerItem>
										<FileImageOutlined
											style={{
												fontSize: "40px",
												color: "#8e9aaf",
											}}
										/>
										<span
											style={{
												fontSize: "16px",
												marginTop: "10px",
												color: "#8e9aaf",
											}}
										>
											Seleccionar Imagen
										</span>
									</ContainerItem>
								</Card>
							}

							{(!documentoSeleccionado.requiereFormatoCarga
								|| (documentoSeleccionado.requiereFormatoCarga
									&& documentoSeleccionado.formatosCarga.includes("pdf")))
								&& <Card
									hoverable
									onClick={onClickSeleccionarPdf}
									style={{
										width: "calc(100% - 100px)",
										height: "150px",
										borderRadius: "5px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<ContainerItem>
										<FilePdfOutlined
											style={{
												fontSize: "40px",
												color: "#8e9aaf",
											}}
										/>
										<span
											style={{
												fontSize: "16px",
												marginTop: "10px",
												color: "#8e9aaf",
											}}
										>
											Seleccionar PDF
										</span>
									</ContainerItem>
								</Card>
							}

							{documentoSeleccionado.requiereFormatoCarga
								&& documentoSeleccionado.formatosCarga.includes("word")
								&& <Card
									hoverable
									onClick={onClickSeleccionarWord}
									style={{
										width: "calc(100% - 100px)",
										height: "150px",
										borderRadius: "5px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										marginTop: "20px"
									}}
								>
									<ContainerItem>
										<FileWordOutlined
											style={{
												fontSize: "40px",
												color: "#8e9aaf",
											}}
										/>
										<span
											style={{
												fontSize: "16px",
												marginTop: "10px",
												color: "#8e9aaf",
											}}
										>
											Seleccionar Word
										</span>
									</ContainerItem>
								</Card>
							}
						</React.Fragment>
					)}
					{rutaSubirDocumentos === "subir-imagen" && <SubirImagen />}
					{rutaSubirDocumentos === "subir-pdf" && <SubirPdf />}
					{rutaSubirDocumentos === "subir-word" && <SubirWord />}
				</div>
			</Body>
		</Container >
	);
};

const Container = styled.div`
	height: calc(100% - 60px);
	width: "100%";
`;

const Body = styled.div`
	border: 1px solid #ccc;
	height: 100%;
	width: 100%;
	#position: relative;
	padding: 10px 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Header = styled.div`
	min-height: 50px;
	max-height: 50px;
	display: flex;
	background: white;
	padding: 0px 10px;
	border: 1px solid #ccc;
	border-bottom: 0px;
	align-items: center;
`;

const ContainerItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export default SubirDocumentos;
