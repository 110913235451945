import React, { useContext } from "react";
import { Button, Col, Input, Modal, Row, Space, Form, Typography, notification,Comment,List,Checkbox } from "antd"
import { ExclamationCircleOutlined,UserOutlined } from "@ant-design/icons"
import { MisDocumentosContext } from "context/MisDocumentos/MisDocumentosContext";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { DetalleConsultaService } from "services/DetalleConsultaService";
import { ConsultaService } from "services/ConsultaService";
import { useState } from "react";
import { TipoConsulta } from "enum/TipoConsulta";
import { EstadoConsulta } from "enum/EstadoConsulta";
import { useAtom } from "jotai";
import * as atoms from "../BandejaEntregaDetalleStorage";

export function ModalConsultar({documentoSeleccionado, visibleModalConsultar, setVisibleConsultar  }) {
const { Text } = Typography;
    const { TextArea } = Input;
    const [loading, setLoading] = useState(false);    
    const [respuestaConsultaAbierta, setRespuestaConsultaAbierta] = useAtom(atoms.respuestaConsultaAbiertaAtom);    
	const [detallesConsulta, setDetallesConsulta] = useAtom(atoms.detallesConsultaAtom);
    const [consultaSeleccionada, setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);   
    const [comentario, setComentario] = useState("");
    const [consultaResuelta, setConsultaResuelta] = useState(false);
    const [formComentario] = Form.useForm();
    
    async function onClickCerrarModal() {
        setVisibleConsultar(false);
        if (consultaResuelta) {
            await ConsultaService.cambiarEstadoConsulta(consultaSeleccionada.id);
            
        }
    }

    async function handleOnclickAceptarConsultar(formulario) {
        try {           
            setLoading(true);
           formulario.tipoConsulta = TipoConsulta.POR_DOCUMENTO;
			let data = await PortalColaboradorService.consultarRRHH(formulario);
			notification.success({
				message: "Operación exitosa",
				description: "Se envío su consulta correctamente.",
			});

			setLoading(false);
			onClickCerrarModal();
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
    }
    
    const handleOnChangeComentario = (e) => {       
        setComentario(e.target.value);
    }
    const handleOnClickAgregar = async () => {        
    
        if (comentario.trim()) {
            try {

                setLoading(true);  
                const detalleConsultaAgregada =
                {
                    consultaColaboradorId:consultaSeleccionada.id,
                    nombreCompletoColaborador:consultaSeleccionada.nombreCompletoColaborador,
                    mensaje:comentario.trim(),		
                }
                await  DetalleConsultaService.insertar(detalleConsultaAgregada);
                detalleConsultaAgregada.fechaRegistroMensaje=new Date();
                setDetallesConsulta([...detallesConsulta, detalleConsultaAgregada]);
                formComentario.setFieldsValue({ valor: "" });
                setComentario("");
                setLoading(false);

            } catch (error) {
                setLoading(false);
                console.log(error);
            }                    
            
        }
        
    }
  const onChangeCerrarConsulta = (e) => {
        setConsultaResuelta(e.target.checked);
        
  }
    return (
            <Modal
                    title={
                        <Space>
                            <ExclamationCircleOutlined
                                style={{ color: "#1678c1" }}
                            />
                            <Text strong>Consultar</Text>
                        </Space>
                    }
                    visible={visibleModalConsultar}
                    onCancel={onClickCerrarModal}
                    footer={null}
                    destroyOnClose={true}
                    
        >
                      {respuestaConsultaAbierta.length > 0 ? (
                                    <React.Fragment>
                                        {detallesConsulta.length > 0 && (
                                            <List
                                                className="comment-list"
                                                itemLayout="horizontal"
                                                dataSource={detallesConsulta}
                                                renderItem={item => (
                                                    <li>
                                                        <Comment
                                                            //actions={item.actions}
                                                            author={item.nombreCompletoColaborador}
                                                            avatar={<UserOutlined style={{ fontSize: '16px', color: '#F5860F' }} />}
                                                            content={item.mensaje}
                                                            datetime={new Date(item.fechaRegistroMensaje).toLocaleString()}
                                                        >
                                                                
                                                            {(item.detalleConsultaRespuesta != null && item.detalleConsultaRespuesta.length > 0) && (
                                                                item.detalleConsultaRespuesta.map((detalleRespuesta) => {
                                                                    return (
                                                                        <Comment
                                                                            //actions={item.actions}
                                                                            author={detalleRespuesta.usuarioNombreCompleto}
                                                                            avatar={<UserOutlined style={{ fontSize: '16px', color: '#099359' }} />}
                                                                            content={detalleRespuesta.respuesta}
                                                                            datetime={new Date(detalleRespuesta.fechaRegistroRespuesta).toLocaleString()}
                                                                        />
                                                                    )
                                                                })
                                                                
                                                            )}
                                                        </Comment>
                                                    </li>
                                                            
                                                )}
                                            />
                                        )
                                        }
                                        {/* {consultaSeleccionada !== undefined && consultaSeleccionada.estadoConsulta === EstadoConsulta.ABIERTO && ( */}
                                                                
                                            <Comment
                                                content={
                                                    <Form form={formComentario}>
                                                        <Form.Item name="valor">
                                                            <TextArea rows={4} onChange={handleOnChangeComentario} value={comentario} />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Button htmlType="submit" onClick={handleOnClickAgregar} loading={loading} type="primary"> Agregar comentario</Button>
                                                        </Form.Item>
                                                        <Checkbox onChange={onChangeCerrarConsulta} >¿Se llegó a solucionar su consulta?</Checkbox>
                                                    </Form>
                                                }
                                            />
                                                                
                                        {/* )}                                         */}
                                    </React.Fragment>
                    ) :
                    (<Form
                        layout="vertical"
                        onFinish={handleOnclickAceptarConsultar}
                    >
                        <Form.Item name="detalleEnvioId" hidden={true} initialValue={documentoSeleccionado}>
                            <Input />
                        </Form.Item>

                        <Row justify="center">
                            <Col span={24}>
                            <Form.Item
                                label="Ingrese"
                                name="consulta"
                                rules={[
                                    {
                                        required: true,
                                        message: "Porfavor ingrese ",
                                    },
                                ]}
                            >
                            
                                    <TextArea /*showCount maxLength={100}*/ size="large" rows={5} />
                                    
                            </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Space>
                                <Col>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading}
                                        >
                                            Aceptar
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item>
                                        <Button
                                            onClick={onClickCerrarModal}
                                        >
                                            {" "}
                                            Cancelar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Space>
                        </Row>
              </Form>
                    )}
            </Modal>
);
}