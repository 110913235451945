import { BaseService } from "./BaseService";

export const DetalleConsultaService = {
    async insertar(detalleConsulta) {
		const respuesta = await BaseService.post(
			"/detalleConsultaColaborador",
			detalleConsulta
		);
		return respuesta.data;
	},
    
};
